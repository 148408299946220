.form-group {

	margin-bottom: _space();

	&-inline {
		margin-bottom: 0;

		@include _breakpoint--mo {
			width: 100%;
			margin-top: _space();

			&:first-of-type {
				margin-top: 0;
			}
		}

		@include _breakpoint--sm {
			margin: {
				left: _space() / 2;
				bottom: 0;
			}

			&:first-of-type {
				margin-left: 0;
			}

			&--long {
				flex-grow: 1;
			}

			&--small {
				width: 200px;
				max-width: 100%;
			}
		}

		&__wrapper {
			display: flex;

			@include _breakpoint--mo {
				flex-wrap: wrap;
			}
		}
	}
}

.copy-text-wrapper {
	position: relative;
}

.btn--copy {
	right: 6px;
	@include _zindex( float );
	@include _center( v );
}

select,
input {
	&.form-control {
		height: 45px;

		@include _breakpoint--md {
			height: 41px;
		}
	}
}

select,
input,
textarea {
	&.form-control {
		color: _text-color( primary );

		&[ disabled ] {
			color: _text-color( secondary );
			background-color: _gray-hex( 'shadow' );
			opacity: .6;
		}
	}
}


.form-control {
	&[ readonly ] {
		color: _text-color( seconodary );
		background-color: _gray-hex();
	}
}

textarea {
	resize: vertical;
}
