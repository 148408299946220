.site-content--forum {
	.feed-icon {
		display: none;
	}
}

.forum-topic-legend {
	&.forum-topic-closed {
		.alert {
			padding: _space();
		}

		p {
			&:first-of-type {
				margin-top: 0;
			}
		}

		& + section {
			margin-top: 0;
		}
	}
}

.forum {
	&-table {
		width: 100%;
		margin: 0;
		border: 0;
		background-color: _gray-hex( 'super-light' );
		font-size: _font-scale( medium );

		&-name {
			float: left;

			a {
				font: {
					size: 3rem;
					weight: 200;
				}
			}
		}

		&-superheader {
			display: inline-block;
			width: 100%;
			margin-top: _double-space();

			.forum-collapsible {
				float: right;
				display: block;
				width: 24px;
				height: 24px;
				background: {
					image: _image( 'collapsing.svg', 'icons' );
					repeat: no-repat;
					size: contain;
				}
				cursor: pointer;

				&.container-collapsed {
					background-image: _image( 'uncollapsing.svg', 'icons' );
				}
			}
		}

		&-wrap {
			&:first-of-type {
				.forum-table-superheader {
					margin-top: _space();
				}
			}
		}

		tbody {
			border: none;
		}

		tr {
			&.even,
			&.odd {
				background-color: _color( white );

				td,
				td.active {
					background-color: _color( white );
				}
			}
		}

		th {
			padding: 1.5rem 1rem;
			background-color: _gray();
			color: _text-color();
			font: {
				size: _font-scale( normal );
				weight: 600;
			}
			text-align: left;

			&.views-field-topic-icon {
				width: 85px;
			}

			img {
				margin-left: .5rem;
			}
		}

		td {
			padding: 1.5rem 1rem;
			border-bottom: 1px solid rgba( 0, 0, 0, .06 );

			&,
			&.active {
				background-color: _color( white );
			}

			&:nth-of-type()

			a {
				color: _text-color( primary );
				text-decoration: underline;
			}
		}
	}

	&-name {
		a {
			font: {
				size: _font-scale( big );
				weight: 500;
			}
		}
	}

	&-description {
		margin-top: 1rem;
	}

	&-details {
		width: 100%;
	}

	&-last-reply {
		min-width: 240px;
		color: _text-color( secondary );

		a {
			color: _text-color( primary );
		}

		a.username {
			color: _color( primary );
		}
	}

	&-list-icon-wrapper {
		span {
			display: block;
			width: 24px;
			height: 24px;
			margin: {
				left: 1rem;
				right: 1rem;
			}
			background: {
				image: _image( 'threads.svg', 'icons' );
				repeat: no-repat;
				size: contain;
			}
			text-indent: -9999px;
		}
	}

	&-tools {
		float: right;
		margin-top: _space();
	}
}

// Search form
#cksource_repo_search_forums_form {
	margin-top: _double-space();
	width: 100%;
	text-align: right;

	input[ type="text" ] {
		display: inline-block;
		@include _breakpoint--sm {
			width: 350px;
		}
	}

	.form-item {
		display: inline-block;
	}

	.form-submit {
		@extend .btn;
		margin-left: 1rem;
		padding: 1.05rem 2rem 1rem;
		vertical-align: top;
	}
}

// Forum threads view styles.
#forum-topic-list {
	margin-top: _space();

	.forum-topic-title {
		font-size: _font-scale( normal );
	}

	.topic-icon {
		display: block;
		width: 65px;
		height: 65px;
		background: {
			image: _image( 'ico-chat-round.svg', 'icons' );
			repeat: no-repat;
			size: contain;
		}
		text-indent: -9999px;
	}
}

#forum-sort {
	float: left;
	margin-top: _space();

	.form-item {
		display: inline-block;
		margin: 0 1rem 0 0;
	}

	.form-submit {
		@extend .btn;
		@extend .btn--small;
		padding: 0.65rem 1.6rem;
		vertical-align: top;
	}
}

// Single thread.
.forum-post {
	position: relative;
	margin-top: _space();
	background-color: _gray-hex( 'super-light' );
	@include _border;
	@include _radius;
	@include _shadow( small );

	&-info {
		display: inline-block;
		width: 100%;
		padding: 1rem;
		text-align: right;
	}

	&-wrapper {
		display: flex;
	}

	&-title {
		font-size: 3rem;

		h1 {
			font-size: 3.2rem;
			line-height: 4rem;
			font-weight: 400;
			font-family: "Nunito Sans",sans-serif;
		}
	}

	&-panel {
		&-sub {
			padding: _space() _space() _double-space();
			font-size: _font-scale( medium );
			flex: 250px 0 0;
		}

		&-main {
			width: calc(100% - 250px);

			.author-signature {
				font-size: _font-scale(medium);
				padding: 1rem;
				background-color: _gray();

				p {
					&:nth-of-type( 2 ) {
						margin-top: 0;
					}

					&:last-of-type {
						margin-bottom: 0;
					}
				}

				.h1 {
					display: none;
				}
			}
		}
	}

	&-content {
		@extend .pretty-text;
		padding: _space();
		padding-left: 0;
	}

	.author-pane {
		&-inner {
			color: _text-color( secondary );
			font-size: _font-scale( small );

			a.username {
				font-size: font-scale( medium );
			}
		}
	}

	&-footer {
		position: absolute;
		bottom: 1rem;
		left: 1rem;
		font-size: _font-scale( medium );
	}
}

#forum-comments {
	.forum-post-title {
		a {
			font-size: 2.4rem;
		}
	}
}

.forum-posted-on {
	display: inline;
	margin-right: _space();
	color: _text-color( secondary );
	font-size: _font-scale( medium );
}

.forum-post-number {
	font-size: _font-scale( medium );
}

#cksource_repo_search_forums_form {
	.form-item {
		margin: 0;
	}
}

#forum-statistics {
	display: none;
}

.page-forum, .node-type-forum {
	.breadcrumbs {
		display: none;
		width: 100%;
		color: _text-color( secondary );
		font-size: _font-scale( medium );
		margin-bottom: $breadcrumb-padding-horizontal;

		@include _breakpoint--sm {
			display: inline-block;
		}

		ul {
			list-style: none;
			margin: 0;
			padding: 0;
		}

		&__item {
			float: left;
			display: block;

			a {
				color: _text-color();
			}

			&:not(.breadcrumbs__item--current) {
				&:after {
					content: $breadcrumb-separator;
					display: inline-block;
					margin: {
						left: 10px;
						right: 10px;
					}
					color: _text-color( hint );
				}
			}
		}

		&__item--current {
			color: _text-color( secondary );
		}
	}
}

#forum-content {
	.row {
		h1 {
			font-size: 3.2rem;
			line-height: 4rem;
			font-weight: 400;
			font-family: "Nunito Sans",sans-serif;
		}
	}

	#forum {
		.forum-tools,
		.forum-list-icon-legend,
		.forum-topic-legend {
			display: none;
		}
	}
}
