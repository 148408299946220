.cla {
	@include _middle-content;
	text-align: inherit;

	&-actions {
		.btn {
			&:first-of-type {
				margin-right: 1rem;
			}
		}
	}

	&__list {
		list-style: none;
		padding-left: 0;

		li {
			list-style: none;
		}
	}

	span {
		&.checked:before,
		&.error:before {
			width: 14px;
			height: 14px;
		}

		&.checked:before {
			content: "\2713";
			color: _color( success );
		}

		&.error:before {
			content: "\0292b";
			color: _color( danger );
		}
	}
}