$button__base-size: 10px;
$button__base-font-size: $base-font-size--rem;
$button__base-change-step: .3rem;
$button__mobile-ratio: 1.2;

$button-sizes: (
	'big': $button__base-size * 1.3,
	'normal': $button__base-size,
	'small': $button__base-size * 0.8,
	'tiny': $button__base-size * 0.6
);

$button-font-sizes: (
	'big': $button__base-font-size,
	'normal': $button__base-font-size - $button__base-change-step,
	'small': $button__base-font-size - ( $button__base-change-step * 1.5 ),
	'tiny': $button__base-font-size - ( $button__base-change-step * 2 )
);

$cta-widths: (
	'super-long': _space() * 12,
	'long': _space() * 9,
	'normal': _space() * 7,
	'short': _space() * 5
);

button {
	background-color: transparent;
}

@mixin _button() {
	display: inline-block;
	text: {
		decoration: none;
		align: center;
	}
	font-weight: 600;
	line-height: 1;
	cursor: pointer;
	user-select: none;
	@include _radius;
	// Prevent transform animation.
	transition:
		color 200ms ease-in-out,
		background-color 200ms ease-in-out,
		border-color 200ms ease-in-out,
		opacity 200ms ease-in-out;

	&:hover,
	&:focus {
		text-decoration: none;
	}

	&.disabled,
	&:disabled,
	&[disabled="true"] {
		opacity: .6;
		cursor: not-allowed;
		pointer-events: none;
	}
}

@mixin _button-reset() {
	background-color: inherit;
	border: 0;
}

@mixin _button-size( $size: normal ) {
	@if ( quote( $size ) != 'big' ) {
		padding: ( map-get( $button-sizes, quote( $size ) ) * $button__mobile-ratio ) ( map-get( $button-sizes, quote( $size ) ) * 2 * $button__mobile-ratio );
		font-size: map-get( $button-font-sizes, quote( $size ) ) * $button__mobile-ratio;
	}
	@else {
		padding: map-get( $button-sizes, quote( $size ) ) map-get( $button-sizes, quote( $size ) ) * 2;
		font-size: map-get( $button-font-sizes, quote( $size ) );
	}

	@include _breakpoint--md {
		padding: map-get( $button-sizes, quote( $size ) ) map-get( $button-sizes, quote( $size ) ) * 2;
		font-size: map-get( $button-font-sizes, quote( $size ) );
	}
}

@mixin _button-color( $inverse: false, $type: primary ) {
	@if ( $inverse != true ) {
		background-color: _color( $type );
		border: 2px solid _color( $type );
		color: _text-color( primary, light );
	}
	@else {
		background-color: _color( white );
		border: 2px solid _color( $type );
		color: _color( $type );
	}

	&:hover,
	&:focus {
		@if ( $inverse != true ) {
			background-color: darken( _color( $type ), 5 );
			border-color: darken( _color( $type ), 5 );
		}
		@else {
			background-color: _color( $type );
		}
		color: _text-color( primary, light );
	}
}

@mixin _cta( $type: normal ) {
	@include _breakpoint--sm {
		min-width: map-get( $cta-widths , $type );
	}
}

.btn {
	@include _button;
	@include _button-color;
	@include _button-size( small );

	@include _breakpoint--sm {
		@include _button-size;
	}

	&--success {
		@include _button-color( false, success );

		&.btn--frame {
			@include _button-color( true, success );
		}
	}

	&--action {
		@include _button-color( false, teal );

		&.btn--frame {
			@include _button-color( true, teal );
		}
	}

	&--info {
		@include _button-color( false, purple );

		&.btn--frame {
			@include _button-color( true, purple );
		}
	}

	&--neutral {
		@include _button-color( false, 'silver-dark' );

		&.btn--frame {
			@include _button-color( true, 'silver-dark' );
			border-width: 1px;
			color: _text-color( primary );
		}
	}

	&--danger {
		@include _button-color( false, danger );

		&.btn--frame {
			@include _button-color( true, danger );
			border-width: 1px;
			color: _text-color( primary );
		}
	}

	&--frame {
		@include _button-color( true );

		&-bright {
			@include _button-color( true, white );
			background-color: transparent;

			&:hover,
			&:focus {
				color: _color( primary );
			}
		}
	}

	&--disabled {
		opacity: .6;
		cursor: not-allowed;
	}

	&--big {
		@include _button_size( big );
	}

	&--small {
		@include _button_size( small );
	}

	&--tiny {
		@include _button_size( tiny );
	}

	&-cta {
		@include _cta;

		&--super-long {
			@include _cta( super-long );
		}

		&--long {
			@include _cta( long );
		}

		&--short {
			@include _cta( short );
		}
	}
}
