.module-testimonials {
	margin-top: _double-space();

	.section-container {
		@include _breakpoint--mo {
			padding: 0;
		}
	}

	&__wrapper {
		@include  _middle-content( wide );
		width: calc( 920px + ( 2 * #{_double-space()} ) );
		position: relative;
		padding: {
			top: _double-space();
			bottom: _space() * 3;
		}

		@include _breakpoint--mo {
			padding-top: 0;
		}
	}

	&__item {
		h3 {
			margin: 0;
		}

		blockquote,
		p.blockquote {
			margin-bottom: _space();
			position: relative;
			padding: _double-space() 0 0;
			font-size: 2.1rem;
			letter-spacing: -0.42px;

			@include _breakpoint--mo {
				margin: 0 0 _space();
				font-style: italic;
			}

			@include _breakpoint--sm {
				margin: 0 _double-space() * 2 _space();
				padding: _space() _double-space() 0;
			}

			@include _breakpoint--sm-plus {
				font-size: 2.4rem;
			}
		}

		blockquote {
			&:before {
				content: "\201C";
				position: absolute;
				top: -27px;
				left: 0;
				font: {
					family: Georgia, serif;
					size: 90px;
					weight: bold;
				}
				color: _color( black, .15 );

				@include _breakpoint--sm {
					left: 20px;
					top: 19px;
					font-size: 60px;
					color: _text-color( hint );
				}
			}
		}
	}

	&__client {
		margin: 0;
		font: {
			size: 1.8rem;
			weight: 800;
		}
		text-transform: uppercase;
	}

	&__company-name {
		margin: 0;
		font: {
			size: _font-scale( medium );
			weight: 600;
		}
	}

	.swiper-pagination {
		bottom: _space();

		&-bullet {
			width: 12px;
			height: 12px;
			margin: 0 8px;
			opacity: 1;
			background-color: _color( 'silver-dark' );
			border: 2px solid _color( 'silver-dark' );

			&-active {
				background-color: _color( white );
			}
		}
	}

	.swiper-button {
		&-prev,
		&-next {
			display: none;
			width: 48px;
			height: 49px;
			margin-top: -24.5px;

			@include _breakpoint--sm {
				display: block;
			}
		}


		&-prev {
			background-image: _image( 'home-icon-testimonials-prev.svg', 'home' );
		}

		&-next {
			background-image: _image( 'home-icon-testimonials-next.svg', 'home' );
		}
	}
}
