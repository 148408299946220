.pager {
	display: inline-block;
	padding: {
		top: _space();
		bottom: _space();
	}
	width: 100%;
	text-align: center;

	ul {
		list-style: none;
		padding: 0;

		li {
			display: none;
			width: 45%;

			&.pagination__prev,
			&.pagination__next {
				display: inline-block;
				max-width: map-get( $cta-widths, 'normal' );
			}

			&.pagination__prev {
				margin-right: 5px;
			}

			&.pagination__next {
				margin-left: 5px;
			}

			.pagination__next-label {
				@include _breakpoint--sm-plus {
					display: none;
				}
			}

			.pagination__prev-arrow,
			.pagination__next-arrow {
				margin: 0;
			}

			.pagination__next-arrow {
				display: none;

				@include _breakpoint--sm-plus {
					display: block;
				}
			}

			.pagination__prev-label {
				@include _breakpoint--sm-plus {
					display: none;
				}
			}

			.pagination__prev-arrow {
				display: none;

				@include _breakpoint--sm-plus {
					display: block;
				}
			}

			@include _breakpoint--sm-plus {
				display: inline-block;
				width: auto;
			}
		}
	}

	a {
		display: inline-block;
		width: 100%;
		padding: 1rem;
		margin: 0;
		font: {
			size: _font-scale( small );
			weight: 500;
		}
		text-decoration: none;
		color: _text-color( secondary );
		@include _transition;
		@include _border;

		@include _breakpoint--sm-plus {
			width: auto;
			padding: 0 1rem;
			font-weight: 300;
		}

		span {
			margin-right: 0;
		}

		&:hover,
		&.current-page {
			background-color: _gray();
			color: _text-color();
		}
	}
}