@mixin _section() {
	width: 100%;
}

@mixin _bs-container() {
	margin-right: auto;
	margin-left: auto;
	padding-left: $main-gutter / 2;
	padding-right: $main-gutter / 2;
}

@mixin _bs-container--fluid() {
	margin-right: auto;
	margin-left: auto;
	padding-left: $main-gutter / 2;
	padding-right: $main-gutter / 2;

	@include _breakpoint--md {
		max-width: $container-desktop;
	}

	@include _breakpoint--lg {
		max-width: $mq__lg;
	}
}
