[data-tooltip] {
	position: relative;
	cursor: default;

	&:before,
	&:after {
		position: absolute;
		visibility: hidden;
		opacity: 0;
		pointer-events: none;
		transition: all .15s cubic-bezier(.5, 1, .25, 1);
		z-index: 1;
	}

	&:before {
		content: attr( data-tooltip );
		padding: .5rem;
		width: 110px;
		border-radius: 3px;
		background: _color( black );
		color: _text-color( primary, light );
		text-align: center;
		font-size: _font-scale( small );
		font-weight: normal;
		line-height: 1.2;
	}

	&:after {
		border: 8px solid transparent;
		width: 0;
		content: "";
		font-size: 0;
		line-height: 0;
	}

	&:hover:before,
	&:hover:after {
		visibility: visible;
		opacity: 1;
	}
}

.tooltip {
	// Sizes.
	&--xxl:before {
		width: 300px;
		padding: 1rem;
	}

	&--xl:before {
		width: 200px;
	}

	&--lg:before {
		width: 170px;
	}

	&--md:before {
		width: 140px;
	}

	&--sm:before {
		width: 110px;
	}

	&--xs:before {
		width: 80px;
	}

	// Positions.
	// *** Top ***
	&--top {
		&:before {
			bottom: 100%;
			left: 50%;
			margin-bottom: .5rem;
			transform: translateX( -50% );
		}

		&:after {
			bottom: 100%;
			left: 50%;
			transform: translateX( -50% );
			border-top: 8px solid _color( black );
			border-bottom: none;
		}

		&:hover:before,
		&:hover:after {
			transform: translateX( -50% ) translateY( -5px )
		}
	}
	// *** Right ***
	&--right {
		&:before {
			top: 50%;
			left: 100%;
			margin-left: .5rem;
			transform: translateY( -50% );
		}

		&:after {
			top: 50%;
			left: 100%;
			transform: translateY( -50% );
			border-right: 8px solid _color( black );
			border-left: none;
		}

		&:hover:before,
		&:hover:after {
			transform: translateX( 5px ) translateY( -50% );
		}
	}
	// *** Bottom ***
	&--bottom {
		&:before {
			top: 100%;
			left: 50%;
			margin-top: .5rem;
			transform: translateX( -50% );
		}

		&:after {
			top: 100%;
			left: 50%;
			transform: translateX( -50% );
			border-bottom: 8px solid _color( black );
			border-top: none;
		}

		&:hover:before,
		&:hover:after {
			transform: translateX( -50% ) translateY( 5px );
		}
	}
	// *** Left ***
	&--left {
		&:before {
			top: 50%;
			right: 100%;
			margin-right: .5rem;
			transform: translateY( -50% );
		}

		&:after {
			top: 50%;
			right: 100%;
			transform: translateY( -50% );
			border-left: 8px solid _color( black );
			border-right: none;
		}

		&:hover:before,
		&:hover:after {
			transform: translateX( -5px ) translateY( -50% );
		}
	}
}
