.left-sidebar-block {
	float: left;
	width: 100%;
	margin-bottom: _space();
	@include _border( bottom );

	h4 {
		margin: 0;
	}

	@include _breakpoint--md {
		width: $size__left-sidebar--width;
		padding-right: _space();
		margin-bottom: 0;
		border-bottom: none;
	}

	&__item,
	&__options {
		@include _breakpoint--md {
			margin-bottom: _space();
		}

		&--centered {
			text-align: center;
		}
	}

	&__options {
		h2 {
			font-size: 2.4rem;
		}

		.input-wrapper,
		.select-wrapper {
			margin-bottom: 15px;

			&:last-of-type {
				margin-bottom: 0;
			}
		}

		.addon-of-the-month {
			display: inline-block;
			width: 100%;
			margin: 0;
			padding: 0;
			background-color: _color( bg );
			list-style: none;

			&__item {
				margin: 2em auto;
				width: 128px;

				p {
					display: block;
					width: 126px;
					height: 127px;
					position: relative;
					margin: 0;
					border-radius: 15px;
					border: 1px solid rgb(212, 212, 212);
					text-align: center;

					img {
						position: absolute;
						top: -10px;
						left: -10px;
					}

					span {
						display: inline-block;
						margin-top: 50px;
						transform: rotate(-45deg);
						font-weight: bold;
						margin-left: 30px;
						line-height: 1.8em;
						font-size: 16px;
					}
				}
			}
		}
	}

	&__submit {
		margin-top: 10px;
		text-align: center;

		.btn {
			width: auto;
			max-width: 90%;

			@include _breakpoint--md {
				width: 100%;
				max-width: 100%;
			}
		}
	}

	.dropdown {
		margin-bottom: 1rem;
	}

	.search {
		#edit-query {
			height: 35px;
		}
	}
}

.category-filter {
	display: inline-block;
	width: 100%;
	font-size: _font-scale( medium );
	list-style: none;
	margin: 0;
	padding: 0;

	&__list-item {
		position: relative;
		padding: 12px;
		padding-left: 0;
		cursor: pointer;
		@include _border( bottom );

		a {
			color: _text-color();

			&:hover {
				color: _color( primary );
			}
		}

		&--active {
			a {
				color: _color( primary );
			}
		}

		&:last-of-type {
			border-bottom: none;
		}
	}

	&__threads-quantity {
		position: absolute;
		right: 5px;
		top: 50%;
		border-radius: 8px;
		padding: 2px 8px;
		background-color: _gray-hex( dark );
		transform: translateY(-50%);
	}
}