.steps {
	width: 515px;
	max-width: 100%;
	margin: _double-space() auto;

	@include _breakpoint--sm {
		display: none;
	}

	&__item {
		width: 50px;
		height: 50px;
		background-color: _gray-hex( shadow );
		display: inline-block;
		border: 2px solid;
		border-color: transparent;
		border-radius: 50%;
		color: _text-color( secondary );
		font-weight: 600;
		text-align: center;
		line-height: 41px;
		vertical-align: middle;

		&:first-of-type {
			line-height: 46px;
		}

		&:nth-child(n+2) {
			margin: 0 0 0 100px;
			transform: translate(0, -4px);
		}

		&:nth-child(n+2):before {
			content: '';
			width: 80px;
			height: 3px;
			display: block;
			background-color: _gray-hex( shadow );
			transform: translate( -95px, 21px );
		}

		&:after {
			content: attr( data-desc );
			width: 150px;
			display: block;
			color: _text-color( secondary );
			font-weight: 400;
			font-size: _font-scale( small );
			transform: translate( -52px, 3px );
		}

		&:first-child:after {
			transform: translate( -55px, -1px );
		}

		&--active {
			border-color: _color( primary );
			color: _text-color( primary );

			&:before,
			&:nth-child(n+2):before {
				background: _color( primary );
			}

			&:after {
				color: _text-color( primary );
				font-weight: 600;
			}
		}

		&-status {
			&:after {
				content: attr( data-step );
			}
		}

		&--done {
			background-color: _color( success );
			border-color: _color( success );
			color: _text-color( primary, light );

			.steps__item-status {
				&:after {
					content: '\2714';
				}
			}

			&:before,
			&:nth-child(n+2):before {
				background-color: _color( success );
			}
		}
	}

	&--small {
		display: none;
		margin: 0;
		width: auto;
		float: right;

		@include _breakpoint--sm {
			display: block;
		}

		.steps__item {
			width: 36px;
			height: 36px;
			background-color: _color( white );
			border-color: _gray( dark );
			font-size: _font-scale( medium );
			color: _text-color( secondary );

			&,
			&:first-of-type {
				line-height: 35px;
			}

			&:after,
			&:before {
				display: none;
			}

			&:nth-child(n+2) {
				margin-left: .5rem;
				transform: none;
			}

			&--active {
				border-color: _color( primary, .8 );
				color: _text-color( primary );
			}

			&--done {
				border-color: _color( success );
				color: _color( success );

				.steps__item-status {
					&:after {
						content: '\2713';
					}
				}
			}
		}

		.current-step {
			p {
				margin: {
					top: 2rem;
					bottom: 0;
				}
				font: {
					size: _font-scale( medium );
					weight: 800;
				}
				color: _text-color( secondary );
				text-align: center;
				line-height: 1;
			}
		}
	}
}