#main-content {
	outline: none;
}
.section.messages-wrapper + #main-content {
  padding-top: 0;
}
.page-title {
	max-width: 100%;
	padding: 0;
	overflow: hidden;
	margin: 25px auto 0;
	text-align: left;

	h1 {
		font: {
			family: 'Nunito Sans', sans-serif;
			size: 4.2rem;
			weight: 200;
		}
		line-height: 1.04;
		margin-bottom: _space();
		position: relative;
	}

	&__wrapper {
		margin-top: 0;

		&+.section {
			margin-top: $main-space;
		}
	}

	&--middle {
		text-align: center;
	}
}

.text--center {
	text-align: center;
}

.thumb__documentation {
	width: 150px;
	height: 150px;
	margin: 0 auto _space();
	background: {
		image: _image( 'documentation.svg', 'backgrounds' );
		repeat: no-repeat;
		size: cover;
	}

	@include _breakpoint--sm {
		width: 215px;
		height: 215px;
	}
}

#admin-menu {
  &.admin-menu-position-fixed {
	z-index: 9999991;
  }

  .dropdown {
	display: block;
  }

  input {
	height: auto;
  }

  .menu {
	top: auto;
	width: auto;
	z-index: auto;
  }
}
