$subscribe__height: 320px;
$subscribe__height--plain: 235px;
$subscribe__input-height: 49px;

.module-subscribe {
	position: relative;
	text-align: center;
	padding: _double-space() 0;
	background: _color( bg );
	overflow: hidden;

	@include _breakpoint--sm {
		padding: 0;
		height: $subscribe__height;

		.section-container {
			width: 100%;
			@include _center;
			z-index: 19;
		}
	}

	&--plain {
		height: $subscribe__height--plain;
		margin-top: _space();
		color: inherit;

		.module-subscribe__actions {
			margin: _space() auto 0;

			input {
				border: 2px solid _color( primary );
			}
		}
	}

	&__actions {
		width: 480px;
		max-width: 100%;
		margin: _space() auto 0;
		@include _radius;

		.form-group {
			width: 100%;

			@include _breakpoint--sm {
				&:first-of-type {
					@include _radius( left );
				}

				&:last-of-type {
					margin-left: .5rem;
					@include _radius( right );
				}
			}

			&-inline {
				&,
				&:last-of-type {
					margin-left: 0;
				}

				&__wrapper {
					@include _breakpoint--mo {
						flex-wrap: wrap;
					}
				}

				@include _breakpoint--sm {
					width: auto;
				}
			}
		}

		.form-control {
			height: $subscribe__input-height;
			border: 2px solid _color( primary );

			@include _breakpoint--sm {
				@include _radius( left );
			}

		}

		.btn {
			display: block;
			width: 100%;

			@include _breakpoint--sm {
				display: inherit;
				width: inherit;
				margin-top: 0;
				@include _radius( right );
			}
		}
	}

	&--short {
		h2 {
			@include _breakpoint--sm {
				font-size: map-get( $header2-types, 'standard' );
			}
		}
	}

	h2 {
		width: 1100px;
		max-width: 100%;
		margin: {
			left: auto;
			right: auto;
			bottom: _space();
		}

		@include _breakpoint--sm {
			font-size: 4.9rem;
		}
	}

	&--narrow {
		h2 {
			width: 850px;
		}
	}

	h3 {
		margin-bottom: _space();
	}

	p {
		width: 640px;
		max-width: 100%;
		margin: {
			left: auto;
			right: auto;
		}
	}
}

#mce-responses {
	justify-content: center;

	.response {
		width: 480px;
		max-width: 100%;
		display: none;
		flex-grow: unset !important;
		padding: .5rem 1.5rem;
		margin: 1.5rem auto;
		font-size: _font-scale( medium );
		@include _radius;

		@include _breakpoint--sm {
			width: auto;
		}

		&#mce-error-response {
			background-color: _color( white, .9 );
			color: _color( danger );
		}

		&#mce-success-response {
			background-color: _color( white );
			color: _text-color( secondary );
		}

		a {
			display: none;
		}
	}
}

.mc-response {
	width: 480px;
	max-width: 100%;
	padding: .5rem 1.5rem;
	font-size: _font-scale( medium );
	@include _radius;

	p {
		margin: 0;
	}

	&--success,
	&--error {
		margin: 1.5rem auto;
	}

	&--success {
		background-color: _color( white );
		color: _text-color( secondary );
	}

	&--error {
		background-color: _color( white, .9 );
		color: _color( danger );
	}
}

.subscribe__note {
	a {
		font-weight: bolder;

		&,
		&:hover {
			color: _text-color( primary, light );
		}
	}
}
