@keyframes pulseDown {
  0% {
    transform: translate3d(0, 0, 0); }
  40% {
    transform: translate3d(0, 0, 0); }
  50% {
    transform: translate3d(0, 1rem, 0); }
  60% {
    transform: translate3d(0, 0, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes _spinner-rotate {
  100% {
    transform: rotate(360deg); } }

@keyframes _fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes _fade-out {
  0% {
    opacity: 1; }
  100% {
    display: none;
    opacity: 0; } }

@keyframes _spinner-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0; }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px; }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px; } }

@keyframes _spinner-color {
  100%,
  0% {
    stroke: red; }
  40% {
    stroke: _color(primary); }
  66% {
    stroke: green; }
  80%,
  90% {
    stroke: orange; } }

.boost--transform {
  will-change: transform; }

.boost--opacity {
  will-change: opacity; }

.boost--background {
  will-change: background-color; }

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100px, 0); }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

/**
 * Default colors.
 *
 * @type Map
 * @access public
 */
/**
 * Default text colors.
 *
 * @type Map
 * @access public
 */
/**
 * Default light text colors.
 *
 * @type Map
 * @access public
 */
/**
 * Pick a color from default colors map.
 *
 * @access public
 * @param {String} $type - Color name.
 * @param {Number} $alpha [ 1 ] - Alpha level.
 */
/**
 * Pick a gray color from grayscale map.
 *
 * @access public
 * @param {String} $type - Color name.
 * @param {Number} $alpha [ 1 ] - Alpha level.
 */
/**
 * Pick a gray color from grayscale hex map.
 *
 * @access public
 * @param {String} $type - Color name.
 */
/**
 * Returns text color from predefinied map.
 *
 * @access public
 * @param {String} $type [ primary ] - Text color type.
 * @param {String} $scale [ gray ] - Scale type (available gray and light).
 */
/**
 * Display border with default color.
 *
 * @access public
 * @param {String} $side [ all ] - Side of the border.
 * @param {String} $width [ 1px ] - Width of the border.
 */
/**
 * Set predefinied border radius.
 *
 * @access public
 * @param {String} $side [ all ] - Side of the border radius.
 */
/**
 * Default colors.
 *
 * @type Map
 * @access public
 */
/**
 * Default text colors.
 *
 * @type Map
 * @access public
 */
/**
 * Default light text colors.
 *
 * @type Map
 * @access public
 */
/**
 * Pick a color from default colors map.
 *
 * @access public
 * @param {String} $type - Color name.
 * @param {Number} $alpha [ 1 ] - Alpha level.
 */
/**
 * Pick a gray color from grayscale map.
 *
 * @access public
 * @param {String} $type - Color name.
 * @param {Number} $alpha [ 1 ] - Alpha level.
 */
/**
 * Pick a gray color from grayscale hex map.
 *
 * @access public
 * @param {String} $type - Color name.
 */
/**
 * Returns text color from predefinied map.
 *
 * @access public
 * @param {String} $type [ primary ] - Text color type.
 * @param {String} $scale [ gray ] - Scale type (available gray and light).
 */
/* mobile first breakpoint create helper */
/* Sizes are defined in ../settings/variables */
/**
 * Default box shadows.
 *
 * @type Map
 * @access public
 */
/**
 * Default box shadows transition.
 *
 * @type variable
 * @access public
 */
/**
 * Implement box shadow
 *
 * @access public
 * @param {String} $type [ default ] - Type of box shadow (small || default).
 * @param {Boolean} $transition [ false ] - Determinated if box shadow should be animated.
 */
/**
 * Implement text shadow
 *
 * @access public
 * @param {String} $type [ default ] - Type of box shadow (big || default).
 */
.clearfix:before, .clearfix:after {
  content: ' ';
  display: table; }

.clearfix:after {
  clear: both; }

/**
 * Set transition with default values.
 *
 * @access public
 * @param {String} $property [ all ] - Property name.
 * @param {String} $duration [ 200ms ] - Transition duration time.
 * @param {String} $timing-function [ 200ms ] - Name of timing function.
 * @param {String} $delay [ null ] - Delay time.
 */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 200;
  src: url("../fonts/NunitoSans-ExtraLight.woff2") format("woff2"), url("../fonts/NunitoSans-ExtraLight.woff") format("woff"), url("../fonts/NunitoSans-ExtraLight.ttf") format("ttf"); }

@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/NunitoSans-Regular.woff2") format("woff2"), url("../fonts/NunitoSans-Regular.woff") format("woff"), url("../fonts/NunitoSans-Regular.ttf") format("ttf"); }

@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/NunitoSans-SemiBold.woff2") format("woff2"), url("../fonts/NunitoSans-SemiBold.woff") format("woff"), url("../fonts/NunitoSans-SemiBold.ttf") format("ttf"); }

@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/NunitoSans-Bold.woff2") format("woff2"), url("../fonts/NunitoSans-Bold.woff") format("woff"), url("../fonts/NunitoSans-Bold.ttf") format("ttf"); }

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/Montserrat-Medium.woff2") format("woff2"), url("../fonts/Montserrat-Medium.woff") format("woff"), url("../fonts/Montserrat-Medium.ttf") format("ttf"); }

@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/NunitoSans-ExtraBold.woff2") format("woff2"), url("../fonts/NunitoSans-ExtraBold.woff") format("woff"), url("../fonts/NunitoSans-ExtraBold.ttf") format("ttf"); }

html {
  font-size: 52.5%; }
  @media (min-width: 768px) {
    html {
      font-size: 57.5%; } }
  @media only screen and (min-width: 768px) and (min-device-pixel-ratio: 2), only screen and (min-width: 768px) and (min-resolution: 192dpi), only screen and (min-width: 768px) and (min-resolution: 2dppx) {
    html {
      font-size: 60%; } }
  @media (min-width: 1280px) {
    html {
      font-size: 62.5%; } }
  @media only screen and (min-width: 1280px) and (min-device-pixel-ratio: 2), only screen and (min-width: 1280px) and (min-resolution: 192dpi), only screen and (min-width: 1280px) and (min-resolution: 2dppx) {
    html {
      font-size: 65%; } }

body {
  font-size: 19px;
  font-size: 1.9rem;
  line-height: 1.5;
  color: rgba(64, 81, 102, 0.96);
  font-family: 'Nunito Sans', sans-serif; }
  @media (min-width: 768px) {
    body {
      line-height: 2.5rem; } }

.form-control::-webkit-input-placeholder {
  color: rgba(64, 81, 102, 0.48); }

.form-control::-moz-placeholder {
  color: rgba(64, 81, 102, 0.48); }

.form-control:-ms-input-placeholder {
  color: rgba(64, 81, 102, 0.48); }

pre, code, kbd {
  font-family: Monaco, Consolas, "Courier New", monospace; }

kbd {
  font-size: 75%;
  background-color: #eeeeee;
  color: rgba(64, 81, 102, 0.96); }

h1, h2, h3, h4, h5, p {
  max-width: 100%; }

h1, h2, h3, h4, h5 {
  margin-top: 0;
  margin-bottom: 0;
  letter-spacing: -0.5px; }

h1 {
  font-size: 4rem;
  font-weight: 800;
  line-height: 5rem; }
  @media (min-width: 768px) {
    h1 {
      font-size: 4.4rem; } }

h2 {
  font-size: 3.2rem;
  font-weight: 400;
  line-height: 4rem; }
  @media (min-width: 768px) {
    h2 {
      font-size: 3.4rem;
      font-weight: 200;
      line-height: 5rem; } }
  @media (min-width: 1280px) {
    h2 {
      font-size: 3.5rem; } }

h3 {
  font-size: 3rem;
  font-weight: 200;
  line-height: 3.5rem;
  margin-top: 1rem;
  margin-bottom: .5rem; }

h4 {
  font-size: 2rem;
  line-height: 2.5rem; }

h5 {
  font-size: 1.7rem;
  line-height: 2.5rem; }

h4,
h5 {
  font-weight: 600; }

p, ul, ol, table, figure {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }

ol ol, ol ul,
ul ol,
ul ul {
  margin: 0; }

a {
  color: #0a90eb;
  text-decoration: none; }
  a:hover {
    text-decoration: underline;
    color: #0872ba; }

@media (min-width: 768px) {
  .pretty-text, .forum-post-content {
    font-size: 17px;
    font-size: 1.7rem;
    line-height: 2.8rem; } }

.pretty-text h1, .forum-post-content h1, .pretty-text h2, .forum-post-content h2, .pretty-text h3, .forum-post-content h3, .pretty-text h4, .forum-post-content h4, .pretty-text h5, .forum-post-content h5 {
  font-weight: 600;
  letter-spacing: inherit; }

.pretty-text h1, .forum-post-content h1 {
  font-size: 3.3rem;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 800;
  line-height: 1.23;
  margin-bottom: 2.8rem; }

.pretty-text h2, .forum-post-content h2 {
  font-size: 2.7rem;
  line-height: 2.8rem;
  margin-top: 4.2rem;
  margin-bottom: 1.4rem;
  letter-spacing: 0.03px; }
  .pretty-text h2 + h3, .forum-post-content h2 + h3 {
    margin-top: 2rem; }

.pretty-text h3, .forum-post-content h3 {
  font-size: 2.2rem;
  line-height: 2.8rem;
  margin-top: 2.8rem; }

.pretty-text h4, .forum-post-content h4 {
  font-size: 1.8rem;
  font-weight: 800;
  line-height: 2.8rem;
  margin-top: 2.17756rem;
  margin-bottom: 0.93324rem; }

.pretty-text h5, .forum-post-content h5 {
  font-size: 1.6rem;
  font-weight: 800;
  line-height: 2.8rem;
  margin-top: 2.17756rem;
  margin-bottom: 0.93324rem; }

.pretty-text h2 + p, .forum-post-content h2 + p,
.pretty-text h2 + ol, .forum-post-content h2 + ol,
.pretty-text h2 + ul, .forum-post-content h2 + ul, .pretty-text h3 + p, .forum-post-content h3 + p,
.pretty-text h3 + ol, .forum-post-content h3 + ol,
.pretty-text h3 + ul, .forum-post-content h3 + ul, .pretty-text h4 + p, .forum-post-content h4 + p,
.pretty-text h4 + ol, .forum-post-content h4 + ol,
.pretty-text h4 + ul, .forum-post-content h4 + ul, .pretty-text h5 + p, .forum-post-content h5 + p,
.pretty-text h5 + ol, .forum-post-content h5 + ol,
.pretty-text h5 + ul, .forum-post-content h5 + ul {
  margin-top: 0; }

.pretty-text p, .forum-post-content p {
  margin-top: 2.8rem;
  margin-bottom: 2.8rem;
  letter-spacing: -.003rem; }
  .pretty-text p img, .forum-post-content p img {
    margin-top: 0;
    margin-bottom: 0; }

.pretty-text img, .forum-post-content img, .pretty-text figure, .forum-post-content figure, .pretty-text ol, .forum-post-content ol, .pretty-text ul, .forum-post-content ul, .pretty-text blockquote, .forum-post-content blockquote {
  margin-top: 2.8rem;
  margin-bottom: 2.8rem; }

.pretty-text figure img, .forum-post-content figure img, .pretty-text figure table, .forum-post-content figure table {
  margin-top: 0;
  margin-bottom: 0; }

.pretty-text figure th, .forum-post-content figure th {
  text-align: center; }

.pretty-text ol ol, .forum-post-content ol ol, .pretty-text ol ul, .forum-post-content ol ul,
.pretty-text ul ol, .forum-post-content ul ol,
.pretty-text ul ul, .forum-post-content ul ul {
  margin: 0; }

.pretty-text ol li, .forum-post-content ol li,
.pretty-text ul li, .forum-post-content ul li {
  margin-top: .5rem; }
  .pretty-text ol li:first-of-type, .forum-post-content ol li:first-of-type,
  .pretty-text ul li:first-of-type, .forum-post-content ul li:first-of-type {
    margin-top: 0; }

.pretty-text table p, .forum-post-content table p,
.pretty-text table ul, .forum-post-content table ul,
.pretty-text table ol, .forum-post-content table ol,
.pretty-text table figure, .forum-post-content table figure,
.pretty-text table blockquote, .forum-post-content table blockquote {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem; }

@media (min-width: 768px) {
  .pretty-text--sm {
    font-size: 19px;
    font-size: 1.9rem;
    line-height: 2.5rem; } }

.pretty-text--sm h1, .pretty-text--sm h2, .pretty-text--sm h3, .pretty-text--sm h4, .pretty-text--sm h5 {
  font-weight: 600;
  letter-spacing: inherit; }

.pretty-text--sm h1 {
  font-size: 3.3rem;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 800;
  line-height: 1.23;
  margin-bottom: 2.5rem; }

.pretty-text--sm h2 {
  font-size: 2.7rem;
  line-height: 2.5rem;
  margin-top: 3.75rem;
  margin-bottom: 1.25rem;
  letter-spacing: 0.03px; }
  .pretty-text--sm h2 + h3 {
    margin-top: 2rem; }

.pretty-text--sm h3 {
  font-size: 2.2rem;
  line-height: 2.5rem;
  margin-top: 2.5rem; }

.pretty-text--sm h4 {
  font-size: 1.8rem;
  font-weight: 800;
  line-height: 2.5rem;
  margin-top: 1.94425rem;
  margin-bottom: 0.83325rem; }

.pretty-text--sm h5 {
  font-size: 1.6rem;
  font-weight: 800;
  line-height: 2.5rem;
  margin-top: 1.94425rem;
  margin-bottom: 0.83325rem; }

.pretty-text--sm h2 + p,
.pretty-text--sm h2 + ol,
.pretty-text--sm h2 + ul, .pretty-text--sm h3 + p,
.pretty-text--sm h3 + ol,
.pretty-text--sm h3 + ul, .pretty-text--sm h4 + p,
.pretty-text--sm h4 + ol,
.pretty-text--sm h4 + ul, .pretty-text--sm h5 + p,
.pretty-text--sm h5 + ol,
.pretty-text--sm h5 + ul {
  margin-top: 0; }

.pretty-text--sm p {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  letter-spacing: -.003rem; }
  .pretty-text--sm p img {
    margin-top: 0;
    margin-bottom: 0; }

.pretty-text--sm img, .pretty-text--sm figure, .pretty-text--sm ol, .pretty-text--sm ul, .pretty-text--sm blockquote {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }

.pretty-text--sm figure img, .pretty-text--sm figure table {
  margin-top: 0;
  margin-bottom: 0; }

.pretty-text--sm figure th {
  text-align: center; }

.pretty-text--sm ol ol, .pretty-text--sm ol ul,
.pretty-text--sm ul ol,
.pretty-text--sm ul ul {
  margin: 0; }

.pretty-text--sm ol li,
.pretty-text--sm ul li {
  margin-top: .5rem; }
  .pretty-text--sm ol li:first-of-type,
  .pretty-text--sm ul li:first-of-type {
    margin-top: 0; }

.pretty-text--sm table p,
.pretty-text--sm table ul,
.pretty-text--sm table ol,
.pretty-text--sm table figure,
.pretty-text--sm table blockquote {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem; }

@media (min-width: 768px) {
  .pretty-text--doc {
    font-size: 15px;
    font-size: 1.5rem;
    line-height: 2.4rem; } }

.pretty-text--doc h1, .pretty-text--doc h2, .pretty-text--doc h3, .pretty-text--doc h4, .pretty-text--doc h5 {
  font-weight: 600;
  letter-spacing: inherit; }

.pretty-text--doc h1 {
  font-size: 3.3rem;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 800;
  line-height: 1.23;
  margin-bottom: 2.4rem; }

.pretty-text--doc h2 {
  font-size: 2.7rem;
  line-height: 2.4rem;
  margin-top: 3.6rem;
  margin-bottom: 1.2rem;
  letter-spacing: 0.03px; }
  .pretty-text--doc h2 + h3 {
    margin-top: 2rem; }

.pretty-text--doc h3 {
  font-size: 2.2rem;
  line-height: 2.4rem;
  margin-top: 2.4rem; }

.pretty-text--doc h4 {
  font-size: 1.8rem;
  font-weight: 800;
  line-height: 2.4rem;
  margin-top: 1.86648rem;
  margin-bottom: 0.79992rem; }

.pretty-text--doc h5 {
  font-size: 1.6rem;
  font-weight: 800;
  line-height: 2.4rem;
  margin-top: 1.86648rem;
  margin-bottom: 0.79992rem; }

.pretty-text--doc h2 + p,
.pretty-text--doc h2 + ol,
.pretty-text--doc h2 + ul, .pretty-text--doc h3 + p,
.pretty-text--doc h3 + ol,
.pretty-text--doc h3 + ul, .pretty-text--doc h4 + p,
.pretty-text--doc h4 + ol,
.pretty-text--doc h4 + ul, .pretty-text--doc h5 + p,
.pretty-text--doc h5 + ol,
.pretty-text--doc h5 + ul {
  margin-top: 0; }

.pretty-text--doc p {
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;
  letter-spacing: -.003rem; }
  .pretty-text--doc p img {
    margin-top: 0;
    margin-bottom: 0; }

.pretty-text--doc img, .pretty-text--doc figure, .pretty-text--doc ol, .pretty-text--doc ul, .pretty-text--doc blockquote {
  margin-top: 2.4rem;
  margin-bottom: 2.4rem; }

.pretty-text--doc figure img, .pretty-text--doc figure table {
  margin-top: 0;
  margin-bottom: 0; }

.pretty-text--doc figure th {
  text-align: center; }

.pretty-text--doc ol ol, .pretty-text--doc ol ul,
.pretty-text--doc ul ol,
.pretty-text--doc ul ul {
  margin: 0; }

.pretty-text--doc ol li,
.pretty-text--doc ul li {
  margin-top: .5rem; }
  .pretty-text--doc ol li:first-of-type,
  .pretty-text--doc ul li:first-of-type {
    margin-top: 0; }

.pretty-text--doc table p,
.pretty-text--doc table ul,
.pretty-text--doc table ol,
.pretty-text--doc table figure,
.pretty-text--doc table blockquote {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem; }

.module h2 {
  font-size: 3.4rem; }
  @media (min-width: 768px) {
    .module h2 {
      font-size: 3.8rem; } }
  @media (min-width: 1280px) {
    .module h2 {
      font-size: 4.9rem; } }

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px); }
  60% {
    -webkit-transform: translateY(-15px);
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -o-transform: translateY(-15px);
    transform: translateY(-15px); } }

@-moz-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px); }
  60% {
    -webkit-transform: translateY(-15px);
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -o-transform: translateY(-15px);
    transform: translateY(-15px); } }

@-ms-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px); }
  60% {
    -webkit-transform: translateY(-15px);
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -o-transform: translateY(-15px);
    transform: translateY(-15px); } }

@-o-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px); }
  60% {
    -webkit-transform: translateY(-15px);
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -o-transform: translateY(-15px);
    transform: translateY(-15px); } }

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px); }
  60% {
    -webkit-transform: translateY(-15px);
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -o-transform: translateY(-15px);
    transform: translateY(-15px); } }

@-webkit-keyframes flash {
  0%, 50%, 100% {
    opacity: 1; }
  25%, 75% {
    opacity: 0; } }

@-moz-keyframes flash {
  0%, 50%, 100% {
    opacity: 1; }
  25%, 75% {
    opacity: 0; } }

@-ms-keyframes flash {
  0%, 50%, 100% {
    opacity: 1; }
  25%, 75% {
    opacity: 0; } }

@-o-keyframes flash {
  0%, 50%, 100% {
    opacity: 1; }
  25%, 75% {
    opacity: 0; } }

@keyframes flash {
  0%, 50%, 100% {
    opacity: 1; }
  25%, 75% {
    opacity: 0; } }

@-webkit-keyframes jello {
  11.1% {
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    -moz-transform: skewX(-12.5deg) skewY(-12.5deg);
    -ms-transform: skewX(-12.5deg) skewY(-12.5deg);
    -o-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg); }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    -moz-transform: skewX(6.25deg) skewY(6.25deg);
    -ms-transform: skewX(6.25deg) skewY(6.25deg);
    -o-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg); }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    -moz-transform: skewX(-3.125deg) skewY(-3.125deg);
    -ms-transform: skewX(-3.125deg) skewY(-3.125deg);
    -o-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg); }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    -moz-transform: skewX(1.5625deg) skewY(1.5625deg);
    -ms-transform: skewX(1.5625deg) skewY(1.5625deg);
    -o-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg); }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    -moz-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    -ms-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    -o-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg); }
  77.7% {
    -webkit-transform: skewX(0.39062deg) skewY(0.39062deg);
    -moz-transform: skewX(0.39062deg) skewY(0.39062deg);
    -ms-transform: skewX(0.39062deg) skewY(0.39062deg);
    -o-transform: skewX(0.39062deg) skewY(0.39062deg);
    transform: skewX(0.39062deg) skewY(0.39062deg); }
  88.8% {
    -webkit-transform: skewX(-0.19531deg) skewY(-0.19531deg);
    -moz-transform: skewX(-0.19531deg) skewY(-0.19531deg);
    -ms-transform: skewX(-0.19531deg) skewY(-0.19531deg);
    -o-transform: skewX(-0.19531deg) skewY(-0.19531deg);
    transform: skewX(-0.19531deg) skewY(-0.19531deg); }
  100% {
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; } }

@-moz-keyframes jello {
  11.1% {
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    -moz-transform: skewX(-12.5deg) skewY(-12.5deg);
    -ms-transform: skewX(-12.5deg) skewY(-12.5deg);
    -o-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg); }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    -moz-transform: skewX(6.25deg) skewY(6.25deg);
    -ms-transform: skewX(6.25deg) skewY(6.25deg);
    -o-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg); }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    -moz-transform: skewX(-3.125deg) skewY(-3.125deg);
    -ms-transform: skewX(-3.125deg) skewY(-3.125deg);
    -o-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg); }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    -moz-transform: skewX(1.5625deg) skewY(1.5625deg);
    -ms-transform: skewX(1.5625deg) skewY(1.5625deg);
    -o-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg); }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    -moz-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    -ms-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    -o-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg); }
  77.7% {
    -webkit-transform: skewX(0.39062deg) skewY(0.39062deg);
    -moz-transform: skewX(0.39062deg) skewY(0.39062deg);
    -ms-transform: skewX(0.39062deg) skewY(0.39062deg);
    -o-transform: skewX(0.39062deg) skewY(0.39062deg);
    transform: skewX(0.39062deg) skewY(0.39062deg); }
  88.8% {
    -webkit-transform: skewX(-0.19531deg) skewY(-0.19531deg);
    -moz-transform: skewX(-0.19531deg) skewY(-0.19531deg);
    -ms-transform: skewX(-0.19531deg) skewY(-0.19531deg);
    -o-transform: skewX(-0.19531deg) skewY(-0.19531deg);
    transform: skewX(-0.19531deg) skewY(-0.19531deg); }
  100% {
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; } }

@-ms-keyframes jello {
  11.1% {
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    -moz-transform: skewX(-12.5deg) skewY(-12.5deg);
    -ms-transform: skewX(-12.5deg) skewY(-12.5deg);
    -o-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg); }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    -moz-transform: skewX(6.25deg) skewY(6.25deg);
    -ms-transform: skewX(6.25deg) skewY(6.25deg);
    -o-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg); }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    -moz-transform: skewX(-3.125deg) skewY(-3.125deg);
    -ms-transform: skewX(-3.125deg) skewY(-3.125deg);
    -o-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg); }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    -moz-transform: skewX(1.5625deg) skewY(1.5625deg);
    -ms-transform: skewX(1.5625deg) skewY(1.5625deg);
    -o-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg); }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    -moz-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    -ms-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    -o-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg); }
  77.7% {
    -webkit-transform: skewX(0.39062deg) skewY(0.39062deg);
    -moz-transform: skewX(0.39062deg) skewY(0.39062deg);
    -ms-transform: skewX(0.39062deg) skewY(0.39062deg);
    -o-transform: skewX(0.39062deg) skewY(0.39062deg);
    transform: skewX(0.39062deg) skewY(0.39062deg); }
  88.8% {
    -webkit-transform: skewX(-0.19531deg) skewY(-0.19531deg);
    -moz-transform: skewX(-0.19531deg) skewY(-0.19531deg);
    -ms-transform: skewX(-0.19531deg) skewY(-0.19531deg);
    -o-transform: skewX(-0.19531deg) skewY(-0.19531deg);
    transform: skewX(-0.19531deg) skewY(-0.19531deg); }
  100% {
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; } }

@-o-keyframes jello {
  11.1% {
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    -moz-transform: skewX(-12.5deg) skewY(-12.5deg);
    -ms-transform: skewX(-12.5deg) skewY(-12.5deg);
    -o-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg); }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    -moz-transform: skewX(6.25deg) skewY(6.25deg);
    -ms-transform: skewX(6.25deg) skewY(6.25deg);
    -o-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg); }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    -moz-transform: skewX(-3.125deg) skewY(-3.125deg);
    -ms-transform: skewX(-3.125deg) skewY(-3.125deg);
    -o-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg); }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    -moz-transform: skewX(1.5625deg) skewY(1.5625deg);
    -ms-transform: skewX(1.5625deg) skewY(1.5625deg);
    -o-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg); }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    -moz-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    -ms-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    -o-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg); }
  77.7% {
    -webkit-transform: skewX(0.39062deg) skewY(0.39062deg);
    -moz-transform: skewX(0.39062deg) skewY(0.39062deg);
    -ms-transform: skewX(0.39062deg) skewY(0.39062deg);
    -o-transform: skewX(0.39062deg) skewY(0.39062deg);
    transform: skewX(0.39062deg) skewY(0.39062deg); }
  88.8% {
    -webkit-transform: skewX(-0.19531deg) skewY(-0.19531deg);
    -moz-transform: skewX(-0.19531deg) skewY(-0.19531deg);
    -ms-transform: skewX(-0.19531deg) skewY(-0.19531deg);
    -o-transform: skewX(-0.19531deg) skewY(-0.19531deg);
    transform: skewX(-0.19531deg) skewY(-0.19531deg); }
  100% {
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; } }

@keyframes jello {
  11.1% {
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    -moz-transform: skewX(-12.5deg) skewY(-12.5deg);
    -ms-transform: skewX(-12.5deg) skewY(-12.5deg);
    -o-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg); }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    -moz-transform: skewX(6.25deg) skewY(6.25deg);
    -ms-transform: skewX(6.25deg) skewY(6.25deg);
    -o-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg); }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    -moz-transform: skewX(-3.125deg) skewY(-3.125deg);
    -ms-transform: skewX(-3.125deg) skewY(-3.125deg);
    -o-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg); }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    -moz-transform: skewX(1.5625deg) skewY(1.5625deg);
    -ms-transform: skewX(1.5625deg) skewY(1.5625deg);
    -o-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg); }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    -moz-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    -ms-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    -o-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg); }
  77.7% {
    -webkit-transform: skewX(0.39062deg) skewY(0.39062deg);
    -moz-transform: skewX(0.39062deg) skewY(0.39062deg);
    -ms-transform: skewX(0.39062deg) skewY(0.39062deg);
    -o-transform: skewX(0.39062deg) skewY(0.39062deg);
    transform: skewX(0.39062deg) skewY(0.39062deg); }
  88.8% {
    -webkit-transform: skewX(-0.19531deg) skewY(-0.19531deg);
    -moz-transform: skewX(-0.19531deg) skewY(-0.19531deg);
    -ms-transform: skewX(-0.19531deg) skewY(-0.19531deg);
    -o-transform: skewX(-0.19531deg) skewY(-0.19531deg);
    transform: skewX(-0.19531deg) skewY(-0.19531deg); }
  100% {
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none; } }

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  50% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@-moz-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  50% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@-ms-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  50% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@-o-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  50% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  50% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@-webkit-keyframes rubberBand {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    -moz-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    -o-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    -moz-transform: scale3d(1.25, 0.75, 1);
    -ms-transform: scale3d(1.25, 0.75, 1);
    -o-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1); }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    -moz-transform: scale3d(0.75, 1.25, 1);
    -ms-transform: scale3d(0.75, 1.25, 1);
    -o-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1); }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    -moz-transform: scale3d(1.15, 0.85, 1);
    -ms-transform: scale3d(1.15, 0.85, 1);
    -o-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1); }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    -moz-transform: scale3d(0.95, 1.05, 1);
    -ms-transform: scale3d(0.95, 1.05, 1);
    -o-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1); }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    -moz-transform: scale3d(1.05, 0.95, 1);
    -ms-transform: scale3d(1.05, 0.95, 1);
    -o-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1); }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    -moz-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    -o-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@-moz-keyframes rubberBand {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    -moz-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    -o-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    -moz-transform: scale3d(1.25, 0.75, 1);
    -ms-transform: scale3d(1.25, 0.75, 1);
    -o-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1); }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    -moz-transform: scale3d(0.75, 1.25, 1);
    -ms-transform: scale3d(0.75, 1.25, 1);
    -o-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1); }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    -moz-transform: scale3d(1.15, 0.85, 1);
    -ms-transform: scale3d(1.15, 0.85, 1);
    -o-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1); }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    -moz-transform: scale3d(0.95, 1.05, 1);
    -ms-transform: scale3d(0.95, 1.05, 1);
    -o-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1); }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    -moz-transform: scale3d(1.05, 0.95, 1);
    -ms-transform: scale3d(1.05, 0.95, 1);
    -o-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1); }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    -moz-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    -o-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@-ms-keyframes rubberBand {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    -moz-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    -o-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    -moz-transform: scale3d(1.25, 0.75, 1);
    -ms-transform: scale3d(1.25, 0.75, 1);
    -o-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1); }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    -moz-transform: scale3d(0.75, 1.25, 1);
    -ms-transform: scale3d(0.75, 1.25, 1);
    -o-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1); }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    -moz-transform: scale3d(1.15, 0.85, 1);
    -ms-transform: scale3d(1.15, 0.85, 1);
    -o-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1); }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    -moz-transform: scale3d(0.95, 1.05, 1);
    -ms-transform: scale3d(0.95, 1.05, 1);
    -o-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1); }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    -moz-transform: scale3d(1.05, 0.95, 1);
    -ms-transform: scale3d(1.05, 0.95, 1);
    -o-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1); }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    -moz-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    -o-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@-o-keyframes rubberBand {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    -moz-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    -o-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    -moz-transform: scale3d(1.25, 0.75, 1);
    -ms-transform: scale3d(1.25, 0.75, 1);
    -o-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1); }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    -moz-transform: scale3d(0.75, 1.25, 1);
    -ms-transform: scale3d(0.75, 1.25, 1);
    -o-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1); }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    -moz-transform: scale3d(1.15, 0.85, 1);
    -ms-transform: scale3d(1.15, 0.85, 1);
    -o-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1); }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    -moz-transform: scale3d(0.95, 1.05, 1);
    -ms-transform: scale3d(0.95, 1.05, 1);
    -o-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1); }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    -moz-transform: scale3d(1.05, 0.95, 1);
    -ms-transform: scale3d(1.05, 0.95, 1);
    -o-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1); }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    -moz-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    -o-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes rubberBand {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    -moz-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    -o-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    -moz-transform: scale3d(1.25, 0.75, 1);
    -ms-transform: scale3d(1.25, 0.75, 1);
    -o-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1); }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    -moz-transform: scale3d(0.75, 1.25, 1);
    -ms-transform: scale3d(0.75, 1.25, 1);
    -o-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1); }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    -moz-transform: scale3d(1.15, 0.85, 1);
    -ms-transform: scale3d(1.15, 0.85, 1);
    -o-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1); }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    -moz-transform: scale3d(0.95, 1.05, 1);
    -ms-transform: scale3d(0.95, 1.05, 1);
    -o-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1); }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    -moz-transform: scale3d(1.05, 0.95, 1);
    -ms-transform: scale3d(1.05, 0.95, 1);
    -o-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1); }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    -moz-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    -o-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@-webkit-keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px); }
  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px); } }

@-moz-keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px); }
  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px); } }

@-ms-keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px); }
  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px); } }

@-o-keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px); }
  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px); } }

@keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px); }
  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px); } }

@-webkit-keyframes swing {
  20%, 40%, 60%, 80%, 100% {
    -webkit-transform-origin: top center;
    -moz-transform-origin: top center;
    -ms-transform-origin: top center;
    -o-transform-origin: top center;
    transform-origin: top center; }
  20% {
    -webkit-transform: rotate(15deg);
    -moz-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    -o-transform: rotate(15deg);
    transform: rotate(15deg); }
  40% {
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg); }
  60% {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    -o-transform: rotate(5deg);
    transform: rotate(5deg); }
  80% {
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg); }
  100% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); } }

@-moz-keyframes swing {
  20%, 40%, 60%, 80%, 100% {
    -webkit-transform-origin: top center;
    -moz-transform-origin: top center;
    -ms-transform-origin: top center;
    -o-transform-origin: top center;
    transform-origin: top center; }
  20% {
    -webkit-transform: rotate(15deg);
    -moz-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    -o-transform: rotate(15deg);
    transform: rotate(15deg); }
  40% {
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg); }
  60% {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    -o-transform: rotate(5deg);
    transform: rotate(5deg); }
  80% {
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg); }
  100% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); } }

@-ms-keyframes swing {
  20%, 40%, 60%, 80%, 100% {
    -webkit-transform-origin: top center;
    -moz-transform-origin: top center;
    -ms-transform-origin: top center;
    -o-transform-origin: top center;
    transform-origin: top center; }
  20% {
    -webkit-transform: rotate(15deg);
    -moz-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    -o-transform: rotate(15deg);
    transform: rotate(15deg); }
  40% {
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg); }
  60% {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    -o-transform: rotate(5deg);
    transform: rotate(5deg); }
  80% {
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg); }
  100% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); } }

@-o-keyframes swing {
  20%, 40%, 60%, 80%, 100% {
    -webkit-transform-origin: top center;
    -moz-transform-origin: top center;
    -ms-transform-origin: top center;
    -o-transform-origin: top center;
    transform-origin: top center; }
  20% {
    -webkit-transform: rotate(15deg);
    -moz-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    -o-transform: rotate(15deg);
    transform: rotate(15deg); }
  40% {
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg); }
  60% {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    -o-transform: rotate(5deg);
    transform: rotate(5deg); }
  80% {
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg); }
  100% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); } }

@keyframes swing {
  20%, 40%, 60%, 80%, 100% {
    -webkit-transform-origin: top center;
    -moz-transform-origin: top center;
    -ms-transform-origin: top center;
    -o-transform-origin: top center;
    transform-origin: top center; }
  20% {
    -webkit-transform: rotate(15deg);
    -moz-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    -o-transform: rotate(15deg);
    transform: rotate(15deg); }
  40% {
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg); }
  60% {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    -o-transform: rotate(5deg);
    transform: rotate(5deg); }
  80% {
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg); }
  100% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); } }

@-webkit-keyframes tada {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  10%, 20% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    -moz-transform: scale(0.9) rotate(-3deg);
    -ms-transform: scale(0.9) rotate(-3deg);
    -o-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg); }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg); }
  40%, 60%, 80% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    -moz-transform: scale(1.1) rotate(-3deg);
    -ms-transform: scale(1.1) rotate(-3deg);
    -o-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg); }
  100% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0); } }

@-moz-keyframes tada {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  10%, 20% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    -moz-transform: scale(0.9) rotate(-3deg);
    -ms-transform: scale(0.9) rotate(-3deg);
    -o-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg); }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg); }
  40%, 60%, 80% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    -moz-transform: scale(1.1) rotate(-3deg);
    -ms-transform: scale(1.1) rotate(-3deg);
    -o-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg); }
  100% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0); } }

@-ms-keyframes tada {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  10%, 20% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    -moz-transform: scale(0.9) rotate(-3deg);
    -ms-transform: scale(0.9) rotate(-3deg);
    -o-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg); }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg); }
  40%, 60%, 80% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    -moz-transform: scale(1.1) rotate(-3deg);
    -ms-transform: scale(1.1) rotate(-3deg);
    -o-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg); }
  100% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0); } }

@-o-keyframes tada {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  10%, 20% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    -moz-transform: scale(0.9) rotate(-3deg);
    -ms-transform: scale(0.9) rotate(-3deg);
    -o-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg); }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg); }
  40%, 60%, 80% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    -moz-transform: scale(1.1) rotate(-3deg);
    -ms-transform: scale(1.1) rotate(-3deg);
    -o-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg); }
  100% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0); } }

@keyframes tada {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  10%, 20% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    -moz-transform: scale(0.9) rotate(-3deg);
    -ms-transform: scale(0.9) rotate(-3deg);
    -o-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg); }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale(1.1) rotate(3deg);
    -moz-transform: scale(1.1) rotate(3deg);
    -ms-transform: scale(1.1) rotate(3deg);
    -o-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg); }
  40%, 60%, 80% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    -moz-transform: scale(1.1) rotate(-3deg);
    -ms-transform: scale(1.1) rotate(-3deg);
    -o-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg); }
  100% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0); } }

@-webkit-keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%); }
  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg);
    -moz-transform: translateX(-25%) rotate(-5deg);
    -ms-transform: translateX(-25%) rotate(-5deg);
    -o-transform: translateX(-25%) rotate(-5deg);
    transform: translateX(-25%) rotate(-5deg); }
  30% {
    -webkit-transform: translateX(20%) rotate(3deg);
    -moz-transform: translateX(20%) rotate(3deg);
    -ms-transform: translateX(20%) rotate(3deg);
    -o-transform: translateX(20%) rotate(3deg);
    transform: translateX(20%) rotate(3deg); }
  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg);
    -moz-transform: translateX(-15%) rotate(-3deg);
    -ms-transform: translateX(-15%) rotate(-3deg);
    -o-transform: translateX(-15%) rotate(-3deg);
    transform: translateX(-15%) rotate(-3deg); }
  60% {
    -webkit-transform: translateX(10%) rotate(2deg);
    -moz-transform: translateX(10%) rotate(2deg);
    -ms-transform: translateX(10%) rotate(2deg);
    -o-transform: translateX(10%) rotate(2deg);
    transform: translateX(10%) rotate(2deg); }
  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg);
    -moz-transform: translateX(-5%) rotate(-1deg);
    -ms-transform: translateX(-5%) rotate(-1deg);
    -o-transform: translateX(-5%) rotate(-1deg);
    transform: translateX(-5%) rotate(-1deg); }
  100% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%); } }

@-moz-keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%); }
  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg);
    -moz-transform: translateX(-25%) rotate(-5deg);
    -ms-transform: translateX(-25%) rotate(-5deg);
    -o-transform: translateX(-25%) rotate(-5deg);
    transform: translateX(-25%) rotate(-5deg); }
  30% {
    -webkit-transform: translateX(20%) rotate(3deg);
    -moz-transform: translateX(20%) rotate(3deg);
    -ms-transform: translateX(20%) rotate(3deg);
    -o-transform: translateX(20%) rotate(3deg);
    transform: translateX(20%) rotate(3deg); }
  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg);
    -moz-transform: translateX(-15%) rotate(-3deg);
    -ms-transform: translateX(-15%) rotate(-3deg);
    -o-transform: translateX(-15%) rotate(-3deg);
    transform: translateX(-15%) rotate(-3deg); }
  60% {
    -webkit-transform: translateX(10%) rotate(2deg);
    -moz-transform: translateX(10%) rotate(2deg);
    -ms-transform: translateX(10%) rotate(2deg);
    -o-transform: translateX(10%) rotate(2deg);
    transform: translateX(10%) rotate(2deg); }
  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg);
    -moz-transform: translateX(-5%) rotate(-1deg);
    -ms-transform: translateX(-5%) rotate(-1deg);
    -o-transform: translateX(-5%) rotate(-1deg);
    transform: translateX(-5%) rotate(-1deg); }
  100% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%); } }

@-ms-keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%); }
  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg);
    -moz-transform: translateX(-25%) rotate(-5deg);
    -ms-transform: translateX(-25%) rotate(-5deg);
    -o-transform: translateX(-25%) rotate(-5deg);
    transform: translateX(-25%) rotate(-5deg); }
  30% {
    -webkit-transform: translateX(20%) rotate(3deg);
    -moz-transform: translateX(20%) rotate(3deg);
    -ms-transform: translateX(20%) rotate(3deg);
    -o-transform: translateX(20%) rotate(3deg);
    transform: translateX(20%) rotate(3deg); }
  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg);
    -moz-transform: translateX(-15%) rotate(-3deg);
    -ms-transform: translateX(-15%) rotate(-3deg);
    -o-transform: translateX(-15%) rotate(-3deg);
    transform: translateX(-15%) rotate(-3deg); }
  60% {
    -webkit-transform: translateX(10%) rotate(2deg);
    -moz-transform: translateX(10%) rotate(2deg);
    -ms-transform: translateX(10%) rotate(2deg);
    -o-transform: translateX(10%) rotate(2deg);
    transform: translateX(10%) rotate(2deg); }
  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg);
    -moz-transform: translateX(-5%) rotate(-1deg);
    -ms-transform: translateX(-5%) rotate(-1deg);
    -o-transform: translateX(-5%) rotate(-1deg);
    transform: translateX(-5%) rotate(-1deg); }
  100% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%); } }

@-o-keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%); }
  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg);
    -moz-transform: translateX(-25%) rotate(-5deg);
    -ms-transform: translateX(-25%) rotate(-5deg);
    -o-transform: translateX(-25%) rotate(-5deg);
    transform: translateX(-25%) rotate(-5deg); }
  30% {
    -webkit-transform: translateX(20%) rotate(3deg);
    -moz-transform: translateX(20%) rotate(3deg);
    -ms-transform: translateX(20%) rotate(3deg);
    -o-transform: translateX(20%) rotate(3deg);
    transform: translateX(20%) rotate(3deg); }
  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg);
    -moz-transform: translateX(-15%) rotate(-3deg);
    -ms-transform: translateX(-15%) rotate(-3deg);
    -o-transform: translateX(-15%) rotate(-3deg);
    transform: translateX(-15%) rotate(-3deg); }
  60% {
    -webkit-transform: translateX(10%) rotate(2deg);
    -moz-transform: translateX(10%) rotate(2deg);
    -ms-transform: translateX(10%) rotate(2deg);
    -o-transform: translateX(10%) rotate(2deg);
    transform: translateX(10%) rotate(2deg); }
  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg);
    -moz-transform: translateX(-5%) rotate(-1deg);
    -ms-transform: translateX(-5%) rotate(-1deg);
    -o-transform: translateX(-5%) rotate(-1deg);
    transform: translateX(-5%) rotate(-1deg); }
  100% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%); } }

@keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%); }
  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg);
    -moz-transform: translateX(-25%) rotate(-5deg);
    -ms-transform: translateX(-25%) rotate(-5deg);
    -o-transform: translateX(-25%) rotate(-5deg);
    transform: translateX(-25%) rotate(-5deg); }
  30% {
    -webkit-transform: translateX(20%) rotate(3deg);
    -moz-transform: translateX(20%) rotate(3deg);
    -ms-transform: translateX(20%) rotate(3deg);
    -o-transform: translateX(20%) rotate(3deg);
    transform: translateX(20%) rotate(3deg); }
  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg);
    -moz-transform: translateX(-15%) rotate(-3deg);
    -ms-transform: translateX(-15%) rotate(-3deg);
    -o-transform: translateX(-15%) rotate(-3deg);
    transform: translateX(-15%) rotate(-3deg); }
  60% {
    -webkit-transform: translateX(10%) rotate(2deg);
    -moz-transform: translateX(10%) rotate(2deg);
    -ms-transform: translateX(10%) rotate(2deg);
    -o-transform: translateX(10%) rotate(2deg);
    transform: translateX(10%) rotate(2deg); }
  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg);
    -moz-transform: translateX(-5%) rotate(-1deg);
    -ms-transform: translateX(-5%) rotate(-1deg);
    -o-transform: translateX(-5%) rotate(-1deg);
    transform: translateX(-5%) rotate(-1deg); }
  100% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%); } }

@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    -moz-transform: scale(0.3);
    -ms-transform: scale(0.3);
    -o-transform: scale(0.3);
    transform: scale(0.3); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05); }
  70% {
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    transform: scale(0.9); }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@-moz-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    -moz-transform: scale(0.3);
    -ms-transform: scale(0.3);
    -o-transform: scale(0.3);
    transform: scale(0.3); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05); }
  70% {
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    transform: scale(0.9); }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@-ms-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    -moz-transform: scale(0.3);
    -ms-transform: scale(0.3);
    -o-transform: scale(0.3);
    transform: scale(0.3); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05); }
  70% {
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    transform: scale(0.9); }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@-o-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    -moz-transform: scale(0.3);
    -ms-transform: scale(0.3);
    -o-transform: scale(0.3);
    transform: scale(0.3); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05); }
  70% {
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    transform: scale(0.9); }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    -moz-transform: scale(0.3);
    -ms-transform: scale(0.3);
    -o-transform: scale(0.3);
    transform: scale(0.3); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05); }
  70% {
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    transform: scale(0.9); }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@-webkit-keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px); }
  80% {
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    transform: translateY(-10px); }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@-moz-keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px); }
  80% {
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    transform: translateY(-10px); }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@-ms-keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px); }
  80% {
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    transform: translateY(-10px); }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@-o-keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px); }
  80% {
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    transform: translateY(-10px); }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px); }
  80% {
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    transform: translateY(-10px); }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(30px);
    -moz-transform: translateX(30px);
    -ms-transform: translateX(30px);
    -o-transform: translateX(30px);
    transform: translateX(30px); }
  80% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px); }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@-moz-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(30px);
    -moz-transform: translateX(30px);
    -ms-transform: translateX(30px);
    -o-transform: translateX(30px);
    transform: translateX(30px); }
  80% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px); }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@-ms-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(30px);
    -moz-transform: translateX(30px);
    -ms-transform: translateX(30px);
    -o-transform: translateX(30px);
    transform: translateX(30px); }
  80% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px); }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@-o-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(30px);
    -moz-transform: translateX(30px);
    -ms-transform: translateX(30px);
    -o-transform: translateX(30px);
    transform: translateX(30px); }
  80% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px); }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(30px);
    -moz-transform: translateX(30px);
    -ms-transform: translateX(30px);
    -o-transform: translateX(30px);
    transform: translateX(30px); }
  80% {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    transform: translateX(-10px); }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@-webkit-keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-30px);
    -moz-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    -o-transform: translateX(-30px);
    transform: translateX(-30px); }
  80% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px); }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@-moz-keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-30px);
    -moz-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    -o-transform: translateX(-30px);
    transform: translateX(-30px); }
  80% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px); }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@-ms-keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-30px);
    -moz-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    -o-transform: translateX(-30px);
    transform: translateX(-30px); }
  80% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px); }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@-o-keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-30px);
    -moz-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    -o-transform: translateX(-30px);
    transform: translateX(-30px); }
  80% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px); }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-30px);
    -moz-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    -o-transform: translateX(-30px);
    transform: translateX(-30px); }
  80% {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px); }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@-webkit-keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px); }
  80% {
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px); }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@-moz-keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px); }
  80% {
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px); }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@-ms-keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px); }
  80% {
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px); }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@-o-keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px); }
  80% {
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px); }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px); }
  80% {
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px); }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes bounceOut {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  25% {
    -webkit-transform: scale(0.95);
    -moz-transform: scale(0.95);
    -ms-transform: scale(0.95);
    -o-transform: scale(0.95);
    transform: scale(0.95); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    -moz-transform: scale(0.3);
    -ms-transform: scale(0.3);
    -o-transform: scale(0.3);
    transform: scale(0.3); } }

@-moz-keyframes bounceOut {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  25% {
    -webkit-transform: scale(0.95);
    -moz-transform: scale(0.95);
    -ms-transform: scale(0.95);
    -o-transform: scale(0.95);
    transform: scale(0.95); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    -moz-transform: scale(0.3);
    -ms-transform: scale(0.3);
    -o-transform: scale(0.3);
    transform: scale(0.3); } }

@-ms-keyframes bounceOut {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  25% {
    -webkit-transform: scale(0.95);
    -moz-transform: scale(0.95);
    -ms-transform: scale(0.95);
    -o-transform: scale(0.95);
    transform: scale(0.95); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    -moz-transform: scale(0.3);
    -ms-transform: scale(0.3);
    -o-transform: scale(0.3);
    transform: scale(0.3); } }

@-o-keyframes bounceOut {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  25% {
    -webkit-transform: scale(0.95);
    -moz-transform: scale(0.95);
    -ms-transform: scale(0.95);
    -o-transform: scale(0.95);
    transform: scale(0.95); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    -moz-transform: scale(0.3);
    -ms-transform: scale(0.3);
    -o-transform: scale(0.3);
    transform: scale(0.3); } }

@keyframes bounceOut {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  25% {
    -webkit-transform: scale(0.95);
    -moz-transform: scale(0.95);
    -ms-transform: scale(0.95);
    -o-transform: scale(0.95);
    transform: scale(0.95); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    -moz-transform: scale(0.3);
    -ms-transform: scale(0.3);
    -o-transform: scale(0.3);
    transform: scale(0.3); } }

@-webkit-keyframes bounceOutDown {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px); } }

@-moz-keyframes bounceOutDown {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px); } }

@-ms-keyframes bounceOutDown {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px); } }

@-o-keyframes bounceOutDown {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px); } }

@keyframes bounceOutDown {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px); } }

@-webkit-keyframes bounceOutLeft {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px); } }

@-moz-keyframes bounceOutLeft {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px); } }

@-ms-keyframes bounceOutLeft {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px); } }

@-o-keyframes bounceOutLeft {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px); } }

@keyframes bounceOutLeft {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px); } }

@-webkit-keyframes bounceOutRight {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px); } }

@-moz-keyframes bounceOutRight {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px); } }

@-ms-keyframes bounceOutRight {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px); } }

@-o-keyframes bounceOutRight {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px); } }

@keyframes bounceOutRight {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px); } }

@-webkit-keyframes bounceOutUp {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px); } }

@-moz-keyframes bounceOutUp {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px); } }

@-ms-keyframes bounceOutUp {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px); } }

@-o-keyframes bounceOutUp {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px); } }

@keyframes bounceOutUp {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px); } }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-moz-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-ms-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-o-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@-moz-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@-ms-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@-o-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@-moz-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@-ms-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@-o-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@-moz-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@-ms-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@-o-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@-webkit-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@-moz-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@-ms-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@-o-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@-moz-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@-ms-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@-o-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@-webkit-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@-moz-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@-ms-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@-o-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@-moz-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@-ms-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@-o-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@-moz-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@-ms-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@-o-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-moz-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-ms-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-o-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes fadeOutDown {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px); } }

@-moz-keyframes fadeOutDown {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px); } }

@-ms-keyframes fadeOutDown {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px); } }

@-o-keyframes fadeOutDown {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px); } }

@keyframes fadeOutDown {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px); } }

@-webkit-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px); } }

@-moz-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px); } }

@-ms-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px); } }

@-o-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px); } }

@keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px); } }

@-webkit-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px); } }

@-moz-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px); } }

@-ms-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px); } }

@-o-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px); } }

@keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px); } }

@-webkit-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px); } }

@-moz-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px); } }

@-ms-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px); } }

@-o-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px); } }

@keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px); } }

@-webkit-keyframes fadeOutRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px); } }

@-moz-keyframes fadeOutRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px); } }

@-ms-keyframes fadeOutRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px); } }

@-o-keyframes fadeOutRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px); } }

@keyframes fadeOutRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px); } }

@-webkit-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px); } }

@-moz-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px); } }

@-ms-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px); } }

@-o-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px); } }

@keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px); } }

@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px); } }

@-moz-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px); } }

@-ms-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px); } }

@-o-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px); } }

@keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px); } }

@-webkit-keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px); } }

@-moz-keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px); } }

@-ms-keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px); } }

@-o-keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px); } }

@keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px); } }

@-webkit-keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotateY(0);
    -moz-transform: perspective(400px) rotateY(0);
    -ms-transform: perspective(400px) rotateY(0);
    -o-transform: perspective(400px) rotateY(0);
    transform: perspective(400px) rotateY(0);
    animation-timing-function: ease-out; }
  40% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -moz-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -ms-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -o-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    transform: perspective(400px) translateZ(150px) rotateY(170deg);
    animation-timing-function: ease-out; }
  50% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -moz-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -ms-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -o-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    animation-timing-function: ease-in; }
  80% {
    -webkit-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -moz-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -ms-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -o-transform: perspective(400px) rotateY(360deg) scale(0.95);
    transform: perspective(400px) rotateY(360deg) scale(0.95);
    animation-timing-function: ease-in; }
  100% {
    -webkit-transform: perspective(400px) scale(1);
    -moz-transform: perspective(400px) scale(1);
    -ms-transform: perspective(400px) scale(1);
    -o-transform: perspective(400px) scale(1);
    transform: perspective(400px) scale(1);
    animation-timing-function: ease-in; } }

@-moz-keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotateY(0);
    -moz-transform: perspective(400px) rotateY(0);
    -ms-transform: perspective(400px) rotateY(0);
    -o-transform: perspective(400px) rotateY(0);
    transform: perspective(400px) rotateY(0);
    animation-timing-function: ease-out; }
  40% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -moz-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -ms-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -o-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    transform: perspective(400px) translateZ(150px) rotateY(170deg);
    animation-timing-function: ease-out; }
  50% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -moz-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -ms-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -o-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    animation-timing-function: ease-in; }
  80% {
    -webkit-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -moz-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -ms-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -o-transform: perspective(400px) rotateY(360deg) scale(0.95);
    transform: perspective(400px) rotateY(360deg) scale(0.95);
    animation-timing-function: ease-in; }
  100% {
    -webkit-transform: perspective(400px) scale(1);
    -moz-transform: perspective(400px) scale(1);
    -ms-transform: perspective(400px) scale(1);
    -o-transform: perspective(400px) scale(1);
    transform: perspective(400px) scale(1);
    animation-timing-function: ease-in; } }

@-ms-keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotateY(0);
    -moz-transform: perspective(400px) rotateY(0);
    -ms-transform: perspective(400px) rotateY(0);
    -o-transform: perspective(400px) rotateY(0);
    transform: perspective(400px) rotateY(0);
    animation-timing-function: ease-out; }
  40% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -moz-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -ms-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -o-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    transform: perspective(400px) translateZ(150px) rotateY(170deg);
    animation-timing-function: ease-out; }
  50% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -moz-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -ms-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -o-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    animation-timing-function: ease-in; }
  80% {
    -webkit-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -moz-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -ms-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -o-transform: perspective(400px) rotateY(360deg) scale(0.95);
    transform: perspective(400px) rotateY(360deg) scale(0.95);
    animation-timing-function: ease-in; }
  100% {
    -webkit-transform: perspective(400px) scale(1);
    -moz-transform: perspective(400px) scale(1);
    -ms-transform: perspective(400px) scale(1);
    -o-transform: perspective(400px) scale(1);
    transform: perspective(400px) scale(1);
    animation-timing-function: ease-in; } }

@-o-keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotateY(0);
    -moz-transform: perspective(400px) rotateY(0);
    -ms-transform: perspective(400px) rotateY(0);
    -o-transform: perspective(400px) rotateY(0);
    transform: perspective(400px) rotateY(0);
    animation-timing-function: ease-out; }
  40% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -moz-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -ms-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -o-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    transform: perspective(400px) translateZ(150px) rotateY(170deg);
    animation-timing-function: ease-out; }
  50% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -moz-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -ms-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -o-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    animation-timing-function: ease-in; }
  80% {
    -webkit-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -moz-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -ms-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -o-transform: perspective(400px) rotateY(360deg) scale(0.95);
    transform: perspective(400px) rotateY(360deg) scale(0.95);
    animation-timing-function: ease-in; }
  100% {
    -webkit-transform: perspective(400px) scale(1);
    -moz-transform: perspective(400px) scale(1);
    -ms-transform: perspective(400px) scale(1);
    -o-transform: perspective(400px) scale(1);
    transform: perspective(400px) scale(1);
    animation-timing-function: ease-in; } }

@keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotateY(0);
    -moz-transform: perspective(400px) rotateY(0);
    -ms-transform: perspective(400px) rotateY(0);
    -o-transform: perspective(400px) rotateY(0);
    transform: perspective(400px) rotateY(0);
    animation-timing-function: ease-out; }
  40% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -moz-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -ms-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -o-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    transform: perspective(400px) translateZ(150px) rotateY(170deg);
    animation-timing-function: ease-out; }
  50% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -moz-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -ms-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -o-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    animation-timing-function: ease-in; }
  80% {
    -webkit-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -moz-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -ms-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -o-transform: perspective(400px) rotateY(360deg) scale(0.95);
    transform: perspective(400px) rotateY(360deg) scale(0.95);
    animation-timing-function: ease-in; }
  100% {
    -webkit-transform: perspective(400px) scale(1);
    -moz-transform: perspective(400px) scale(1);
    -ms-transform: perspective(400px) scale(1);
    -o-transform: perspective(400px) scale(1);
    transform: perspective(400px) scale(1);
    animation-timing-function: ease-in; } }

@-webkit-keyframes flipInX {
  0% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateX(90deg);
    -moz-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    -o-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg); }
  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
    -moz-transform: perspective(400px) rotateX(-10deg);
    -ms-transform: perspective(400px) rotateX(-10deg);
    -o-transform: perspective(400px) rotateX(-10deg);
    transform: perspective(400px) rotateX(-10deg); }
  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    -moz-transform: perspective(400px) rotateX(10deg);
    -ms-transform: perspective(400px) rotateX(10deg);
    -o-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg); }
  100% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateX(0deg);
    -moz-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    -o-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg); } }

@-moz-keyframes flipInX {
  0% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateX(90deg);
    -moz-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    -o-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg); }
  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
    -moz-transform: perspective(400px) rotateX(-10deg);
    -ms-transform: perspective(400px) rotateX(-10deg);
    -o-transform: perspective(400px) rotateX(-10deg);
    transform: perspective(400px) rotateX(-10deg); }
  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    -moz-transform: perspective(400px) rotateX(10deg);
    -ms-transform: perspective(400px) rotateX(10deg);
    -o-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg); }
  100% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateX(0deg);
    -moz-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    -o-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg); } }

@-ms-keyframes flipInX {
  0% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateX(90deg);
    -moz-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    -o-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg); }
  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
    -moz-transform: perspective(400px) rotateX(-10deg);
    -ms-transform: perspective(400px) rotateX(-10deg);
    -o-transform: perspective(400px) rotateX(-10deg);
    transform: perspective(400px) rotateX(-10deg); }
  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    -moz-transform: perspective(400px) rotateX(10deg);
    -ms-transform: perspective(400px) rotateX(10deg);
    -o-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg); }
  100% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateX(0deg);
    -moz-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    -o-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg); } }

@-o-keyframes flipInX {
  0% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateX(90deg);
    -moz-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    -o-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg); }
  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
    -moz-transform: perspective(400px) rotateX(-10deg);
    -ms-transform: perspective(400px) rotateX(-10deg);
    -o-transform: perspective(400px) rotateX(-10deg);
    transform: perspective(400px) rotateX(-10deg); }
  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    -moz-transform: perspective(400px) rotateX(10deg);
    -ms-transform: perspective(400px) rotateX(10deg);
    -o-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg); }
  100% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateX(0deg);
    -moz-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    -o-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg); } }

@keyframes flipInX {
  0% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateX(90deg);
    -moz-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    -o-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg); }
  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
    -moz-transform: perspective(400px) rotateX(-10deg);
    -ms-transform: perspective(400px) rotateX(-10deg);
    -o-transform: perspective(400px) rotateX(-10deg);
    transform: perspective(400px) rotateX(-10deg); }
  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    -moz-transform: perspective(400px) rotateX(10deg);
    -ms-transform: perspective(400px) rotateX(10deg);
    -o-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg); }
  100% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateX(0deg);
    -moz-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    -o-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg); } }

@-webkit-keyframes flipInY {
  0% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateY(90deg);
    -moz-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    -o-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg); }
  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
    -moz-transform: perspective(400px) rotateY(-10deg);
    -ms-transform: perspective(400px) rotateY(-10deg);
    -o-transform: perspective(400px) rotateY(-10deg);
    transform: perspective(400px) rotateY(-10deg); }
  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    -moz-transform: perspective(400px) rotateY(10deg);
    -ms-transform: perspective(400px) rotateY(10deg);
    -o-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg); }
  100% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateY(0deg);
    -moz-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    -o-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg); } }

@-moz-keyframes flipInY {
  0% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateY(90deg);
    -moz-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    -o-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg); }
  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
    -moz-transform: perspective(400px) rotateY(-10deg);
    -ms-transform: perspective(400px) rotateY(-10deg);
    -o-transform: perspective(400px) rotateY(-10deg);
    transform: perspective(400px) rotateY(-10deg); }
  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    -moz-transform: perspective(400px) rotateY(10deg);
    -ms-transform: perspective(400px) rotateY(10deg);
    -o-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg); }
  100% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateY(0deg);
    -moz-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    -o-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg); } }

@-ms-keyframes flipInY {
  0% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateY(90deg);
    -moz-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    -o-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg); }
  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
    -moz-transform: perspective(400px) rotateY(-10deg);
    -ms-transform: perspective(400px) rotateY(-10deg);
    -o-transform: perspective(400px) rotateY(-10deg);
    transform: perspective(400px) rotateY(-10deg); }
  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    -moz-transform: perspective(400px) rotateY(10deg);
    -ms-transform: perspective(400px) rotateY(10deg);
    -o-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg); }
  100% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateY(0deg);
    -moz-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    -o-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg); } }

@-o-keyframes flipInY {
  0% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateY(90deg);
    -moz-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    -o-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg); }
  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
    -moz-transform: perspective(400px) rotateY(-10deg);
    -ms-transform: perspective(400px) rotateY(-10deg);
    -o-transform: perspective(400px) rotateY(-10deg);
    transform: perspective(400px) rotateY(-10deg); }
  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    -moz-transform: perspective(400px) rotateY(10deg);
    -ms-transform: perspective(400px) rotateY(10deg);
    -o-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg); }
  100% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateY(0deg);
    -moz-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    -o-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg); } }

@keyframes flipInY {
  0% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateY(90deg);
    -moz-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    -o-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg); }
  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
    -moz-transform: perspective(400px) rotateY(-10deg);
    -ms-transform: perspective(400px) rotateY(-10deg);
    -o-transform: perspective(400px) rotateY(-10deg);
    transform: perspective(400px) rotateY(-10deg); }
  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    -moz-transform: perspective(400px) rotateY(10deg);
    -ms-transform: perspective(400px) rotateY(10deg);
    -o-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg); }
  100% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateY(0deg);
    -moz-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    -o-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg); } }

@-webkit-keyframes flipOutX {
  0% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateX(0deg);
    -moz-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    -o-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg); }
  100% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateX(90deg);
    -moz-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    -o-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg); } }

@-moz-keyframes flipOutX {
  0% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateX(0deg);
    -moz-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    -o-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg); }
  100% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateX(90deg);
    -moz-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    -o-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg); } }

@-ms-keyframes flipOutX {
  0% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateX(0deg);
    -moz-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    -o-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg); }
  100% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateX(90deg);
    -moz-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    -o-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg); } }

@-o-keyframes flipOutX {
  0% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateX(0deg);
    -moz-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    -o-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg); }
  100% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateX(90deg);
    -moz-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    -o-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg); } }

@keyframes flipOutX {
  0% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateX(0deg);
    -moz-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    -o-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg); }
  100% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateX(90deg);
    -moz-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    -o-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg); } }

@-webkit-keyframes flipOutY {
  0% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateY(0deg);
    -moz-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    -o-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg); }
  100% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateY(90deg);
    -moz-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    -o-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg); } }

@-moz-keyframes flipOutY {
  0% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateY(0deg);
    -moz-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    -o-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg); }
  100% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateY(90deg);
    -moz-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    -o-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg); } }

@-ms-keyframes flipOutY {
  0% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateY(0deg);
    -moz-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    -o-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg); }
  100% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateY(90deg);
    -moz-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    -o-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg); } }

@-o-keyframes flipOutY {
  0% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateY(0deg);
    -moz-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    -o-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg); }
  100% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateY(90deg);
    -moz-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    -o-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg); } }

@keyframes flipOutY {
  0% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateY(0deg);
    -moz-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    -o-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg); }
  100% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateY(90deg);
    -moz-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    -o-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg); } }

@-webkit-keyframes lightSpeedIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%) skewX(-30deg);
    -moz-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    -o-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20%) skewX(30deg);
    -moz-transform: translateX(-20%) skewX(30deg);
    -ms-transform: translateX(-20%) skewX(30deg);
    -o-transform: translateX(-20%) skewX(30deg);
    transform: translateX(-20%) skewX(30deg); }
  80% {
    opacity: 1;
    -webkit-transform: translateX(0%) skewX(-15deg);
    -moz-transform: translateX(0%) skewX(-15deg);
    -ms-transform: translateX(0%) skewX(-15deg);
    -o-transform: translateX(0%) skewX(-15deg);
    transform: translateX(0%) skewX(-15deg); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%) skewX(0deg);
    -moz-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    -o-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg); } }

@-moz-keyframes lightSpeedIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%) skewX(-30deg);
    -moz-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    -o-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20%) skewX(30deg);
    -moz-transform: translateX(-20%) skewX(30deg);
    -ms-transform: translateX(-20%) skewX(30deg);
    -o-transform: translateX(-20%) skewX(30deg);
    transform: translateX(-20%) skewX(30deg); }
  80% {
    opacity: 1;
    -webkit-transform: translateX(0%) skewX(-15deg);
    -moz-transform: translateX(0%) skewX(-15deg);
    -ms-transform: translateX(0%) skewX(-15deg);
    -o-transform: translateX(0%) skewX(-15deg);
    transform: translateX(0%) skewX(-15deg); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%) skewX(0deg);
    -moz-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    -o-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg); } }

@-ms-keyframes lightSpeedIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%) skewX(-30deg);
    -moz-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    -o-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20%) skewX(30deg);
    -moz-transform: translateX(-20%) skewX(30deg);
    -ms-transform: translateX(-20%) skewX(30deg);
    -o-transform: translateX(-20%) skewX(30deg);
    transform: translateX(-20%) skewX(30deg); }
  80% {
    opacity: 1;
    -webkit-transform: translateX(0%) skewX(-15deg);
    -moz-transform: translateX(0%) skewX(-15deg);
    -ms-transform: translateX(0%) skewX(-15deg);
    -o-transform: translateX(0%) skewX(-15deg);
    transform: translateX(0%) skewX(-15deg); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%) skewX(0deg);
    -moz-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    -o-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg); } }

@-o-keyframes lightSpeedIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%) skewX(-30deg);
    -moz-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    -o-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20%) skewX(30deg);
    -moz-transform: translateX(-20%) skewX(30deg);
    -ms-transform: translateX(-20%) skewX(30deg);
    -o-transform: translateX(-20%) skewX(30deg);
    transform: translateX(-20%) skewX(30deg); }
  80% {
    opacity: 1;
    -webkit-transform: translateX(0%) skewX(-15deg);
    -moz-transform: translateX(0%) skewX(-15deg);
    -ms-transform: translateX(0%) skewX(-15deg);
    -o-transform: translateX(0%) skewX(-15deg);
    transform: translateX(0%) skewX(-15deg); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%) skewX(0deg);
    -moz-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    -o-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg); } }

@keyframes lightSpeedIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%) skewX(-30deg);
    -moz-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    -o-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20%) skewX(30deg);
    -moz-transform: translateX(-20%) skewX(30deg);
    -ms-transform: translateX(-20%) skewX(30deg);
    -o-transform: translateX(-20%) skewX(30deg);
    transform: translateX(-20%) skewX(30deg); }
  80% {
    opacity: 1;
    -webkit-transform: translateX(0%) skewX(-15deg);
    -moz-transform: translateX(0%) skewX(-15deg);
    -ms-transform: translateX(0%) skewX(-15deg);
    -o-transform: translateX(0%) skewX(-15deg);
    transform: translateX(0%) skewX(-15deg); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%) skewX(0deg);
    -moz-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    -o-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg); } }

@-webkit-keyframes lightSpeedOut {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0%) skewX(0deg);
    -moz-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    -o-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%) skewX(-30deg);
    -moz-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    -o-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg); } }

@-moz-keyframes lightSpeedOut {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0%) skewX(0deg);
    -moz-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    -o-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%) skewX(-30deg);
    -moz-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    -o-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg); } }

@-ms-keyframes lightSpeedOut {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0%) skewX(0deg);
    -moz-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    -o-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%) skewX(-30deg);
    -moz-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    -o-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg); } }

@-o-keyframes lightSpeedOut {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0%) skewX(0deg);
    -moz-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    -o-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%) skewX(-30deg);
    -moz-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    -o-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg); } }

@keyframes lightSpeedOut {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0%) skewX(0deg);
    -moz-transform: translateX(0%) skewX(0deg);
    -ms-transform: translateX(0%) skewX(0deg);
    -o-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%) skewX(-30deg);
    -moz-transform: translateX(100%) skewX(-30deg);
    -ms-transform: translateX(100%) skewX(-30deg);
    -o-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg); } }

@-webkit-keyframes rotateIn {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-200deg);
    -moz-transform: rotate(-200deg);
    -ms-transform: rotate(-200deg);
    -o-transform: rotate(-200deg);
    transform: rotate(-200deg);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center; } }

@-moz-keyframes rotateIn {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-200deg);
    -moz-transform: rotate(-200deg);
    -ms-transform: rotate(-200deg);
    -o-transform: rotate(-200deg);
    transform: rotate(-200deg);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center; } }

@-ms-keyframes rotateIn {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-200deg);
    -moz-transform: rotate(-200deg);
    -ms-transform: rotate(-200deg);
    -o-transform: rotate(-200deg);
    transform: rotate(-200deg);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center; } }

@-o-keyframes rotateIn {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-200deg);
    -moz-transform: rotate(-200deg);
    -ms-transform: rotate(-200deg);
    -o-transform: rotate(-200deg);
    transform: rotate(-200deg);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center; } }

@keyframes rotateIn {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-200deg);
    -moz-transform: rotate(-200deg);
    -ms-transform: rotate(-200deg);
    -o-transform: rotate(-200deg);
    transform: rotate(-200deg);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center; } }

@-webkit-keyframes rotateInDownLeft {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom; } }

@-moz-keyframes rotateInDownLeft {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom; } }

@-ms-keyframes rotateInDownLeft {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom; } }

@-o-keyframes rotateInDownLeft {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom; } }

@keyframes rotateInDownLeft {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom; } }

@-webkit-keyframes rotateInDownRight {
  0% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom; } }

@-moz-keyframes rotateInDownRight {
  0% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom; } }

@-ms-keyframes rotateInDownRight {
  0% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom; } }

@-o-keyframes rotateInDownRight {
  0% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom; } }

@keyframes rotateInDownRight {
  0% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom; } }

@-webkit-keyframes rotateInUpLeft {
  0% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom; } }

@-moz-keyframes rotateInUpLeft {
  0% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom; } }

@-ms-keyframes rotateInUpLeft {
  0% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom; } }

@-o-keyframes rotateInUpLeft {
  0% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom; } }

@keyframes rotateInUpLeft {
  0% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom; } }

@-webkit-keyframes rotateInUpRight {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom; } }

@-moz-keyframes rotateInUpRight {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom; } }

@-ms-keyframes rotateInUpRight {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom; } }

@-o-keyframes rotateInUpRight {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom; } }

@keyframes rotateInUpRight {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom; } }

@-webkit-keyframes rotateOut {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(200deg);
    -moz-transform: rotate(200deg);
    -ms-transform: rotate(200deg);
    -o-transform: rotate(200deg);
    transform: rotate(200deg);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center; } }

@-moz-keyframes rotateOut {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(200deg);
    -moz-transform: rotate(200deg);
    -ms-transform: rotate(200deg);
    -o-transform: rotate(200deg);
    transform: rotate(200deg);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center; } }

@-ms-keyframes rotateOut {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(200deg);
    -moz-transform: rotate(200deg);
    -ms-transform: rotate(200deg);
    -o-transform: rotate(200deg);
    transform: rotate(200deg);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center; } }

@-o-keyframes rotateOut {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(200deg);
    -moz-transform: rotate(200deg);
    -ms-transform: rotate(200deg);
    -o-transform: rotate(200deg);
    transform: rotate(200deg);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center; } }

@keyframes rotateOut {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(200deg);
    -moz-transform: rotate(200deg);
    -ms-transform: rotate(200deg);
    -o-transform: rotate(200deg);
    transform: rotate(200deg);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center; } }

@-webkit-keyframes rotateOutDownLeft {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom; } }

@-moz-keyframes rotateOutDownLeft {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom; } }

@-ms-keyframes rotateOutDownLeft {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom; } }

@-o-keyframes rotateOutDownLeft {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom; } }

@keyframes rotateOutDownLeft {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom; } }

@-webkit-keyframes rotateOutDownRight {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom; } }

@-moz-keyframes rotateOutDownRight {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom; } }

@-ms-keyframes rotateOutDownRight {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom; } }

@-o-keyframes rotateOutDownRight {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom; } }

@keyframes rotateOutDownRight {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom; } }

@-webkit-keyframes rotateOutUpLeft {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom; } }

@-moz-keyframes rotateOutUpLeft {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom; } }

@-ms-keyframes rotateOutUpLeft {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom; } }

@-o-keyframes rotateOutUpLeft {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom; } }

@keyframes rotateOutUpLeft {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom; } }

@-webkit-keyframes rotateOutUpRight {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom; } }

@-moz-keyframes rotateOutUpRight {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom; } }

@-ms-keyframes rotateOutUpRight {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom; } }

@-o-keyframes rotateOutUpRight {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom; } }

@keyframes rotateOutUpRight {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom; } }

@-webkit-keyframes slideInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px); }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@-moz-keyframes slideInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px); }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@-ms-keyframes slideInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px); }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@-o-keyframes slideInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px); }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@keyframes slideInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px); }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes slideInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@-moz-keyframes slideInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@-ms-keyframes slideInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@-o-keyframes slideInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@keyframes slideInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@-webkit-keyframes slideInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px); }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@-moz-keyframes slideInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px); }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@-ms-keyframes slideInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px); }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@-o-keyframes slideInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px); }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@keyframes slideInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px); }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@-webkit-keyframes slideInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px); }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@-moz-keyframes slideInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px); }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@-ms-keyframes slideInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px); }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@-o-keyframes slideInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px); }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@keyframes slideInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px); }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes slideOutDown {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px); } }

@-moz-keyframes slideOutDown {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px); } }

@-ms-keyframes slideOutDown {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px); } }

@-o-keyframes slideOutDown {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px); } }

@keyframes slideOutDown {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    -moz-transform: translateY(2000px);
    -ms-transform: translateY(2000px);
    -o-transform: translateY(2000px);
    transform: translateY(2000px); } }

@-webkit-keyframes slideOutLeft {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px); } }

@-moz-keyframes slideOutLeft {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px); } }

@-ms-keyframes slideOutLeft {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px); } }

@-o-keyframes slideOutLeft {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px); } }

@keyframes slideOutLeft {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    -moz-transform: translateX(-2000px);
    -ms-transform: translateX(-2000px);
    -o-transform: translateX(-2000px);
    transform: translateX(-2000px); } }

@-webkit-keyframes slideOutRight {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px); } }

@-moz-keyframes slideOutRight {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px); } }

@-ms-keyframes slideOutRight {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px); } }

@-o-keyframes slideOutRight {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px); } }

@keyframes slideOutRight {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -moz-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    -o-transform: translateX(2000px);
    transform: translateX(2000px); } }

@-webkit-keyframes slideOutUp {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px); } }

@-moz-keyframes slideOutUp {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px); } }

@-ms-keyframes slideOutUp {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px); } }

@-o-keyframes slideOutUp {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px); } }

@keyframes slideOutUp {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -moz-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    -o-transform: translateY(-2000px);
    transform: translateY(-2000px); } }

@-webkit-keyframes hinge {
  0% {
    animation-timing-function: ease-in-out;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left;
    transform-origin: top left; }
  20%, 60% {
    animation-timing-function: ease-in-out;
    -webkit-transform: rotate(80deg);
    -moz-transform: rotate(80deg);
    -ms-transform: rotate(80deg);
    -o-transform: rotate(80deg);
    transform: rotate(80deg);
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left;
    transform-origin: top left; }
  40% {
    animation-timing-function: ease-in-out;
    -webkit-transform: rotate(60deg);
    -moz-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    -o-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left;
    transform-origin: top left; }
  80% {
    opacity: 1;
    animation-timing-function: ease-in-out;
    -webkit-transform: rotate(60deg) translateY(0);
    -moz-transform: rotate(60deg) translateY(0);
    -ms-transform: rotate(60deg) translateY(0);
    -o-transform: rotate(60deg) translateY(0);
    transform: rotate(60deg) translateY(0);
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left;
    transform-origin: top left; }
  100% {
    opacity: 0;
    -webkit-transform: translateY(700px);
    -moz-transform: translateY(700px);
    -ms-transform: translateY(700px);
    -o-transform: translateY(700px);
    transform: translateY(700px); } }

@-moz-keyframes hinge {
  0% {
    animation-timing-function: ease-in-out;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left;
    transform-origin: top left; }
  20%, 60% {
    animation-timing-function: ease-in-out;
    -webkit-transform: rotate(80deg);
    -moz-transform: rotate(80deg);
    -ms-transform: rotate(80deg);
    -o-transform: rotate(80deg);
    transform: rotate(80deg);
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left;
    transform-origin: top left; }
  40% {
    animation-timing-function: ease-in-out;
    -webkit-transform: rotate(60deg);
    -moz-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    -o-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left;
    transform-origin: top left; }
  80% {
    opacity: 1;
    animation-timing-function: ease-in-out;
    -webkit-transform: rotate(60deg) translateY(0);
    -moz-transform: rotate(60deg) translateY(0);
    -ms-transform: rotate(60deg) translateY(0);
    -o-transform: rotate(60deg) translateY(0);
    transform: rotate(60deg) translateY(0);
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left;
    transform-origin: top left; }
  100% {
    opacity: 0;
    -webkit-transform: translateY(700px);
    -moz-transform: translateY(700px);
    -ms-transform: translateY(700px);
    -o-transform: translateY(700px);
    transform: translateY(700px); } }

@-ms-keyframes hinge {
  0% {
    animation-timing-function: ease-in-out;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left;
    transform-origin: top left; }
  20%, 60% {
    animation-timing-function: ease-in-out;
    -webkit-transform: rotate(80deg);
    -moz-transform: rotate(80deg);
    -ms-transform: rotate(80deg);
    -o-transform: rotate(80deg);
    transform: rotate(80deg);
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left;
    transform-origin: top left; }
  40% {
    animation-timing-function: ease-in-out;
    -webkit-transform: rotate(60deg);
    -moz-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    -o-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left;
    transform-origin: top left; }
  80% {
    opacity: 1;
    animation-timing-function: ease-in-out;
    -webkit-transform: rotate(60deg) translateY(0);
    -moz-transform: rotate(60deg) translateY(0);
    -ms-transform: rotate(60deg) translateY(0);
    -o-transform: rotate(60deg) translateY(0);
    transform: rotate(60deg) translateY(0);
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left;
    transform-origin: top left; }
  100% {
    opacity: 0;
    -webkit-transform: translateY(700px);
    -moz-transform: translateY(700px);
    -ms-transform: translateY(700px);
    -o-transform: translateY(700px);
    transform: translateY(700px); } }

@-o-keyframes hinge {
  0% {
    animation-timing-function: ease-in-out;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left;
    transform-origin: top left; }
  20%, 60% {
    animation-timing-function: ease-in-out;
    -webkit-transform: rotate(80deg);
    -moz-transform: rotate(80deg);
    -ms-transform: rotate(80deg);
    -o-transform: rotate(80deg);
    transform: rotate(80deg);
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left;
    transform-origin: top left; }
  40% {
    animation-timing-function: ease-in-out;
    -webkit-transform: rotate(60deg);
    -moz-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    -o-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left;
    transform-origin: top left; }
  80% {
    opacity: 1;
    animation-timing-function: ease-in-out;
    -webkit-transform: rotate(60deg) translateY(0);
    -moz-transform: rotate(60deg) translateY(0);
    -ms-transform: rotate(60deg) translateY(0);
    -o-transform: rotate(60deg) translateY(0);
    transform: rotate(60deg) translateY(0);
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left;
    transform-origin: top left; }
  100% {
    opacity: 0;
    -webkit-transform: translateY(700px);
    -moz-transform: translateY(700px);
    -ms-transform: translateY(700px);
    -o-transform: translateY(700px);
    transform: translateY(700px); } }

@keyframes hinge {
  0% {
    animation-timing-function: ease-in-out;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left;
    transform-origin: top left; }
  20%, 60% {
    animation-timing-function: ease-in-out;
    -webkit-transform: rotate(80deg);
    -moz-transform: rotate(80deg);
    -ms-transform: rotate(80deg);
    -o-transform: rotate(80deg);
    transform: rotate(80deg);
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left;
    transform-origin: top left; }
  40% {
    animation-timing-function: ease-in-out;
    -webkit-transform: rotate(60deg);
    -moz-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    -o-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left;
    transform-origin: top left; }
  80% {
    opacity: 1;
    animation-timing-function: ease-in-out;
    -webkit-transform: rotate(60deg) translateY(0);
    -moz-transform: rotate(60deg) translateY(0);
    -ms-transform: rotate(60deg) translateY(0);
    -o-transform: rotate(60deg) translateY(0);
    transform: rotate(60deg) translateY(0);
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left;
    transform-origin: top left; }
  100% {
    opacity: 0;
    -webkit-transform: translateY(700px);
    -moz-transform: translateY(700px);
    -ms-transform: translateY(700px);
    -o-transform: translateY(700px);
    transform: translateY(700px); } }

@-webkit-keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    -moz-transform: translateX(-100%) rotate(-120deg);
    -ms-transform: translateX(-100%) rotate(-120deg);
    -o-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -moz-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    -o-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg); } }

@-moz-keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    -moz-transform: translateX(-100%) rotate(-120deg);
    -ms-transform: translateX(-100%) rotate(-120deg);
    -o-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -moz-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    -o-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg); } }

@-ms-keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    -moz-transform: translateX(-100%) rotate(-120deg);
    -ms-transform: translateX(-100%) rotate(-120deg);
    -o-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -moz-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    -o-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg); } }

@-o-keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    -moz-transform: translateX(-100%) rotate(-120deg);
    -ms-transform: translateX(-100%) rotate(-120deg);
    -o-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -moz-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    -o-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg); } }

@keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    -moz-transform: translateX(-100%) rotate(-120deg);
    -ms-transform: translateX(-100%) rotate(-120deg);
    -o-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -moz-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    -o-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg); } }

@-webkit-keyframes rollOut {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -moz-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    -o-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%) rotate(120deg);
    -moz-transform: translateX(100%) rotate(120deg);
    -ms-transform: translateX(100%) rotate(120deg);
    -o-transform: translateX(100%) rotate(120deg);
    transform: translateX(100%) rotate(120deg); } }

@-moz-keyframes rollOut {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -moz-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    -o-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%) rotate(120deg);
    -moz-transform: translateX(100%) rotate(120deg);
    -ms-transform: translateX(100%) rotate(120deg);
    -o-transform: translateX(100%) rotate(120deg);
    transform: translateX(100%) rotate(120deg); } }

@-ms-keyframes rollOut {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -moz-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    -o-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%) rotate(120deg);
    -moz-transform: translateX(100%) rotate(120deg);
    -ms-transform: translateX(100%) rotate(120deg);
    -o-transform: translateX(100%) rotate(120deg);
    transform: translateX(100%) rotate(120deg); } }

@-o-keyframes rollOut {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -moz-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    -o-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%) rotate(120deg);
    -moz-transform: translateX(100%) rotate(120deg);
    -ms-transform: translateX(100%) rotate(120deg);
    -o-transform: translateX(100%) rotate(120deg);
    transform: translateX(100%) rotate(120deg); } }

@keyframes rollOut {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    -moz-transform: translateX(0px) rotate(0deg);
    -ms-transform: translateX(0px) rotate(0deg);
    -o-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%) rotate(120deg);
    -moz-transform: translateX(100%) rotate(120deg);
    -ms-transform: translateX(100%) rotate(120deg);
    -o-transform: translateX(100%) rotate(120deg);
    transform: translateX(100%) rotate(120deg); } }

@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    -moz-transform: scale3d(0.3, 0.3, 0.3);
    -ms-transform: scale3d(0.3, 0.3, 0.3);
    -o-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@-moz-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    -moz-transform: scale3d(0.3, 0.3, 0.3);
    -ms-transform: scale3d(0.3, 0.3, 0.3);
    -o-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@-ms-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    -moz-transform: scale3d(0.3, 0.3, 0.3);
    -ms-transform: scale3d(0.3, 0.3, 0.3);
    -o-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@-o-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    -moz-transform: scale3d(0.3, 0.3, 0.3);
    -ms-transform: scale3d(0.3, 0.3, 0.3);
    -o-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    -moz-transform: scale3d(0.3, 0.3, 0.3);
    -ms-transform: scale3d(0.3, 0.3, 0.3);
    -o-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@-webkit-keyframes zoomInDown {
  0% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0); }
  60% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0); } }

@-moz-keyframes zoomInDown {
  0% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0); }
  60% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0); } }

@-ms-keyframes zoomInDown {
  0% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0); }
  60% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0); } }

@-o-keyframes zoomInDown {
  0% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0); }
  60% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0); } }

@keyframes zoomInDown {
  0% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0); }
  60% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0); } }

@-webkit-keyframes zoomInLeft {
  0% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0); }
  60% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0); } }

@-moz-keyframes zoomInLeft {
  0% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0); }
  60% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0); } }

@-ms-keyframes zoomInLeft {
  0% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0); }
  60% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0); } }

@-o-keyframes zoomInLeft {
  0% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0); }
  60% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0); } }

@keyframes zoomInLeft {
  0% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0); }
  60% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0); } }

@-webkit-keyframes zoomInRight {
  0% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0); }
  60% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0); } }

@-moz-keyframes zoomInRight {
  0% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0); }
  60% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0); } }

@-ms-keyframes zoomInRight {
  0% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0); }
  60% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0); } }

@-o-keyframes zoomInRight {
  0% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0); }
  60% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0); } }

@keyframes zoomInRight {
  0% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0); }
  60% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0); } }

@-webkit-keyframes zoomInUp {
  0% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0); }
  60% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0); } }

@-moz-keyframes zoomInUp {
  0% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0); }
  60% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0); } }

@-ms-keyframes zoomInUp {
  0% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0); }
  60% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0); } }

@-o-keyframes zoomInUp {
  0% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0); }
  60% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0); } }

@keyframes zoomInUp {
  0% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0); }
  60% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0); } }

@-webkit-keyframes zoomOut {
  0% {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    -moz-transform: scale3d(0.3, 0.3, 0.3);
    -ms-transform: scale3d(0.3, 0.3, 0.3);
    -o-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  100% {
    opacity: 0; } }

@-moz-keyframes zoomOut {
  0% {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    -moz-transform: scale3d(0.3, 0.3, 0.3);
    -ms-transform: scale3d(0.3, 0.3, 0.3);
    -o-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  100% {
    opacity: 0; } }

@-ms-keyframes zoomOut {
  0% {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    -moz-transform: scale3d(0.3, 0.3, 0.3);
    -ms-transform: scale3d(0.3, 0.3, 0.3);
    -o-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  100% {
    opacity: 0; } }

@-o-keyframes zoomOut {
  0% {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    -moz-transform: scale3d(0.3, 0.3, 0.3);
    -ms-transform: scale3d(0.3, 0.3, 0.3);
    -o-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  100% {
    opacity: 0; } }

@keyframes zoomOut {
  0% {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    -moz-transform: scale3d(0.3, 0.3, 0.3);
    -ms-transform: scale3d(0.3, 0.3, 0.3);
    -o-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  100% {
    opacity: 0; } }

@-webkit-keyframes zoomOutDown {
  40% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0); }
  100% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom; } }

@-moz-keyframes zoomOutDown {
  40% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0); }
  100% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom; } }

@-ms-keyframes zoomOutDown {
  40% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0); }
  100% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom; } }

@-o-keyframes zoomOutDown {
  40% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0); }
  100% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom; } }

@keyframes zoomOutDown {
  40% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0); }
  100% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom; } }

@-webkit-keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    -moz-transform: scale(0.1) translate3d(-2000px, 0, 0);
    -ms-transform: scale(0.1) translate3d(-2000px, 0, 0);
    -o-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -ms-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center; } }

@-moz-keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    -moz-transform: scale(0.1) translate3d(-2000px, 0, 0);
    -ms-transform: scale(0.1) translate3d(-2000px, 0, 0);
    -o-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -ms-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center; } }

@-ms-keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    -moz-transform: scale(0.1) translate3d(-2000px, 0, 0);
    -ms-transform: scale(0.1) translate3d(-2000px, 0, 0);
    -o-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -ms-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center; } }

@-o-keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    -moz-transform: scale(0.1) translate3d(-2000px, 0, 0);
    -ms-transform: scale(0.1) translate3d(-2000px, 0, 0);
    -o-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -ms-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center; } }

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    -moz-transform: scale(0.1) translate3d(-2000px, 0, 0);
    -ms-transform: scale(0.1) translate3d(-2000px, 0, 0);
    -o-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -ms-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center; } }

@-webkit-keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    -moz-transform: scale(0.1) translate3d(2000px, 0, 0);
    -ms-transform: scale(0.1) translate3d(2000px, 0, 0);
    -o-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    -moz-transform-origin: right center;
    -ms-transform-origin: right center;
    -o-transform-origin: right center;
    transform-origin: right center; } }

@-moz-keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    -moz-transform: scale(0.1) translate3d(2000px, 0, 0);
    -ms-transform: scale(0.1) translate3d(2000px, 0, 0);
    -o-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    -moz-transform-origin: right center;
    -ms-transform-origin: right center;
    -o-transform-origin: right center;
    transform-origin: right center; } }

@-ms-keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    -moz-transform: scale(0.1) translate3d(2000px, 0, 0);
    -ms-transform: scale(0.1) translate3d(2000px, 0, 0);
    -o-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    -moz-transform-origin: right center;
    -ms-transform-origin: right center;
    -o-transform-origin: right center;
    transform-origin: right center; } }

@-o-keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    -moz-transform: scale(0.1) translate3d(2000px, 0, 0);
    -ms-transform: scale(0.1) translate3d(2000px, 0, 0);
    -o-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    -moz-transform-origin: right center;
    -ms-transform-origin: right center;
    -o-transform-origin: right center;
    transform-origin: right center; } }

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    -moz-transform: scale(0.1) translate3d(2000px, 0, 0);
    -ms-transform: scale(0.1) translate3d(2000px, 0, 0);
    -o-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    -moz-transform-origin: right center;
    -ms-transform-origin: right center;
    -o-transform-origin: right center;
    transform-origin: right center; } }

@-webkit-keyframes zoomOutUp {
  40% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0); }
  100% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom; } }

@-moz-keyframes zoomOutUp {
  40% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0); }
  100% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom; } }

@-ms-keyframes zoomOutUp {
  40% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0); }
  100% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom; } }

@-o-keyframes zoomOutUp {
  40% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0); }
  100% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom; } }

@keyframes zoomOutUp {
  40% {
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0); }
  100% {
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom; } }

.svg-ico__unknown, .addon__releases .no-data, .svg-ico__addon, .right-sidebar__section .skin-link:before, .svg-ico__addon--preset, #editor-builder #draggable-plugins .plugins-presets ol li .plugin-ico--preset:before,
#editor-builder #draggable-plugins .additional-plugins ol li .plugin-ico--preset:before,
#editor-builder .languages .plugins-presets ol li .plugin-ico--preset:before,
#editor-builder .languages .additional-plugins ol li .plugin-ico--preset:before, #editor-builder .legend__ico--preset:before, .right-sidebar__section .plugins-list__item--preset:before, .svg-ico__addon--required, #editor-builder #draggable-plugins .plugins-presets ol li .plugin-ico--required:before,
#editor-builder #draggable-plugins .additional-plugins ol li .plugin-ico--required:before,
#editor-builder .languages .plugins-presets ol li .plugin-ico--required:before,
#editor-builder .languages .additional-plugins ol li .plugin-ico--required:before, #editor-builder .legend__ico--required:before, .right-sidebar__section .plugins-list__item--required:before, .svg-ico__addon--selected, #editor-builder #draggable-plugins .plugins-presets ol li .plugin-ico--selected:before,
#editor-builder #draggable-plugins .additional-plugins ol li .plugin-ico--selected:before,
#editor-builder .languages .plugins-presets ol li .plugin-ico--selected:before,
#editor-builder .languages .additional-plugins ol li .plugin-ico--selected:before, #editor-builder #draggable-plugins .plugins-presets ol li .plugin-ico--optional:before,
#editor-builder #draggable-plugins .additional-plugins ol li .plugin-ico--optional:before,
#editor-builder .languages .plugins-presets ol li .plugin-ico--optional:before,
#editor-builder .languages .additional-plugins ol li .plugin-ico--optional:before, #editor-builder .legend__ico--optional:before, .right-sidebar__section .plugins-list__item--selected:before, .svg-ico__check, .svg-ico__check-black, .addon__releases .yes, .svg-ico__close--red, .addon__releases .no, .svg-ico__api, .svg-ico__arrow-down, .svg-ico__big-white-arrow-down, .svg-ico__close-white, .svg-ico__examples, .svg-ico__faq, .svg-ico__fb, .svg-ico__fb-black, .svg-ico__fb-flat, .svg-ico__fb-flat-blue, .svg-ico__fb-flat-white, .svg-ico__fb-outline, .svg-ico__g-plus, .svg-ico__g-plus-black, .svg-ico__g-plus-flat, .svg-ico__g-plus-flat-blue, .svg-ico__g-plus-flat-white, .svg-ico__g-plus-outline, .svg-ico__github, .svg-ico__gitter, .svg-ico__guides, .svg-ico__guildes, .svg-ico__ideas, .svg-ico__instagram, .svg-ico__instagram-black, .svg-ico__issue-tracker, .svg-ico__localization, .svg-ico__medium, .svg-ico__medium-black, .svg-ico__menu, .svg-ico__menu-dark, .svg-ico__search, .search:after, .svg-ico__phone, .svg-ico__stackoverflow, .svg-ico__tutorials, .svg-ico__twitter, .svg-ico__twitter-black, .svg-ico__twitter-flat, .svg-ico__twitter-flat-blue, .svg-ico__twitter-flat-white, .svg-ico__twitter-outline, .svg-logo__adobe, .svg-logo__att, .svg-logo__citi, .svg-logo__disney, .svg-logo__ibm, .svg-logo__microsoft, .svg-logo__nbc, .svg-logo__oracle, .svg-logo__siemens {
  background: url("../images/vector.svg") no-repeat; }

.svg-ico__unknown, .addon__releases .no-data {
  background-position: 99.85875706214689% 36.95652173913044%;
  width: 20px;
  height: 20px; }

.svg-ico__addon, .right-sidebar__section .skin-link:before {
  background-position: 99.71791255289139% 22.897196261682243%;
  width: 19px;
  height: 22px; }

.svg-ico__addon--preset, #editor-builder #draggable-plugins .plugins-presets ol li .plugin-ico--preset:before,
#editor-builder #draggable-plugins .additional-plugins ol li .plugin-ico--preset:before,
#editor-builder .languages .plugins-presets ol li .plugin-ico--preset:before,
#editor-builder .languages .additional-plugins ol li .plugin-ico--preset:before, #editor-builder .legend__ico--preset:before, .right-sidebar__section .plugins-list__item--preset:before {
  background-position: 99.71791255289139% 33.177570093457945%;
  width: 19px;
  height: 22px; }

.svg-ico__addon--required, #editor-builder #draggable-plugins .plugins-presets ol li .plugin-ico--required:before,
#editor-builder #draggable-plugins .additional-plugins ol li .plugin-ico--required:before,
#editor-builder .languages .plugins-presets ol li .plugin-ico--required:before,
#editor-builder .languages .additional-plugins ol li .plugin-ico--required:before, #editor-builder .legend__ico--required:before, .right-sidebar__section .plugins-list__item--required:before {
  background-position: 99.71791255289139% 26.32398753894081%;
  width: 19px;
  height: 22px; }

.svg-ico__addon--selected, #editor-builder #draggable-plugins .plugins-presets ol li .plugin-ico--selected:before,
#editor-builder #draggable-plugins .additional-plugins ol li .plugin-ico--selected:before,
#editor-builder .languages .plugins-presets ol li .plugin-ico--selected:before,
#editor-builder .languages .additional-plugins ol li .plugin-ico--selected:before, #editor-builder #draggable-plugins .plugins-presets ol li .plugin-ico--optional:before,
#editor-builder #draggable-plugins .additional-plugins ol li .plugin-ico--optional:before,
#editor-builder .languages .plugins-presets ol li .plugin-ico--optional:before,
#editor-builder .languages .additional-plugins ol li .plugin-ico--optional:before, #editor-builder .legend__ico--optional:before, .right-sidebar__section .plugins-list__item--selected:before {
  background-position: 99.71791255289139% 29.75077881619938%;
  width: 19px;
  height: 22px; }

.svg-ico__check {
  background-position: 98.72881355932203% 14.923076923076923%;
  width: 20px;
  height: 14px; }

.svg-ico__check-black, .addon__releases .yes {
  background-position: 99.85915492957747% 17.076923076923077%;
  width: 18px;
  height: 14px; }

.svg-ico__close--red, .addon__releases .no {
  background-position: 99.01960784313725% 44.46153846153846%;
  width: 14px;
  height: 14px; }

.svg-ico__api {
  background-position: 0 90.7258064516129%;
  width: 84px;
  height: 84px; }

.svg-ico__arrow-down {
  background-position: 92.51700680272108% 0;
  width: 68px;
  height: 68px; }

.svg-ico__big-white-arrow-down {
  background-position: 64.51612903225806% 26.38623326959847%;
  width: 98px;
  height: 57px; }

.svg-ico__check {
  background-position: 99.35064935064935% 16.117216117216117%;
  width: 40px;
  height: 34px; }

.svg-ico__close-white {
  background-position: 99.02912621359224% 22.509225092250922%;
  width: 38px;
  height: 38px; }

.svg-ico__examples {
  background-position: 80.41958041958041% 17.741935483870968%;
  width: 84px;
  height: 84px; }

.svg-ico__faq {
  background-position: 80.41958041958041% 51.61290322580645%;
  width: 84px;
  height: 84px; }

.svg-ico__fb {
  background-position: 55.008210180623976% 84.11214953271028%;
  width: 47px;
  height: 45px; }

.svg-ico__fb-black {
  background-position: 89.32676518883416% 74.95327102803738%;
  width: 47px;
  height: 45px; }

.svg-ico__fb-flat {
  background-position: 27.512355848434925% 88.23529411764706%;
  width: 49px;
  height: 70px; }

.svg-ico__fb-flat-blue {
  background-position: 35.584843492586494% 88.23529411764706%;
  width: 49px;
  height: 70px; }

.svg-ico__fb-flat-white {
  background-position: 92.51700680272108% 13.28125%;
  width: 68px;
  height: 68px; }

.svg-ico__fb-outline {
  background-position: 88.16855753646678% 35.416666666666664%;
  width: 39px;
  height: 52px; }

.svg-ico__g-plus {
  background-position: 89.62108731466228% 44.095940959409596%;
  width: 49px;
  height: 38px; }

.svg-ico__g-plus-black {
  background-position: 89.62108731466228% 58.11808118081181%;
  width: 49px;
  height: 38px; }

.svg-ico__g-plus-flat {
  background-position: 64.74820143884892% 0;
  width: 100px;
  height: 69px; }

.svg-ico__g-plus-flat-blue {
  background-position: 64.74820143884892% 13.50293542074364%;
  width: 100px;
  height: 69px; }

.svg-ico__g-plus-flat-white {
  background-position: 89.62108731466228% 51.1070110701107%;
  width: 49px;
  height: 38px; }

.svg-ico__g-plus-outline {
  background-position: 45.22184300341297% 85.06616257088847%;
  width: 70px;
  height: 51px; }

.svg-ico__github {
  background-position: 14.685314685314685% 73.46938775510205%;
  width: 84px;
  height: 90px; }

.svg-ico__gitter {
  background-position: 80.41958041958041% 34.67741935483871%;
  width: 84px;
  height: 84px; }

.svg-ico__guides {
  background-position: 58.74125874125874% 73.17073170731707%;
  width: 84px;
  height: 88px; }

.svg-ico__guildes {
  background-position: 80.41958041958041% 0;
  width: 84px;
  height: 88px; }

.svg-ico__ideas {
  background-position: 63.716814159292035% 39.877300613496935%;
  width: 91px;
  height: 91px; }

.svg-ico__instagram {
  background-position: 89.32676518883416% 83.52059925093633%;
  width: 47px;
  height: 46px; }

.svg-ico__instagram-black {
  background-position: 0 100%;
  width: 47px;
  height: 46px; }

.svg-ico__issue-tracker {
  background-position: 44.05594405594405% 73.17073170731707%;
  width: 84px;
  height: 88px; }

.svg-ico__localization {
  background-position: 0 73.46938775510205%;
  width: 84px;
  height: 90px; }

.svg-ico__medium {
  background-position: 100% 8.208955223880597%;
  width: 44px;
  height: 44px; }

.svg-ico__medium-black {
  background-position: 100% 0;
  width: 44px;
  height: 44px; }

.svg-ico__menu {
  background-position: 7.6923076923076925% 98.34254143646409%;
  width: 45px;
  height: 37px; }

.svg-ico__menu-dark {
  background-position: 77.9050736497545% 82.87292817679558%;
  width: 45px;
  height: 37px; }

.svg-ico__search, .search:after {
  background-position: 99.15848527349229% 40.523882896764256%;
  width: 15px;
  height: 15px; }

.svg-ico__phone {
  background-position: 89.47368421052632% 66.35338345864662%;
  width: 48px;
  height: 48px; }

.svg-ico__stackoverflow {
  background-position: 29.37062937062937% 73.17073170731707%;
  width: 84px;
  height: 88px; }

.svg-ico__tutorials {
  background-position: 80.41958041958041% 68.54838709677419%;
  width: 84px;
  height: 84px; }

.svg-ico__twitter {
  background-position: 62.725779967159276% 83.64312267657992%;
  width: 47px;
  height: 42px; }

.svg-ico__twitter-black {
  background-position: 70.44334975369458% 83.64312267657992%;
  width: 47px;
  height: 42px; }

.svg-ico__twitter-flat {
  background-position: 62.82722513089005% 56.07843137254902%;
  width: 83px;
  height: 70px; }

.svg-ico__twitter-flat-blue {
  background-position: 14.659685863874346% 88.23529411764706%;
  width: 83px;
  height: 70px; }

.svg-ico__twitter-flat-white {
  background-position: 89.32676518883416% 91.44981412639405%;
  width: 47px;
  height: 42px; }

.svg-ico__twitter-outline {
  background-position: 90.81803005008348% 25.708884688090738%;
  width: 57px;
  height: 51px; }

.svg-logo__adobe {
  background-position: 0 0;
  width: 120px;
  height: 120px; }

.svg-logo__att {
  background-position: 44.776119402985074% 52.17391304347826%;
  width: 120px;
  height: 120px; }

.svg-logo__citi {
  background-position: 22.388059701492537% 52.17391304347826%;
  width: 120px;
  height: 120px; }

.svg-logo__disney {
  background-position: 0 52.17391304347826%;
  width: 120px;
  height: 120px; }

.svg-logo__ibm {
  background-position: 44.776119402985074% 26.08695652173913%;
  width: 120px;
  height: 120px; }

.svg-logo__microsoft {
  background-position: 44.776119402985074% 0;
  width: 120px;
  height: 120px; }

.svg-logo__nbc {
  background-position: 22.388059701492537% 26.08695652173913%;
  width: 120px;
  height: 120px; }

.svg-logo__oracle {
  background-position: 0 26.08695652173913%;
  width: 120px;
  height: 120px; }

.svg-logo__siemens {
  background-position: 22.388059701492537% 0;
  width: 120px;
  height: 120px; }

.ico {
  display: inline-block;
  vertical-align: middle; }

.footer {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  color: white;
  background-color: #3b474e; }
  .footer .section-container, .footer .site-info__wrapper {
    width: 1000px;
    max-width: 100%; }
    @media (min-width: 1680px) {
      .footer .section-container, .footer .site-info__wrapper {
        width: 1000px; } }
  @media (min-width: 1024px) {
    .footer-site-map {
      display: flex;
      justify-content: space-around; } }
  .footer-menu {
    list-style: none;
    padding-left: 1px;
    margin-top: .5rem;
    margin-bottom: .5rem; }
    @media (min-width: 768px) {
      .footer-menu {
        margin-top: 0; } }
    .footer-menu li {
      height: 23px;
      line-height: 23px; }
    .footer-menu a {
      color: rgba(255, 255, 255, 0.8);
      font-size: 1.3rem;
      font-weight: 600; }
    .footer-menu__wrapper {
      width: 100%;
      text-align: center;
      margin-top: 2.5rem;
      margin-bottom: 2.5rem; }
      @media (min-width: 768px) {
        .footer-menu__wrapper {
          width: auto;
          text-align: inherit; } }
      @media (min-width: 1280px) {
        .footer-menu__wrapper {
          margin: 0;
          text-align: inherit; }
          .footer-menu__wrapper:last-of-type {
            width: auto; } }
      .footer-menu__wrapper h2 {
        margin-top: 0;
        margin-bottom: .5rem;
        font-size: 1.9rem;
        font-weight: 600;
        line-height: 2.5rem; }
    .footer-menu__category-items {
      width: 100%;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap; }
      @media (min-width: 1280px) {
        .footer-menu__category-items {
          justify-content: space-between;
          flex-wrap: nowrap; } }
  .footer .module-subscribe__actions {
    width: 400px;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    box-shadow: none; }
    .footer .module-subscribe__actions .btn, .footer .module-subscribe__actions #cksource_repo_search_forums_form .form-submit, #cksource_repo_search_forums_form .footer .module-subscribe__actions .form-submit, .footer .module-subscribe__actions #forum-sort .form-submit, #forum-sort .footer .module-subscribe__actions .form-submit, .footer .module-subscribe__actions .add-plugin input[type="submit"], .add-plugin .footer .module-subscribe__actions input[type="submit"], .footer .module-subscribe__actions
    .add-plugin select[type="submit"],
    .add-plugin .footer .module-subscribe__actions select[type="submit"], .footer .module-subscribe__actions
    .plugin-edit input[type="submit"],
    .plugin-edit .footer .module-subscribe__actions input[type="submit"], .footer .module-subscribe__actions
    .plugin-edit select[type="submit"],
    .plugin-edit .footer .module-subscribe__actions select[type="submit"], .footer .module-subscribe__actions
    .plugin-release input[type="submit"],
    .plugin-release .footer .module-subscribe__actions input[type="submit"], .footer .module-subscribe__actions
    .plugin-release select[type="submit"],
    .plugin-release .footer .module-subscribe__actions select[type="submit"], .footer .module-subscribe__actions .add-plugin .field-type-image-form input[type="file"], .add-plugin .field-type-image-form .footer .module-subscribe__actions input[type="file"], .footer .module-subscribe__actions
    .plugin-edit .field-type-image-form input[type="file"],
    .plugin-edit .field-type-image-form .footer .module-subscribe__actions input[type="file"], .footer .module-subscribe__actions
    .plugin-release .field-type-image-form input[type="file"],
    .plugin-release .field-type-image-form .footer .module-subscribe__actions input[type="file"], .footer .module-subscribe__actions .add-plugin .field-type-image-form button, .add-plugin .field-type-image-form .footer .module-subscribe__actions button, .footer .module-subscribe__actions
    .plugin-edit .field-type-image-form button,
    .plugin-edit .field-type-image-form .footer .module-subscribe__actions button, .footer .module-subscribe__actions
    .plugin-release .field-type-image-form button,
    .plugin-release .field-type-image-form .footer .module-subscribe__actions button, .footer .module-subscribe__actions .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], .add-plugin .field-type-image-form .footer .module-subscribe__actions input[name="field_addon_logo_und_0_remove_button"], .footer .module-subscribe__actions
    .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
    .plugin-edit .field-type-image-form .footer .module-subscribe__actions input[name="field_addon_logo_und_0_remove_button"], .footer .module-subscribe__actions
    .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
    .plugin-release .field-type-image-form .footer .module-subscribe__actions input[name="field_addon_logo_und_0_remove_button"], .footer .module-subscribe__actions #editor-builder #draggable-plugins .all-elements-option a, #editor-builder #draggable-plugins .all-elements-option .footer .module-subscribe__actions a, .footer .module-subscribe__actions
    #editor-builder .languages .all-elements-option a,
    #editor-builder .languages .all-elements-option .footer .module-subscribe__actions a, .footer .module-subscribe__actions .login .form-control[type="submit"], .login .footer .module-subscribe__actions .form-control[type="submit"], .footer .module-subscribe__actions
    .register .form-control[type="submit"],
    .register .footer .module-subscribe__actions .form-control[type="submit"], .footer .module-subscribe__actions
    .info-page .form-control[type="submit"],
    .info-page .footer .module-subscribe__actions .form-control[type="submit"], .footer .module-subscribe__actions .most-popular__wrapper .add_to_basket, .most-popular__wrapper .footer .module-subscribe__actions .add_to_basket, .footer .module-subscribe__actions .add-ons-list .views-row .add_to_basket, .add-ons-list .views-row .footer .module-subscribe__actions .add_to_basket, .footer .module-subscribe__actions
    .add-ons-list .views-row .check-details,
    .add-ons-list .views-row .footer .module-subscribe__actions .check-details, .footer .module-subscribe__actions
    .add-ons-list .views-row .remove_from_basket,
    .add-ons-list .views-row .footer .module-subscribe__actions .remove_from_basket, .footer .module-subscribe__actions
    .add-ons-list .views-row .required,
    .add-ons-list .views-row .footer .module-subscribe__actions .required, .footer .module-subscribe__actions .user-edit input[type="submit"], .user-edit .footer .module-subscribe__actions input[type="submit"], .footer .module-subscribe__actions
    .user-edit select[type="submit"],
    .user-edit .footer .module-subscribe__actions select[type="submit"] {
      padding: 9.5px 24px; }
    @media (min-width: 1280px) {
      .footer .module-subscribe__actions {
        margin-bottom: 0;
        margin-left: 0; }
        .footer .module-subscribe__actions .btn, .footer .module-subscribe__actions #cksource_repo_search_forums_form .form-submit, #cksource_repo_search_forums_form .footer .module-subscribe__actions .form-submit, .footer .module-subscribe__actions #forum-sort .form-submit, #forum-sort .footer .module-subscribe__actions .form-submit, .footer .module-subscribe__actions .add-plugin input[type="submit"], .add-plugin .footer .module-subscribe__actions input[type="submit"], .footer .module-subscribe__actions
        .add-plugin select[type="submit"],
        .add-plugin .footer .module-subscribe__actions select[type="submit"], .footer .module-subscribe__actions
        .plugin-edit input[type="submit"],
        .plugin-edit .footer .module-subscribe__actions input[type="submit"], .footer .module-subscribe__actions
        .plugin-edit select[type="submit"],
        .plugin-edit .footer .module-subscribe__actions select[type="submit"], .footer .module-subscribe__actions
        .plugin-release input[type="submit"],
        .plugin-release .footer .module-subscribe__actions input[type="submit"], .footer .module-subscribe__actions
        .plugin-release select[type="submit"],
        .plugin-release .footer .module-subscribe__actions select[type="submit"], .footer .module-subscribe__actions .add-plugin .field-type-image-form input[type="file"], .add-plugin .field-type-image-form .footer .module-subscribe__actions input[type="file"], .footer .module-subscribe__actions
        .plugin-edit .field-type-image-form input[type="file"],
        .plugin-edit .field-type-image-form .footer .module-subscribe__actions input[type="file"], .footer .module-subscribe__actions
        .plugin-release .field-type-image-form input[type="file"],
        .plugin-release .field-type-image-form .footer .module-subscribe__actions input[type="file"], .footer .module-subscribe__actions .add-plugin .field-type-image-form button, .add-plugin .field-type-image-form .footer .module-subscribe__actions button, .footer .module-subscribe__actions
        .plugin-edit .field-type-image-form button,
        .plugin-edit .field-type-image-form .footer .module-subscribe__actions button, .footer .module-subscribe__actions
        .plugin-release .field-type-image-form button,
        .plugin-release .field-type-image-form .footer .module-subscribe__actions button, .footer .module-subscribe__actions .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], .add-plugin .field-type-image-form .footer .module-subscribe__actions input[name="field_addon_logo_und_0_remove_button"], .footer .module-subscribe__actions
        .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
        .plugin-edit .field-type-image-form .footer .module-subscribe__actions input[name="field_addon_logo_und_0_remove_button"], .footer .module-subscribe__actions
        .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
        .plugin-release .field-type-image-form .footer .module-subscribe__actions input[name="field_addon_logo_und_0_remove_button"], .footer .module-subscribe__actions #editor-builder #draggable-plugins .all-elements-option a, #editor-builder #draggable-plugins .all-elements-option .footer .module-subscribe__actions a, .footer .module-subscribe__actions
        #editor-builder .languages .all-elements-option a,
        #editor-builder .languages .all-elements-option .footer .module-subscribe__actions a, .footer .module-subscribe__actions .login .form-control[type="submit"], .login .footer .module-subscribe__actions .form-control[type="submit"], .footer .module-subscribe__actions
        .register .form-control[type="submit"],
        .register .footer .module-subscribe__actions .form-control[type="submit"], .footer .module-subscribe__actions
        .info-page .form-control[type="submit"],
        .info-page .footer .module-subscribe__actions .form-control[type="submit"], .footer .module-subscribe__actions .most-popular__wrapper .add_to_basket, .most-popular__wrapper .footer .module-subscribe__actions .add_to_basket, .footer .module-subscribe__actions .add-ons-list .views-row .add_to_basket, .add-ons-list .views-row .footer .module-subscribe__actions .add_to_basket, .footer .module-subscribe__actions
        .add-ons-list .views-row .check-details,
        .add-ons-list .views-row .footer .module-subscribe__actions .check-details, .footer .module-subscribe__actions
        .add-ons-list .views-row .remove_from_basket,
        .add-ons-list .views-row .footer .module-subscribe__actions .remove_from_basket, .footer .module-subscribe__actions
        .add-ons-list .views-row .required,
        .add-ons-list .views-row .footer .module-subscribe__actions .required, .footer .module-subscribe__actions .user-edit input[type="submit"], .user-edit .footer .module-subscribe__actions input[type="submit"], .footer .module-subscribe__actions
        .user-edit select[type="submit"],
        .user-edit .footer .module-subscribe__actions select[type="submit"] {
          padding: 10px 24px; } }
    .footer .module-subscribe__actions .form-group input {
      height: 40px;
      border: 2px solid #30a3f1;
      font-size: 1.6rem; }
    .footer .module-subscribe__actions .form-group:last-of-type {
      margin-left: 0; }
    .footer .module-subscribe__actions + .mc-response {
      box-shadow: none; }
      .footer .module-subscribe__actions + .mc-response--success, .footer .module-subscribe__actions + .mc-response--error {
        border: 1px solid rgba(0, 0, 0, 0.15); }

.footer-social {
  margin: 2.5rem auto; }
  .footer-social ul {
    list-style: none;
    padding: 0;
    margin: 0; }
  .footer-social li {
    display: inline-block;
    margin-left: 0.625rem;
    margin-right: 0.625rem; }
    .footer-social li:first-of-type {
      margin-left: 0; }
    .footer-social li .icon svg * {
      fill: rgba(255, 255, 255, 0.8); }
    .footer-social li .icon:hover svg * {
      fill: white; }
  @media (max-width: 767px) {
    .footer-social {
      margin-top: 0; } }
  @media (min-width: 1024px) {
    .footer-social {
      text-align: inherit;
      margin-top: 2.5rem;
      margin-bottom: 0; } }

.footer-policy {
  width: 100%;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.8);
  text-align: center; }
  @media (min-width: 768px) {
    .footer-policy {
      font-size: 1.3rem; } }
  @media (min-width: 1280px) {
    .footer-policy {
      text-align: left; } }
  @media (min-width: 1280px) {
    .footer-policy.section-container, .footer-policy.site-info__wrapper {
      margin-top: 5rem; } }
  @media (min-width: 1280px) {
    .footer-policy.footer-simple.section-container, .footer-policy.footer-simple.site-info__wrapper {
      margin-top: 1.25rem; } }
  .footer-policy p {
    display: inline-block;
    margin: 0; }
  .footer-policy__menu {
    display: inline-block;
    list-style: none;
    margin: 0;
    padding: 0;
    margin-left: 2.5rem; }
    .footer-policy__menu li {
      display: inline-block;
      margin-right: 1.25rem; }
      .footer-policy__menu li:last-of-type {
        margin-right: 0; }
      .footer-policy__menu li a {
        color: white; }
        .footer-policy__menu li a:hover {
          text-decoration: underline; }
  .footer-policy .dashboard-link {
    margin-top: 2.5rem;
    margin-bottom: 1.25rem;
    border-bottom: 1px solid #51616b; }
    .footer-policy .dashboard-link a {
      color: rgba(255, 255, 255, 0.8);
      font-weight: 800; }
      .footer-policy .dashboard-link a:hover {
        color: white; }

.footer-mobile {
  width: 100%;
  max-width: 100%;
  text-align: center; }
  .footer-mobile ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  @media (min-width: 768px) {
    .footer-mobile {
      width: 460px;
      margin-bottom: 2.5rem;
      margin-left: auto;
      margin-right: auto;
      text-align: inherit; } }
  @media (min-width: 1024px) {
    .footer-mobile {
      display: none; } }
  .footer-mobile h2 {
    margin: 0;
    font-size: 1.9rem;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.8);
    line-height: 5rem; }
  .footer-mobile__column {
    margin-bottom: 2.5rem;
    padding: 0; }
    @media (min-width: 768px) {
      .footer-mobile__column {
        padding-left: 5rem;
        padding-right: 5rem; } }
  .footer-mobile__social {
    text-align: center; }
  .footer-mobile__menu li {
    height: 3rem;
    line-height: 3rem; }
    .footer-mobile__menu li a {
      display: block;
      color: white;
      font-size: 1.3rem;
      font-weight: 700; }
      @media (min-width: 1024px) {
        .footer-mobile__menu li a {
          font-size: 1.6rem; } }
      @media (min-width: 1024px) {
        .footer-mobile__menu li a {
          font-size: inherit;
          font-weight: inherit; } }

.footer-newsletter h2 {
  font-size: 1.9rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0; }
  @media (min-width: 768px) {
    .footer-newsletter h2 {
      margin-bottom: .5rem; } }

.footer-newsletter .module-subscribe__actions {
  width: 400px;
  max-width: 80%;
  margin-top: 0.625rem;
  margin-bottom: 0;
  box-shadow: none; }
  .footer-newsletter .module-subscribe__actions .btn, .footer-newsletter .module-subscribe__actions #cksource_repo_search_forums_form .form-submit, #cksource_repo_search_forums_form .footer-newsletter .module-subscribe__actions .form-submit, .footer-newsletter .module-subscribe__actions #forum-sort .form-submit, #forum-sort .footer-newsletter .module-subscribe__actions .form-submit, .footer-newsletter .module-subscribe__actions .add-plugin input[type="submit"], .add-plugin .footer-newsletter .module-subscribe__actions input[type="submit"], .footer-newsletter .module-subscribe__actions
  .add-plugin select[type="submit"],
  .add-plugin .footer-newsletter .module-subscribe__actions select[type="submit"], .footer-newsletter .module-subscribe__actions
  .plugin-edit input[type="submit"],
  .plugin-edit .footer-newsletter .module-subscribe__actions input[type="submit"], .footer-newsletter .module-subscribe__actions
  .plugin-edit select[type="submit"],
  .plugin-edit .footer-newsletter .module-subscribe__actions select[type="submit"], .footer-newsletter .module-subscribe__actions
  .plugin-release input[type="submit"],
  .plugin-release .footer-newsletter .module-subscribe__actions input[type="submit"], .footer-newsletter .module-subscribe__actions
  .plugin-release select[type="submit"],
  .plugin-release .footer-newsletter .module-subscribe__actions select[type="submit"], .footer-newsletter .module-subscribe__actions .add-plugin .field-type-image-form input[type="file"], .add-plugin .field-type-image-form .footer-newsletter .module-subscribe__actions input[type="file"], .footer-newsletter .module-subscribe__actions
  .plugin-edit .field-type-image-form input[type="file"],
  .plugin-edit .field-type-image-form .footer-newsletter .module-subscribe__actions input[type="file"], .footer-newsletter .module-subscribe__actions
  .plugin-release .field-type-image-form input[type="file"],
  .plugin-release .field-type-image-form .footer-newsletter .module-subscribe__actions input[type="file"], .footer-newsletter .module-subscribe__actions .add-plugin .field-type-image-form button, .add-plugin .field-type-image-form .footer-newsletter .module-subscribe__actions button, .footer-newsletter .module-subscribe__actions
  .plugin-edit .field-type-image-form button,
  .plugin-edit .field-type-image-form .footer-newsletter .module-subscribe__actions button, .footer-newsletter .module-subscribe__actions
  .plugin-release .field-type-image-form button,
  .plugin-release .field-type-image-form .footer-newsletter .module-subscribe__actions button, .footer-newsletter .module-subscribe__actions .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], .add-plugin .field-type-image-form .footer-newsletter .module-subscribe__actions input[name="field_addon_logo_und_0_remove_button"], .footer-newsletter .module-subscribe__actions
  .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
  .plugin-edit .field-type-image-form .footer-newsletter .module-subscribe__actions input[name="field_addon_logo_und_0_remove_button"], .footer-newsletter .module-subscribe__actions
  .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
  .plugin-release .field-type-image-form .footer-newsletter .module-subscribe__actions input[name="field_addon_logo_und_0_remove_button"], .footer-newsletter .module-subscribe__actions #editor-builder #draggable-plugins .all-elements-option a, #editor-builder #draggable-plugins .all-elements-option .footer-newsletter .module-subscribe__actions a, .footer-newsletter .module-subscribe__actions
  #editor-builder .languages .all-elements-option a,
  #editor-builder .languages .all-elements-option .footer-newsletter .module-subscribe__actions a, .footer-newsletter .module-subscribe__actions .login .form-control[type="submit"], .login .footer-newsletter .module-subscribe__actions .form-control[type="submit"], .footer-newsletter .module-subscribe__actions
  .register .form-control[type="submit"],
  .register .footer-newsletter .module-subscribe__actions .form-control[type="submit"], .footer-newsletter .module-subscribe__actions
  .info-page .form-control[type="submit"],
  .info-page .footer-newsletter .module-subscribe__actions .form-control[type="submit"], .footer-newsletter .module-subscribe__actions .most-popular__wrapper .add_to_basket, .most-popular__wrapper .footer-newsletter .module-subscribe__actions .add_to_basket, .footer-newsletter .module-subscribe__actions .add-ons-list .views-row .add_to_basket, .add-ons-list .views-row .footer-newsletter .module-subscribe__actions .add_to_basket, .footer-newsletter .module-subscribe__actions
  .add-ons-list .views-row .check-details,
  .add-ons-list .views-row .footer-newsletter .module-subscribe__actions .check-details, .footer-newsletter .module-subscribe__actions
  .add-ons-list .views-row .remove_from_basket,
  .add-ons-list .views-row .footer-newsletter .module-subscribe__actions .remove_from_basket, .footer-newsletter .module-subscribe__actions
  .add-ons-list .views-row .required,
  .add-ons-list .views-row .footer-newsletter .module-subscribe__actions .required, .footer-newsletter .module-subscribe__actions .user-edit input[type="submit"], .user-edit .footer-newsletter .module-subscribe__actions input[type="submit"], .footer-newsletter .module-subscribe__actions
  .user-edit select[type="submit"],
  .user-edit .footer-newsletter .module-subscribe__actions select[type="submit"] {
    padding: 9.5px 24px; }
  @media (min-width: 768px) {
    .footer-newsletter .module-subscribe__actions {
      margin-top: 0; } }
  @media (min-width: 1280px) {
    .footer-newsletter .module-subscribe__actions .btn, .footer-newsletter .module-subscribe__actions #cksource_repo_search_forums_form .form-submit, #cksource_repo_search_forums_form .footer-newsletter .module-subscribe__actions .form-submit, .footer-newsletter .module-subscribe__actions #forum-sort .form-submit, #forum-sort .footer-newsletter .module-subscribe__actions .form-submit, .footer-newsletter .module-subscribe__actions .add-plugin input[type="submit"], .add-plugin .footer-newsletter .module-subscribe__actions input[type="submit"], .footer-newsletter .module-subscribe__actions
    .add-plugin select[type="submit"],
    .add-plugin .footer-newsletter .module-subscribe__actions select[type="submit"], .footer-newsletter .module-subscribe__actions
    .plugin-edit input[type="submit"],
    .plugin-edit .footer-newsletter .module-subscribe__actions input[type="submit"], .footer-newsletter .module-subscribe__actions
    .plugin-edit select[type="submit"],
    .plugin-edit .footer-newsletter .module-subscribe__actions select[type="submit"], .footer-newsletter .module-subscribe__actions
    .plugin-release input[type="submit"],
    .plugin-release .footer-newsletter .module-subscribe__actions input[type="submit"], .footer-newsletter .module-subscribe__actions
    .plugin-release select[type="submit"],
    .plugin-release .footer-newsletter .module-subscribe__actions select[type="submit"], .footer-newsletter .module-subscribe__actions .add-plugin .field-type-image-form input[type="file"], .add-plugin .field-type-image-form .footer-newsletter .module-subscribe__actions input[type="file"], .footer-newsletter .module-subscribe__actions
    .plugin-edit .field-type-image-form input[type="file"],
    .plugin-edit .field-type-image-form .footer-newsletter .module-subscribe__actions input[type="file"], .footer-newsletter .module-subscribe__actions
    .plugin-release .field-type-image-form input[type="file"],
    .plugin-release .field-type-image-form .footer-newsletter .module-subscribe__actions input[type="file"], .footer-newsletter .module-subscribe__actions .add-plugin .field-type-image-form button, .add-plugin .field-type-image-form .footer-newsletter .module-subscribe__actions button, .footer-newsletter .module-subscribe__actions
    .plugin-edit .field-type-image-form button,
    .plugin-edit .field-type-image-form .footer-newsletter .module-subscribe__actions button, .footer-newsletter .module-subscribe__actions
    .plugin-release .field-type-image-form button,
    .plugin-release .field-type-image-form .footer-newsletter .module-subscribe__actions button, .footer-newsletter .module-subscribe__actions .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], .add-plugin .field-type-image-form .footer-newsletter .module-subscribe__actions input[name="field_addon_logo_und_0_remove_button"], .footer-newsletter .module-subscribe__actions
    .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
    .plugin-edit .field-type-image-form .footer-newsletter .module-subscribe__actions input[name="field_addon_logo_und_0_remove_button"], .footer-newsletter .module-subscribe__actions
    .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
    .plugin-release .field-type-image-form .footer-newsletter .module-subscribe__actions input[name="field_addon_logo_und_0_remove_button"], .footer-newsletter .module-subscribe__actions #editor-builder #draggable-plugins .all-elements-option a, #editor-builder #draggable-plugins .all-elements-option .footer-newsletter .module-subscribe__actions a, .footer-newsletter .module-subscribe__actions
    #editor-builder .languages .all-elements-option a,
    #editor-builder .languages .all-elements-option .footer-newsletter .module-subscribe__actions a, .footer-newsletter .module-subscribe__actions .login .form-control[type="submit"], .login .footer-newsletter .module-subscribe__actions .form-control[type="submit"], .footer-newsletter .module-subscribe__actions
    .register .form-control[type="submit"],
    .register .footer-newsletter .module-subscribe__actions .form-control[type="submit"], .footer-newsletter .module-subscribe__actions
    .info-page .form-control[type="submit"],
    .info-page .footer-newsletter .module-subscribe__actions .form-control[type="submit"], .footer-newsletter .module-subscribe__actions .most-popular__wrapper .add_to_basket, .most-popular__wrapper .footer-newsletter .module-subscribe__actions .add_to_basket, .footer-newsletter .module-subscribe__actions .add-ons-list .views-row .add_to_basket, .add-ons-list .views-row .footer-newsletter .module-subscribe__actions .add_to_basket, .footer-newsletter .module-subscribe__actions
    .add-ons-list .views-row .check-details,
    .add-ons-list .views-row .footer-newsletter .module-subscribe__actions .check-details, .footer-newsletter .module-subscribe__actions
    .add-ons-list .views-row .remove_from_basket,
    .add-ons-list .views-row .footer-newsletter .module-subscribe__actions .remove_from_basket, .footer-newsletter .module-subscribe__actions
    .add-ons-list .views-row .required,
    .add-ons-list .views-row .footer-newsletter .module-subscribe__actions .required, .footer-newsletter .module-subscribe__actions .user-edit input[type="submit"], .user-edit .footer-newsletter .module-subscribe__actions input[type="submit"], .footer-newsletter .module-subscribe__actions
    .user-edit select[type="submit"],
    .user-edit .footer-newsletter .module-subscribe__actions select[type="submit"] {
      padding: 10px 24px; } }
  .footer-newsletter .module-subscribe__actions .form-group input {
    height: 40px;
    border: 0;
    font-size: 1.6rem; }
  .footer-newsletter .module-subscribe__actions .form-group:last-of-type {
    margin-left: 0; }
    @media (max-width: 767px) {
      .footer-newsletter .module-subscribe__actions .form-group:last-of-type {
        margin-top: 1.25rem; } }
  .footer-newsletter .module-subscribe__actions + .mc-response {
    box-shadow: none; }
    .footer-newsletter .module-subscribe__actions + .mc-response--success, .footer-newsletter .module-subscribe__actions + .mc-response--error {
      color: white;
      border: 1px solid rgba(0, 0, 0, 0.15); }
    .footer-newsletter .module-subscribe__actions + .mc-response--success {
      background-color: #2dbe61; }
    .footer-newsletter .module-subscribe__actions + .mc-response--error {
      background-color: #d9534f; }
    .footer-newsletter .module-subscribe__actions + .mc-response a {
      font-weight: 600;
      color: white; }

.header {
  position: relative;
  height: 100vh;
  background-attachment: fixed;
  background-size: cover;
  background-color: #111111;
  background-repeat: no-repeat;
  background-position: center top;
  color: white;
  text-align: center;
  overflow: hidden; }
  .header:not(.header--new) picture img {
    min-width: 100%;
    height: 100vh; }
    @media (min-width: 1024px) {
      .header:not(.header--new) picture img {
        width: 100%; } }
    @media (min-width: 1280px) {
      .header:not(.header--new) picture img {
        width: auto;
        height: auto; } }
    @media (min-width: 1920px) {
      .header:not(.header--new) picture img {
        width: 100%;
        height: 450px; } }
  @media (min-width: 1280px) {
    .header {
      height: 450px;
      background-size: 100% 450px; } }
  .header--no-tagline .header-content {
    padding-bottom: 2.5rem; }
  .header-content {
    width: 100%;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    transition: transform 200ms ease-in-out; }
    @media (min-width: 768px) {
      .header-content {
        top: auto;
        bottom: 50%;
        transform: translate(-50%, 50%); } }
    @media (min-width: 1280px) {
      .header-content {
        bottom: 0;
        transform: translateX(-50%); } }
  .header-cover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(rgba(17, 17, 17, 0.7), rgba(17, 17, 17, 0.1));
    opacity: 0.9;
    z-index: -1;
    transition: all 200ms ease-in-out; }
    .header-cover--active {
      z-index: 99; }
  .header__goto-content {
    display: block;
    position: absolute;
    bottom: 2.5rem;
    left: 50%;
    width: 68px;
    height: 33px;
    margin-left: -34px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../images/icon/arrow-down.svg);
    z-index: 999;
    animation-name: pulseDown;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease; }
    @media (min-width: 768px) {
      .header__goto-content {
        bottom: 5rem; } }
    @media (min-width: 1280px) {
      .header__goto-content {
        display: none; } }
  .header .btn.btn-cta--long, .header #cksource_repo_search_forums_form .btn-cta--long.form-submit, #cksource_repo_search_forums_form .header .btn-cta--long.form-submit, .header #forum-sort .btn-cta--long.form-submit, #forum-sort .header .btn-cta--long.form-submit, .header .add-plugin input.btn-cta--long[type="submit"], .add-plugin .header input.btn-cta--long[type="submit"], .header
  .add-plugin select.btn-cta--long[type="submit"],
  .add-plugin .header select.btn-cta--long[type="submit"], .header
  .plugin-edit input.btn-cta--long[type="submit"],
  .plugin-edit .header input.btn-cta--long[type="submit"], .header
  .plugin-edit select.btn-cta--long[type="submit"],
  .plugin-edit .header select.btn-cta--long[type="submit"], .header
  .plugin-release input.btn-cta--long[type="submit"],
  .plugin-release .header input.btn-cta--long[type="submit"], .header
  .plugin-release select.btn-cta--long[type="submit"],
  .plugin-release .header select.btn-cta--long[type="submit"], .header .add-plugin .field-type-image-form input.btn-cta--long[type="file"], .add-plugin .field-type-image-form .header input.btn-cta--long[type="file"], .header
  .plugin-edit .field-type-image-form input.btn-cta--long[type="file"],
  .plugin-edit .field-type-image-form .header input.btn-cta--long[type="file"], .header
  .plugin-release .field-type-image-form input.btn-cta--long[type="file"],
  .plugin-release .field-type-image-form .header input.btn-cta--long[type="file"], .header .add-plugin .field-type-image-form button.btn-cta--long, .add-plugin .field-type-image-form .header button.btn-cta--long, .header
  .plugin-edit .field-type-image-form button.btn-cta--long,
  .plugin-edit .field-type-image-form .header button.btn-cta--long, .header
  .plugin-release .field-type-image-form button.btn-cta--long,
  .plugin-release .field-type-image-form .header button.btn-cta--long, .header .add-plugin .field-type-image-form input.btn-cta--long[name="field_addon_logo_und_0_remove_button"], .add-plugin .field-type-image-form .header input.btn-cta--long[name="field_addon_logo_und_0_remove_button"], .header
  .plugin-edit .field-type-image-form input.btn-cta--long[name="field_addon_logo_und_0_remove_button"],
  .plugin-edit .field-type-image-form .header input.btn-cta--long[name="field_addon_logo_und_0_remove_button"], .header
  .plugin-release .field-type-image-form input.btn-cta--long[name="field_addon_logo_und_0_remove_button"],
  .plugin-release .field-type-image-form .header input.btn-cta--long[name="field_addon_logo_und_0_remove_button"], .header #editor-builder #draggable-plugins .all-elements-option a.btn-cta--long, #editor-builder #draggable-plugins .all-elements-option .header a.btn-cta--long, .header
  #editor-builder .languages .all-elements-option a.btn-cta--long,
  #editor-builder .languages .all-elements-option .header a.btn-cta--long, .header .login .btn-cta--long.form-control[type="submit"], .login .header .btn-cta--long.form-control[type="submit"], .header
  .register .btn-cta--long.form-control[type="submit"],
  .register .header .btn-cta--long.form-control[type="submit"], .header
  .info-page .btn-cta--long.form-control[type="submit"],
  .info-page .header .btn-cta--long.form-control[type="submit"], .header .most-popular__wrapper .btn-cta--long.add_to_basket, .most-popular__wrapper .header .btn-cta--long.add_to_basket, .header .add-ons-list .views-row .btn-cta--long.add_to_basket, .add-ons-list .views-row .header .btn-cta--long.add_to_basket, .header
  .add-ons-list .views-row .btn-cta--long.check-details,
  .add-ons-list .views-row .header .btn-cta--long.check-details, .header
  .add-ons-list .views-row .btn-cta--long.remove_from_basket,
  .add-ons-list .views-row .header .btn-cta--long.remove_from_basket, .header
  .add-ons-list .views-row .btn-cta--long.required,
  .add-ons-list .views-row .header .btn-cta--long.required, .header .user-edit input.btn-cta--long[type="submit"], .user-edit .header input.btn-cta--long[type="submit"], .header
  .user-edit select.btn-cta--long[type="submit"],
  .user-edit .header select.btn-cta--long[type="submit"] {
    background-color: white;
    border-color: white;
    color: rgba(64, 81, 102, 0.96); }
    .header .btn.btn-cta--long:first-of-type, .header #cksource_repo_search_forums_form .btn-cta--long.form-submit:first-of-type, #cksource_repo_search_forums_form .header .btn-cta--long.form-submit:first-of-type, .header #forum-sort .btn-cta--long.form-submit:first-of-type, #forum-sort .header .btn-cta--long.form-submit:first-of-type, .header .add-plugin input.btn-cta--long[type="submit"]:first-of-type, .add-plugin .header input.btn-cta--long[type="submit"]:first-of-type, .header
    .add-plugin select.btn-cta--long[type="submit"]:first-of-type,
    .add-plugin .header select.btn-cta--long[type="submit"]:first-of-type, .header
    .plugin-edit input.btn-cta--long[type="submit"]:first-of-type,
    .plugin-edit .header input.btn-cta--long[type="submit"]:first-of-type, .header
    .plugin-edit select.btn-cta--long[type="submit"]:first-of-type,
    .plugin-edit .header select.btn-cta--long[type="submit"]:first-of-type, .header
    .plugin-release input.btn-cta--long[type="submit"]:first-of-type,
    .plugin-release .header input.btn-cta--long[type="submit"]:first-of-type, .header
    .plugin-release select.btn-cta--long[type="submit"]:first-of-type,
    .plugin-release .header select.btn-cta--long[type="submit"]:first-of-type, .header .add-plugin .field-type-image-form input.btn-cta--long[type="file"]:first-of-type, .add-plugin .field-type-image-form .header input.btn-cta--long[type="file"]:first-of-type, .header
    .plugin-edit .field-type-image-form input.btn-cta--long[type="file"]:first-of-type,
    .plugin-edit .field-type-image-form .header input.btn-cta--long[type="file"]:first-of-type, .header
    .plugin-release .field-type-image-form input.btn-cta--long[type="file"]:first-of-type,
    .plugin-release .field-type-image-form .header input.btn-cta--long[type="file"]:first-of-type, .header .add-plugin .field-type-image-form button.btn-cta--long:first-of-type, .add-plugin .field-type-image-form .header button.btn-cta--long:first-of-type, .header
    .plugin-edit .field-type-image-form button.btn-cta--long:first-of-type,
    .plugin-edit .field-type-image-form .header button.btn-cta--long:first-of-type, .header
    .plugin-release .field-type-image-form button.btn-cta--long:first-of-type,
    .plugin-release .field-type-image-form .header button.btn-cta--long:first-of-type, .header .add-plugin .field-type-image-form input.btn-cta--long[name="field_addon_logo_und_0_remove_button"]:first-of-type, .add-plugin .field-type-image-form .header input.btn-cta--long[name="field_addon_logo_und_0_remove_button"]:first-of-type, .header
    .plugin-edit .field-type-image-form input.btn-cta--long[name="field_addon_logo_und_0_remove_button"]:first-of-type,
    .plugin-edit .field-type-image-form .header input.btn-cta--long[name="field_addon_logo_und_0_remove_button"]:first-of-type, .header
    .plugin-release .field-type-image-form input.btn-cta--long[name="field_addon_logo_und_0_remove_button"]:first-of-type,
    .plugin-release .field-type-image-form .header input.btn-cta--long[name="field_addon_logo_und_0_remove_button"]:first-of-type, .header #editor-builder #draggable-plugins .all-elements-option a.btn-cta--long:first-of-type, #editor-builder #draggable-plugins .all-elements-option .header a.btn-cta--long:first-of-type, .header
    #editor-builder .languages .all-elements-option a.btn-cta--long:first-of-type,
    #editor-builder .languages .all-elements-option .header a.btn-cta--long:first-of-type, .header .login .btn-cta--long.form-control[type="submit"]:first-of-type, .login .header .btn-cta--long.form-control[type="submit"]:first-of-type, .header
    .register .btn-cta--long.form-control[type="submit"]:first-of-type,
    .register .header .btn-cta--long.form-control[type="submit"]:first-of-type, .header
    .info-page .btn-cta--long.form-control[type="submit"]:first-of-type,
    .info-page .header .btn-cta--long.form-control[type="submit"]:first-of-type, .header .most-popular__wrapper .btn-cta--long.add_to_basket:first-of-type, .most-popular__wrapper .header .btn-cta--long.add_to_basket:first-of-type, .header .add-ons-list .views-row .btn-cta--long.add_to_basket:first-of-type, .add-ons-list .views-row .header .btn-cta--long.add_to_basket:first-of-type, .header
    .add-ons-list .views-row .btn-cta--long.check-details:first-of-type,
    .add-ons-list .views-row .header .btn-cta--long.check-details:first-of-type, .header
    .add-ons-list .views-row .btn-cta--long.remove_from_basket:first-of-type,
    .add-ons-list .views-row .header .btn-cta--long.remove_from_basket:first-of-type, .header
    .add-ons-list .views-row .btn-cta--long.required:first-of-type,
    .add-ons-list .views-row .header .btn-cta--long.required:first-of-type, .header .user-edit input.btn-cta--long[type="submit"]:first-of-type, .user-edit .header input.btn-cta--long[type="submit"]:first-of-type, .header
    .user-edit select.btn-cta--long[type="submit"]:first-of-type,
    .user-edit .header select.btn-cta--long[type="submit"]:first-of-type {
      margin-right: 1.25rem; }
    @media (min-width: 768px) {
      .header .btn.btn-cta--long, .header #cksource_repo_search_forums_form .btn-cta--long.form-submit, #cksource_repo_search_forums_form .header .btn-cta--long.form-submit, .header #forum-sort .btn-cta--long.form-submit, #forum-sort .header .btn-cta--long.form-submit, .header .add-plugin input.btn-cta--long[type="submit"], .add-plugin .header input.btn-cta--long[type="submit"], .header
      .add-plugin select.btn-cta--long[type="submit"],
      .add-plugin .header select.btn-cta--long[type="submit"], .header
      .plugin-edit input.btn-cta--long[type="submit"],
      .plugin-edit .header input.btn-cta--long[type="submit"], .header
      .plugin-edit select.btn-cta--long[type="submit"],
      .plugin-edit .header select.btn-cta--long[type="submit"], .header
      .plugin-release input.btn-cta--long[type="submit"],
      .plugin-release .header input.btn-cta--long[type="submit"], .header
      .plugin-release select.btn-cta--long[type="submit"],
      .plugin-release .header select.btn-cta--long[type="submit"], .header .add-plugin .field-type-image-form input.btn-cta--long[type="file"], .add-plugin .field-type-image-form .header input.btn-cta--long[type="file"], .header
      .plugin-edit .field-type-image-form input.btn-cta--long[type="file"],
      .plugin-edit .field-type-image-form .header input.btn-cta--long[type="file"], .header
      .plugin-release .field-type-image-form input.btn-cta--long[type="file"],
      .plugin-release .field-type-image-form .header input.btn-cta--long[type="file"], .header .add-plugin .field-type-image-form button.btn-cta--long, .add-plugin .field-type-image-form .header button.btn-cta--long, .header
      .plugin-edit .field-type-image-form button.btn-cta--long,
      .plugin-edit .field-type-image-form .header button.btn-cta--long, .header
      .plugin-release .field-type-image-form button.btn-cta--long,
      .plugin-release .field-type-image-form .header button.btn-cta--long, .header .add-plugin .field-type-image-form input.btn-cta--long[name="field_addon_logo_und_0_remove_button"], .add-plugin .field-type-image-form .header input.btn-cta--long[name="field_addon_logo_und_0_remove_button"], .header
      .plugin-edit .field-type-image-form input.btn-cta--long[name="field_addon_logo_und_0_remove_button"],
      .plugin-edit .field-type-image-form .header input.btn-cta--long[name="field_addon_logo_und_0_remove_button"], .header
      .plugin-release .field-type-image-form input.btn-cta--long[name="field_addon_logo_und_0_remove_button"],
      .plugin-release .field-type-image-form .header input.btn-cta--long[name="field_addon_logo_und_0_remove_button"], .header #editor-builder #draggable-plugins .all-elements-option a.btn-cta--long, #editor-builder #draggable-plugins .all-elements-option .header a.btn-cta--long, .header
      #editor-builder .languages .all-elements-option a.btn-cta--long,
      #editor-builder .languages .all-elements-option .header a.btn-cta--long, .header .login .btn-cta--long.form-control[type="submit"], .login .header .btn-cta--long.form-control[type="submit"], .header
      .register .btn-cta--long.form-control[type="submit"],
      .register .header .btn-cta--long.form-control[type="submit"], .header
      .info-page .btn-cta--long.form-control[type="submit"],
      .info-page .header .btn-cta--long.form-control[type="submit"], .header .most-popular__wrapper .btn-cta--long.add_to_basket, .most-popular__wrapper .header .btn-cta--long.add_to_basket, .header .add-ons-list .views-row .btn-cta--long.add_to_basket, .add-ons-list .views-row .header .btn-cta--long.add_to_basket, .header
      .add-ons-list .views-row .btn-cta--long.check-details,
      .add-ons-list .views-row .header .btn-cta--long.check-details, .header
      .add-ons-list .views-row .btn-cta--long.remove_from_basket,
      .add-ons-list .views-row .header .btn-cta--long.remove_from_basket, .header
      .add-ons-list .views-row .btn-cta--long.required,
      .add-ons-list .views-row .header .btn-cta--long.required, .header .user-edit input.btn-cta--long[type="submit"], .user-edit .header input.btn-cta--long[type="submit"], .header
      .user-edit select.btn-cta--long[type="submit"],
      .user-edit .header select.btn-cta--long[type="submit"] {
        min-width: 18.5rem; } }
    .header .btn.btn-cta--long:hover, .header #cksource_repo_search_forums_form .btn-cta--long.form-submit:hover, #cksource_repo_search_forums_form .header .btn-cta--long.form-submit:hover, .header #forum-sort .btn-cta--long.form-submit:hover, #forum-sort .header .btn-cta--long.form-submit:hover, .header .add-plugin input.btn-cta--long[type="submit"]:hover, .add-plugin .header input.btn-cta--long[type="submit"]:hover, .header
    .add-plugin select.btn-cta--long[type="submit"]:hover,
    .add-plugin .header select.btn-cta--long[type="submit"]:hover, .header
    .plugin-edit input.btn-cta--long[type="submit"]:hover,
    .plugin-edit .header input.btn-cta--long[type="submit"]:hover, .header
    .plugin-edit select.btn-cta--long[type="submit"]:hover,
    .plugin-edit .header select.btn-cta--long[type="submit"]:hover, .header
    .plugin-release input.btn-cta--long[type="submit"]:hover,
    .plugin-release .header input.btn-cta--long[type="submit"]:hover, .header
    .plugin-release select.btn-cta--long[type="submit"]:hover,
    .plugin-release .header select.btn-cta--long[type="submit"]:hover, .header .add-plugin .field-type-image-form input.btn-cta--long[type="file"]:hover, .add-plugin .field-type-image-form .header input.btn-cta--long[type="file"]:hover, .header
    .plugin-edit .field-type-image-form input.btn-cta--long[type="file"]:hover,
    .plugin-edit .field-type-image-form .header input.btn-cta--long[type="file"]:hover, .header
    .plugin-release .field-type-image-form input.btn-cta--long[type="file"]:hover,
    .plugin-release .field-type-image-form .header input.btn-cta--long[type="file"]:hover, .header .add-plugin .field-type-image-form button.btn-cta--long:hover, .add-plugin .field-type-image-form .header button.btn-cta--long:hover, .header
    .plugin-edit .field-type-image-form button.btn-cta--long:hover,
    .plugin-edit .field-type-image-form .header button.btn-cta--long:hover, .header
    .plugin-release .field-type-image-form button.btn-cta--long:hover,
    .plugin-release .field-type-image-form .header button.btn-cta--long:hover, .header .add-plugin .field-type-image-form input.btn-cta--long[name="field_addon_logo_und_0_remove_button"]:hover, .add-plugin .field-type-image-form .header input.btn-cta--long[name="field_addon_logo_und_0_remove_button"]:hover, .header
    .plugin-edit .field-type-image-form input.btn-cta--long[name="field_addon_logo_und_0_remove_button"]:hover,
    .plugin-edit .field-type-image-form .header input.btn-cta--long[name="field_addon_logo_und_0_remove_button"]:hover, .header
    .plugin-release .field-type-image-form input.btn-cta--long[name="field_addon_logo_und_0_remove_button"]:hover,
    .plugin-release .field-type-image-form .header input.btn-cta--long[name="field_addon_logo_und_0_remove_button"]:hover, .header #editor-builder #draggable-plugins .all-elements-option a.btn-cta--long:hover, #editor-builder #draggable-plugins .all-elements-option .header a.btn-cta--long:hover, .header
    #editor-builder .languages .all-elements-option a.btn-cta--long:hover,
    #editor-builder .languages .all-elements-option .header a.btn-cta--long:hover, .header .login .btn-cta--long.form-control[type="submit"]:hover, .login .header .btn-cta--long.form-control[type="submit"]:hover, .header
    .register .btn-cta--long.form-control[type="submit"]:hover,
    .register .header .btn-cta--long.form-control[type="submit"]:hover, .header
    .info-page .btn-cta--long.form-control[type="submit"]:hover,
    .info-page .header .btn-cta--long.form-control[type="submit"]:hover, .header .most-popular__wrapper .btn-cta--long.add_to_basket:hover, .most-popular__wrapper .header .btn-cta--long.add_to_basket:hover, .header .add-ons-list .views-row .btn-cta--long.add_to_basket:hover, .add-ons-list .views-row .header .btn-cta--long.add_to_basket:hover, .header
    .add-ons-list .views-row .btn-cta--long.check-details:hover,
    .add-ons-list .views-row .header .btn-cta--long.check-details:hover, .header
    .add-ons-list .views-row .btn-cta--long.remove_from_basket:hover,
    .add-ons-list .views-row .header .btn-cta--long.remove_from_basket:hover, .header
    .add-ons-list .views-row .btn-cta--long.required:hover,
    .add-ons-list .views-row .header .btn-cta--long.required:hover, .header .user-edit input.btn-cta--long[type="submit"]:hover, .user-edit .header input.btn-cta--long[type="submit"]:hover, .header
    .user-edit select.btn-cta--long[type="submit"]:hover,
    .user-edit .header select.btn-cta--long[type="submit"]:hover {
      background-color: white;
      border-color: white;
      color: #30a3f1; }
  .header h1,
  .header h2 {
    width: 900px;
    max-width: 100%;
    margin: 0 auto;
    text-align: center; }
  .header h1 {
    position: relative;
    padding-top: 100px;
    margin-bottom: 1rem; }
    @media (min-width: 768px) {
      .header h1 {
        padding-top: 140px; } }
    @media (min-width: 1280px) {
      .header h1 {
        margin-bottom: 0; } }
    .header h1:before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      width: 90px;
      height: 90px;
      margin-left: 8px;
      transform: translateX(-50%);
      background-repeat: no-repeat;
      background-size: contain; }
      @media (min-width: 768px) {
        .header h1:before {
          width: 130px;
          height: 130px;
          margin-left: 7.5px; } }
  .header h2 {
    display: none;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    color: rgba(255, 255, 255, 0.8);
    text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5); }
    @media (min-height: 460px) {
      .header h2 {
        display: block; } }
    @media (max-width: 767px) {
      .header h2 {
        font-size: 2.8rem;
        line-height: 3.5rem;
        margin-top: 1rem; } }
    @media (min-width: 768px) {
      .header h2 {
        margin-top: 0; } }
  .header--new {
    height: 360px;
    background-size: 100% 360px;
    text-align: left; }
    @media (max-width: 767px) {
      .header--new {
        height: auto; } }
    .header--new.header--ckeditor-5 {
      background-color: #1f9961; }
    .header--new.header--ckeditor-4 {
      background-color: #0287cc; }
      @media (min-width: 1024px) {
        .header--new.header--ckeditor-4 .header-content {
          width: 655px; } }
    .header--new.header--ckfinder {
      background-color: #13ae97; }
      @media (min-width: 768px) and (max-width: 1279px) {
        .header--new.header--ckfinder .header-content {
          width: 420px; } }
    .header--new.header--real-time-collaborative-editing, .header--new.header--real-time-collaborative-editing-demo, .header--new.header--track-changes, .header--new.header--track-changes-demo, .header--new.header--comments, .header--new.header--comments-demo, .header--new.header--collaboration, .header--new.header--startups {
      background-color: #1f9961; }
    .header--new.header--contact {
      background-color: #404F5F; }
    .header--new.header--why-ckeditor, .header--new.header--enterprise, .header--new.header--pricing {
      background-color: #942cc8; }
    .header--new .header-content {
      width: 480px;
      max-width: 100%;
      padding: 0 2.5rem;
      margin-top: 5rem; }
      @media (max-width: 767px) {
        .header--new .header-content {
          width: 100%;
          position: relative;
          text-align: center;
          margin-bottom: 2.5rem;
          transform: none;
          left: auto;
          margin-top: 75px; } }
      @media (min-width: 768px) {
        .header--new .header-content {
          top: 50%;
          bottom: auto;
          left: 2.5rem;
          padding: 0;
          margin-top: 0;
          transform: translateY(-50%);
          padding-top: 50px; } }
      @media (min-width: 1024px) {
        .header--new .header-content {
          width: 700px; } }
      @media (min-width: 1280px) {
        .header--new .header-content {
          left: 50%;
          margin-left: -200px;
          transform: translate(-50%, -50%); } }
      @media (min-width: 1500px) and (min-height: 900px) {
        .header--new .header-content {
          margin-left: -350px; } }
    @media (max-width: 767px) {
      .header--new picture {
        display: none; } }
    .header--new picture img {
      position: absolute;
      left: 50%;
      transform: translateX(-50%); }
      @media (min-width: 768px) {
        .header--new picture img {
          transform: translateX(-46.5%); } }
      @media (min-width: 1024px) {
        .header--new picture img {
          transform: translateX(-40%); } }
    .header--new h1 {
      padding: 0;
      width: auto;
      font-size: 3.6rem;
      text-align: inherit; }
      @media (min-width: 1024px) {
        .header--new h1 {
          font-size: 4.4rem; } }
    .header--new p {
      margin-top: 1rem;
      font-size: 1.8rem; }
      .header--new p span {
        display: inline-block;
        clear: both; }
    .header--new .header__goto-content {
      display: none; }
  .header--letters h1:before {
    background-image: url(../images/product/letters.svg);
    width: 80px;
    margin-left: 0; }
    @media (min-width: 768px) {
      .header--letters h1:before {
        width: 115px; } }
  .header--accessibility-checker h1, .header--collaborative-editing h1, .header--collaboration-features h1, .header--ckfinder h1, .header--easy-image h1 {
    padding-top: 0; }
    .header--accessibility-checker h1 :before, .header--collaborative-editing h1 :before, .header--collaboration-features h1 :before, .header--ckfinder h1 :before, .header--easy-image h1 :before {
      display: none; }
  @media (min-width: 1280px) {
    .header--collaborative-editing .header-content {
      bottom: 60px; } }
  .header--ckeditor-cloud-services h1:before {
    background-image: url(../images/product/cloud.svg);
    width: 80px;
    margin-left: 0; }
    @media (min-width: 768px) {
      .header--ckeditor-cloud-services h1:before {
        width: 115px; } }
  .header--404 {
    background-image: url(../images/header/404.jpg); }
  .header--letters-for-drupal {
    background-image: url(../images/header/letters.jpg); }
  .header--letters-for-wordpress {
    background-image: url(../images/header/letters-for-wordpress.jpg); }
  .header--blog {
    height: 40vh; }
    .header--blog h2 {
      margin-top: 2.5rem; }
    .header--blog h1,
    .header--blog h2 {
      max-width: 100%; }

section,
.section {
  width: 100%;
  overflow: hidden;
  margin-top: 5rem; }
  section:last-of-type,
  .section:last-of-type {
    margin-bottom: 5rem; }
  @media (min-width: 768px) {
    section,
    .section {
      margin-top: 10rem; }
      section:first-of-type,
      .section:first-of-type {
        margin-top: 5rem; }
      section:last-of-type,
      .section:last-of-type {
        margin-bottom: 5rem; } }
  @media (min-width: 1280px) {
    section,
    .section {
      padding: 0; } }

.section-container, .site-info__wrapper {
  width: 100%;
  padding-left: 2.5rem;
  padding-right: 2.5rem; }
  @media (min-width: 768px) {
    .section-container, .site-info__wrapper {
      margin-right: auto;
      margin-left: auto;
      padding-left: 10px;
      padding-right: 10px; } }
  @media (min-width: 768px) and (min-width: 1280px) {
    .section-container, .site-info__wrapper {
      max-width: 1260px; } }
  @media (min-width: 768px) and (min-width: 1680px) {
    .section-container, .site-info__wrapper {
      max-width: 1680px; } }
  @media (min-width: 1280px) {
    .section-container, .site-info__wrapper {
      margin-right: auto;
      margin-left: auto;
      padding-left: 10px;
      padding-right: 10px; } }

header,
main,
footer {
  display: flex;
  width: 100%;
  flex-direction: column; }

* {
  box-sizing: border-box; }

a:focus, a:active,
button:focus,
button:active {
  outline-width: 2px;
  outline-style: solid;
  outline-color: Highlight; }
  @media (-webkit-min-device-pixel-ratio: 0) {
    a:focus, a:active,
    button:focus,
    button:active {
      outline-color: -webkit-focus-ring-color;
      outline-style: auto; } }
  @media (-moz-touch-enabled: 1), (pointer: coarse) {
    a:focus, a:active,
    button:focus,
    button:active {
      outline: none; } }

.module-cta {
  text-align: center; }
  @media (max-width: 767px) {
    .module-cta {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem; } }
  .module-cta__actions {
    margin-top: 2.5rem; }
  .module-cta--blue {
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-color: #f2f9ff; }
    .module-cta--blue h2 {
      width: 1100px;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto; }
  .module-cta--gray-narrow, .module-cta--gray {
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-color: #f6f9fc; }
    .module-cta--gray-narrow, .module-cta--gray-narrow:last-of-type, .module-cta--gray, .module-cta--gray:last-of-type {
      margin-bottom: 0; }
    .module-cta--gray-narrow h2, .module-cta--gray h2 {
      max-width: 100%;
      margin-left: auto;
      margin-right: auto; }
      @media (min-width: 1280px) {
        .module-cta--gray-narrow h2, .module-cta--gray h2 {
          font-size: 4.4rem; } }
  .module-cta--gray h2 {
    width: 870px; }
  .module-cta--gray-narrow h2 {
    width: 660px; }
  .module-cta--2 .btn, .module-cta--2 #cksource_repo_search_forums_form .form-submit, #cksource_repo_search_forums_form .module-cta--2 .form-submit, .module-cta--2 #forum-sort .form-submit, #forum-sort .module-cta--2 .form-submit, .module-cta--2 .add-plugin input[type="submit"], .add-plugin .module-cta--2 input[type="submit"], .module-cta--2
  .add-plugin select[type="submit"],
  .add-plugin .module-cta--2 select[type="submit"], .module-cta--2
  .plugin-edit input[type="submit"],
  .plugin-edit .module-cta--2 input[type="submit"], .module-cta--2
  .plugin-edit select[type="submit"],
  .plugin-edit .module-cta--2 select[type="submit"], .module-cta--2
  .plugin-release input[type="submit"],
  .plugin-release .module-cta--2 input[type="submit"], .module-cta--2
  .plugin-release select[type="submit"],
  .plugin-release .module-cta--2 select[type="submit"], .module-cta--2 .add-plugin .field-type-image-form input[type="file"], .add-plugin .field-type-image-form .module-cta--2 input[type="file"], .module-cta--2
  .plugin-edit .field-type-image-form input[type="file"],
  .plugin-edit .field-type-image-form .module-cta--2 input[type="file"], .module-cta--2
  .plugin-release .field-type-image-form input[type="file"],
  .plugin-release .field-type-image-form .module-cta--2 input[type="file"], .module-cta--2 .add-plugin .field-type-image-form button, .add-plugin .field-type-image-form .module-cta--2 button, .module-cta--2
  .plugin-edit .field-type-image-form button,
  .plugin-edit .field-type-image-form .module-cta--2 button, .module-cta--2
  .plugin-release .field-type-image-form button,
  .plugin-release .field-type-image-form .module-cta--2 button, .module-cta--2 .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], .add-plugin .field-type-image-form .module-cta--2 input[name="field_addon_logo_und_0_remove_button"], .module-cta--2
  .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
  .plugin-edit .field-type-image-form .module-cta--2 input[name="field_addon_logo_und_0_remove_button"], .module-cta--2
  .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
  .plugin-release .field-type-image-form .module-cta--2 input[name="field_addon_logo_und_0_remove_button"], .module-cta--2 #editor-builder #draggable-plugins .all-elements-option a, #editor-builder #draggable-plugins .all-elements-option .module-cta--2 a, .module-cta--2
  #editor-builder .languages .all-elements-option a,
  #editor-builder .languages .all-elements-option .module-cta--2 a, .module-cta--2 .login .form-control[type="submit"], .login .module-cta--2 .form-control[type="submit"], .module-cta--2
  .register .form-control[type="submit"],
  .register .module-cta--2 .form-control[type="submit"], .module-cta--2
  .info-page .form-control[type="submit"],
  .info-page .module-cta--2 .form-control[type="submit"], .module-cta--2 .most-popular__wrapper .add_to_basket, .most-popular__wrapper .module-cta--2 .add_to_basket, .module-cta--2 .add-ons-list .views-row .add_to_basket, .add-ons-list .views-row .module-cta--2 .add_to_basket, .module-cta--2
  .add-ons-list .views-row .check-details,
  .add-ons-list .views-row .module-cta--2 .check-details, .module-cta--2
  .add-ons-list .views-row .remove_from_basket,
  .add-ons-list .views-row .module-cta--2 .remove_from_basket, .module-cta--2
  .add-ons-list .views-row .required,
  .add-ons-list .views-row .module-cta--2 .required, .module-cta--2 .user-edit input[type="submit"], .user-edit .module-cta--2 input[type="submit"], .module-cta--2
  .user-edit select[type="submit"],
  .user-edit .module-cta--2 select[type="submit"] {
    margin-right: 2.5rem; }
    .module-cta--2 .btn:last-of-type, .module-cta--2 #cksource_repo_search_forums_form .form-submit:last-of-type, #cksource_repo_search_forums_form .module-cta--2 .form-submit:last-of-type, .module-cta--2 #forum-sort .form-submit:last-of-type, #forum-sort .module-cta--2 .form-submit:last-of-type, .module-cta--2 .add-plugin input[type="submit"]:last-of-type, .add-plugin .module-cta--2 input[type="submit"]:last-of-type, .module-cta--2
    .add-plugin select[type="submit"]:last-of-type,
    .add-plugin .module-cta--2 select[type="submit"]:last-of-type, .module-cta--2
    .plugin-edit input[type="submit"]:last-of-type,
    .plugin-edit .module-cta--2 input[type="submit"]:last-of-type, .module-cta--2
    .plugin-edit select[type="submit"]:last-of-type,
    .plugin-edit .module-cta--2 select[type="submit"]:last-of-type, .module-cta--2
    .plugin-release input[type="submit"]:last-of-type,
    .plugin-release .module-cta--2 input[type="submit"]:last-of-type, .module-cta--2
    .plugin-release select[type="submit"]:last-of-type,
    .plugin-release .module-cta--2 select[type="submit"]:last-of-type, .module-cta--2 .add-plugin .field-type-image-form input[type="file"]:last-of-type, .add-plugin .field-type-image-form .module-cta--2 input[type="file"]:last-of-type, .module-cta--2
    .plugin-edit .field-type-image-form input[type="file"]:last-of-type,
    .plugin-edit .field-type-image-form .module-cta--2 input[type="file"]:last-of-type, .module-cta--2
    .plugin-release .field-type-image-form input[type="file"]:last-of-type,
    .plugin-release .field-type-image-form .module-cta--2 input[type="file"]:last-of-type, .module-cta--2 .add-plugin .field-type-image-form button:last-of-type, .add-plugin .field-type-image-form .module-cta--2 button:last-of-type, .module-cta--2
    .plugin-edit .field-type-image-form button:last-of-type,
    .plugin-edit .field-type-image-form .module-cta--2 button:last-of-type, .module-cta--2
    .plugin-release .field-type-image-form button:last-of-type,
    .plugin-release .field-type-image-form .module-cta--2 button:last-of-type, .module-cta--2 .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:last-of-type, .add-plugin .field-type-image-form .module-cta--2 input[name="field_addon_logo_und_0_remove_button"]:last-of-type, .module-cta--2
    .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:last-of-type,
    .plugin-edit .field-type-image-form .module-cta--2 input[name="field_addon_logo_und_0_remove_button"]:last-of-type, .module-cta--2
    .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:last-of-type,
    .plugin-release .field-type-image-form .module-cta--2 input[name="field_addon_logo_und_0_remove_button"]:last-of-type, .module-cta--2 #editor-builder #draggable-plugins .all-elements-option a:last-of-type, #editor-builder #draggable-plugins .all-elements-option .module-cta--2 a:last-of-type, .module-cta--2
    #editor-builder .languages .all-elements-option a:last-of-type,
    #editor-builder .languages .all-elements-option .module-cta--2 a:last-of-type, .module-cta--2 .login .form-control[type="submit"]:last-of-type, .login .module-cta--2 .form-control[type="submit"]:last-of-type, .module-cta--2
    .register .form-control[type="submit"]:last-of-type,
    .register .module-cta--2 .form-control[type="submit"]:last-of-type, .module-cta--2
    .info-page .form-control[type="submit"]:last-of-type,
    .info-page .module-cta--2 .form-control[type="submit"]:last-of-type, .module-cta--2 .most-popular__wrapper .add_to_basket:last-of-type, .most-popular__wrapper .module-cta--2 .add_to_basket:last-of-type, .module-cta--2 .add-ons-list .views-row .add_to_basket:last-of-type, .add-ons-list .views-row .module-cta--2 .add_to_basket:last-of-type, .module-cta--2
    .add-ons-list .views-row .check-details:last-of-type,
    .add-ons-list .views-row .module-cta--2 .check-details:last-of-type, .module-cta--2
    .add-ons-list .views-row .remove_from_basket:last-of-type,
    .add-ons-list .views-row .module-cta--2 .remove_from_basket:last-of-type, .module-cta--2
    .add-ons-list .views-row .required:last-of-type,
    .add-ons-list .views-row .module-cta--2 .required:last-of-type, .module-cta--2 .user-edit input[type="submit"]:last-of-type, .user-edit .module-cta--2 input[type="submit"]:last-of-type, .module-cta--2
    .user-edit select[type="submit"]:last-of-type,
    .user-edit .module-cta--2 select[type="submit"]:last-of-type {
      margin-right: 0; }
  .module-cta p {
    width: 740px;
    max-width: 100%;
    margin: 0 auto; }
    .module-cta p:first-of-type {
      margin-top: 2.5rem; }
    .module-cta p:last-of-type {
      margin-bottom: 2.5rem; }
  .module-cta--home {
    margin-top: 2.5rem;
    background-color: #f8fbfc;
    padding-top: 5rem;
    padding-bottom: 5rem; }
    @media (min-width: 1280px) {
      .module-cta--home {
        margin-top: 7.5rem; } }
    .module-cta--home h2 {
      font-weight: 800; }
    .module-cta--home p {
      width: 800px;
      margin-top: 0; }
    .module-cta--home .btn, .module-cta--home #cksource_repo_search_forums_form .form-submit, #cksource_repo_search_forums_form .module-cta--home .form-submit, .module-cta--home #forum-sort .form-submit, #forum-sort .module-cta--home .form-submit, .module-cta--home .add-plugin input[type="submit"], .add-plugin .module-cta--home input[type="submit"], .module-cta--home
    .add-plugin select[type="submit"],
    .add-plugin .module-cta--home select[type="submit"], .module-cta--home
    .plugin-edit input[type="submit"],
    .plugin-edit .module-cta--home input[type="submit"], .module-cta--home
    .plugin-edit select[type="submit"],
    .plugin-edit .module-cta--home select[type="submit"], .module-cta--home
    .plugin-release input[type="submit"],
    .plugin-release .module-cta--home input[type="submit"], .module-cta--home
    .plugin-release select[type="submit"],
    .plugin-release .module-cta--home select[type="submit"], .module-cta--home .add-plugin .field-type-image-form input[type="file"], .add-plugin .field-type-image-form .module-cta--home input[type="file"], .module-cta--home
    .plugin-edit .field-type-image-form input[type="file"],
    .plugin-edit .field-type-image-form .module-cta--home input[type="file"], .module-cta--home
    .plugin-release .field-type-image-form input[type="file"],
    .plugin-release .field-type-image-form .module-cta--home input[type="file"], .module-cta--home .add-plugin .field-type-image-form button, .add-plugin .field-type-image-form .module-cta--home button, .module-cta--home
    .plugin-edit .field-type-image-form button,
    .plugin-edit .field-type-image-form .module-cta--home button, .module-cta--home
    .plugin-release .field-type-image-form button,
    .plugin-release .field-type-image-form .module-cta--home button, .module-cta--home .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], .add-plugin .field-type-image-form .module-cta--home input[name="field_addon_logo_und_0_remove_button"], .module-cta--home
    .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
    .plugin-edit .field-type-image-form .module-cta--home input[name="field_addon_logo_und_0_remove_button"], .module-cta--home
    .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
    .plugin-release .field-type-image-form .module-cta--home input[name="field_addon_logo_und_0_remove_button"], .module-cta--home #editor-builder #draggable-plugins .all-elements-option a, #editor-builder #draggable-plugins .all-elements-option .module-cta--home a, .module-cta--home
    #editor-builder .languages .all-elements-option a,
    #editor-builder .languages .all-elements-option .module-cta--home a, .module-cta--home .login .form-control[type="submit"], .login .module-cta--home .form-control[type="submit"], .module-cta--home
    .register .form-control[type="submit"],
    .register .module-cta--home .form-control[type="submit"], .module-cta--home
    .info-page .form-control[type="submit"],
    .info-page .module-cta--home .form-control[type="submit"], .module-cta--home .most-popular__wrapper .add_to_basket, .most-popular__wrapper .module-cta--home .add_to_basket, .module-cta--home .add-ons-list .views-row .add_to_basket, .add-ons-list .views-row .module-cta--home .add_to_basket, .module-cta--home
    .add-ons-list .views-row .check-details,
    .add-ons-list .views-row .module-cta--home .check-details, .module-cta--home
    .add-ons-list .views-row .remove_from_basket,
    .add-ons-list .views-row .module-cta--home .remove_from_basket, .module-cta--home
    .add-ons-list .views-row .required,
    .add-ons-list .views-row .module-cta--home .required, .module-cta--home .user-edit input[type="submit"], .user-edit .module-cta--home input[type="submit"], .module-cta--home
    .user-edit select[type="submit"],
    .user-edit .module-cta--home select[type="submit"] {
      background-color: #0a90eb;
      border-color: #0a90eb; }
      .module-cta--home .btn:hover, .module-cta--home #cksource_repo_search_forums_form .form-submit:hover, #cksource_repo_search_forums_form .module-cta--home .form-submit:hover, .module-cta--home #forum-sort .form-submit:hover, #forum-sort .module-cta--home .form-submit:hover, .module-cta--home .add-plugin input[type="submit"]:hover, .add-plugin .module-cta--home input[type="submit"]:hover, .module-cta--home
      .add-plugin select[type="submit"]:hover,
      .add-plugin .module-cta--home select[type="submit"]:hover, .module-cta--home
      .plugin-edit input[type="submit"]:hover,
      .plugin-edit .module-cta--home input[type="submit"]:hover, .module-cta--home
      .plugin-edit select[type="submit"]:hover,
      .plugin-edit .module-cta--home select[type="submit"]:hover, .module-cta--home
      .plugin-release input[type="submit"]:hover,
      .plugin-release .module-cta--home input[type="submit"]:hover, .module-cta--home
      .plugin-release select[type="submit"]:hover,
      .plugin-release .module-cta--home select[type="submit"]:hover, .module-cta--home .add-plugin .field-type-image-form input[type="file"]:hover, .add-plugin .field-type-image-form .module-cta--home input[type="file"]:hover, .module-cta--home
      .plugin-edit .field-type-image-form input[type="file"]:hover,
      .plugin-edit .field-type-image-form .module-cta--home input[type="file"]:hover, .module-cta--home
      .plugin-release .field-type-image-form input[type="file"]:hover,
      .plugin-release .field-type-image-form .module-cta--home input[type="file"]:hover, .module-cta--home .add-plugin .field-type-image-form button:hover, .add-plugin .field-type-image-form .module-cta--home button:hover, .module-cta--home
      .plugin-edit .field-type-image-form button:hover,
      .plugin-edit .field-type-image-form .module-cta--home button:hover, .module-cta--home
      .plugin-release .field-type-image-form button:hover,
      .plugin-release .field-type-image-form .module-cta--home button:hover, .module-cta--home .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:hover, .add-plugin .field-type-image-form .module-cta--home input[name="field_addon_logo_und_0_remove_button"]:hover, .module-cta--home
      .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:hover,
      .plugin-edit .field-type-image-form .module-cta--home input[name="field_addon_logo_und_0_remove_button"]:hover, .module-cta--home
      .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:hover,
      .plugin-release .field-type-image-form .module-cta--home input[name="field_addon_logo_und_0_remove_button"]:hover, .module-cta--home #editor-builder #draggable-plugins .all-elements-option a:hover, #editor-builder #draggable-plugins .all-elements-option .module-cta--home a:hover, .module-cta--home
      #editor-builder .languages .all-elements-option a:hover,
      #editor-builder .languages .all-elements-option .module-cta--home a:hover, .module-cta--home .login .form-control[type="submit"]:hover, .login .module-cta--home .form-control[type="submit"]:hover, .module-cta--home
      .register .form-control[type="submit"]:hover,
      .register .module-cta--home .form-control[type="submit"]:hover, .module-cta--home
      .info-page .form-control[type="submit"]:hover,
      .info-page .module-cta--home .form-control[type="submit"]:hover, .module-cta--home .most-popular__wrapper .add_to_basket:hover, .most-popular__wrapper .module-cta--home .add_to_basket:hover, .module-cta--home .add-ons-list .views-row .add_to_basket:hover, .add-ons-list .views-row .module-cta--home .add_to_basket:hover, .module-cta--home
      .add-ons-list .views-row .check-details:hover,
      .add-ons-list .views-row .module-cta--home .check-details:hover, .module-cta--home
      .add-ons-list .views-row .remove_from_basket:hover,
      .add-ons-list .views-row .module-cta--home .remove_from_basket:hover, .module-cta--home
      .add-ons-list .views-row .required:hover,
      .add-ons-list .views-row .module-cta--home .required:hover, .module-cta--home .user-edit input[type="submit"]:hover, .user-edit .module-cta--home input[type="submit"]:hover, .module-cta--home
      .user-edit select[type="submit"]:hover,
      .user-edit .module-cta--home select[type="submit"]:hover {
        border-color: #0981d3;
        background-color: #0981d3; }

.form-response {
  display: none;
  float: left;
  text-align: center;
  opacity: 0;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.8); }
  @media (min-width: 768px) {
    .form-response {
      text-align: right; } }
  .form-response:before {
    content: '';
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-right: 1rem;
    margin-bottom: .5rem;
    vertical-align: middle;
    background-size: cover;
    background-repeat: no-repeat; }
  .form-response--error {
    color: #d9534f; }
    .form-response--error:before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 300 300'%3E%3Cpath fill='%23D9534F' d='M150 0C67.2 0 0 67.2 0 150s67.2 150 150 150 150-67.2 150-150S232.8 0 150 0zm56.6 207.2c-6 6-15.7 6-21.7 0L150.7 173 115 208.7c-6 6-15.6 6-21.6 0-6-6-6-15.7 0-21.7l35.7-35.6L96 118c-6-6-6-15.6 0-21.5 6-6 15.6-6 21.6 0l33.2 33.2L182.4 98c6-6 15.7-6 21.7 0s6 15.7 0 21.7l-31.5 31.7 34 34c6 6 6 15.8 0 21.8z'/%3E%3C/svg%3E%0A"); }
  .form-response--success {
    text-align: center;
    color: #2dbe61; }
    .form-response--success:before {
      background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 300 300'%3E%3Cpath fill='%235CB85C' d='M150 0C67.2 0 0 67.2 0 150s67.2 150 150 150 150-67.2 150-150S232.8 0 150 0zm73.4 128.8l-76.7 69.5c-2 1.7-4 2.8-6.3 3.4-2 1-4.2 1.4-6.3 1.4-3.7 0-7.5-1.3-10.5-4l-47.2-45c-6-5.8-6.4-15.5-.5-21.7 5.8-6 15.5-6.4 21.7-.5l37.5 35.6 67.8-61.3c6.3-5.6 16-5 21.7 1.2 5.7 6.2 5.2 16-1 21.6z'/%3E%3C/svg%3E%0A"); }
  .form-response--idle {
    width: 100%;
    margin-top: 2.5rem; }
  .form-response--active {
    display: block;
    opacity: 1;
    -webkit-animation-name: fadeIn;
    -moz-animation-name: fadeIn;
    -ms-animation-name: fadeIn;
    -o-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-iteration-count: 1s;
    -moz-animation-iteration-count: 1s;
    -ms-animation-iteration-count: 1s;
    -o-animation-iteration-count: 1s;
    animation-iteration-count: 1s;
    -webkit-animation-duration: 300ms;
    -moz-animation-duration: 300ms;
    -ms-animation-duration: 300ms;
    -o-animation-duration: 300ms;
    animation-duration: 300ms;
    -webkit-animation-delay: 0s;
    -moz-animation-delay: 0s;
    -ms-animation-delay: 0s;
    -o-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    -o-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden; }
    .form-response--active.removing {
      opacity: 0;
      animation: none;
      transition: opacity 200ms ease-in-out; }

pre[class*="language-"] {
  margin-top: 28px;
  margin-bottom: 28px;
  padding: 28px;
  border-radius: 4px; }

code[class*="language-"],
pre[class*="language-"] {
  font-size: 1.6rem;
  font-family: Monaco, Consolas, monospace; }

.site-blog-post .zEWidget-launcher {
  margin-bottom: 7.5rem !important; }
  @media (min-width: 1024px) {
    .site-blog-post .zEWidget-launcher {
      margin-bottom: 2.5rem !important; } }

.site-blog-post footer.footer {
  padding-bottom: 7.5rem; }
  @media (min-width: 1024px) {
    .site-blog-post footer.footer {
      padding-bottom: 2.5rem; } }

.zEWidget-webWidget {
  z-index: 9999999 !important; }

#at4-share,
#at4-soc {
  left: 2.5rem; }

.lg-backdrop {
  z-index: 999999 !important; }

.lg-outer {
  z-index: 1000000 !important; }
  .lg-outer .lg-toolbar .lg-icon {
    font-size: 42px;
    margin-top: 1.25rem;
    margin-right: 1.25rem; }

body .ck-body:not(.ltrs-body),
.collaborative-demo #collaboration,
.collaboration-demo__container {
  --ck-color-base-foreground: #fafafa;
  --ck-color-base-background: white;
  --ck-color-base-border: #c4c4c4;
  --ck-color-base-action: #61b045;
  --ck-color-base-focus: #6cb5f9;
  --ck-color-base-text: #333333;
  --ck-color-base-active: #198cf0;
  --ck-color-base-active-focus: #0e7fe1;
  /* -- Generic colors ------------------------------------------------------------------------ */
  --ck-color-focus-border: #47a4f5;
  --ck-color-focus-shadow: rgba(119, 186, 248, 0.5);
  --ck-color-focus-disabled-shadow: rgba(119, 186, 248, 0.3);
  --ck-color-text: var(--ck-color-base-text);
  --ck-color-shadow-drop: rgba(0, 0, 0, 0.15);
  --ck-color-shadow-inner: rgba(0, 0, 0, 0.1);
  /* -- Buttons ------------------------------------------------------------------------------- */
  --ck-color-button-default-background: transparent;
  --ck-color-button-default-hover-background: #e6e6e6;
  --ck-color-button-default-active-background: #d9d9d9;
  --ck-color-button-default-active-shadow: #bfbfbf;
  --ck-color-button-default-disabled-background: transparent;
  --ck-color-button-on-background: #dedede;
  --ck-color-button-on-hover-background: #c4c4c4;
  --ck-color-button-on-active-background: #bababa;
  --ck-color-button-on-active-shadow: #a1a1a1;
  --ck-color-button-on-disabled-background: #dedede;
  --ck-color-button-action-background: var(--ck-color-base-action);
  --ck-color-button-action-hover-background: #579e3d;
  --ck-color-button-action-active-background: #53973b;
  --ck-color-button-action-active-shadow: #498433;
  --ck-color-button-action-disabled-background: #7ec365;
  --ck-color-button-action-text: var(--ck-color-base-background);
  --ck-color-switch-button-off-background: #b0b0b0;
  --ck-color-switch-button-on-background: var(--ck-color-button-action-background);
  --ck-color-switch-button-inner-background: var(--ck-color-base-background);
  /* -- Dropdown ------------------------------------------------------------------------------ */
  --ck-color-dropdown-panel-background: var(--ck-color-base-background);
  --ck-color-dropdown-panel-border: var(--ck-color-base-border);
  /* -- Input --------------------------------------------------------------------------------- */
  --ck-color-input-background: var(--ck-color-base-background);
  --ck-color-input-border: #c7c7c7;
  --ck-color-input-text: var(--ck-color-base-text);
  --ck-color-input-disabled-background: #f2f2f2;
  --ck-color-input-disabled-border: #c7c7c7;
  --ck-color-input-disabled-text: #5c5c5c;
  /* -- List ---------------------------------------------------------------------------------- */
  --ck-color-list-background: var(--ck-color-base-background);
  --ck-color-list-button-hover-background: var(--ck-color-base-foreground);
  --ck-color-list-button-on-background: var(--ck-color-base-active);
  --ck-color-list-button-on-background-focus: var(--ck-color-base-active-focus);
  --ck-color-list-button-on-text: var(--ck-color-base-background);
  /* -- Panel --------------------------------------------------------------------------------- */
  --ck-color-panel-background: var(--ck-color-base-background);
  --ck-color-panel-border: var(--ck-color-base-border);
  /* -- Toolbar ------------------------------------------------------------------------------- */
  --ck-color-toolbar-background: var(--ck-color-base-foreground);
  --ck-color-toolbar-border: var(--ck-color-base-border);
  /* -- Tooltip ------------------------------------------------------------------------------- */
  --ck-color-tooltip-background: var(--ck-color-base-text);
  --ck-color-tooltip-text: var(--ck-color-base-background);
  /* -- Engine -------------------------------------------------------------------------------- */
  --ck-color-engine-placeholder-text: #c2c2c2;
  /* -- Upload -------------------------------------------------------------------------------- */
  --ck-color-upload-bar-background: #6cb5f9;
  --ck-color-upload-infinite-background: rgba(0, 0, 0, 0.1);
  /* -- Link -------------------------------------------------------------------------------- */
  --ck-color-link-default: #0000f0;
  --ck-color-link-selected-background: #ebf8ff; }
  body .ck-body:not(.ltrs-body) .ck.ck-link-actions .ck-button.ck-link-actions__preview .ck-button__label,
  .collaborative-demo #collaboration .ck.ck-link-actions .ck-button.ck-link-actions__preview .ck-button__label,
  .collaboration-demo__container .ck.ck-link-actions .ck-button.ck-link-actions__preview .ck-button__label {
    --ck-color-link-default: #0000f0;
    --ck-color-link-selected-background: #ebf8ff; }
  body .ck-body:not(.ltrs-body) .ck.ck-button.ck-button-save, body .ck-body:not(.ltrs-body) a.ck.ck-button.ck-button-save,
  .collaborative-demo #collaboration .ck.ck-button.ck-button-save,
  .collaborative-demo #collaboration a.ck.ck-button.ck-button-save,
  .collaboration-demo__container .ck.ck-button.ck-button-save,
  .collaboration-demo__container a.ck.ck-button.ck-button-save {
    --ck-color-button-save: #008a00; }
  body .ck-body:not(.ltrs-body) .ck.ck-button.ck-button-cancel, body .ck-body:not(.ltrs-body) a.ck.ck-button.ck-button-cancel,
  .collaborative-demo #collaboration .ck.ck-button.ck-button-cancel,
  .collaborative-demo #collaboration a.ck.ck-button.ck-button-cancel,
  .collaboration-demo__container .ck.ck-button.ck-button-cancel,
  .collaboration-demo__container a.ck.ck-button.ck-button-cancel {
    --ck-color-button-cancel: #db3700; }

.cke_emoji-panel {
  z-index: 99 !important; }

.skip-link a {
  position: fixed;
  left: 0;
  top: 0;
  padding: .5rem 1.5rem;
  background-color: rgba(17, 17, 17, 0.9);
  font-size: 1.6rem;
  color: white;
  transform: translateX(-100%);
  z-index: 9999999; }
  .skip-link a:active, .skip-link a:focus {
    text-decoration: none;
    transform: translateX(0); }

.skip-link__list {
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0; }

.steps {
  width: 515px;
  max-width: 100%;
  margin: 5rem auto; }
  @media (min-width: 768px) {
    .steps {
      display: none; } }
  .steps__item {
    width: 50px;
    height: 50px;
    background-color: #f0f0f0;
    display: inline-block;
    border: 2px solid;
    border-color: transparent;
    border-radius: 50%;
    color: rgba(64, 81, 102, 0.75);
    font-weight: 600;
    text-align: center;
    line-height: 41px;
    vertical-align: middle; }
    .steps__item:first-of-type {
      line-height: 46px; }
    .steps__item:nth-child(n+2) {
      margin: 0 0 0 100px;
      transform: translate(0, -4px); }
    .steps__item:nth-child(n+2):before {
      content: '';
      width: 80px;
      height: 3px;
      display: block;
      background-color: #f0f0f0;
      transform: translate(-95px, 21px); }
    .steps__item:after {
      content: attr(data-desc);
      width: 150px;
      display: block;
      color: rgba(64, 81, 102, 0.75);
      font-weight: 400;
      font-size: 1.3rem;
      transform: translate(-52px, 3px); }
    .steps__item:first-child:after {
      transform: translate(-55px, -1px); }
    .steps__item--active {
      border-color: #30a3f1;
      color: rgba(64, 81, 102, 0.96); }
      .steps__item--active:before, .steps__item--active:nth-child(n+2):before {
        background: #30a3f1; }
      .steps__item--active:after {
        color: rgba(64, 81, 102, 0.96);
        font-weight: 600; }
    .steps__item-status:after {
      content: attr(data-step); }
    .steps__item--done {
      background-color: #2dbe61;
      border-color: #2dbe61;
      color: white; }
      .steps__item--done .steps__item-status:after {
        content: '\2714'; }
      .steps__item--done:before, .steps__item--done:nth-child(n+2):before {
        background-color: #2dbe61; }
  .steps--small {
    display: none;
    margin: 0;
    width: auto;
    float: right; }
    @media (min-width: 768px) {
      .steps--small {
        display: block; } }
    .steps--small .steps__item {
      width: 36px;
      height: 36px;
      background-color: white;
      border-color: rgba(0, 0, 0, 0.15);
      font-size: 1.6rem;
      color: rgba(64, 81, 102, 0.75); }
      .steps--small .steps__item, .steps--small .steps__item:first-of-type {
        line-height: 35px; }
      .steps--small .steps__item:after, .steps--small .steps__item:before {
        display: none; }
      .steps--small .steps__item:nth-child(n+2) {
        margin-left: .5rem;
        transform: none; }
      .steps--small .steps__item--active {
        border-color: rgba(48, 163, 241, 0.8);
        color: rgba(64, 81, 102, 0.96); }
      .steps--small .steps__item--done {
        border-color: #2dbe61;
        color: #2dbe61; }
        .steps--small .steps__item--done .steps__item-status:after {
          content: '\2713'; }
    .steps--small .current-step p {
      margin-top: 2rem;
      margin-bottom: 0;
      font-size: 1.6rem;
      font-weight: 800;
      color: rgba(64, 81, 102, 0.75);
      text-align: center;
      line-height: 1; }

.module-subscribe {
  position: relative;
  text-align: center;
  padding: 5rem 0;
  background: #f2f9ff;
  overflow: hidden; }
  @media (min-width: 768px) {
    .module-subscribe {
      padding: 0;
      height: 320px; }
      .module-subscribe .section-container, .module-subscribe .site-info__wrapper {
        width: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 19; } }
  .module-subscribe--plain {
    height: 235px;
    margin-top: 2.5rem;
    color: inherit; }
    .module-subscribe--plain .module-subscribe__actions {
      margin: 2.5rem auto 0; }
      .module-subscribe--plain .module-subscribe__actions input {
        border: 2px solid #30a3f1; }
  .module-subscribe__actions {
    width: 480px;
    max-width: 100%;
    margin: 2.5rem auto 0;
    border-radius: 4px; }
    .module-subscribe__actions .form-group {
      width: 100%; }
      @media (min-width: 768px) {
        .module-subscribe__actions .form-group:first-of-type {
          border-radius: 4px 0 0 4px; }
        .module-subscribe__actions .form-group:last-of-type {
          margin-left: .5rem;
          border-radius: 0 4px 4px 0; } }
      .module-subscribe__actions .form-group-inline, .module-subscribe__actions .form-group-inline:last-of-type {
        margin-left: 0; }
      @media (max-width: 767px) {
        .module-subscribe__actions .form-group-inline__wrapper {
          flex-wrap: wrap; } }
      @media (min-width: 768px) {
        .module-subscribe__actions .form-group-inline {
          width: auto; } }
    .module-subscribe__actions .form-control {
      height: 49px;
      border: 2px solid #30a3f1; }
      @media (min-width: 768px) {
        .module-subscribe__actions .form-control {
          border-radius: 4px 0 0 4px; } }
    .module-subscribe__actions .btn, .module-subscribe__actions #cksource_repo_search_forums_form .form-submit, #cksource_repo_search_forums_form .module-subscribe__actions .form-submit, .module-subscribe__actions #forum-sort .form-submit, #forum-sort .module-subscribe__actions .form-submit, .module-subscribe__actions .add-plugin input[type="submit"], .add-plugin .module-subscribe__actions input[type="submit"], .module-subscribe__actions
    .add-plugin select[type="submit"],
    .add-plugin .module-subscribe__actions select[type="submit"], .module-subscribe__actions
    .plugin-edit input[type="submit"],
    .plugin-edit .module-subscribe__actions input[type="submit"], .module-subscribe__actions
    .plugin-edit select[type="submit"],
    .plugin-edit .module-subscribe__actions select[type="submit"], .module-subscribe__actions
    .plugin-release input[type="submit"],
    .plugin-release .module-subscribe__actions input[type="submit"], .module-subscribe__actions
    .plugin-release select[type="submit"],
    .plugin-release .module-subscribe__actions select[type="submit"], .module-subscribe__actions .add-plugin .field-type-image-form input[type="file"], .add-plugin .field-type-image-form .module-subscribe__actions input[type="file"], .module-subscribe__actions
    .plugin-edit .field-type-image-form input[type="file"],
    .plugin-edit .field-type-image-form .module-subscribe__actions input[type="file"], .module-subscribe__actions
    .plugin-release .field-type-image-form input[type="file"],
    .plugin-release .field-type-image-form .module-subscribe__actions input[type="file"], .module-subscribe__actions .add-plugin .field-type-image-form button, .add-plugin .field-type-image-form .module-subscribe__actions button, .module-subscribe__actions
    .plugin-edit .field-type-image-form button,
    .plugin-edit .field-type-image-form .module-subscribe__actions button, .module-subscribe__actions
    .plugin-release .field-type-image-form button,
    .plugin-release .field-type-image-form .module-subscribe__actions button, .module-subscribe__actions .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], .add-plugin .field-type-image-form .module-subscribe__actions input[name="field_addon_logo_und_0_remove_button"], .module-subscribe__actions
    .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
    .plugin-edit .field-type-image-form .module-subscribe__actions input[name="field_addon_logo_und_0_remove_button"], .module-subscribe__actions
    .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
    .plugin-release .field-type-image-form .module-subscribe__actions input[name="field_addon_logo_und_0_remove_button"], .module-subscribe__actions #editor-builder #draggable-plugins .all-elements-option a, #editor-builder #draggable-plugins .all-elements-option .module-subscribe__actions a, .module-subscribe__actions
    #editor-builder .languages .all-elements-option a,
    #editor-builder .languages .all-elements-option .module-subscribe__actions a, .module-subscribe__actions .login .form-control[type="submit"], .login .module-subscribe__actions .form-control[type="submit"], .module-subscribe__actions
    .register .form-control[type="submit"],
    .register .module-subscribe__actions .form-control[type="submit"], .module-subscribe__actions
    .info-page .form-control[type="submit"],
    .info-page .module-subscribe__actions .form-control[type="submit"], .module-subscribe__actions .most-popular__wrapper .add_to_basket, .most-popular__wrapper .module-subscribe__actions .add_to_basket, .module-subscribe__actions .add-ons-list .views-row .add_to_basket, .add-ons-list .views-row .module-subscribe__actions .add_to_basket, .module-subscribe__actions
    .add-ons-list .views-row .check-details,
    .add-ons-list .views-row .module-subscribe__actions .check-details, .module-subscribe__actions
    .add-ons-list .views-row .remove_from_basket,
    .add-ons-list .views-row .module-subscribe__actions .remove_from_basket, .module-subscribe__actions
    .add-ons-list .views-row .required,
    .add-ons-list .views-row .module-subscribe__actions .required, .module-subscribe__actions .user-edit input[type="submit"], .user-edit .module-subscribe__actions input[type="submit"], .module-subscribe__actions
    .user-edit select[type="submit"],
    .user-edit .module-subscribe__actions select[type="submit"] {
      display: block;
      width: 100%; }
      @media (min-width: 768px) {
        .module-subscribe__actions .btn, .module-subscribe__actions #cksource_repo_search_forums_form .form-submit, #cksource_repo_search_forums_form .module-subscribe__actions .form-submit, .module-subscribe__actions #forum-sort .form-submit, #forum-sort .module-subscribe__actions .form-submit, .module-subscribe__actions .add-plugin input[type="submit"], .add-plugin .module-subscribe__actions input[type="submit"], .module-subscribe__actions
        .add-plugin select[type="submit"],
        .add-plugin .module-subscribe__actions select[type="submit"], .module-subscribe__actions
        .plugin-edit input[type="submit"],
        .plugin-edit .module-subscribe__actions input[type="submit"], .module-subscribe__actions
        .plugin-edit select[type="submit"],
        .plugin-edit .module-subscribe__actions select[type="submit"], .module-subscribe__actions
        .plugin-release input[type="submit"],
        .plugin-release .module-subscribe__actions input[type="submit"], .module-subscribe__actions
        .plugin-release select[type="submit"],
        .plugin-release .module-subscribe__actions select[type="submit"], .module-subscribe__actions .add-plugin .field-type-image-form input[type="file"], .add-plugin .field-type-image-form .module-subscribe__actions input[type="file"], .module-subscribe__actions
        .plugin-edit .field-type-image-form input[type="file"],
        .plugin-edit .field-type-image-form .module-subscribe__actions input[type="file"], .module-subscribe__actions
        .plugin-release .field-type-image-form input[type="file"],
        .plugin-release .field-type-image-form .module-subscribe__actions input[type="file"], .module-subscribe__actions .add-plugin .field-type-image-form button, .add-plugin .field-type-image-form .module-subscribe__actions button, .module-subscribe__actions
        .plugin-edit .field-type-image-form button,
        .plugin-edit .field-type-image-form .module-subscribe__actions button, .module-subscribe__actions
        .plugin-release .field-type-image-form button,
        .plugin-release .field-type-image-form .module-subscribe__actions button, .module-subscribe__actions .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], .add-plugin .field-type-image-form .module-subscribe__actions input[name="field_addon_logo_und_0_remove_button"], .module-subscribe__actions
        .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
        .plugin-edit .field-type-image-form .module-subscribe__actions input[name="field_addon_logo_und_0_remove_button"], .module-subscribe__actions
        .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
        .plugin-release .field-type-image-form .module-subscribe__actions input[name="field_addon_logo_und_0_remove_button"], .module-subscribe__actions #editor-builder #draggable-plugins .all-elements-option a, #editor-builder #draggable-plugins .all-elements-option .module-subscribe__actions a, .module-subscribe__actions
        #editor-builder .languages .all-elements-option a,
        #editor-builder .languages .all-elements-option .module-subscribe__actions a, .module-subscribe__actions .login .form-control[type="submit"], .login .module-subscribe__actions .form-control[type="submit"], .module-subscribe__actions
        .register .form-control[type="submit"],
        .register .module-subscribe__actions .form-control[type="submit"], .module-subscribe__actions
        .info-page .form-control[type="submit"],
        .info-page .module-subscribe__actions .form-control[type="submit"], .module-subscribe__actions .most-popular__wrapper .add_to_basket, .most-popular__wrapper .module-subscribe__actions .add_to_basket, .module-subscribe__actions .add-ons-list .views-row .add_to_basket, .add-ons-list .views-row .module-subscribe__actions .add_to_basket, .module-subscribe__actions
        .add-ons-list .views-row .check-details,
        .add-ons-list .views-row .module-subscribe__actions .check-details, .module-subscribe__actions
        .add-ons-list .views-row .remove_from_basket,
        .add-ons-list .views-row .module-subscribe__actions .remove_from_basket, .module-subscribe__actions
        .add-ons-list .views-row .required,
        .add-ons-list .views-row .module-subscribe__actions .required, .module-subscribe__actions .user-edit input[type="submit"], .user-edit .module-subscribe__actions input[type="submit"], .module-subscribe__actions
        .user-edit select[type="submit"],
        .user-edit .module-subscribe__actions select[type="submit"] {
          display: inherit;
          width: inherit;
          margin-top: 0;
          border-radius: 0 4px 4px 0; } }
  @media (min-width: 768px) {
    .module-subscribe--short h2 {
      font-size: 3.5rem; } }
  .module-subscribe h2 {
    width: 1100px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2.5rem; }
    @media (min-width: 768px) {
      .module-subscribe h2 {
        font-size: 4.9rem; } }
  .module-subscribe--narrow h2 {
    width: 850px; }
  .module-subscribe h3 {
    margin-bottom: 2.5rem; }
  .module-subscribe p {
    width: 640px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto; }

#mce-responses {
  justify-content: center; }
  #mce-responses .response {
    width: 480px;
    max-width: 100%;
    display: none;
    flex-grow: unset !important;
    padding: .5rem 1.5rem;
    margin: 1.5rem auto;
    font-size: 1.6rem;
    border-radius: 4px; }
    @media (min-width: 768px) {
      #mce-responses .response {
        width: auto; } }
    #mce-responses .response#mce-error-response {
      background-color: rgba(255, 255, 255, 0.9);
      color: #d9534f; }
    #mce-responses .response#mce-success-response {
      background-color: white;
      color: rgba(64, 81, 102, 0.75); }
    #mce-responses .response a {
      display: none; }

.mc-response {
  width: 480px;
  max-width: 100%;
  padding: .5rem 1.5rem;
  font-size: 1.6rem;
  border-radius: 4px; }
  .mc-response p {
    margin: 0; }
  .mc-response--success, .mc-response--error {
    margin: 1.5rem auto; }
  .mc-response--success {
    background-color: white;
    color: rgba(64, 81, 102, 0.75); }
  .mc-response--error {
    background-color: rgba(255, 255, 255, 0.9);
    color: #d9534f; }

.subscribe__note a {
  font-weight: bolder; }
  .subscribe__note a, .subscribe__note a:hover {
    color: white; }

button {
  background-color: transparent; }

.btn, #cksource_repo_search_forums_form .form-submit, #forum-sort .form-submit, .add-plugin input[type="submit"],
.add-plugin select[type="submit"],
.plugin-edit input[type="submit"],
.plugin-edit select[type="submit"],
.plugin-release input[type="submit"],
.plugin-release select[type="submit"], .add-plugin .field-type-image-form input[type="file"],
.plugin-edit .field-type-image-form input[type="file"],
.plugin-release .field-type-image-form input[type="file"], .add-plugin .field-type-image-form button,
.plugin-edit .field-type-image-form button,
.plugin-release .field-type-image-form button, .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
.plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
.plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], #editor-builder #draggable-plugins .all-elements-option a,
#editor-builder .languages .all-elements-option a, .login .form-control[type="submit"],
.register .form-control[type="submit"],
.info-page .form-control[type="submit"], .most-popular__wrapper .add_to_basket, .add-ons-list .views-row .add_to_basket,
.add-ons-list .views-row .check-details,
.add-ons-list .views-row .remove_from_basket,
.add-ons-list .views-row .required, .user-edit input[type="submit"],
.user-edit select[type="submit"] {
  display: inline-block;
  text-decoration: none;
  text-align: center;
  font-weight: 600;
  line-height: 1;
  cursor: pointer;
  user-select: none;
  border-radius: 4px;
  transition: color 200ms ease-in-out, background-color 200ms ease-in-out, border-color 200ms ease-in-out, opacity 200ms ease-in-out;
  background-color: #30a3f1;
  border: 2px solid #30a3f1;
  color: white;
  padding: 9.6px 19.2px;
  font-size: 1.74rem; }
  .btn:hover, #cksource_repo_search_forums_form .form-submit:hover, #forum-sort .form-submit:hover, .add-plugin input[type="submit"]:hover,
  .add-plugin select[type="submit"]:hover,
  .plugin-edit input[type="submit"]:hover,
  .plugin-edit select[type="submit"]:hover,
  .plugin-release input[type="submit"]:hover,
  .plugin-release select[type="submit"]:hover, .add-plugin .field-type-image-form input[type="file"]:hover,
  .plugin-edit .field-type-image-form input[type="file"]:hover,
  .plugin-release .field-type-image-form input[type="file"]:hover, .add-plugin .field-type-image-form button:hover,
  .plugin-edit .field-type-image-form button:hover,
  .plugin-release .field-type-image-form button:hover, .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:hover,
  .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:hover,
  .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:hover, #editor-builder #draggable-plugins .all-elements-option a:hover,
  #editor-builder .languages .all-elements-option a:hover, .login .form-control[type="submit"]:hover,
  .register .form-control[type="submit"]:hover,
  .info-page .form-control[type="submit"]:hover, .most-popular__wrapper .add_to_basket:hover, .add-ons-list .views-row .add_to_basket:hover,
  .add-ons-list .views-row .check-details:hover,
  .add-ons-list .views-row .remove_from_basket:hover,
  .add-ons-list .views-row .required:hover, .user-edit input[type="submit"]:hover,
  .user-edit select[type="submit"]:hover, .btn:focus, #cksource_repo_search_forums_form .form-submit:focus, #forum-sort .form-submit:focus, .add-plugin input[type="submit"]:focus,
  .add-plugin select[type="submit"]:focus,
  .plugin-edit input[type="submit"]:focus,
  .plugin-edit select[type="submit"]:focus,
  .plugin-release input[type="submit"]:focus,
  .plugin-release select[type="submit"]:focus, .add-plugin .field-type-image-form input[type="file"]:focus,
  .plugin-edit .field-type-image-form input[type="file"]:focus,
  .plugin-release .field-type-image-form input[type="file"]:focus, .add-plugin .field-type-image-form button:focus,
  .plugin-edit .field-type-image-form button:focus,
  .plugin-release .field-type-image-form button:focus, .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:focus,
  .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:focus,
  .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:focus, #editor-builder #draggable-plugins .all-elements-option a:focus,
  #editor-builder .languages .all-elements-option a:focus, .login .form-control[type="submit"]:focus,
  .register .form-control[type="submit"]:focus,
  .info-page .form-control[type="submit"]:focus, .most-popular__wrapper .add_to_basket:focus, .add-ons-list .views-row .add_to_basket:focus,
  .add-ons-list .views-row .check-details:focus,
  .add-ons-list .views-row .remove_from_basket:focus,
  .add-ons-list .views-row .required:focus, .user-edit input[type="submit"]:focus,
  .user-edit select[type="submit"]:focus {
    text-decoration: none; }
  .btn.disabled, #cksource_repo_search_forums_form .disabled.form-submit, #forum-sort .disabled.form-submit, .add-plugin input.disabled[type="submit"],
  .add-plugin select.disabled[type="submit"],
  .plugin-edit input.disabled[type="submit"],
  .plugin-edit select.disabled[type="submit"],
  .plugin-release input.disabled[type="submit"],
  .plugin-release select.disabled[type="submit"], .add-plugin .field-type-image-form input.disabled[type="file"],
  .plugin-edit .field-type-image-form input.disabled[type="file"],
  .plugin-release .field-type-image-form input.disabled[type="file"], .add-plugin .field-type-image-form button.disabled,
  .plugin-edit .field-type-image-form button.disabled,
  .plugin-release .field-type-image-form button.disabled, .add-plugin .field-type-image-form input.disabled[name="field_addon_logo_und_0_remove_button"],
  .plugin-edit .field-type-image-form input.disabled[name="field_addon_logo_und_0_remove_button"],
  .plugin-release .field-type-image-form input.disabled[name="field_addon_logo_und_0_remove_button"], #editor-builder #draggable-plugins .all-elements-option a.disabled,
  #editor-builder .languages .all-elements-option a.disabled, .login .disabled.form-control[type="submit"],
  .register .disabled.form-control[type="submit"],
  .info-page .disabled.form-control[type="submit"], .most-popular__wrapper .disabled.add_to_basket, .add-ons-list .views-row .disabled.add_to_basket,
  .add-ons-list .views-row .disabled.check-details,
  .add-ons-list .views-row .disabled.remove_from_basket,
  .add-ons-list .views-row .disabled.required, .user-edit input.disabled[type="submit"],
  .user-edit select.disabled[type="submit"], .btn:disabled, #cksource_repo_search_forums_form .form-submit:disabled, #forum-sort .form-submit:disabled, .add-plugin input[type="submit"]:disabled,
  .add-plugin select[type="submit"]:disabled,
  .plugin-edit input[type="submit"]:disabled,
  .plugin-edit select[type="submit"]:disabled,
  .plugin-release input[type="submit"]:disabled,
  .plugin-release select[type="submit"]:disabled, .add-plugin .field-type-image-form input[type="file"]:disabled,
  .plugin-edit .field-type-image-form input[type="file"]:disabled,
  .plugin-release .field-type-image-form input[type="file"]:disabled, .add-plugin .field-type-image-form button:disabled,
  .plugin-edit .field-type-image-form button:disabled,
  .plugin-release .field-type-image-form button:disabled, .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:disabled,
  .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:disabled,
  .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:disabled, #editor-builder #draggable-plugins .all-elements-option a:disabled,
  #editor-builder .languages .all-elements-option a:disabled, .login .form-control[type="submit"]:disabled,
  .register .form-control[type="submit"]:disabled,
  .info-page .form-control[type="submit"]:disabled, .most-popular__wrapper .add_to_basket:disabled, .add-ons-list .views-row .add_to_basket:disabled,
  .add-ons-list .views-row .check-details:disabled,
  .add-ons-list .views-row .remove_from_basket:disabled,
  .add-ons-list .views-row .required:disabled, .user-edit input[type="submit"]:disabled,
  .user-edit select[type="submit"]:disabled, .btn[disabled="true"], #cksource_repo_search_forums_form [disabled="true"].form-submit, #forum-sort [disabled="true"].form-submit, .add-plugin input[disabled="true"][type="submit"],
  .add-plugin select[disabled="true"][type="submit"],
  .plugin-edit input[disabled="true"][type="submit"],
  .plugin-edit select[disabled="true"][type="submit"],
  .plugin-release input[disabled="true"][type="submit"],
  .plugin-release select[disabled="true"][type="submit"], .add-plugin .field-type-image-form input[disabled="true"][type="file"],
  .plugin-edit .field-type-image-form input[disabled="true"][type="file"],
  .plugin-release .field-type-image-form input[disabled="true"][type="file"], .add-plugin .field-type-image-form button[disabled="true"],
  .plugin-edit .field-type-image-form button[disabled="true"],
  .plugin-release .field-type-image-form button[disabled="true"], .add-plugin .field-type-image-form input[disabled="true"][name="field_addon_logo_und_0_remove_button"],
  .plugin-edit .field-type-image-form input[disabled="true"][name="field_addon_logo_und_0_remove_button"],
  .plugin-release .field-type-image-form input[disabled="true"][name="field_addon_logo_und_0_remove_button"], #editor-builder #draggable-plugins .all-elements-option a[disabled="true"],
  #editor-builder .languages .all-elements-option a[disabled="true"], .login [disabled="true"].form-control[type="submit"],
  .register [disabled="true"].form-control[type="submit"],
  .info-page [disabled="true"].form-control[type="submit"], .most-popular__wrapper [disabled="true"].add_to_basket, .add-ons-list .views-row [disabled="true"].add_to_basket,
  .add-ons-list .views-row [disabled="true"].check-details,
  .add-ons-list .views-row [disabled="true"].remove_from_basket,
  .add-ons-list .views-row [disabled="true"].required, .user-edit input[disabled="true"][type="submit"],
  .user-edit select[disabled="true"][type="submit"] {
    opacity: .6;
    cursor: not-allowed;
    pointer-events: none; }
  .btn:hover, #cksource_repo_search_forums_form .form-submit:hover, #forum-sort .form-submit:hover, .add-plugin input[type="submit"]:hover,
  .add-plugin select[type="submit"]:hover,
  .plugin-edit input[type="submit"]:hover,
  .plugin-edit select[type="submit"]:hover,
  .plugin-release input[type="submit"]:hover,
  .plugin-release select[type="submit"]:hover, .add-plugin .field-type-image-form input[type="file"]:hover,
  .plugin-edit .field-type-image-form input[type="file"]:hover,
  .plugin-release .field-type-image-form input[type="file"]:hover, .add-plugin .field-type-image-form button:hover,
  .plugin-edit .field-type-image-form button:hover,
  .plugin-release .field-type-image-form button:hover, .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:hover,
  .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:hover,
  .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:hover, #editor-builder #draggable-plugins .all-elements-option a:hover,
  #editor-builder .languages .all-elements-option a:hover, .login .form-control[type="submit"]:hover,
  .register .form-control[type="submit"]:hover,
  .info-page .form-control[type="submit"]:hover, .most-popular__wrapper .add_to_basket:hover, .add-ons-list .views-row .add_to_basket:hover,
  .add-ons-list .views-row .check-details:hover,
  .add-ons-list .views-row .remove_from_basket:hover,
  .add-ons-list .views-row .required:hover, .user-edit input[type="submit"]:hover,
  .user-edit select[type="submit"]:hover, .btn:focus, #cksource_repo_search_forums_form .form-submit:focus, #forum-sort .form-submit:focus, .add-plugin input[type="submit"]:focus,
  .add-plugin select[type="submit"]:focus,
  .plugin-edit input[type="submit"]:focus,
  .plugin-edit select[type="submit"]:focus,
  .plugin-release input[type="submit"]:focus,
  .plugin-release select[type="submit"]:focus, .add-plugin .field-type-image-form input[type="file"]:focus,
  .plugin-edit .field-type-image-form input[type="file"]:focus,
  .plugin-release .field-type-image-form input[type="file"]:focus, .add-plugin .field-type-image-form button:focus,
  .plugin-edit .field-type-image-form button:focus,
  .plugin-release .field-type-image-form button:focus, .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:focus,
  .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:focus,
  .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:focus, #editor-builder #draggable-plugins .all-elements-option a:focus,
  #editor-builder .languages .all-elements-option a:focus, .login .form-control[type="submit"]:focus,
  .register .form-control[type="submit"]:focus,
  .info-page .form-control[type="submit"]:focus, .most-popular__wrapper .add_to_basket:focus, .add-ons-list .views-row .add_to_basket:focus,
  .add-ons-list .views-row .check-details:focus,
  .add-ons-list .views-row .remove_from_basket:focus,
  .add-ons-list .views-row .required:focus, .user-edit input[type="submit"]:focus,
  .user-edit select[type="submit"]:focus {
    background-color: #1898ef;
    border-color: #1898ef;
    color: white; }
  @media (min-width: 1280px) {
    .btn, #cksource_repo_search_forums_form .form-submit, #forum-sort .form-submit, .add-plugin input[type="submit"],
    .add-plugin select[type="submit"],
    .plugin-edit input[type="submit"],
    .plugin-edit select[type="submit"],
    .plugin-release input[type="submit"],
    .plugin-release select[type="submit"], .add-plugin .field-type-image-form input[type="file"],
    .plugin-edit .field-type-image-form input[type="file"],
    .plugin-release .field-type-image-form input[type="file"], .add-plugin .field-type-image-form button,
    .plugin-edit .field-type-image-form button,
    .plugin-release .field-type-image-form button, .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
    .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
    .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], #editor-builder #draggable-plugins .all-elements-option a,
    #editor-builder .languages .all-elements-option a, .login .form-control[type="submit"],
    .register .form-control[type="submit"],
    .info-page .form-control[type="submit"], .most-popular__wrapper .add_to_basket, .add-ons-list .views-row .add_to_basket,
    .add-ons-list .views-row .check-details,
    .add-ons-list .views-row .remove_from_basket,
    .add-ons-list .views-row .required, .user-edit input[type="submit"],
    .user-edit select[type="submit"] {
      padding: 8px 16px;
      font-size: 1.45rem; } }
  @media (min-width: 768px) {
    .btn, #cksource_repo_search_forums_form .form-submit, #forum-sort .form-submit, .add-plugin input[type="submit"],
    .add-plugin select[type="submit"],
    .plugin-edit input[type="submit"],
    .plugin-edit select[type="submit"],
    .plugin-release input[type="submit"],
    .plugin-release select[type="submit"], .add-plugin .field-type-image-form input[type="file"],
    .plugin-edit .field-type-image-form input[type="file"],
    .plugin-release .field-type-image-form input[type="file"], .add-plugin .field-type-image-form button,
    .plugin-edit .field-type-image-form button,
    .plugin-release .field-type-image-form button, .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
    .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
    .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], #editor-builder #draggable-plugins .all-elements-option a,
    #editor-builder .languages .all-elements-option a, .login .form-control[type="submit"],
    .register .form-control[type="submit"],
    .info-page .form-control[type="submit"], .most-popular__wrapper .add_to_basket, .add-ons-list .views-row .add_to_basket,
    .add-ons-list .views-row .check-details,
    .add-ons-list .views-row .remove_from_basket,
    .add-ons-list .views-row .required, .user-edit input[type="submit"],
    .user-edit select[type="submit"] {
      padding: 12px 24px;
      font-size: 1.92rem; } }
  @media (min-width: 768px) and (min-width: 1280px) {
    .btn, #cksource_repo_search_forums_form .form-submit, #forum-sort .form-submit, .add-plugin input[type="submit"],
    .add-plugin select[type="submit"],
    .plugin-edit input[type="submit"],
    .plugin-edit select[type="submit"],
    .plugin-release input[type="submit"],
    .plugin-release select[type="submit"], .add-plugin .field-type-image-form input[type="file"],
    .plugin-edit .field-type-image-form input[type="file"],
    .plugin-release .field-type-image-form input[type="file"], .add-plugin .field-type-image-form button,
    .plugin-edit .field-type-image-form button,
    .plugin-release .field-type-image-form button, .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
    .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
    .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], #editor-builder #draggable-plugins .all-elements-option a,
    #editor-builder .languages .all-elements-option a, .login .form-control[type="submit"],
    .register .form-control[type="submit"],
    .info-page .form-control[type="submit"], .most-popular__wrapper .add_to_basket, .add-ons-list .views-row .add_to_basket,
    .add-ons-list .views-row .check-details,
    .add-ons-list .views-row .remove_from_basket,
    .add-ons-list .views-row .required, .user-edit input[type="submit"],
    .user-edit select[type="submit"] {
      padding: 10px 20px;
      font-size: 1.6rem; } }
  .btn--success, .most-popular__wrapper .add_to_basket, .add-ons-list .views-row .add_to_basket, .add-on-description .add_to_basket {
    background-color: #2dbe61;
    border: 2px solid #2dbe61;
    color: white; }
    .btn--success:hover, .most-popular__wrapper .add_to_basket:hover, .add-ons-list .views-row .add_to_basket:hover, .add-on-description .add_to_basket:hover, .btn--success:focus, .most-popular__wrapper .add_to_basket:focus, .add-ons-list .views-row .add_to_basket:focus, .add-on-description .add_to_basket:focus {
      background-color: #28a956;
      border-color: #28a956;
      color: white; }
    .btn--success.btn--frame, .add-plugin .field-type-image-form input.btn--success[type="file"], .add-plugin .field-type-image-form .most-popular__wrapper input[type="file"].add_to_basket, .most-popular__wrapper .add-plugin .field-type-image-form input[type="file"].add_to_basket, .add-plugin .field-type-image-form .add-ons-list .views-row input[type="file"].add_to_basket, .add-ons-list .views-row .add-plugin .field-type-image-form input[type="file"].add_to_basket, .add-plugin .field-type-image-form .add-on-description input[type="file"].add_to_basket, .add-on-description .add-plugin .field-type-image-form input[type="file"].add_to_basket,
    .plugin-edit .field-type-image-form input.btn--success[type="file"],
    .plugin-edit .field-type-image-form .most-popular__wrapper input[type="file"].add_to_basket, .most-popular__wrapper
    .plugin-edit .field-type-image-form input[type="file"].add_to_basket,
    .plugin-edit .field-type-image-form .add-ons-list .views-row input[type="file"].add_to_basket, .add-ons-list .views-row
    .plugin-edit .field-type-image-form input[type="file"].add_to_basket,
    .plugin-edit .field-type-image-form .add-on-description input[type="file"].add_to_basket, .add-on-description
    .plugin-edit .field-type-image-form input[type="file"].add_to_basket,
    .plugin-release .field-type-image-form input.btn--success[type="file"],
    .plugin-release .field-type-image-form .most-popular__wrapper input[type="file"].add_to_basket, .most-popular__wrapper
    .plugin-release .field-type-image-form input[type="file"].add_to_basket,
    .plugin-release .field-type-image-form .add-ons-list .views-row input[type="file"].add_to_basket, .add-ons-list .views-row
    .plugin-release .field-type-image-form input[type="file"].add_to_basket,
    .plugin-release .field-type-image-form .add-on-description input[type="file"].add_to_basket, .add-on-description
    .plugin-release .field-type-image-form input[type="file"].add_to_basket, #editor-builder #draggable-plugins .all-elements-option a.btn--success, #editor-builder #draggable-plugins .all-elements-option .most-popular__wrapper a.add_to_basket, .most-popular__wrapper #editor-builder #draggable-plugins .all-elements-option a.add_to_basket, #editor-builder #draggable-plugins .all-elements-option .add-ons-list .views-row a.add_to_basket, .add-ons-list .views-row #editor-builder #draggable-plugins .all-elements-option a.add_to_basket, #editor-builder #draggable-plugins .all-elements-option .add-on-description a.add_to_basket, .add-on-description #editor-builder #draggable-plugins .all-elements-option a.add_to_basket,
    #editor-builder .languages .all-elements-option a.btn--success,
    #editor-builder .languages .all-elements-option .most-popular__wrapper a.add_to_basket, .most-popular__wrapper
    #editor-builder .languages .all-elements-option a.add_to_basket,
    #editor-builder .languages .all-elements-option .add-ons-list .views-row a.add_to_basket, .add-ons-list .views-row
    #editor-builder .languages .all-elements-option a.add_to_basket,
    #editor-builder .languages .all-elements-option .add-on-description a.add_to_basket, .add-on-description
    #editor-builder .languages .all-elements-option a.add_to_basket, .most-popular__wrapper .btn--frame.add_to_basket, .add-ons-list .views-row .btn--frame.add_to_basket, .add-on-description .btn--frame.add_to_basket {
      background-color: white;
      border: 2px solid #2dbe61;
      color: #2dbe61; }
      .btn--success.btn--frame:hover, .add-plugin .field-type-image-form input.btn--success[type="file"]:hover, .add-plugin .field-type-image-form .most-popular__wrapper input[type="file"].add_to_basket:hover, .most-popular__wrapper .add-plugin .field-type-image-form input[type="file"].add_to_basket:hover, .add-plugin .field-type-image-form .add-ons-list .views-row input[type="file"].add_to_basket:hover, .add-ons-list .views-row .add-plugin .field-type-image-form input[type="file"].add_to_basket:hover, .add-plugin .field-type-image-form .add-on-description input[type="file"].add_to_basket:hover, .add-on-description .add-plugin .field-type-image-form input[type="file"].add_to_basket:hover,
      .plugin-edit .field-type-image-form input.btn--success[type="file"]:hover,
      .plugin-edit .field-type-image-form .most-popular__wrapper input[type="file"].add_to_basket:hover, .most-popular__wrapper
      .plugin-edit .field-type-image-form input[type="file"].add_to_basket:hover,
      .plugin-edit .field-type-image-form .add-ons-list .views-row input[type="file"].add_to_basket:hover, .add-ons-list .views-row
      .plugin-edit .field-type-image-form input[type="file"].add_to_basket:hover,
      .plugin-edit .field-type-image-form .add-on-description input[type="file"].add_to_basket:hover, .add-on-description
      .plugin-edit .field-type-image-form input[type="file"].add_to_basket:hover,
      .plugin-release .field-type-image-form input.btn--success[type="file"]:hover,
      .plugin-release .field-type-image-form .most-popular__wrapper input[type="file"].add_to_basket:hover, .most-popular__wrapper
      .plugin-release .field-type-image-form input[type="file"].add_to_basket:hover,
      .plugin-release .field-type-image-form .add-ons-list .views-row input[type="file"].add_to_basket:hover, .add-ons-list .views-row
      .plugin-release .field-type-image-form input[type="file"].add_to_basket:hover,
      .plugin-release .field-type-image-form .add-on-description input[type="file"].add_to_basket:hover, .add-on-description
      .plugin-release .field-type-image-form input[type="file"].add_to_basket:hover, #editor-builder #draggable-plugins .all-elements-option a.btn--success:hover, #editor-builder #draggable-plugins .all-elements-option .most-popular__wrapper a.add_to_basket:hover, .most-popular__wrapper #editor-builder #draggable-plugins .all-elements-option a.add_to_basket:hover, #editor-builder #draggable-plugins .all-elements-option .add-ons-list .views-row a.add_to_basket:hover, .add-ons-list .views-row #editor-builder #draggable-plugins .all-elements-option a.add_to_basket:hover, #editor-builder #draggable-plugins .all-elements-option .add-on-description a.add_to_basket:hover, .add-on-description #editor-builder #draggable-plugins .all-elements-option a.add_to_basket:hover,
      #editor-builder .languages .all-elements-option a.btn--success:hover,
      #editor-builder .languages .all-elements-option .most-popular__wrapper a.add_to_basket:hover, .most-popular__wrapper
      #editor-builder .languages .all-elements-option a.add_to_basket:hover,
      #editor-builder .languages .all-elements-option .add-ons-list .views-row a.add_to_basket:hover, .add-ons-list .views-row
      #editor-builder .languages .all-elements-option a.add_to_basket:hover,
      #editor-builder .languages .all-elements-option .add-on-description a.add_to_basket:hover, .add-on-description
      #editor-builder .languages .all-elements-option a.add_to_basket:hover, .most-popular__wrapper .btn--frame.add_to_basket:hover, .add-ons-list .views-row .btn--frame.add_to_basket:hover, .add-on-description .btn--frame.add_to_basket:hover, .btn--success.btn--frame:focus, .add-plugin .field-type-image-form input.btn--success[type="file"]:focus, .add-plugin .field-type-image-form .most-popular__wrapper input[type="file"].add_to_basket:focus, .most-popular__wrapper .add-plugin .field-type-image-form input[type="file"].add_to_basket:focus, .add-plugin .field-type-image-form .add-ons-list .views-row input[type="file"].add_to_basket:focus, .add-ons-list .views-row .add-plugin .field-type-image-form input[type="file"].add_to_basket:focus, .add-plugin .field-type-image-form .add-on-description input[type="file"].add_to_basket:focus, .add-on-description .add-plugin .field-type-image-form input[type="file"].add_to_basket:focus,
      .plugin-edit .field-type-image-form input.btn--success[type="file"]:focus,
      .plugin-edit .field-type-image-form .most-popular__wrapper input[type="file"].add_to_basket:focus, .most-popular__wrapper
      .plugin-edit .field-type-image-form input[type="file"].add_to_basket:focus,
      .plugin-edit .field-type-image-form .add-ons-list .views-row input[type="file"].add_to_basket:focus, .add-ons-list .views-row
      .plugin-edit .field-type-image-form input[type="file"].add_to_basket:focus,
      .plugin-edit .field-type-image-form .add-on-description input[type="file"].add_to_basket:focus, .add-on-description
      .plugin-edit .field-type-image-form input[type="file"].add_to_basket:focus,
      .plugin-release .field-type-image-form input.btn--success[type="file"]:focus,
      .plugin-release .field-type-image-form .most-popular__wrapper input[type="file"].add_to_basket:focus, .most-popular__wrapper
      .plugin-release .field-type-image-form input[type="file"].add_to_basket:focus,
      .plugin-release .field-type-image-form .add-ons-list .views-row input[type="file"].add_to_basket:focus, .add-ons-list .views-row
      .plugin-release .field-type-image-form input[type="file"].add_to_basket:focus,
      .plugin-release .field-type-image-form .add-on-description input[type="file"].add_to_basket:focus, .add-on-description
      .plugin-release .field-type-image-form input[type="file"].add_to_basket:focus, #editor-builder #draggable-plugins .all-elements-option a.btn--success:focus, #editor-builder #draggable-plugins .all-elements-option .most-popular__wrapper a.add_to_basket:focus, .most-popular__wrapper #editor-builder #draggable-plugins .all-elements-option a.add_to_basket:focus, #editor-builder #draggable-plugins .all-elements-option .add-ons-list .views-row a.add_to_basket:focus, .add-ons-list .views-row #editor-builder #draggable-plugins .all-elements-option a.add_to_basket:focus, #editor-builder #draggable-plugins .all-elements-option .add-on-description a.add_to_basket:focus, .add-on-description #editor-builder #draggable-plugins .all-elements-option a.add_to_basket:focus,
      #editor-builder .languages .all-elements-option a.btn--success:focus,
      #editor-builder .languages .all-elements-option .most-popular__wrapper a.add_to_basket:focus, .most-popular__wrapper
      #editor-builder .languages .all-elements-option a.add_to_basket:focus,
      #editor-builder .languages .all-elements-option .add-ons-list .views-row a.add_to_basket:focus, .add-ons-list .views-row
      #editor-builder .languages .all-elements-option a.add_to_basket:focus,
      #editor-builder .languages .all-elements-option .add-on-description a.add_to_basket:focus, .add-on-description
      #editor-builder .languages .all-elements-option a.add_to_basket:focus, .most-popular__wrapper .btn--frame.add_to_basket:focus, .add-ons-list .views-row .btn--frame.add_to_basket:focus, .add-on-description .btn--frame.add_to_basket:focus {
        background-color: #2dbe61;
        color: white; }
  .btn--action {
    background-color: #00897b;
    border: 2px solid #00897b;
    color: white; }
    .btn--action:hover, .btn--action:focus {
      background-color: #007064;
      border-color: #007064;
      color: white; }
    .btn--action.btn--frame, .add-plugin .field-type-image-form input.btn--action[type="file"],
    .plugin-edit .field-type-image-form input.btn--action[type="file"],
    .plugin-release .field-type-image-form input.btn--action[type="file"], #editor-builder #draggable-plugins .all-elements-option a.btn--action,
    #editor-builder .languages .all-elements-option a.btn--action {
      background-color: white;
      border: 2px solid #00897b;
      color: #00897b; }
      .btn--action.btn--frame:hover, .add-plugin .field-type-image-form input.btn--action[type="file"]:hover,
      .plugin-edit .field-type-image-form input.btn--action[type="file"]:hover,
      .plugin-release .field-type-image-form input.btn--action[type="file"]:hover, #editor-builder #draggable-plugins .all-elements-option a.btn--action:hover,
      #editor-builder .languages .all-elements-option a.btn--action:hover, .btn--action.btn--frame:focus, .add-plugin .field-type-image-form input.btn--action[type="file"]:focus,
      .plugin-edit .field-type-image-form input.btn--action[type="file"]:focus,
      .plugin-release .field-type-image-form input.btn--action[type="file"]:focus, #editor-builder #draggable-plugins .all-elements-option a.btn--action:focus,
      #editor-builder .languages .all-elements-option a.btn--action:focus {
        background-color: #00897b;
        color: white; }
  .btn--info {
    background-color: #673ab7;
    border: 2px solid #673ab7;
    color: white; }
    .btn--info:hover, .btn--info:focus {
      background-color: #5c34a4;
      border-color: #5c34a4;
      color: white; }
    .btn--info.btn--frame, .add-plugin .field-type-image-form input.btn--info[type="file"],
    .plugin-edit .field-type-image-form input.btn--info[type="file"],
    .plugin-release .field-type-image-form input.btn--info[type="file"], #editor-builder #draggable-plugins .all-elements-option a.btn--info,
    #editor-builder .languages .all-elements-option a.btn--info {
      background-color: white;
      border: 2px solid #673ab7;
      color: #673ab7; }
      .btn--info.btn--frame:hover, .add-plugin .field-type-image-form input.btn--info[type="file"]:hover,
      .plugin-edit .field-type-image-form input.btn--info[type="file"]:hover,
      .plugin-release .field-type-image-form input.btn--info[type="file"]:hover, #editor-builder #draggable-plugins .all-elements-option a.btn--info:hover,
      #editor-builder .languages .all-elements-option a.btn--info:hover, .btn--info.btn--frame:focus, .add-plugin .field-type-image-form input.btn--info[type="file"]:focus,
      .plugin-edit .field-type-image-form input.btn--info[type="file"]:focus,
      .plugin-release .field-type-image-form input.btn--info[type="file"]:focus, #editor-builder #draggable-plugins .all-elements-option a.btn--info:focus,
      #editor-builder .languages .all-elements-option a.btn--info:focus {
        background-color: #673ab7;
        color: white; }
  .btn--neutral {
    background-color: #979797;
    border: 2px solid #979797;
    color: white; }
    .btn--neutral:hover, .btn--neutral:focus {
      background-color: #8a8a8a;
      border-color: #8a8a8a;
      color: white; }
    .btn--neutral.btn--frame, .add-plugin .field-type-image-form input.btn--neutral[type="file"],
    .plugin-edit .field-type-image-form input.btn--neutral[type="file"],
    .plugin-release .field-type-image-form input.btn--neutral[type="file"], #editor-builder #draggable-plugins .all-elements-option a.btn--neutral,
    #editor-builder .languages .all-elements-option a.btn--neutral {
      background-color: white;
      border: 2px solid #979797;
      color: #979797;
      border-width: 1px;
      color: rgba(64, 81, 102, 0.96); }
      .btn--neutral.btn--frame:hover, .add-plugin .field-type-image-form input.btn--neutral[type="file"]:hover,
      .plugin-edit .field-type-image-form input.btn--neutral[type="file"]:hover,
      .plugin-release .field-type-image-form input.btn--neutral[type="file"]:hover, #editor-builder #draggable-plugins .all-elements-option a.btn--neutral:hover,
      #editor-builder .languages .all-elements-option a.btn--neutral:hover, .btn--neutral.btn--frame:focus, .add-plugin .field-type-image-form input.btn--neutral[type="file"]:focus,
      .plugin-edit .field-type-image-form input.btn--neutral[type="file"]:focus,
      .plugin-release .field-type-image-form input.btn--neutral[type="file"]:focus, #editor-builder #draggable-plugins .all-elements-option a.btn--neutral:focus,
      #editor-builder .languages .all-elements-option a.btn--neutral:focus {
        background-color: #979797;
        color: white; }
  .btn--danger, .add-ons-list .views-row .remove_from_basket, .add-on-description .remove_from_basket {
    background-color: #d9534f;
    border: 2px solid #d9534f;
    color: white; }
    .btn--danger:hover, .add-ons-list .views-row .remove_from_basket:hover, .add-on-description .remove_from_basket:hover, .btn--danger:focus, .add-ons-list .views-row .remove_from_basket:focus, .add-on-description .remove_from_basket:focus {
      background-color: #d43f3a;
      border-color: #d43f3a;
      color: white; }
    .btn--danger.btn--frame, .add-plugin .field-type-image-form input.btn--danger[type="file"], .add-plugin .field-type-image-form .add-ons-list .views-row input[type="file"].remove_from_basket, .add-ons-list .views-row .add-plugin .field-type-image-form input[type="file"].remove_from_basket, .add-plugin .field-type-image-form .add-on-description input[type="file"].remove_from_basket, .add-on-description .add-plugin .field-type-image-form input[type="file"].remove_from_basket,
    .plugin-edit .field-type-image-form input.btn--danger[type="file"],
    .plugin-edit .field-type-image-form .add-ons-list .views-row input[type="file"].remove_from_basket, .add-ons-list .views-row
    .plugin-edit .field-type-image-form input[type="file"].remove_from_basket,
    .plugin-edit .field-type-image-form .add-on-description input[type="file"].remove_from_basket, .add-on-description
    .plugin-edit .field-type-image-form input[type="file"].remove_from_basket,
    .plugin-release .field-type-image-form input.btn--danger[type="file"],
    .plugin-release .field-type-image-form .add-ons-list .views-row input[type="file"].remove_from_basket, .add-ons-list .views-row
    .plugin-release .field-type-image-form input[type="file"].remove_from_basket,
    .plugin-release .field-type-image-form .add-on-description input[type="file"].remove_from_basket, .add-on-description
    .plugin-release .field-type-image-form input[type="file"].remove_from_basket, #editor-builder #draggable-plugins .all-elements-option a.btn--danger, #editor-builder #draggable-plugins .all-elements-option .add-ons-list .views-row a.remove_from_basket, .add-ons-list .views-row #editor-builder #draggable-plugins .all-elements-option a.remove_from_basket, #editor-builder #draggable-plugins .all-elements-option .add-on-description a.remove_from_basket, .add-on-description #editor-builder #draggable-plugins .all-elements-option a.remove_from_basket,
    #editor-builder .languages .all-elements-option a.btn--danger,
    #editor-builder .languages .all-elements-option .add-ons-list .views-row a.remove_from_basket, .add-ons-list .views-row
    #editor-builder .languages .all-elements-option a.remove_from_basket,
    #editor-builder .languages .all-elements-option .add-on-description a.remove_from_basket, .add-on-description
    #editor-builder .languages .all-elements-option a.remove_from_basket, .add-ons-list .views-row .btn--frame.remove_from_basket, .add-on-description .btn--frame.remove_from_basket {
      background-color: white;
      border: 2px solid #d9534f;
      color: #d9534f;
      border-width: 1px;
      color: rgba(64, 81, 102, 0.96); }
      .btn--danger.btn--frame:hover, .add-plugin .field-type-image-form input.btn--danger[type="file"]:hover, .add-plugin .field-type-image-form .add-ons-list .views-row input[type="file"].remove_from_basket:hover, .add-ons-list .views-row .add-plugin .field-type-image-form input[type="file"].remove_from_basket:hover, .add-plugin .field-type-image-form .add-on-description input[type="file"].remove_from_basket:hover, .add-on-description .add-plugin .field-type-image-form input[type="file"].remove_from_basket:hover,
      .plugin-edit .field-type-image-form input.btn--danger[type="file"]:hover,
      .plugin-edit .field-type-image-form .add-ons-list .views-row input[type="file"].remove_from_basket:hover, .add-ons-list .views-row
      .plugin-edit .field-type-image-form input[type="file"].remove_from_basket:hover,
      .plugin-edit .field-type-image-form .add-on-description input[type="file"].remove_from_basket:hover, .add-on-description
      .plugin-edit .field-type-image-form input[type="file"].remove_from_basket:hover,
      .plugin-release .field-type-image-form input.btn--danger[type="file"]:hover,
      .plugin-release .field-type-image-form .add-ons-list .views-row input[type="file"].remove_from_basket:hover, .add-ons-list .views-row
      .plugin-release .field-type-image-form input[type="file"].remove_from_basket:hover,
      .plugin-release .field-type-image-form .add-on-description input[type="file"].remove_from_basket:hover, .add-on-description
      .plugin-release .field-type-image-form input[type="file"].remove_from_basket:hover, #editor-builder #draggable-plugins .all-elements-option a.btn--danger:hover, #editor-builder #draggable-plugins .all-elements-option .add-ons-list .views-row a.remove_from_basket:hover, .add-ons-list .views-row #editor-builder #draggable-plugins .all-elements-option a.remove_from_basket:hover, #editor-builder #draggable-plugins .all-elements-option .add-on-description a.remove_from_basket:hover, .add-on-description #editor-builder #draggable-plugins .all-elements-option a.remove_from_basket:hover,
      #editor-builder .languages .all-elements-option a.btn--danger:hover,
      #editor-builder .languages .all-elements-option .add-ons-list .views-row a.remove_from_basket:hover, .add-ons-list .views-row
      #editor-builder .languages .all-elements-option a.remove_from_basket:hover,
      #editor-builder .languages .all-elements-option .add-on-description a.remove_from_basket:hover, .add-on-description
      #editor-builder .languages .all-elements-option a.remove_from_basket:hover, .add-ons-list .views-row .btn--frame.remove_from_basket:hover, .add-on-description .btn--frame.remove_from_basket:hover, .btn--danger.btn--frame:focus, .add-plugin .field-type-image-form input.btn--danger[type="file"]:focus, .add-plugin .field-type-image-form .add-ons-list .views-row input[type="file"].remove_from_basket:focus, .add-ons-list .views-row .add-plugin .field-type-image-form input[type="file"].remove_from_basket:focus, .add-plugin .field-type-image-form .add-on-description input[type="file"].remove_from_basket:focus, .add-on-description .add-plugin .field-type-image-form input[type="file"].remove_from_basket:focus,
      .plugin-edit .field-type-image-form input.btn--danger[type="file"]:focus,
      .plugin-edit .field-type-image-form .add-ons-list .views-row input[type="file"].remove_from_basket:focus, .add-ons-list .views-row
      .plugin-edit .field-type-image-form input[type="file"].remove_from_basket:focus,
      .plugin-edit .field-type-image-form .add-on-description input[type="file"].remove_from_basket:focus, .add-on-description
      .plugin-edit .field-type-image-form input[type="file"].remove_from_basket:focus,
      .plugin-release .field-type-image-form input.btn--danger[type="file"]:focus,
      .plugin-release .field-type-image-form .add-ons-list .views-row input[type="file"].remove_from_basket:focus, .add-ons-list .views-row
      .plugin-release .field-type-image-form input[type="file"].remove_from_basket:focus,
      .plugin-release .field-type-image-form .add-on-description input[type="file"].remove_from_basket:focus, .add-on-description
      .plugin-release .field-type-image-form input[type="file"].remove_from_basket:focus, #editor-builder #draggable-plugins .all-elements-option a.btn--danger:focus, #editor-builder #draggable-plugins .all-elements-option .add-ons-list .views-row a.remove_from_basket:focus, .add-ons-list .views-row #editor-builder #draggable-plugins .all-elements-option a.remove_from_basket:focus, #editor-builder #draggable-plugins .all-elements-option .add-on-description a.remove_from_basket:focus, .add-on-description #editor-builder #draggable-plugins .all-elements-option a.remove_from_basket:focus,
      #editor-builder .languages .all-elements-option a.btn--danger:focus,
      #editor-builder .languages .all-elements-option .add-ons-list .views-row a.remove_from_basket:focus, .add-ons-list .views-row
      #editor-builder .languages .all-elements-option a.remove_from_basket:focus,
      #editor-builder .languages .all-elements-option .add-on-description a.remove_from_basket:focus, .add-on-description
      #editor-builder .languages .all-elements-option a.remove_from_basket:focus, .add-ons-list .views-row .btn--frame.remove_from_basket:focus, .add-on-description .btn--frame.remove_from_basket:focus {
        background-color: #d9534f;
        color: white; }
  .btn--frame, .add-plugin .field-type-image-form input[type="file"],
  .plugin-edit .field-type-image-form input[type="file"],
  .plugin-release .field-type-image-form input[type="file"], #editor-builder #draggable-plugins .all-elements-option a,
  #editor-builder .languages .all-elements-option a {
    background-color: white;
    border: 2px solid #30a3f1;
    color: #30a3f1; }
    .btn--frame:hover, .add-plugin .field-type-image-form input[type="file"]:hover,
    .plugin-edit .field-type-image-form input[type="file"]:hover,
    .plugin-release .field-type-image-form input[type="file"]:hover, #editor-builder #draggable-plugins .all-elements-option a:hover,
    #editor-builder .languages .all-elements-option a:hover, .btn--frame:focus, .add-plugin .field-type-image-form input[type="file"]:focus,
    .plugin-edit .field-type-image-form input[type="file"]:focus,
    .plugin-release .field-type-image-form input[type="file"]:focus, #editor-builder #draggable-plugins .all-elements-option a:focus,
    #editor-builder .languages .all-elements-option a:focus {
      background-color: #30a3f1;
      color: white; }
    .btn--frame-bright {
      background-color: white;
      border: 2px solid white;
      color: white;
      background-color: transparent; }
      .btn--frame-bright:hover, .btn--frame-bright:focus {
        background-color: white;
        color: white; }
      .btn--frame-bright:hover, .btn--frame-bright:focus {
        color: #30a3f1; }
  .btn--disabled, .add-ons-list .views-row .required {
    opacity: .6;
    cursor: not-allowed; }
  .btn--big, .add-plugin #edit-actions input[type="submit"],
  .plugin-edit #edit-actions input[type="submit"],
  .plugin-release #edit-actions input[type="submit"], .user-edit #edit-actions input[type="submit"] {
    padding: 13px 26px;
    font-size: 1.9rem; }
    @media (min-width: 1280px) {
      .btn--big, .add-plugin #edit-actions input[type="submit"],
      .plugin-edit #edit-actions input[type="submit"],
      .plugin-release #edit-actions input[type="submit"], .user-edit #edit-actions input[type="submit"] {
        padding: 13px 26px;
        font-size: 1.9rem; } }
  .btn--small, #forum-sort .form-submit, .add-plugin input[type="submit"],
  .add-plugin select[type="submit"],
  .plugin-edit input[type="submit"],
  .plugin-edit select[type="submit"],
  .plugin-release input[type="submit"],
  .plugin-release select[type="submit"], .add-plugin .field-type-image-form input[type="file"],
  .plugin-edit .field-type-image-form input[type="file"],
  .plugin-release .field-type-image-form input[type="file"], .add-plugin .field-type-image-form button,
  .plugin-edit .field-type-image-form button,
  .plugin-release .field-type-image-form button, .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
  .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
  .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], #editor-builder #draggable-plugins .all-elements-option a,
  #editor-builder .languages .all-elements-option a, .most-popular__wrapper .add_to_basket, .add-ons-list .views-row .add_to_basket,
  .add-ons-list .views-row .check-details,
  .add-ons-list .views-row .remove_from_basket,
  .add-ons-list .views-row .required {
    padding: 9.6px 19.2px;
    font-size: 1.74rem; }
    @media (min-width: 1280px) {
      .btn--small, #forum-sort .form-submit, .add-plugin input[type="submit"],
      .add-plugin select[type="submit"],
      .plugin-edit input[type="submit"],
      .plugin-edit select[type="submit"],
      .plugin-release input[type="submit"],
      .plugin-release select[type="submit"], .add-plugin .field-type-image-form input[type="file"],
      .plugin-edit .field-type-image-form input[type="file"],
      .plugin-release .field-type-image-form input[type="file"], .add-plugin .field-type-image-form button,
      .plugin-edit .field-type-image-form button,
      .plugin-release .field-type-image-form button, .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
      .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
      .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], #editor-builder #draggable-plugins .all-elements-option a,
      #editor-builder .languages .all-elements-option a, .most-popular__wrapper .add_to_basket, .add-ons-list .views-row .add_to_basket,
      .add-ons-list .views-row .check-details,
      .add-ons-list .views-row .remove_from_basket,
      .add-ons-list .views-row .required {
        padding: 8px 16px;
        font-size: 1.45rem; } }
  .btn--tiny {
    padding: 7.2px 14.4px;
    font-size: 1.56rem; }
    @media (min-width: 1280px) {
      .btn--tiny {
        padding: 6px 12px;
        font-size: 1.3rem; } }
  @media (min-width: 768px) {
    .btn-cta {
      min-width: 17.5rem; } }
  @media (min-width: 768px) {
    .btn-cta--super-long {
      min-width: 30rem; } }
  @media (min-width: 768px) {
    .btn-cta--long {
      min-width: 22.5rem; } }
  @media (min-width: 768px) {
    .btn-cta--short {
      min-width: 12.5rem; } }

.form-group {
  margin-bottom: 2.5rem; }
  .form-group-inline {
    margin-bottom: 0; }
    @media (max-width: 767px) {
      .form-group-inline {
        width: 100%;
        margin-top: 2.5rem; }
        .form-group-inline:first-of-type {
          margin-top: 0; } }
    @media (min-width: 768px) {
      .form-group-inline {
        margin-left: 1.25rem;
        margin-bottom: 0; }
        .form-group-inline:first-of-type {
          margin-left: 0; }
        .form-group-inline--long {
          flex-grow: 1; }
        .form-group-inline--small {
          width: 200px;
          max-width: 100%; } }
    .form-group-inline__wrapper {
      display: flex; }
      @media (max-width: 767px) {
        .form-group-inline__wrapper {
          flex-wrap: wrap; } }

.copy-text-wrapper {
  position: relative; }

.btn--copy {
  right: 6px;
  z-index: 99;
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

select.form-control,
input.form-control {
  height: 45px; }
  @media (min-width: 1280px) {
    select.form-control,
    input.form-control {
      height: 41px; } }

select.form-control,
input.form-control,
textarea.form-control {
  color: rgba(64, 81, 102, 0.96); }
  select.form-control[disabled],
  input.form-control[disabled],
  textarea.form-control[disabled] {
    color: rgba(64, 81, 102, 0.75);
    background-color: #f0f0f0;
    opacity: .6; }

.form-control[readonly] {
  background-color: #fdfdfd; }

textarea {
  resize: vertical; }

.icon {
  display: inline-block;
  vertical-align: middle;
  width: 4rem;
  height: 2.4rem; }
  .icon > * {
    height: 2.4rem;
    width: 4rem; }
  .icon-set .icon {
    margin-right: 1rem; }
    .icon-set .icon:last-of-type {
      margin-right: 0; }
  .icon--big {
    height: 3.2rem;
    width: 4.5rem; }
    .icon--big > * {
      height: 3.2rem;
      width: 4.5rem; }
  .icon--small {
    width: 2.2rem;
    height: 2rem; }
    .icon--small > * {
      width: 2.2rem;
      height: 2rem; }
  .icon svg * {
    fill: rgba(64, 81, 102, 0.75);
    transition: all 200ms ease-in-out; }
  .icon:hover svg * {
    fill: rgba(64, 81, 102, 0.96); }
  .icon--blue svg * {
    fill: #90cdf7; }
  .icon--blue:hover svg * {
    fill: #30a3f1; }

[data-tooltip] {
  position: relative;
  cursor: default; }
  [data-tooltip]:before, [data-tooltip]:after {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: all 0.15s cubic-bezier(0.5, 1, 0.25, 1);
    z-index: 1; }
  [data-tooltip]:before {
    content: attr(data-tooltip);
    padding: .5rem;
    width: 110px;
    border-radius: 3px;
    background: #111111;
    color: white;
    text-align: center;
    font-size: 1.3rem;
    font-weight: normal;
    line-height: 1.2; }
  [data-tooltip]:after {
    border: 8px solid transparent;
    width: 0;
    content: "";
    font-size: 0;
    line-height: 0; }
  [data-tooltip]:hover:before, [data-tooltip]:hover:after {
    visibility: visible;
    opacity: 1; }

.tooltip--xxl:before {
  width: 300px;
  padding: 1rem; }

.tooltip--xl:before {
  width: 200px; }

.tooltip--lg:before {
  width: 170px; }

.tooltip--md:before {
  width: 140px; }

.tooltip--sm:before {
  width: 110px; }

.tooltip--xs:before {
  width: 80px; }

.tooltip--top:before {
  bottom: 100%;
  left: 50%;
  margin-bottom: .5rem;
  transform: translateX(-50%); }

.tooltip--top:after {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-top: 8px solid #111111;
  border-bottom: none; }

.tooltip--top:hover:before, .tooltip--top:hover:after {
  transform: translateX(-50%) translateY(-5px); }

.tooltip--right:before {
  top: 50%;
  left: 100%;
  margin-left: .5rem;
  transform: translateY(-50%); }

.tooltip--right:after {
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  border-right: 8px solid #111111;
  border-left: none; }

.tooltip--right:hover:before, .tooltip--right:hover:after {
  transform: translateX(5px) translateY(-50%); }

.tooltip--bottom:before {
  top: 100%;
  left: 50%;
  margin-top: .5rem;
  transform: translateX(-50%); }

.tooltip--bottom:after {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 8px solid #111111;
  border-top: none; }

.tooltip--bottom:hover:before, .tooltip--bottom:hover:after {
  transform: translateX(-50%) translateY(5px); }

.tooltip--left:before {
  top: 50%;
  right: 100%;
  margin-right: .5rem;
  transform: translateY(-50%); }

.tooltip--left:after {
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
  border-left: 8px solid #111111;
  border-right: none; }

.tooltip--left:hover:before, .tooltip--left:hover:after {
  transform: translateX(-5px) translateY(-50%); }

.dialog-form {
  width: 450px;
  max-width: 90%;
  margin: 0 auto;
  font-size: 1.6rem; }
  .dialog-form .btn, .dialog-form #cksource_repo_search_forums_form .form-submit, #cksource_repo_search_forums_form .dialog-form .form-submit, .dialog-form #forum-sort .form-submit, #forum-sort .dialog-form .form-submit, .dialog-form .add-plugin input[type="submit"], .add-plugin .dialog-form input[type="submit"], .dialog-form
  .add-plugin select[type="submit"],
  .add-plugin .dialog-form select[type="submit"], .dialog-form
  .plugin-edit input[type="submit"],
  .plugin-edit .dialog-form input[type="submit"], .dialog-form
  .plugin-edit select[type="submit"],
  .plugin-edit .dialog-form select[type="submit"], .dialog-form
  .plugin-release input[type="submit"],
  .plugin-release .dialog-form input[type="submit"], .dialog-form
  .plugin-release select[type="submit"],
  .plugin-release .dialog-form select[type="submit"], .dialog-form .add-plugin .field-type-image-form input[type="file"], .add-plugin .field-type-image-form .dialog-form input[type="file"], .dialog-form
  .plugin-edit .field-type-image-form input[type="file"],
  .plugin-edit .field-type-image-form .dialog-form input[type="file"], .dialog-form
  .plugin-release .field-type-image-form input[type="file"],
  .plugin-release .field-type-image-form .dialog-form input[type="file"], .dialog-form .add-plugin .field-type-image-form button, .add-plugin .field-type-image-form .dialog-form button, .dialog-form
  .plugin-edit .field-type-image-form button,
  .plugin-edit .field-type-image-form .dialog-form button, .dialog-form
  .plugin-release .field-type-image-form button,
  .plugin-release .field-type-image-form .dialog-form button, .dialog-form .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], .add-plugin .field-type-image-form .dialog-form input[name="field_addon_logo_und_0_remove_button"], .dialog-form
  .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
  .plugin-edit .field-type-image-form .dialog-form input[name="field_addon_logo_und_0_remove_button"], .dialog-form
  .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
  .plugin-release .field-type-image-form .dialog-form input[name="field_addon_logo_und_0_remove_button"], .dialog-form #editor-builder #draggable-plugins .all-elements-option a, #editor-builder #draggable-plugins .all-elements-option .dialog-form a, .dialog-form
  #editor-builder .languages .all-elements-option a,
  #editor-builder .languages .all-elements-option .dialog-form a, .dialog-form .login .form-control[type="submit"], .login .dialog-form .form-control[type="submit"], .dialog-form
  .register .form-control[type="submit"],
  .register .dialog-form .form-control[type="submit"], .dialog-form
  .info-page .form-control[type="submit"],
  .info-page .dialog-form .form-control[type="submit"], .dialog-form .most-popular__wrapper .add_to_basket, .most-popular__wrapper .dialog-form .add_to_basket, .dialog-form .add-ons-list .views-row .add_to_basket, .add-ons-list .views-row .dialog-form .add_to_basket, .dialog-form
  .add-ons-list .views-row .check-details,
  .add-ons-list .views-row .dialog-form .check-details, .dialog-form
  .add-ons-list .views-row .remove_from_basket,
  .add-ons-list .views-row .dialog-form .remove_from_basket, .dialog-form
  .add-ons-list .views-row .required,
  .add-ons-list .views-row .dialog-form .required, .dialog-form .user-edit input[type="submit"], .user-edit .dialog-form input[type="submit"], .dialog-form
  .user-edit select[type="submit"],
  .user-edit .dialog-form select[type="submit"] {
    float: right; }
  .dialog-form textarea.form-control {
    height: auto;
    resize: none; }
  .dialog-form form {
    opacity: 1;
    max-height: 800px;
    overflow: hidden;
    transition: all 400ms ease-in-out; }
    .dialog-form form.fade-out {
      opacity: 0;
      max-height: 1px; }
  .dialog-form .form-control {
    font-size: 1.6rem; }
  .dialog-form .form-response {
    float: none;
    text-align: center; }
    .dialog-form .form-response--error {
      border: 2px solid;
      padding: 1.25rem;
      margin: 2.5rem auto; }
    .dialog-form .form-response--success {
      position: relative;
      margin: 0;
      padding-bottom: 7.5rem; }
      .dialog-form .form-response--success:before {
        position: absolute;
        width: 50px;
        height: 50px;
        left: 50%;
        bottom: -10px;
        transform: translateX(-50%); }

.dropdown {
  display: inline-block;
  position: relative; }
  .dropdown--block {
    display: block;
    width: 100%; }
  .dropdown ul {
    display: none !important;
    position: absolute;
    top: 100%;
    right: 0;
    min-width: 100%;
    background-color: white;
    z-index: 99;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.22), 0 6px 6px rgba(0, 0, 0, 0.1); }
    .dropdown ul li {
      width: 100%; }
      .dropdown ul li a {
        color: rgba(64, 81, 102, 0.96);
        padding: 1.25rem;
        line-height: 1.5; }
        .dropdown ul li a:hover {
          color: rgba(64, 81, 102, 0.96);
          background-color: rgba(17, 17, 17, 0.05); }
  .dropdown__list {
    list-style: none;
    margin: 0;
    padding: 0;
    margin: 0;
    z-index: 999;
    border-radius: 0 0 4px 4px; }
    .dropdown__list li {
      position: relative; }
      .dropdown__list li a {
        display: block; }
      .dropdown__list li:first-of-type a {
        padding-top: 1.5rem; }
      .dropdown__list li:last-of-type {
        border-radius: 0 0 4px 4px; }
        .dropdown__list li:last-of-type a {
          padding-bottom: 1.5rem;
          border-radius: 0 0 4px 4px; }
  .dropdown--dark .dropdown__list {
    background-color: rgba(3, 14, 27, 0.9); }
    .dropdown--dark .dropdown__list li a {
      color: rgba(255, 255, 255, 0.8); }
  .dropdown[data-open="true"] ul {
    display: block !important;
    -webkit-animation-name: fadeInDown;
    -moz-animation-name: fadeInDown;
    -ms-animation-name: fadeInDown;
    -o-animation-name: fadeInDown;
    animation-name: fadeInDown;
    -webkit-animation-iteration-count: 1;
    -moz-animation-iteration-count: 1;
    -ms-animation-iteration-count: 1;
    -o-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-duration: 300ms;
    -moz-animation-duration: 300ms;
    -ms-animation-duration: 300ms;
    -o-animation-duration: 300ms;
    animation-duration: 300ms;
    -webkit-animation-delay: 0s;
    -moz-animation-delay: 0s;
    -ms-animation-delay: 0s;
    -o-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    -o-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden; }
  .dropdown button {
    position: relative;
    padding-right: 3rem; }
    .dropdown button:after {
      content: '\25BC';
      position: absolute;
      right: 1.25rem;
      top: 55%;
      font-size: 1.1rem;
      transform: translateY(-50%); }
    .dropdown button.btn--select {
      display: block;
      width: 100%;
      background-color: white;
      color: rgba(64, 81, 102, 0.75);
      text-align: left;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 4px; }
      .dropdown button.btn--select + .dropdown__list a {
        font-size: 1.6rem; }
        .dropdown button.btn--select + .dropdown__list a:hover {
          text-decoration: none; }
  .dropdown-menu__quantity {
    position: absolute;
    right: 5px;
    top: 50%;
    border-radius: 50%;
    padding: 2px 8px;
    background-color: #f2f9ff;
    color: #30a3f1;
    transform: translateY(-50%);
    font-size: 1.4rem; }

.stickme {
  background-color: transparent;
  transition: all 200ms ease-in-out; }
  .stickme--sticked, .stickme--hold {
    position: fixed;
    width: 100%;
    top: 50px;
    background-color: rgba(255, 255, 255, 0.9);
    opacity: .9;
    transform: translateY(0);
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    z-index: 999; }
    .stickme--sticked .pricing-licenses, .stickme--hold .pricing-licenses {
      margin-bottom: 0; }
  .stickme--hold {
    transform: translateY(-10%);
    opacity: .3;
    z-index: -1; }

#mce-responses {
  display: flex; }
  #mce-responses .response {
    flex-grow: 1;
    margin-top: 1.25rem; }
  #mce-responses #mce-error-response {
    color: #f56764; }
  #mce-responses #mce-success-response {
    color: #27d465; }

.menu {
  position: absolute;
  top: 1rem;
  width: 100%;
  background-color: transparent;
  height: 50px;
  z-index: 999; }
  @media (max-width: 767px) {
    .menu {
      top: 0;
      position: fixed;
      background-color: white;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15); }
      .menu .menu__page-title a,
      .menu .menu-more__toggler {
        color: rgba(64, 81, 102, 0.96); } }
  @media (min-width: 1280px) {
    .menu--no-bg-md {
      background: transparent !important; }
      .menu--no-bg-md.menu--small {
        border-bottom: 0; } }
  .menu__page-title {
    display: inline-block;
    height: 100%;
    width: 270px;
    padding-left: 70px; }
    .menu__page-title.with-subtitle a {
      font-weight: 400; }
      .menu__page-title.with-subtitle a span {
        margin-left: 1.5rem;
        font-weight: 300; }
    @media (max-width: 359px) {
      .menu__page-title {
        width: 230px; } }
    @media (min-width: 768px) {
      .menu__page-title {
        padding-left: 90px;
        width: auto; } }
    .menu__page-title a {
      font-family: 'Nunito Sans', sans-serif;
      font-weight: 600;
      font-size: 2.1rem;
      color: white;
      line-height: 50px;
      letter-spacing: inherit;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-decoration: none; }
      .menu__page-title a:hover {
        opacity: .8; }
  @media (max-width: 767px) {
    .menu--no-links .menu__page-title {
      width: 100%; } }
  .menu__page-subtitle {
    display: none;
    position: relative;
    float: right;
    height: 50px;
    padding-right: 3.5rem;
    padding-left: 3.5rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-radius: 4px 0 0 4px;
    transition: background-color 200ms ease-in-out; }
    @media (min-width: 1024px) {
      .menu__page-subtitle {
        display: block; } }
    .menu__page-subtitle p {
      margin: 0;
      font-size: 1.9rem;
      font-weight: 500; }
  @media (min-width: 768px) {
    .menu:not(.menu--tiny) {
      height: 50px; }
      .menu:not(.menu--tiny),
      .menu:not(.menu--tiny) h1,
      .menu:not(.menu--tiny) h2 {
        line-height: 50px; } }
  .menu--tiny .menu__page-title a {
    font-size: 2.4rem;
    font-weight: 400; }
  .menu .progress-bar {
    display: none;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 3px; }
    @media (min-width: 768px) {
      .menu .progress-bar {
        display: block; } }
    .menu .progress-bar__fill {
      display: block;
      width: 100%;
      max-width: 0%;
      height: 100%;
      background-color: #2dbe61;
      transition: max-width 200ms ease; }
  .menu-product, .menu-home {
    float: right;
    height: 50px;
    line-height: 50px;
    visibility: hidden;
    opacity: 0;
    transition: padding 200ms ease-in-out; }
    @media (min-width: 768px) {
      .menu-product, .menu-home {
        padding-right: 3.5rem; } }
    .menu-product--ready, .menu-home--ready {
      visibility: visible;
      opacity: 1; }
    @media (min-width: 1280px) {
      .menu-product--legal, .menu-home--legal {
        visibility: hidden;
        opacity: 0; } }
    .menu-product ul, .menu-home ul {
      display: inline-block;
      vertical-align: top;
      list-style: none;
      margin: 0;
      padding: 0; }
    .menu-product li, .menu-home li {
      display: inline-block;
      vertical-align: top;
      height: 100%; }
      .menu-product li:first-of-type, .menu-home li:first-of-type {
        margin-left: 0; }
      .menu-product li a, .menu-home li a {
        display: block;
        font-size: 1.6rem;
        font-weight: 600;
        color: rgba(64, 81, 102, 0.75);
        height: 100%;
        line-height: 50px;
        padding: 0 1rem;
        transition: color 200ms ease-in-out; }
        .menu-product li a:hover, .menu-home li a:hover {
          color: rgba(64, 81, 102, 0.96);
          text-decoration: none; }
        .menu-product li a.menu-product__current-link, .menu-home li a.menu-product__current-link {
          color: rgba(64, 81, 102, 0.96); }
        @media (min-width: 768px) {
          .menu-product li a, .menu-home li a {
            color: rgba(255, 255, 255, 0.8);
            line-height: 50px; }
            .menu-product li a:hover, .menu-home li a:hover {
              color: white;
              text-decoration: none; }
            .menu-product li a.menu-product__current-link, .menu-home li a.menu-product__current-link {
              color: white; } }
        @media (min-width: 768px) and (max-width: 1279px) {
          .menu-product li a, .menu-home li a {
            font-size: 1.6rem; } }
  @media (min-width: 768px) {
    .menu:not(.menu--tiny) .menu-product {
      height: 50px; } }
  .menu--no-hero .menu__page-title a, .menu--home .menu__page-title a {
    color: rgba(64, 81, 102, 0.96); }
  .menu--no-hero .menu-more__list, .menu--home .menu-more__list {
    background-color: rgba(255, 255, 255, 0.9); }
  .menu--no-hero .menu-more__toggler, .menu--home .menu-more__toggler {
    color: #30a3f1; }
    .menu--no-hero .menu-more__toggler:hover, .menu--no-hero .menu-more__toggler:focus, .menu--home .menu-more__toggler:hover, .menu--home .menu-more__toggler:focus {
      background-color: transparent;
      border: none;
      outline: none; }
    @media (min-width: 1280px) {
      .menu--no-hero .menu-more__toggler:hover, .menu--no-hero .menu-more__toggler:focus, .menu--home .menu-more__toggler:hover, .menu--home .menu-more__toggler:focus {
        color: #30a3f1; } }
  .menu--no-hero .menu-more__list li a,
  .menu--no-hero ul li a, .menu--home .menu-more__list li a,
  .menu--home ul li a {
    color: rgba(64, 81, 102, 0.75); }
    .menu--no-hero .menu-more__list li a:hover,
    .menu--no-hero ul li a:hover, .menu--home .menu-more__list li a:hover,
    .menu--home ul li a:hover {
      color: rgba(64, 81, 102, 0.96);
      text-decoration: none; }
    .menu--no-hero .menu-more__list li a.menu-product__current-link,
    .menu--no-hero ul li a.menu-product__current-link, .menu--home .menu-more__list li a.menu-product__current-link,
    .menu--home ul li a.menu-product__current-link {
      color: rgba(64, 81, 102, 0.96); }
  .menu--no-hero .menu-product a.btn-icon *, .menu--home .menu-product a.btn-icon * {
    fill: rgba(17, 17, 17, 0.9); }
  .menu--no-hero .menu-product a.btn-icon:hover *, .menu--home .menu-product a.btn-icon:hover * {
    fill: #111111; }
  .menu .github-btn__wrapper {
    display: none; }
    @media (min-width: 768px) {
      .menu .github-btn__wrapper {
        display: inline-block; } }
  .menu a.btn-icon {
    display: inline-block;
    width: 30px;
    border: none;
    padding: 0 1rem;
    margin: 0 1rem;
    background-color: transparent;
    vertical-align: middle; }
    .menu a.btn-icon svg {
      height: 30px;
      width: 30px;
      margin-right: .5rem; }
      .menu a.btn-icon svg * {
        fill: rgba(255, 255, 255, 0.9);
        transition: all 200ms ease-in-out; }
    .menu a.btn-icon:hover svg * {
      fill: white; }
  .menu--small {
    position: fixed;
    top: 0;
    background-color: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    -webkit-animation-name: fadeIn;
    -moz-animation-name: fadeIn;
    -ms-animation-name: fadeIn;
    -o-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-iteration-count: 1;
    -moz-animation-iteration-count: 1;
    -ms-animation-iteration-count: 1;
    -o-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-duration: 300ms;
    -moz-animation-duration: 300ms;
    -ms-animation-duration: 300ms;
    -o-animation-duration: 300ms;
    animation-duration: 300ms;
    -webkit-animation-delay: 0s;
    -moz-animation-delay: 0s;
    -ms-animation-delay: 0s;
    -o-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation-timing-function: ease-out;
    -moz-animation-timing-function: ease-out;
    -ms-animation-timing-function: ease-out;
    -o-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden; }
    .menu--small .menu-more__list {
      background-color: rgba(255, 255, 255, 0.9); }
    .menu--small .menu__page-title a {
      color: rgba(64, 81, 102, 0.96); }
      @media (min-width: 768px) {
        .menu--small .menu__page-title a {
          font-size: 1.9rem; } }
    .menu--small .menu-more__toggler {
      color: #30a3f1; }
      .menu--small .menu-more__toggler:hover, .menu--small .menu-more__toggler:focus {
        background-color: transparent;
        border: none;
        outline: none; }
      @media (min-width: 1280px) {
        .menu--small .menu-more__toggler:hover, .menu--small .menu-more__toggler:focus {
          color: #30a3f1; } }
    .menu--small ul li a {
      color: rgba(64, 81, 102, 0.75); }
      .menu--small ul li a:hover {
        color: rgba(64, 81, 102, 0.96);
        text-decoration: none; }
      .menu--small ul li a.menu-product__current-link {
        color: rgba(64, 81, 102, 0.96); }
    .menu--small a.btn-icon svg * {
      fill: rgba(17, 17, 17, 0.9); }
    .menu--small a.btn-icon:hover svg * {
      fill: #111111; }
    .menu--small-alpha {
      background-color: white;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15); }
      .menu--small-alpha .menu-more__list {
        background-color: white; }
      .menu--small-alpha.menu--tiny {
        background-color: white; }
    .menu--small .menu-product,
    .menu--small .menu-side {
      padding-top: 0; }
    .menu--small .menu__page-subtitle {
      border-radius: 0;
      background-color: transparent; }
  .menu-more {
    display: none;
    height: 50px; }
    .menu-more--active {
      display: inline-block; }
    .menu-more.dropdown ul {
      max-width: 300px; }
      @media (min-width: 768px) {
        .menu-more.dropdown ul {
          max-width: 350px; } }
      .menu-more.dropdown ul li {
        display: block; }
        .menu-more.dropdown ul li a {
          padding: 1.25rem 2.5rem 1.25rem;
          color: rgba(64, 81, 102, 0.96);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
          .menu-more.dropdown ul li a.menu-product__current-link {
            color: #30a3f1; }
    .menu-more__toggler[data-toggle="dropdown"] {
      height: 100%;
      padding-left: 1rem;
      border: none;
      background-color: transparent;
      font-size: 1.6rem;
      font-weight: 600; }
      .menu-more__toggler[data-toggle="dropdown"]:after {
        right: 1.75rem;
        font-size: .9rem;
        top: 50%; }
      @media (min-width: 768px) {
        .menu-more__toggler[data-toggle="dropdown"] {
          padding-left: 1rem;
          padding-right: 2rem;
          font-size: 1.9rem; }
          .menu-more__toggler[data-toggle="dropdown"]:after {
            top: 52%;
            right: 0;
            font-size: 1.1rem; } }
      .menu-more__toggler[data-toggle="dropdown"]:hover, .menu-more__toggler[data-toggle="dropdown"]:focus {
        background-color: transparent;
        border: none;
        outline: none; }
      @media (min-width: 1280px) {
        .menu-more__toggler[data-toggle="dropdown"]:hover, .menu-more__toggler[data-toggle="dropdown"]:focus {
          color: #30a3f1; } }
  @media (min-width: 1280px) {
    .menu--trial.menu--small ul li:last-of-type a {
      display: inline-block;
      height: 34px;
      line-height: 34px;
      padding: 0 15px;
      color: white;
      font-weight: 600;
      background-color: #30a3f1;
      vertical-align: middle;
      border-radius: 4px; }
      .menu--trial.menu--small ul li:last-of-type a:hover, .menu--trial.menu--small ul li:last-of-type a:focus {
        background-color: #1898ef; } }

.side-menu {
  position: fixed;
  display: none;
  top: 64px;
  left: 0;
  height: calc( 100vh - 64px);
  width: 350px;
  padding: 0 2.5rem;
  background-color: white;
  z-index: 999999; }
  .side-menu__navigation {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 2.5rem; }
    .side-menu__navigation-item a {
      color: rgba(64, 81, 102, 0.96); }
  .side-menu__close {
    position: absolute;
    right: 2.5rem;
    top: 2.5rem; }

.site-nav {
  position: absolute;
  top: 1rem;
  left: 0;
  height: 50px;
  z-index: 1002;
  transition: padding 200ms ease-in-out; }
  @media (min-width: 768px) {
    .site-nav:not(.menu--tiny) {
      height: 50px; } }
  @media (max-width: 767px) {
    .site-nav {
      position: fixed;
      top: 0; }
      .site-nav .site-nav__toggler .bar {
        background-color: #111111; } }
  .site-nav--fixed {
    position: fixed;
    top: 0; }
    .site-nav--fixed .site-nav__toggler {
      -webkit-animation-name: fadeIn;
      -moz-animation-name: fadeIn;
      -ms-animation-name: fadeIn;
      -o-animation-name: fadeIn;
      animation-name: fadeIn;
      -webkit-animation-iteration-count: 1s;
      -moz-animation-iteration-count: 1s;
      -ms-animation-iteration-count: 1s;
      -o-animation-iteration-count: 1s;
      animation-iteration-count: 1s;
      -webkit-animation-duration: 300ms;
      -moz-animation-duration: 300ms;
      -ms-animation-duration: 300ms;
      -o-animation-duration: 300ms;
      animation-duration: 300ms;
      -webkit-animation-delay: 0s;
      -moz-animation-delay: 0s;
      -ms-animation-delay: 0s;
      -o-animation-delay: 0s;
      animation-delay: 0s;
      -webkit-animation-timing-function: ease-in;
      -moz-animation-timing-function: ease-in;
      -ms-animation-timing-function: ease-in;
      -o-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      -webkit-animation-fill-mode: both;
      -moz-animation-fill-mode: both;
      -ms-animation-fill-mode: both;
      -o-animation-fill-mode: both;
      animation-fill-mode: both;
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      -ms-backface-visibility: hidden;
      -o-backface-visibility: hidden;
      backface-visibility: hidden; }
      .site-nav--fixed .site-nav__toggler .bar {
        background-color: #111111; }
      @media (min-width: 1280px) {
        .site-nav--fixed .site-nav__toggler:focus .bar, .site-nav--fixed .site-nav__toggler:active .bar {
          background-color: #30a3f1; } }
  .site-nav--active {
    position: fixed;
    top: 0;
    transition: all 200ms ease-in-out; }
  .site-nav--no-hero .site-nav__toggler .bar, .site-nav--active .site-nav__toggler .bar, .site-nav--hero-inside.site-nav--fixed .site-nav__toggler .bar, .site-nav--light.site-nav--fixed .site-nav__toggler .bar {
    background-color: #111111; }
  @media (min-width: 1280px) {
    .site-nav--no-hero .site-nav__toggler:focus .bar, .site-nav--no-hero .site-nav__toggler:active .bar, .site-nav--active .site-nav__toggler:focus .bar, .site-nav--active .site-nav__toggler:active .bar, .site-nav--hero-inside.site-nav--fixed .site-nav__toggler:focus .bar, .site-nav--hero-inside.site-nav--fixed .site-nav__toggler:active .bar, .site-nav--light.site-nav--fixed .site-nav__toggler:focus .bar, .site-nav--light.site-nav--fixed .site-nav__toggler:active .bar {
      background-color: #30a3f1; } }
  .site-nav--no-hero .site-nav__current, .site-nav--active .site-nav__current, .site-nav--hero-inside.site-nav--fixed .site-nav__current, .site-nav--light.site-nav--fixed .site-nav__current {
    color: rgba(64, 81, 102, 0.96); }
  .site-nav__toggler {
    position: absolute;
    top: 50%;
    left: 1.5rem;
    height: 39px;
    width: 40px;
    padding: 0;
    transform: translateY(-50%);
    cursor: pointer;
    background-color: inherit;
    border: 0;
    transition: transform 200ms ease-in-out;
    /* Show button above navigation. */
    z-index: 1002; }
    .site-nav__toggler--on-move {
      transition: transform 300ms ease-in-out, outline 200ms linear 300ms; }
    @media (min-width: 768px) {
      .site-nav__toggler {
        left: 2.5rem; } }
    .site-nav__toggler .bar {
      position: absolute;
      left: 5px;
      height: 3px;
      width: 30px;
      display: block;
      background-color: white;
      border-radius: 10px;
      transition: transform 200ms ease-in-out; }
      .site-nav__toggler .bar:nth-of-type(1) {
        top: 10px;
        transition: top 200ms ease-in-out 100ms, transform 200ms ease-in-out 100ms;
        animation: mrotr 2s cubic-bezier(0.5, 0.2, 0.2, 1.01); }
      .site-nav__toggler .bar:nth-of-type(2) {
        top: 18px;
        transition: opacity 100ms ease-in-out 100ms;
        animation: fade 2s cubic-bezier(0.5, 0.2, 0.2, 1.01); }
      .site-nav__toggler .bar:nth-of-type(3) {
        top: 26px;
        transition: top 200ms  ease-in-out 100ms, transform 200ms ease-in-out 100ms;
        animation: mrotl 2s cubic-bezier(0.5, 0.2, 0.2, 1.01); }
    .site-nav__toggler--active {
      transition: transform 200ms ease-in-out; }
      .site-nav__toggler--active:focus, .site-nav__toggler--active:active {
        outline: none; }
      @media (pointer: fine) {
        .site-nav__toggler--active:focus .bar, .site-nav__toggler--active:active .bar {
          background-color: #30a3f1; } }
      @media (min-width: 768px) {
        .site-nav__toggler--active {
          transform: translate(-1rem, -50%); } }
      .site-nav__toggler--active .bar:nth-of-type(1) {
        top: 18px;
        transform: rotate(35deg) scale(0.7, 1) translate(8px, 5px);
        transition: top 200ms ease-in-out 100ms, transform 200ms ease-in-out 100ms; }
      .site-nav__toggler--active .bar:nth-of-type(2) {
        opacity: 0; }
      .site-nav__toggler--active .bar:nth-of-type(3) {
        top: 18px;
        transform: rotate(-35deg) scale(0.7, 1) translate(5px, -2px);
        transition: top 200ms ease-in-out 100ms, transform 200ms ease-in-out 100ms; }
  .site-nav__panel {
    overscroll-behavior: contain;
    position: fixed;
    top: 0;
    bottom: 0;
    left: -230px;
    min-height: 100vh;
    width: 230px;
    padding-top: 7.5rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    background-color: white;
    overflow-y: auto;
    z-index: 1001;
    transition: transform 300ms ease-in-out; }
    .site-nav__panel--active {
      transform: translateX(230px); }
    @media (min-width: 768px) {
      .site-nav__panel {
        left: -280px;
        width: 280px; }
        .site-nav__panel--active {
          transform: translateX(280px); } }
    .site-nav__panel nav {
      height: 100%; }
    .site-nav__panel h2 {
      margin-bottom: 0.625rem;
      font-size: 1.6rem;
      font-weight: 800;
      line-height: 2.5rem;
      letter-spacing: 0; }
      .site-nav__panel h2 a {
        color: rgba(64, 81, 102, 0.75); }
        .site-nav__panel h2 a:hover {
          text-decoration: none;
          color: rgba(64, 81, 102, 0.96); }
  .site-nav__sets-container {
    display: flex;
    min-height: calc( 100vh - 12rem);
    flex-direction: column; }
  .site-nav__sets-inner {
    margin-top: 2.5rem; }
    @media (max-width: 767px) {
      .site-nav__sets-inner {
        padding-bottom: 1.25rem; } }
    .site-nav__sets-inner:last-of-type {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      padding-top: 2.5rem; }
  .site-nav__menu {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 2.5rem; }
    .site-nav__menu:first-of-type {
      margin-top: 0; }
    .site-nav__menu-item {
      margin-bottom: 0; }
      .site-nav__menu-item a {
        display: block;
        position: relative;
        color: rgba(64, 81, 102, 0.75);
        margin-bottom: .2rem;
        font-size: 1.6rem;
        font-weight: 600; }
        .site-nav__menu-item a:hover {
          text-decoration: none;
          color: rgba(64, 81, 102, 0.96); }
      .site-nav__menu-item--current a {
        text-decoration: none;
        color: #30a3f1; }
      .site-nav__menu-item-badge {
        position: absolute;
        right: 0;
        top: 50%;
        padding: .4em .8em 0.3em;
        color: white;
        font-size: 1.3rem;
        font-weight: 800;
        line-height: 1;
        border-radius: 8px;
        background-color: #8e44ad;
        transform: translateY(-50%); }
    .site-nav__menu-category {
      margin-top: 2.5rem;
      margin-bottom: .6rem;
      color: rgba(64, 81, 102, 0.48);
      font-size: 1.6rem;
      font-weight: 600; }

.site-cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background-color: rgba(3, 14, 27, 0.7);
  z-index: -1;
  transition: opacity 200ms ease-in-out; }
  .site-cover--active {
    z-index: 1000;
    opacity: 1; }
  .site-cover--hide {
    opacity: 0; }

.pagination {
  text-align: center; }
  .pagination a,
  .pagination span {
    margin-right: 1rem; }
  .pagination:first-child, .pagination:last-child {
    font-size: 2.1rem; }
  .pagination:last-child {
    margin-right: 0; }

/* Let's define individual tab theme color. */
.site-content--ckeditor-5 .tab.tab--active::before {
  background: #84e6b9; }

.site-content--ckeditor-4 .tab.tab--active::before {
  background: #83d4fe; }

.site-content--collaborative-editing .tab.tab--active::before {
  background: #916dd1; }

.tabs__wrapper {
  display: none;
  width: 900px;
  max-width: 100%;
  margin: 0 auto;
  text-align: center; }
  @media (min-width: 768px) {
    .tabs__wrapper {
      display: block;
      text-align: inherit; } }
  @media (min-width: 1280px) {
    .tabs__wrapper {
      width: 1030px; }
      .tabs__wrapper--wide {
        width: 100%; }
      .tabs__wrapper--wide-mid {
        width: 1160px; } }
  .tabs__wrapper .state-change-target {
    display: none; }

.tabs__header {
  position: relative;
  display: block;
  width: 900px;
  max-width: 100%;
  margin: 0 auto;
  /* Move tabs to overflow header. */
  top: -50px; }

.tabs__list {
  display: flex;
  width: 100%;
  align-items: stretch;
  justify-content: center;
  margin: 0;
  padding: 0; }
  .tabs__list .tab {
    display: inline-block;
    position: relative;
    flex: 1;
    background: white;
    height: 50px;
    /* We need to reset default button border-style. */
    border: 0;
    border-bottom: 1px solid #d0d0d0;
    border-left: 1px solid #d0d0d0;
    text-align: center;
    font-size: 1.6rem;
    color: rgba(64, 81, 102, 0.75);
    padding: 12px 0;
    cursor: pointer;
    user-select: none;
    transition: background 200ms ease-in-out; }
    .tabs__list .tab:first-of-type {
      border-left: 0; }
    .tabs__list .tab--active {
      border-bottom: 0;
      color: rgba(64, 81, 102, 0.96); }
      .tabs__list .tab--active::before {
        content: '';
        width: 100%;
        position: absolute;
        height: 3px;
        top: -3px;
        left: 0;
        background: #d7edfc; }
    .tabs__list .tab:focus, .tabs__list .tab:active {
      outline: none;
      background: #f2f2f2; }
    .tabs__list .tab:hover:not(.tab--active) {
      background: #f2f2f2; }

.tabs__content-section {
  position: relative;
  overflow: hidden;
  min-height: 300px; }

.tabs__content {
  display: none;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  min-height: 500px;
  padding: 0 15px 0; }
  .tabs__content--demo {
    text-align: left; }
  .tabs__content.tabs__content--active {
    display: block; }

.tabs .demo-actions {
  text-align: center; }
  .tabs .demo-actions .btn, .tabs .demo-actions #cksource_repo_search_forums_form .form-submit, #cksource_repo_search_forums_form .tabs .demo-actions .form-submit, .tabs .demo-actions #forum-sort .form-submit, #forum-sort .tabs .demo-actions .form-submit, .tabs .demo-actions .add-plugin input[type="submit"], .add-plugin .tabs .demo-actions input[type="submit"], .tabs .demo-actions
  .add-plugin select[type="submit"],
  .add-plugin .tabs .demo-actions select[type="submit"], .tabs .demo-actions
  .plugin-edit input[type="submit"],
  .plugin-edit .tabs .demo-actions input[type="submit"], .tabs .demo-actions
  .plugin-edit select[type="submit"],
  .plugin-edit .tabs .demo-actions select[type="submit"], .tabs .demo-actions
  .plugin-release input[type="submit"],
  .plugin-release .tabs .demo-actions input[type="submit"], .tabs .demo-actions
  .plugin-release select[type="submit"],
  .plugin-release .tabs .demo-actions select[type="submit"], .tabs .demo-actions .add-plugin .field-type-image-form input[type="file"], .add-plugin .field-type-image-form .tabs .demo-actions input[type="file"], .tabs .demo-actions
  .plugin-edit .field-type-image-form input[type="file"],
  .plugin-edit .field-type-image-form .tabs .demo-actions input[type="file"], .tabs .demo-actions
  .plugin-release .field-type-image-form input[type="file"],
  .plugin-release .field-type-image-form .tabs .demo-actions input[type="file"], .tabs .demo-actions .add-plugin .field-type-image-form button, .add-plugin .field-type-image-form .tabs .demo-actions button, .tabs .demo-actions
  .plugin-edit .field-type-image-form button,
  .plugin-edit .field-type-image-form .tabs .demo-actions button, .tabs .demo-actions
  .plugin-release .field-type-image-form button,
  .plugin-release .field-type-image-form .tabs .demo-actions button, .tabs .demo-actions .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], .add-plugin .field-type-image-form .tabs .demo-actions input[name="field_addon_logo_und_0_remove_button"], .tabs .demo-actions
  .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
  .plugin-edit .field-type-image-form .tabs .demo-actions input[name="field_addon_logo_und_0_remove_button"], .tabs .demo-actions
  .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
  .plugin-release .field-type-image-form .tabs .demo-actions input[name="field_addon_logo_und_0_remove_button"], .tabs .demo-actions #editor-builder #draggable-plugins .all-elements-option a, #editor-builder #draggable-plugins .all-elements-option .tabs .demo-actions a, .tabs .demo-actions
  #editor-builder .languages .all-elements-option a,
  #editor-builder .languages .all-elements-option .tabs .demo-actions a, .tabs .demo-actions .login .form-control[type="submit"], .login .tabs .demo-actions .form-control[type="submit"], .tabs .demo-actions
  .register .form-control[type="submit"],
  .register .tabs .demo-actions .form-control[type="submit"], .tabs .demo-actions
  .info-page .form-control[type="submit"],
  .info-page .tabs .demo-actions .form-control[type="submit"], .tabs .demo-actions .most-popular__wrapper .add_to_basket, .most-popular__wrapper .tabs .demo-actions .add_to_basket, .tabs .demo-actions .add-ons-list .views-row .add_to_basket, .add-ons-list .views-row .tabs .demo-actions .add_to_basket, .tabs .demo-actions
  .add-ons-list .views-row .check-details,
  .add-ons-list .views-row .tabs .demo-actions .check-details, .tabs .demo-actions
  .add-ons-list .views-row .remove_from_basket,
  .add-ons-list .views-row .tabs .demo-actions .remove_from_basket, .tabs .demo-actions
  .add-ons-list .views-row .required,
  .add-ons-list .views-row .tabs .demo-actions .required, .tabs .demo-actions .user-edit input[type="submit"], .user-edit .tabs .demo-actions input[type="submit"], .tabs .demo-actions
  .user-edit select[type="submit"],
  .user-edit .tabs .demo-actions select[type="submit"] {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2.5rem;
    width: 320px;
    max-width: 90%; }
    @media (min-width: 1680px) {
      .tabs .demo-actions .btn, .tabs .demo-actions #cksource_repo_search_forums_form .form-submit, #cksource_repo_search_forums_form .tabs .demo-actions .form-submit, .tabs .demo-actions #forum-sort .form-submit, #forum-sort .tabs .demo-actions .form-submit, .tabs .demo-actions .add-plugin input[type="submit"], .add-plugin .tabs .demo-actions input[type="submit"], .tabs .demo-actions
      .add-plugin select[type="submit"],
      .add-plugin .tabs .demo-actions select[type="submit"], .tabs .demo-actions
      .plugin-edit input[type="submit"],
      .plugin-edit .tabs .demo-actions input[type="submit"], .tabs .demo-actions
      .plugin-edit select[type="submit"],
      .plugin-edit .tabs .demo-actions select[type="submit"], .tabs .demo-actions
      .plugin-release input[type="submit"],
      .plugin-release .tabs .demo-actions input[type="submit"], .tabs .demo-actions
      .plugin-release select[type="submit"],
      .plugin-release .tabs .demo-actions select[type="submit"], .tabs .demo-actions .add-plugin .field-type-image-form input[type="file"], .add-plugin .field-type-image-form .tabs .demo-actions input[type="file"], .tabs .demo-actions
      .plugin-edit .field-type-image-form input[type="file"],
      .plugin-edit .field-type-image-form .tabs .demo-actions input[type="file"], .tabs .demo-actions
      .plugin-release .field-type-image-form input[type="file"],
      .plugin-release .field-type-image-form .tabs .demo-actions input[type="file"], .tabs .demo-actions .add-plugin .field-type-image-form button, .add-plugin .field-type-image-form .tabs .demo-actions button, .tabs .demo-actions
      .plugin-edit .field-type-image-form button,
      .plugin-edit .field-type-image-form .tabs .demo-actions button, .tabs .demo-actions
      .plugin-release .field-type-image-form button,
      .plugin-release .field-type-image-form .tabs .demo-actions button, .tabs .demo-actions .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], .add-plugin .field-type-image-form .tabs .demo-actions input[name="field_addon_logo_und_0_remove_button"], .tabs .demo-actions
      .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
      .plugin-edit .field-type-image-form .tabs .demo-actions input[name="field_addon_logo_und_0_remove_button"], .tabs .demo-actions
      .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
      .plugin-release .field-type-image-form .tabs .demo-actions input[name="field_addon_logo_und_0_remove_button"], .tabs .demo-actions #editor-builder #draggable-plugins .all-elements-option a, #editor-builder #draggable-plugins .all-elements-option .tabs .demo-actions a, .tabs .demo-actions
      #editor-builder .languages .all-elements-option a,
      #editor-builder .languages .all-elements-option .tabs .demo-actions a, .tabs .demo-actions .login .form-control[type="submit"], .login .tabs .demo-actions .form-control[type="submit"], .tabs .demo-actions
      .register .form-control[type="submit"],
      .register .tabs .demo-actions .form-control[type="submit"], .tabs .demo-actions
      .info-page .form-control[type="submit"],
      .info-page .tabs .demo-actions .form-control[type="submit"], .tabs .demo-actions .most-popular__wrapper .add_to_basket, .most-popular__wrapper .tabs .demo-actions .add_to_basket, .tabs .demo-actions .add-ons-list .views-row .add_to_basket, .add-ons-list .views-row .tabs .demo-actions .add_to_basket, .tabs .demo-actions
      .add-ons-list .views-row .check-details,
      .add-ons-list .views-row .tabs .demo-actions .check-details, .tabs .demo-actions
      .add-ons-list .views-row .remove_from_basket,
      .add-ons-list .views-row .tabs .demo-actions .remove_from_basket, .tabs .demo-actions
      .add-ons-list .views-row .required,
      .add-ons-list .views-row .tabs .demo-actions .required, .tabs .demo-actions .user-edit input[type="submit"], .user-edit .tabs .demo-actions input[type="submit"], .tabs .demo-actions
      .user-edit select[type="submit"],
      .user-edit .tabs .demo-actions select[type="submit"] {
        display: inline-block;
        margin-right: 17px;
        width: auto; }
        .tabs .demo-actions .btn:last-of-type, .tabs .demo-actions #cksource_repo_search_forums_form .form-submit:last-of-type, #cksource_repo_search_forums_form .tabs .demo-actions .form-submit:last-of-type, .tabs .demo-actions #forum-sort .form-submit:last-of-type, #forum-sort .tabs .demo-actions .form-submit:last-of-type, .tabs .demo-actions .add-plugin input[type="submit"]:last-of-type, .add-plugin .tabs .demo-actions input[type="submit"]:last-of-type, .tabs .demo-actions
        .add-plugin select[type="submit"]:last-of-type,
        .add-plugin .tabs .demo-actions select[type="submit"]:last-of-type, .tabs .demo-actions
        .plugin-edit input[type="submit"]:last-of-type,
        .plugin-edit .tabs .demo-actions input[type="submit"]:last-of-type, .tabs .demo-actions
        .plugin-edit select[type="submit"]:last-of-type,
        .plugin-edit .tabs .demo-actions select[type="submit"]:last-of-type, .tabs .demo-actions
        .plugin-release input[type="submit"]:last-of-type,
        .plugin-release .tabs .demo-actions input[type="submit"]:last-of-type, .tabs .demo-actions
        .plugin-release select[type="submit"]:last-of-type,
        .plugin-release .tabs .demo-actions select[type="submit"]:last-of-type, .tabs .demo-actions .add-plugin .field-type-image-form input[type="file"]:last-of-type, .add-plugin .field-type-image-form .tabs .demo-actions input[type="file"]:last-of-type, .tabs .demo-actions
        .plugin-edit .field-type-image-form input[type="file"]:last-of-type,
        .plugin-edit .field-type-image-form .tabs .demo-actions input[type="file"]:last-of-type, .tabs .demo-actions
        .plugin-release .field-type-image-form input[type="file"]:last-of-type,
        .plugin-release .field-type-image-form .tabs .demo-actions input[type="file"]:last-of-type, .tabs .demo-actions .add-plugin .field-type-image-form button:last-of-type, .add-plugin .field-type-image-form .tabs .demo-actions button:last-of-type, .tabs .demo-actions
        .plugin-edit .field-type-image-form button:last-of-type,
        .plugin-edit .field-type-image-form .tabs .demo-actions button:last-of-type, .tabs .demo-actions
        .plugin-release .field-type-image-form button:last-of-type,
        .plugin-release .field-type-image-form .tabs .demo-actions button:last-of-type, .tabs .demo-actions .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:last-of-type, .add-plugin .field-type-image-form .tabs .demo-actions input[name="field_addon_logo_und_0_remove_button"]:last-of-type, .tabs .demo-actions
        .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:last-of-type,
        .plugin-edit .field-type-image-form .tabs .demo-actions input[name="field_addon_logo_und_0_remove_button"]:last-of-type, .tabs .demo-actions
        .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:last-of-type,
        .plugin-release .field-type-image-form .tabs .demo-actions input[name="field_addon_logo_und_0_remove_button"]:last-of-type, .tabs .demo-actions #editor-builder #draggable-plugins .all-elements-option a:last-of-type, #editor-builder #draggable-plugins .all-elements-option .tabs .demo-actions a:last-of-type, .tabs .demo-actions
        #editor-builder .languages .all-elements-option a:last-of-type,
        #editor-builder .languages .all-elements-option .tabs .demo-actions a:last-of-type, .tabs .demo-actions .login .form-control[type="submit"]:last-of-type, .login .tabs .demo-actions .form-control[type="submit"]:last-of-type, .tabs .demo-actions
        .register .form-control[type="submit"]:last-of-type,
        .register .tabs .demo-actions .form-control[type="submit"]:last-of-type, .tabs .demo-actions
        .info-page .form-control[type="submit"]:last-of-type,
        .info-page .tabs .demo-actions .form-control[type="submit"]:last-of-type, .tabs .demo-actions .most-popular__wrapper .add_to_basket:last-of-type, .most-popular__wrapper .tabs .demo-actions .add_to_basket:last-of-type, .tabs .demo-actions .add-ons-list .views-row .add_to_basket:last-of-type, .add-ons-list .views-row .tabs .demo-actions .add_to_basket:last-of-type, .tabs .demo-actions
        .add-ons-list .views-row .check-details:last-of-type,
        .add-ons-list .views-row .tabs .demo-actions .check-details:last-of-type, .tabs .demo-actions
        .add-ons-list .views-row .remove_from_basket:last-of-type,
        .add-ons-list .views-row .tabs .demo-actions .remove_from_basket:last-of-type, .tabs .demo-actions
        .add-ons-list .views-row .required:last-of-type,
        .add-ons-list .views-row .tabs .demo-actions .required:last-of-type, .tabs .demo-actions .user-edit input[type="submit"]:last-of-type, .user-edit .tabs .demo-actions input[type="submit"]:last-of-type, .tabs .demo-actions
        .user-edit select[type="submit"]:last-of-type,
        .user-edit .tabs .demo-actions select[type="submit"]:last-of-type {
          margin-right: 0; } }

.tabs__sdk-link {
  text-align: right;
  font-size: 0.7em;
  margin-top: 0.6em;
  clear: both; }

.module-testimonials {
  margin-top: 5rem; }
  @media (max-width: 767px) {
    .module-testimonials .section-container, .module-testimonials .site-info__wrapper {
      padding: 0; } }
  .module-testimonials__wrapper {
    width: 1000px;
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
    width: calc( 920px + ( 2 * 5rem ));
    position: relative;
    padding-top: 5rem;
    padding-bottom: 7.5rem; }
    @media (max-width: 767px) {
      .module-testimonials__wrapper {
        padding-top: 0; } }
  .module-testimonials__item h3 {
    margin: 0; }
  .module-testimonials__item blockquote,
  .module-testimonials__item p.blockquote {
    margin-bottom: 2.5rem;
    position: relative;
    padding: 5rem 0 0;
    font-size: 2.1rem;
    letter-spacing: -0.42px; }
    @media (max-width: 767px) {
      .module-testimonials__item blockquote,
      .module-testimonials__item p.blockquote {
        margin: 0 0 2.5rem;
        font-style: italic; } }
    @media (min-width: 768px) {
      .module-testimonials__item blockquote,
      .module-testimonials__item p.blockquote {
        margin: 0 10rem 2.5rem;
        padding: 2.5rem 5rem 0; } }
    @media (min-width: 1024px) {
      .module-testimonials__item blockquote,
      .module-testimonials__item p.blockquote {
        font-size: 2.4rem; } }
  .module-testimonials__item blockquote:before {
    content: "\201C";
    position: absolute;
    top: -27px;
    left: 0;
    font-family: Georgia, serif;
    font-size: 90px;
    font-weight: bold;
    color: rgba(17, 17, 17, 0.15); }
    @media (min-width: 768px) {
      .module-testimonials__item blockquote:before {
        left: 20px;
        top: 19px;
        font-size: 60px;
        color: rgba(64, 81, 102, 0.48); } }
  .module-testimonials__client {
    margin: 0;
    font-size: 1.8rem;
    font-weight: 800;
    text-transform: uppercase; }
  .module-testimonials__company-name {
    margin: 0;
    font-size: 1.6rem;
    font-weight: 600; }
  .module-testimonials .swiper-pagination {
    bottom: 2.5rem; }
    .module-testimonials .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      margin: 0 8px;
      opacity: 1;
      background-color: #979797;
      border: 2px solid #979797; }
      .module-testimonials .swiper-pagination-bullet-active {
        background-color: white; }
  .module-testimonials .swiper-button-prev, .module-testimonials .swiper-button-next {
    display: none;
    width: 48px;
    height: 49px;
    margin-top: -24.5px; }
    @media (min-width: 768px) {
      .module-testimonials .swiper-button-prev, .module-testimonials .swiper-button-next {
        display: block; } }
  .module-testimonials .swiper-button-prev {
    background-image: url(../images/home/home-icon-testimonials-prev.svg); }
  .module-testimonials .swiper-button-next {
    background-image: url(../images/home/home-icon-testimonials-next.svg); }

.tingle-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  display: flex;
  visibility: hidden;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  align-items: center;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  cursor: pointer;
  transition: all 300ms ease-in-out; }
  .tingle-modal * {
    box-sizing: border-box; }
  .tingle-modal h2 {
    text-align: center;
    margin-bottom: 2.5rem; }
  .tingle-modal--letters .tingle-modal-box {
    padding: 5rem 10rem; }
  .tingle-modal__loader {
    position: absolute;
    left: 0;
    top: 0;
    background: white;
    width: 100%;
    height: 100%;
    text-align: center;
    opacity: 0;
    z-index: -1;
    transition: all 700ms ease-in-out;
    border-radius: 4px; }
    .tingle-modal__loader--active {
      z-index: 1;
      opacity: 1; }
      .tingle-modal__loader--active .ckloader__spinner-text--error {
        display: none; }
    .tingle-modal__loader--error .ckloader {
      z-index: 999;
      opacity: 1; }
      .tingle-modal__loader--error .ckloader__spinner {
        display: block; }
        .tingle-modal__loader--error .ckloader__spinner .circular {
          animation: none; }
        .tingle-modal__loader--error .ckloader__spinner .path {
          animation: none;
          stroke: #d9534f;
          stroke-dasharray: 16px;
          stroke-dashoffset: 0; }
        .tingle-modal__loader--error .ckloader__spinner-text {
          display: none; }
          .tingle-modal__loader--error .ckloader__spinner-text--error {
            color: #d9534f;
            display: block;
            position: relative;
            width: 500px;
            text-align: center; }
    .tingle-modal__loader .ckloader {
      border-radius: 4px; }
      .tingle-modal__loader .ckloader__inner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding-top: 40px; }
      .tingle-modal__loader .ckloader__spinner {
        position: relative;
        width: 90px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
  .tingle-modal__close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    padding: 0;
    width: 30px;
    height: 30px;
    border: none;
    background-color: transparent;
    color: rgba(64, 81, 102, 0.48);
    font-size: 32px;
    line-height: 30px;
    cursor: pointer;
    z-index: 1000;
    transition: color 200ms ease-in-out; }
    .tingle-modal__close:hover {
      color: rgba(64, 81, 102, 0.75); }
  .tingle-modal__closeLabel {
    display: none; }
  .tingle-modal-box {
    position: absolute;
    top: 50%;
    left: 50%;
    flex-shrink: 0;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 4px;
    background: #fff;
    opacity: 1;
    cursor: auto;
    transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform: translate(-50%, -50%);
    padding: 4rem;
    max-width: 1000px;
    border-radius: 4px; }
    .tingle-modal-box__content {
      display: flex;
      flex-direction: column;
      max-width: 1000px;
      margin: 0 auto; }
      .tingle-modal-box__content img {
        width: 100%; }
      .tingle-modal-box__content--mobile {
        text-align: center; }
    .tingle-modal-box__inputs-row {
      display: flex;
      flex-direction: column; }
      .tingle-modal-box__inputs-row label {
        font-weight: normal; }
      .tingle-modal-box__inputs-row input[type="text"] {
        margin-bottom: 20px;
        padding: 5px; }
    .tingle-modal-box--noOverlayClose {
      cursor: default; }
    .tingle-modal-box__footer {
      text-align: center; }
      .tingle-modal-box__footer--sticky {
        position: fixed;
        bottom: -200px;
        /* TODO : find a better way */
        z-index: 10001;
        opacity: 1;
        transition: bottom .3s ease-in-out .3s; }
      .tingle-modal-box__footer button {
        border-radius: 4px; }
    .tingle-modal-box .module-subscribe__actions {
      box-shadow: none;
      margin-top: 0; }
      .tingle-modal-box .module-subscribe__actions .form-control {
        border: 1px solid rgba(0, 0, 0, 0.15); }
    .tingle-modal-box .mc-response {
      box-shadow: none;
      padding: 0;
      margin-bottom: 0; }
      .tingle-modal-box .mc-response--success {
        font-size: 2.1rem;
        color: #2dbe61;
        text-align: center; }
  .tingle-modal__header {
    position: relative;
    padding: 5rem 0 2.5rem; }
    .tingle-modal__header h2 {
      margin-bottom: 0;
      color: rgba(64, 81, 102, 0.75); }
  .tingle-modal--info .tingle-modal-box {
    padding: 0; }
    .tingle-modal--info .tingle-modal-box__content-inner {
      padding: 2.5rem; }
      @media (min-width: 1280px) {
        .tingle-modal--info .tingle-modal-box__content-inner {
          padding: 0 5rem 2.5rem; } }
    .tingle-modal--info .tingle-modal-box__footer {
      font-size: 1.6rem;
      color: rgba(64, 81, 102, 0.75); }
  .tingle-modal--with-footer .tingle-modal-box__content-inner {
    padding-bottom: 0; }

.tingle-modal--confirm .tingle-modal-box {
  text-align: center; }

.tingle-modal .tingle-modal--noClose .tingle-modal__close {
  display: none; }

.tingle-enabled {
  overflow: hidden;
  height: 100%; }

.tingle-modal--visible .tingle-modal-box__footer {
  bottom: 0; }

.tingle-enabled .tingle-content-wrapper {
  filter: blur(15px); }

.tingle-modal--visible {
  visibility: visible;
  opacity: 1; }

.tingle-modal--overflow {
  overflow-y: scroll;
  padding-top: 8vh; }

@media (max-width: 540px) {
  .tingle-modal {
    display: block;
    width: 100%; }
    .tingle-modal h2 {
      font-size: 2.5rem;
      margin-bottom: 0; }
    .tingle-modal-box {
      width: 90%; }
    .tingle-modal--noClose {
      top: 0; }
    .tingle-modal--overflow {
      padding: 0; }
    .tingle-modal .module-subscribe__actions input {
      border: 1px solid #fdfdfd; } }

.section-describe {
  text-align: center; }
  @media (min-width: 768px) {
    .section-describe {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      max-width: 100%;
      text-align: inherit; }
      .section-describe--left {
        right: 0;
        text-align: right; } }
    @media (min-width: 768px) and (min-width: 768px) {
      .section-describe--left {
        padding-left: 2rem; } }
    @media (min-width: 768px) and (min-width: 1280px) {
      .section-describe--left {
        padding: 0; } }
  @media (min-width: 768px) {
        .section-describe--left p,
        .section-describe--left .btn,
        .section-describe--left #cksource_repo_search_forums_form .form-submit, #cksource_repo_search_forums_form
        .section-describe--left .form-submit,
        .section-describe--left #forum-sort .form-submit, #forum-sort
        .section-describe--left .form-submit,
        .section-describe--left .add-plugin input[type="submit"], .add-plugin
        .section-describe--left input[type="submit"],
        .section-describe--left
        .add-plugin select[type="submit"],
        .add-plugin
        .section-describe--left select[type="submit"],
        .section-describe--left
        .plugin-edit input[type="submit"],
        .plugin-edit
        .section-describe--left input[type="submit"],
        .section-describe--left
        .plugin-edit select[type="submit"],
        .plugin-edit
        .section-describe--left select[type="submit"],
        .section-describe--left
        .plugin-release input[type="submit"],
        .plugin-release
        .section-describe--left input[type="submit"],
        .section-describe--left
        .plugin-release select[type="submit"],
        .plugin-release
        .section-describe--left select[type="submit"],
        .section-describe--left .add-plugin .field-type-image-form input[type="file"], .add-plugin .field-type-image-form
        .section-describe--left input[type="file"],
        .section-describe--left
        .plugin-edit .field-type-image-form input[type="file"],
        .plugin-edit .field-type-image-form
        .section-describe--left input[type="file"],
        .section-describe--left
        .plugin-release .field-type-image-form input[type="file"],
        .plugin-release .field-type-image-form
        .section-describe--left input[type="file"],
        .section-describe--left .add-plugin .field-type-image-form button, .add-plugin .field-type-image-form
        .section-describe--left button,
        .section-describe--left
        .plugin-edit .field-type-image-form button,
        .plugin-edit .field-type-image-form
        .section-describe--left button,
        .section-describe--left
        .plugin-release .field-type-image-form button,
        .plugin-release .field-type-image-form
        .section-describe--left button,
        .section-describe--left .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], .add-plugin .field-type-image-form
        .section-describe--left input[name="field_addon_logo_und_0_remove_button"],
        .section-describe--left
        .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
        .plugin-edit .field-type-image-form
        .section-describe--left input[name="field_addon_logo_und_0_remove_button"],
        .section-describe--left
        .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
        .plugin-release .field-type-image-form
        .section-describe--left input[name="field_addon_logo_und_0_remove_button"],
        .section-describe--left #editor-builder #draggable-plugins .all-elements-option a, #editor-builder #draggable-plugins .all-elements-option
        .section-describe--left a,
        .section-describe--left
        #editor-builder .languages .all-elements-option a,
        #editor-builder .languages .all-elements-option
        .section-describe--left a,
        .section-describe--left .login .form-control[type="submit"], .login
        .section-describe--left .form-control[type="submit"],
        .section-describe--left
        .register .form-control[type="submit"],
        .register
        .section-describe--left .form-control[type="submit"],
        .section-describe--left
        .info-page .form-control[type="submit"],
        .info-page
        .section-describe--left .form-control[type="submit"],
        .section-describe--left .most-popular__wrapper .add_to_basket, .most-popular__wrapper
        .section-describe--left .add_to_basket,
        .section-describe--left .add-ons-list .views-row .add_to_basket, .add-ons-list .views-row
        .section-describe--left .add_to_basket,
        .section-describe--left
        .add-ons-list .views-row .check-details,
        .add-ons-list .views-row
        .section-describe--left .check-details,
        .section-describe--left
        .add-ons-list .views-row .remove_from_basket,
        .add-ons-list .views-row
        .section-describe--left .remove_from_basket,
        .section-describe--left
        .add-ons-list .views-row .required,
        .add-ons-list .views-row
        .section-describe--left .required,
        .section-describe--left .user-edit input[type="submit"], .user-edit
        .section-describe--left input[type="submit"],
        .section-describe--left
        .user-edit select[type="submit"],
        .user-edit
        .section-describe--left select[type="submit"] {
          float: right;
          clear: both; }
      .section-describe--right {
        left: 0; } }
    @media (min-width: 768px) and (min-width: 768px) {
      .section-describe--right {
        padding-right: 2rem; } }
    @media (min-width: 768px) and (min-width: 1280px) {
      .section-describe--right {
        padding: 0; } }
  @media (min-width: 768px) {
      .section-describe__wrapper {
        position: relative; }
        .section-describe__wrapper h2 {
          font-size: 4.9rem; }
        .section-describe__wrapper p {
          width: 400px;
          max-width: 100%; } }
  .section-describe.hint {
    color: rgba(64, 81, 102, 0.75); }
    .section-describe.hint p:last-of-type {
      margin-top: 0; }

@media (min-width: 768px) {
  .section-wrapper {
    display: flex; } }

.section-wrapper .thumb-wrapper {
  display: inline-block;
  overflow: hidden; }
  @media (min-width: 768px) {
    .section-wrapper .thumb-wrapper--left .composition {
      float: right; } }
  .section-wrapper .thumb-wrapper .composition {
    margin: 2.5rem 0; }

.site-content {
  background-color: white; }
  .site-content--no-hero {
    padding-top: 40px; }
    @media (min-width: 768px) {
      .site-content--no-hero {
        padding-top: 90px; } }
  .site-content--blue-bg {
    background-image: url(../images/bg-home.png);
    background-repeat: no-repeat;
    background-position: 50% 25%;
    background-size: 100% auto; }
    .site-content--blue-bg.site-content--ckeditor-5-download {
      background: none; }
  .site-content--home, .site-content--legal, .site-content--inner-header {
    padding-top: 0; }

.composition {
  display: inline-block;
  position: relative;
  margin: 2.5rem 0; }
  .composition--hint {
    opacity: .5; }
  .composition__thumb {
    background-repeat: no-repeat;
    background-size: contain; }
  @media (max-width: 767px) {
    .composition {
      width: 100%;
      text-align: center;
      background-size: contain; }
      .composition__image {
        display: none; }
      .composition__thumb {
        position: static;
        width: 100%;
        height: 230px;
        margin: 0 auto 2.5rem;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
        background-position: center; } }
  .composition__mobile {
    width: 320px;
    max-width: 100%;
    margin: 2.5rem auto; }
    .composition__mobile img {
      width: 340px;
      max-width: 100%;
      height: auto; }
  @media (min-width: 768px) {
    .composition {
      width: auto;
      background: transparent; }
      .composition, .composition__thumb {
        max-width: 490px; }
      .composition__mobile {
        display: none; } }
  @media (min-width: 1280px) {
    .composition {
      display: block;
      transform: none; }
      .composition, .composition__thumb {
        max-width: none; } }
  .composition__image {
    position: absolute;
    background-repeat: no-repeat;
    image-rendering: -webkit-optimize-contrast; }

.introduce {
  display: inline-block;
  width: 100%;
  max-width: 100%;
  text-align: center;
  padding: 2.5rem 1.5rem 0;
  margin-top: 2.5rem; }
  @media (min-width: 768px) {
    .introduce {
      margin-top: 5rem; } }
  @media (min-width: 1280px) {
    .introduce {
      margin-top: 10rem; }
      .introduce + section {
        margin-top: 5rem; } }
  .introduce h2,
  .introduce h3 {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto; }
  .introduce h2 {
    width: 800px; }
    .introduce h2 + h3 {
      width: 1168px;
      max-width: 100%;
      line-height: 4rem; }
  .introduce p {
    max-width: 100%;
    width: 770px;
    margin-left: auto;
    margin-right: auto; }
  .introduce .btn-cta {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  @media (min-width: 1280px) {
    .introduce--wide h2 {
      width: 1200px; }
    .introduce--narrow h2 {
      width: 1080px; }
    .introduce--narrow h3 {
      width: 840px; }
    .introduce--middle h2 {
      width: 900px; } }
  .introduce--sequence {
    margin-top: 0; }
    .introduce--sequence .btn-cta {
      margin-bottom: 0; }
  .introduce--thin {
    padding-top: 0; }
    .introduce--thin p {
      width: 400px; }

.hidden {
  display: none !important; }

.site-content--ckeditor-4 .product-section--full-of-features {
  margin-top: 5rem; }
  @media (min-width: 768px) {
    .site-content--ckeditor-4 .product-section--full-of-features {
      margin-top: 7.5rem; } }
  @media (min-width: 1280px) {
    .site-content--ckeditor-4 .product-section--full-of-features {
      margin-top: 15rem; } }
  @media (min-width: 768px) and (max-width: 1279px) {
    .site-content--ckeditor-4 .product-section--full-of-features .product-section__graphic img {
      width: 600px; } }
  @media (min-width: 768px) {
    .site-content--ckeditor-4 .product-section--full-of-features .product-section__graphic {
      padding-left: 5rem; } }
  @media (min-width: 1280px) {
    .site-content--ckeditor-4 .product-section--full-of-features .columns__outer {
      width: 705px; } }
  .site-content--ckeditor-4 .product-section--full-of-features .columns__actions .btn, .site-content--ckeditor-4 .product-section--full-of-features .columns__actions #cksource_repo_search_forums_form .form-submit, #cksource_repo_search_forums_form .site-content--ckeditor-4 .product-section--full-of-features .columns__actions .form-submit, .site-content--ckeditor-4 .product-section--full-of-features .columns__actions #forum-sort .form-submit, #forum-sort .site-content--ckeditor-4 .product-section--full-of-features .columns__actions .form-submit, .site-content--ckeditor-4 .product-section--full-of-features .columns__actions .add-plugin input[type="submit"], .add-plugin .site-content--ckeditor-4 .product-section--full-of-features .columns__actions input[type="submit"], .site-content--ckeditor-4 .product-section--full-of-features .columns__actions
  .add-plugin select[type="submit"],
  .add-plugin .site-content--ckeditor-4 .product-section--full-of-features .columns__actions select[type="submit"], .site-content--ckeditor-4 .product-section--full-of-features .columns__actions
  .plugin-edit input[type="submit"],
  .plugin-edit .site-content--ckeditor-4 .product-section--full-of-features .columns__actions input[type="submit"], .site-content--ckeditor-4 .product-section--full-of-features .columns__actions
  .plugin-edit select[type="submit"],
  .plugin-edit .site-content--ckeditor-4 .product-section--full-of-features .columns__actions select[type="submit"], .site-content--ckeditor-4 .product-section--full-of-features .columns__actions
  .plugin-release input[type="submit"],
  .plugin-release .site-content--ckeditor-4 .product-section--full-of-features .columns__actions input[type="submit"], .site-content--ckeditor-4 .product-section--full-of-features .columns__actions
  .plugin-release select[type="submit"],
  .plugin-release .site-content--ckeditor-4 .product-section--full-of-features .columns__actions select[type="submit"], .site-content--ckeditor-4 .product-section--full-of-features .columns__actions .add-plugin .field-type-image-form input[type="file"], .add-plugin .field-type-image-form .site-content--ckeditor-4 .product-section--full-of-features .columns__actions input[type="file"], .site-content--ckeditor-4 .product-section--full-of-features .columns__actions
  .plugin-edit .field-type-image-form input[type="file"],
  .plugin-edit .field-type-image-form .site-content--ckeditor-4 .product-section--full-of-features .columns__actions input[type="file"], .site-content--ckeditor-4 .product-section--full-of-features .columns__actions
  .plugin-release .field-type-image-form input[type="file"],
  .plugin-release .field-type-image-form .site-content--ckeditor-4 .product-section--full-of-features .columns__actions input[type="file"], .site-content--ckeditor-4 .product-section--full-of-features .columns__actions .add-plugin .field-type-image-form button, .add-plugin .field-type-image-form .site-content--ckeditor-4 .product-section--full-of-features .columns__actions button, .site-content--ckeditor-4 .product-section--full-of-features .columns__actions
  .plugin-edit .field-type-image-form button,
  .plugin-edit .field-type-image-form .site-content--ckeditor-4 .product-section--full-of-features .columns__actions button, .site-content--ckeditor-4 .product-section--full-of-features .columns__actions
  .plugin-release .field-type-image-form button,
  .plugin-release .field-type-image-form .site-content--ckeditor-4 .product-section--full-of-features .columns__actions button, .site-content--ckeditor-4 .product-section--full-of-features .columns__actions .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], .add-plugin .field-type-image-form .site-content--ckeditor-4 .product-section--full-of-features .columns__actions input[name="field_addon_logo_und_0_remove_button"], .site-content--ckeditor-4 .product-section--full-of-features .columns__actions
  .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
  .plugin-edit .field-type-image-form .site-content--ckeditor-4 .product-section--full-of-features .columns__actions input[name="field_addon_logo_und_0_remove_button"], .site-content--ckeditor-4 .product-section--full-of-features .columns__actions
  .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
  .plugin-release .field-type-image-form .site-content--ckeditor-4 .product-section--full-of-features .columns__actions input[name="field_addon_logo_und_0_remove_button"], .site-content--ckeditor-4 .product-section--full-of-features .columns__actions #editor-builder #draggable-plugins .all-elements-option a, #editor-builder #draggable-plugins .all-elements-option .site-content--ckeditor-4 .product-section--full-of-features .columns__actions a, .site-content--ckeditor-4 .product-section--full-of-features .columns__actions
  #editor-builder .languages .all-elements-option a,
  #editor-builder .languages .all-elements-option .site-content--ckeditor-4 .product-section--full-of-features .columns__actions a, .site-content--ckeditor-4 .product-section--full-of-features .columns__actions .login .form-control[type="submit"], .login .site-content--ckeditor-4 .product-section--full-of-features .columns__actions .form-control[type="submit"], .site-content--ckeditor-4 .product-section--full-of-features .columns__actions
  .register .form-control[type="submit"],
  .register .site-content--ckeditor-4 .product-section--full-of-features .columns__actions .form-control[type="submit"], .site-content--ckeditor-4 .product-section--full-of-features .columns__actions
  .info-page .form-control[type="submit"],
  .info-page .site-content--ckeditor-4 .product-section--full-of-features .columns__actions .form-control[type="submit"], .site-content--ckeditor-4 .product-section--full-of-features .columns__actions .most-popular__wrapper .add_to_basket, .most-popular__wrapper .site-content--ckeditor-4 .product-section--full-of-features .columns__actions .add_to_basket, .site-content--ckeditor-4 .product-section--full-of-features .columns__actions .add-ons-list .views-row .add_to_basket, .add-ons-list .views-row .site-content--ckeditor-4 .product-section--full-of-features .columns__actions .add_to_basket, .site-content--ckeditor-4 .product-section--full-of-features .columns__actions
  .add-ons-list .views-row .check-details,
  .add-ons-list .views-row .site-content--ckeditor-4 .product-section--full-of-features .columns__actions .check-details, .site-content--ckeditor-4 .product-section--full-of-features .columns__actions
  .add-ons-list .views-row .remove_from_basket,
  .add-ons-list .views-row .site-content--ckeditor-4 .product-section--full-of-features .columns__actions .remove_from_basket, .site-content--ckeditor-4 .product-section--full-of-features .columns__actions
  .add-ons-list .views-row .required,
  .add-ons-list .views-row .site-content--ckeditor-4 .product-section--full-of-features .columns__actions .required, .site-content--ckeditor-4 .product-section--full-of-features .columns__actions .user-edit input[type="submit"], .user-edit .site-content--ckeditor-4 .product-section--full-of-features .columns__actions input[type="submit"], .site-content--ckeditor-4 .product-section--full-of-features .columns__actions
  .user-edit select[type="submit"],
  .user-edit .site-content--ckeditor-4 .product-section--full-of-features .columns__actions select[type="submit"] {
    margin-right: 0;
    margin-bottom: 1.25rem; }
  @media (min-width: 1280px) {
    .site-content--ckeditor-4 .product-section--full-of-features .columns__actions .btn, .site-content--ckeditor-4 .product-section--full-of-features .columns__actions #cksource_repo_search_forums_form .form-submit, #cksource_repo_search_forums_form .site-content--ckeditor-4 .product-section--full-of-features .columns__actions .form-submit, .site-content--ckeditor-4 .product-section--full-of-features .columns__actions #forum-sort .form-submit, #forum-sort .site-content--ckeditor-4 .product-section--full-of-features .columns__actions .form-submit, .site-content--ckeditor-4 .product-section--full-of-features .columns__actions .add-plugin input[type="submit"], .add-plugin .site-content--ckeditor-4 .product-section--full-of-features .columns__actions input[type="submit"], .site-content--ckeditor-4 .product-section--full-of-features .columns__actions
    .add-plugin select[type="submit"],
    .add-plugin .site-content--ckeditor-4 .product-section--full-of-features .columns__actions select[type="submit"], .site-content--ckeditor-4 .product-section--full-of-features .columns__actions
    .plugin-edit input[type="submit"],
    .plugin-edit .site-content--ckeditor-4 .product-section--full-of-features .columns__actions input[type="submit"], .site-content--ckeditor-4 .product-section--full-of-features .columns__actions
    .plugin-edit select[type="submit"],
    .plugin-edit .site-content--ckeditor-4 .product-section--full-of-features .columns__actions select[type="submit"], .site-content--ckeditor-4 .product-section--full-of-features .columns__actions
    .plugin-release input[type="submit"],
    .plugin-release .site-content--ckeditor-4 .product-section--full-of-features .columns__actions input[type="submit"], .site-content--ckeditor-4 .product-section--full-of-features .columns__actions
    .plugin-release select[type="submit"],
    .plugin-release .site-content--ckeditor-4 .product-section--full-of-features .columns__actions select[type="submit"], .site-content--ckeditor-4 .product-section--full-of-features .columns__actions .add-plugin .field-type-image-form input[type="file"], .add-plugin .field-type-image-form .site-content--ckeditor-4 .product-section--full-of-features .columns__actions input[type="file"], .site-content--ckeditor-4 .product-section--full-of-features .columns__actions
    .plugin-edit .field-type-image-form input[type="file"],
    .plugin-edit .field-type-image-form .site-content--ckeditor-4 .product-section--full-of-features .columns__actions input[type="file"], .site-content--ckeditor-4 .product-section--full-of-features .columns__actions
    .plugin-release .field-type-image-form input[type="file"],
    .plugin-release .field-type-image-form .site-content--ckeditor-4 .product-section--full-of-features .columns__actions input[type="file"], .site-content--ckeditor-4 .product-section--full-of-features .columns__actions .add-plugin .field-type-image-form button, .add-plugin .field-type-image-form .site-content--ckeditor-4 .product-section--full-of-features .columns__actions button, .site-content--ckeditor-4 .product-section--full-of-features .columns__actions
    .plugin-edit .field-type-image-form button,
    .plugin-edit .field-type-image-form .site-content--ckeditor-4 .product-section--full-of-features .columns__actions button, .site-content--ckeditor-4 .product-section--full-of-features .columns__actions
    .plugin-release .field-type-image-form button,
    .plugin-release .field-type-image-form .site-content--ckeditor-4 .product-section--full-of-features .columns__actions button, .site-content--ckeditor-4 .product-section--full-of-features .columns__actions .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], .add-plugin .field-type-image-form .site-content--ckeditor-4 .product-section--full-of-features .columns__actions input[name="field_addon_logo_und_0_remove_button"], .site-content--ckeditor-4 .product-section--full-of-features .columns__actions
    .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
    .plugin-edit .field-type-image-form .site-content--ckeditor-4 .product-section--full-of-features .columns__actions input[name="field_addon_logo_und_0_remove_button"], .site-content--ckeditor-4 .product-section--full-of-features .columns__actions
    .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
    .plugin-release .field-type-image-form .site-content--ckeditor-4 .product-section--full-of-features .columns__actions input[name="field_addon_logo_und_0_remove_button"], .site-content--ckeditor-4 .product-section--full-of-features .columns__actions #editor-builder #draggable-plugins .all-elements-option a, #editor-builder #draggable-plugins .all-elements-option .site-content--ckeditor-4 .product-section--full-of-features .columns__actions a, .site-content--ckeditor-4 .product-section--full-of-features .columns__actions
    #editor-builder .languages .all-elements-option a,
    #editor-builder .languages .all-elements-option .site-content--ckeditor-4 .product-section--full-of-features .columns__actions a, .site-content--ckeditor-4 .product-section--full-of-features .columns__actions .login .form-control[type="submit"], .login .site-content--ckeditor-4 .product-section--full-of-features .columns__actions .form-control[type="submit"], .site-content--ckeditor-4 .product-section--full-of-features .columns__actions
    .register .form-control[type="submit"],
    .register .site-content--ckeditor-4 .product-section--full-of-features .columns__actions .form-control[type="submit"], .site-content--ckeditor-4 .product-section--full-of-features .columns__actions
    .info-page .form-control[type="submit"],
    .info-page .site-content--ckeditor-4 .product-section--full-of-features .columns__actions .form-control[type="submit"], .site-content--ckeditor-4 .product-section--full-of-features .columns__actions .most-popular__wrapper .add_to_basket, .most-popular__wrapper .site-content--ckeditor-4 .product-section--full-of-features .columns__actions .add_to_basket, .site-content--ckeditor-4 .product-section--full-of-features .columns__actions .add-ons-list .views-row .add_to_basket, .add-ons-list .views-row .site-content--ckeditor-4 .product-section--full-of-features .columns__actions .add_to_basket, .site-content--ckeditor-4 .product-section--full-of-features .columns__actions
    .add-ons-list .views-row .check-details,
    .add-ons-list .views-row .site-content--ckeditor-4 .product-section--full-of-features .columns__actions .check-details, .site-content--ckeditor-4 .product-section--full-of-features .columns__actions
    .add-ons-list .views-row .remove_from_basket,
    .add-ons-list .views-row .site-content--ckeditor-4 .product-section--full-of-features .columns__actions .remove_from_basket, .site-content--ckeditor-4 .product-section--full-of-features .columns__actions
    .add-ons-list .views-row .required,
    .add-ons-list .views-row .site-content--ckeditor-4 .product-section--full-of-features .columns__actions .required, .site-content--ckeditor-4 .product-section--full-of-features .columns__actions .user-edit input[type="submit"], .user-edit .site-content--ckeditor-4 .product-section--full-of-features .columns__actions input[type="submit"], .site-content--ckeditor-4 .product-section--full-of-features .columns__actions
    .user-edit select[type="submit"],
    .user-edit .site-content--ckeditor-4 .product-section--full-of-features .columns__actions select[type="submit"] {
      margin-right: 2.5rem;
      margin-bottom: 0; } }

@media (min-width: 768px) {
  .site-content--ckeditor-4 .product-section--more-features {
    margin-top: 5rem; } }

@media (min-width: 1024px) {
  .site-content--ckeditor-4 .product-section--more-features {
    margin-top: 10rem; } }

.site-content--ckeditor-4 .product-section--more-features .columns__actions .btn, .site-content--ckeditor-4 .product-section--more-features .columns__actions #cksource_repo_search_forums_form .form-submit, #cksource_repo_search_forums_form .site-content--ckeditor-4 .product-section--more-features .columns__actions .form-submit, .site-content--ckeditor-4 .product-section--more-features .columns__actions #forum-sort .form-submit, #forum-sort .site-content--ckeditor-4 .product-section--more-features .columns__actions .form-submit, .site-content--ckeditor-4 .product-section--more-features .columns__actions .add-plugin input[type="submit"], .add-plugin .site-content--ckeditor-4 .product-section--more-features .columns__actions input[type="submit"], .site-content--ckeditor-4 .product-section--more-features .columns__actions
.add-plugin select[type="submit"],
.add-plugin .site-content--ckeditor-4 .product-section--more-features .columns__actions select[type="submit"], .site-content--ckeditor-4 .product-section--more-features .columns__actions
.plugin-edit input[type="submit"],
.plugin-edit .site-content--ckeditor-4 .product-section--more-features .columns__actions input[type="submit"], .site-content--ckeditor-4 .product-section--more-features .columns__actions
.plugin-edit select[type="submit"],
.plugin-edit .site-content--ckeditor-4 .product-section--more-features .columns__actions select[type="submit"], .site-content--ckeditor-4 .product-section--more-features .columns__actions
.plugin-release input[type="submit"],
.plugin-release .site-content--ckeditor-4 .product-section--more-features .columns__actions input[type="submit"], .site-content--ckeditor-4 .product-section--more-features .columns__actions
.plugin-release select[type="submit"],
.plugin-release .site-content--ckeditor-4 .product-section--more-features .columns__actions select[type="submit"], .site-content--ckeditor-4 .product-section--more-features .columns__actions .add-plugin .field-type-image-form input[type="file"], .add-plugin .field-type-image-form .site-content--ckeditor-4 .product-section--more-features .columns__actions input[type="file"], .site-content--ckeditor-4 .product-section--more-features .columns__actions
.plugin-edit .field-type-image-form input[type="file"],
.plugin-edit .field-type-image-form .site-content--ckeditor-4 .product-section--more-features .columns__actions input[type="file"], .site-content--ckeditor-4 .product-section--more-features .columns__actions
.plugin-release .field-type-image-form input[type="file"],
.plugin-release .field-type-image-form .site-content--ckeditor-4 .product-section--more-features .columns__actions input[type="file"], .site-content--ckeditor-4 .product-section--more-features .columns__actions .add-plugin .field-type-image-form button, .add-plugin .field-type-image-form .site-content--ckeditor-4 .product-section--more-features .columns__actions button, .site-content--ckeditor-4 .product-section--more-features .columns__actions
.plugin-edit .field-type-image-form button,
.plugin-edit .field-type-image-form .site-content--ckeditor-4 .product-section--more-features .columns__actions button, .site-content--ckeditor-4 .product-section--more-features .columns__actions
.plugin-release .field-type-image-form button,
.plugin-release .field-type-image-form .site-content--ckeditor-4 .product-section--more-features .columns__actions button, .site-content--ckeditor-4 .product-section--more-features .columns__actions .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], .add-plugin .field-type-image-form .site-content--ckeditor-4 .product-section--more-features .columns__actions input[name="field_addon_logo_und_0_remove_button"], .site-content--ckeditor-4 .product-section--more-features .columns__actions
.plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
.plugin-edit .field-type-image-form .site-content--ckeditor-4 .product-section--more-features .columns__actions input[name="field_addon_logo_und_0_remove_button"], .site-content--ckeditor-4 .product-section--more-features .columns__actions
.plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
.plugin-release .field-type-image-form .site-content--ckeditor-4 .product-section--more-features .columns__actions input[name="field_addon_logo_und_0_remove_button"], .site-content--ckeditor-4 .product-section--more-features .columns__actions #editor-builder #draggable-plugins .all-elements-option a, #editor-builder #draggable-plugins .all-elements-option .site-content--ckeditor-4 .product-section--more-features .columns__actions a, .site-content--ckeditor-4 .product-section--more-features .columns__actions
#editor-builder .languages .all-elements-option a,
#editor-builder .languages .all-elements-option .site-content--ckeditor-4 .product-section--more-features .columns__actions a, .site-content--ckeditor-4 .product-section--more-features .columns__actions .login .form-control[type="submit"], .login .site-content--ckeditor-4 .product-section--more-features .columns__actions .form-control[type="submit"], .site-content--ckeditor-4 .product-section--more-features .columns__actions
.register .form-control[type="submit"],
.register .site-content--ckeditor-4 .product-section--more-features .columns__actions .form-control[type="submit"], .site-content--ckeditor-4 .product-section--more-features .columns__actions
.info-page .form-control[type="submit"],
.info-page .site-content--ckeditor-4 .product-section--more-features .columns__actions .form-control[type="submit"], .site-content--ckeditor-4 .product-section--more-features .columns__actions .most-popular__wrapper .add_to_basket, .most-popular__wrapper .site-content--ckeditor-4 .product-section--more-features .columns__actions .add_to_basket, .site-content--ckeditor-4 .product-section--more-features .columns__actions .add-ons-list .views-row .add_to_basket, .add-ons-list .views-row .site-content--ckeditor-4 .product-section--more-features .columns__actions .add_to_basket, .site-content--ckeditor-4 .product-section--more-features .columns__actions
.add-ons-list .views-row .check-details,
.add-ons-list .views-row .site-content--ckeditor-4 .product-section--more-features .columns__actions .check-details, .site-content--ckeditor-4 .product-section--more-features .columns__actions
.add-ons-list .views-row .remove_from_basket,
.add-ons-list .views-row .site-content--ckeditor-4 .product-section--more-features .columns__actions .remove_from_basket, .site-content--ckeditor-4 .product-section--more-features .columns__actions
.add-ons-list .views-row .required,
.add-ons-list .views-row .site-content--ckeditor-4 .product-section--more-features .columns__actions .required, .site-content--ckeditor-4 .product-section--more-features .columns__actions .user-edit input[type="submit"], .user-edit .site-content--ckeditor-4 .product-section--more-features .columns__actions input[type="submit"], .site-content--ckeditor-4 .product-section--more-features .columns__actions
.user-edit select[type="submit"],
.user-edit .site-content--ckeditor-4 .product-section--more-features .columns__actions select[type="submit"] {
  margin-right: 0;
  margin-bottom: 1.25rem; }

@media (min-width: 1280px) {
  .site-content--ckeditor-4 .product-section--more-features .columns__actions .btn, .site-content--ckeditor-4 .product-section--more-features .columns__actions #cksource_repo_search_forums_form .form-submit, #cksource_repo_search_forums_form .site-content--ckeditor-4 .product-section--more-features .columns__actions .form-submit, .site-content--ckeditor-4 .product-section--more-features .columns__actions #forum-sort .form-submit, #forum-sort .site-content--ckeditor-4 .product-section--more-features .columns__actions .form-submit, .site-content--ckeditor-4 .product-section--more-features .columns__actions .add-plugin input[type="submit"], .add-plugin .site-content--ckeditor-4 .product-section--more-features .columns__actions input[type="submit"], .site-content--ckeditor-4 .product-section--more-features .columns__actions
  .add-plugin select[type="submit"],
  .add-plugin .site-content--ckeditor-4 .product-section--more-features .columns__actions select[type="submit"], .site-content--ckeditor-4 .product-section--more-features .columns__actions
  .plugin-edit input[type="submit"],
  .plugin-edit .site-content--ckeditor-4 .product-section--more-features .columns__actions input[type="submit"], .site-content--ckeditor-4 .product-section--more-features .columns__actions
  .plugin-edit select[type="submit"],
  .plugin-edit .site-content--ckeditor-4 .product-section--more-features .columns__actions select[type="submit"], .site-content--ckeditor-4 .product-section--more-features .columns__actions
  .plugin-release input[type="submit"],
  .plugin-release .site-content--ckeditor-4 .product-section--more-features .columns__actions input[type="submit"], .site-content--ckeditor-4 .product-section--more-features .columns__actions
  .plugin-release select[type="submit"],
  .plugin-release .site-content--ckeditor-4 .product-section--more-features .columns__actions select[type="submit"], .site-content--ckeditor-4 .product-section--more-features .columns__actions .add-plugin .field-type-image-form input[type="file"], .add-plugin .field-type-image-form .site-content--ckeditor-4 .product-section--more-features .columns__actions input[type="file"], .site-content--ckeditor-4 .product-section--more-features .columns__actions
  .plugin-edit .field-type-image-form input[type="file"],
  .plugin-edit .field-type-image-form .site-content--ckeditor-4 .product-section--more-features .columns__actions input[type="file"], .site-content--ckeditor-4 .product-section--more-features .columns__actions
  .plugin-release .field-type-image-form input[type="file"],
  .plugin-release .field-type-image-form .site-content--ckeditor-4 .product-section--more-features .columns__actions input[type="file"], .site-content--ckeditor-4 .product-section--more-features .columns__actions .add-plugin .field-type-image-form button, .add-plugin .field-type-image-form .site-content--ckeditor-4 .product-section--more-features .columns__actions button, .site-content--ckeditor-4 .product-section--more-features .columns__actions
  .plugin-edit .field-type-image-form button,
  .plugin-edit .field-type-image-form .site-content--ckeditor-4 .product-section--more-features .columns__actions button, .site-content--ckeditor-4 .product-section--more-features .columns__actions
  .plugin-release .field-type-image-form button,
  .plugin-release .field-type-image-form .site-content--ckeditor-4 .product-section--more-features .columns__actions button, .site-content--ckeditor-4 .product-section--more-features .columns__actions .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], .add-plugin .field-type-image-form .site-content--ckeditor-4 .product-section--more-features .columns__actions input[name="field_addon_logo_und_0_remove_button"], .site-content--ckeditor-4 .product-section--more-features .columns__actions
  .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
  .plugin-edit .field-type-image-form .site-content--ckeditor-4 .product-section--more-features .columns__actions input[name="field_addon_logo_und_0_remove_button"], .site-content--ckeditor-4 .product-section--more-features .columns__actions
  .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
  .plugin-release .field-type-image-form .site-content--ckeditor-4 .product-section--more-features .columns__actions input[name="field_addon_logo_und_0_remove_button"], .site-content--ckeditor-4 .product-section--more-features .columns__actions #editor-builder #draggable-plugins .all-elements-option a, #editor-builder #draggable-plugins .all-elements-option .site-content--ckeditor-4 .product-section--more-features .columns__actions a, .site-content--ckeditor-4 .product-section--more-features .columns__actions
  #editor-builder .languages .all-elements-option a,
  #editor-builder .languages .all-elements-option .site-content--ckeditor-4 .product-section--more-features .columns__actions a, .site-content--ckeditor-4 .product-section--more-features .columns__actions .login .form-control[type="submit"], .login .site-content--ckeditor-4 .product-section--more-features .columns__actions .form-control[type="submit"], .site-content--ckeditor-4 .product-section--more-features .columns__actions
  .register .form-control[type="submit"],
  .register .site-content--ckeditor-4 .product-section--more-features .columns__actions .form-control[type="submit"], .site-content--ckeditor-4 .product-section--more-features .columns__actions
  .info-page .form-control[type="submit"],
  .info-page .site-content--ckeditor-4 .product-section--more-features .columns__actions .form-control[type="submit"], .site-content--ckeditor-4 .product-section--more-features .columns__actions .most-popular__wrapper .add_to_basket, .most-popular__wrapper .site-content--ckeditor-4 .product-section--more-features .columns__actions .add_to_basket, .site-content--ckeditor-4 .product-section--more-features .columns__actions .add-ons-list .views-row .add_to_basket, .add-ons-list .views-row .site-content--ckeditor-4 .product-section--more-features .columns__actions .add_to_basket, .site-content--ckeditor-4 .product-section--more-features .columns__actions
  .add-ons-list .views-row .check-details,
  .add-ons-list .views-row .site-content--ckeditor-4 .product-section--more-features .columns__actions .check-details, .site-content--ckeditor-4 .product-section--more-features .columns__actions
  .add-ons-list .views-row .remove_from_basket,
  .add-ons-list .views-row .site-content--ckeditor-4 .product-section--more-features .columns__actions .remove_from_basket, .site-content--ckeditor-4 .product-section--more-features .columns__actions
  .add-ons-list .views-row .required,
  .add-ons-list .views-row .site-content--ckeditor-4 .product-section--more-features .columns__actions .required, .site-content--ckeditor-4 .product-section--more-features .columns__actions .user-edit input[type="submit"], .user-edit .site-content--ckeditor-4 .product-section--more-features .columns__actions input[type="submit"], .site-content--ckeditor-4 .product-section--more-features .columns__actions
  .user-edit select[type="submit"],
  .user-edit .site-content--ckeditor-4 .product-section--more-features .columns__actions select[type="submit"] {
    margin-right: 2.5rem;
    margin-bottom: 0; } }

@media (min-width: 768px) {
  .site-content--ckeditor-4 .product-section--easy-to-use .columns__item--learn {
    background-image: url(../images/icon/blue-head-book.svg); }
  .site-content--ckeditor-4 .product-section--easy-to-use .columns__item--adjust {
    background-image: url(../images/icon/blue-head-box.svg); } }

@media (min-width: 1024px) {
  .site-content--ckeditor-4 .product-section--easy-to-use {
    margin-top: 10rem; }
    .site-content--ckeditor-4 .product-section--easy-to-use h2 {
      margin-bottom: 2.5rem; } }

.site-content--ckeditor-4 .product-section--easy-to-use .columns__actions {
  margin-top: 1.25rem; }

@media (min-width: 768px) {
  .site-content--ckeditor-4 .product-section--customizable {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    background-size: 100% 100%;
    background-position: 0 0; } }

@media (min-width: 1024px) {
  .site-content--ckeditor-4 .product-section--customizable {
    margin-top: 10rem; } }

@media (min-width: 1280px) {
  .site-content--ckeditor-4 .product-section--customizable {
    padding: 0; } }

@media (min-width: 1280px) {
  .site-content--ckeditor-4 .product-section--customizable .product-section__content h2 {
    width: 600px; }
  .site-content--ckeditor-4 .product-section--customizable .product-section__content p {
    width: 540px; } }

@media (min-width: 1280px) {
  .site-content--ckeditor-4 .product-section--customizable .product-section__graphic {
    padding-left: 10rem; } }

.site-content--ckeditor-4 .product-section--customizable .product-section__graphic figure {
  margin-bottom: 0; }
  @media (min-width: 768px) {
    .site-content--ckeditor-4 .product-section--customizable .product-section__graphic figure {
      margin-top: 75px; } }

@media (min-width: 1280px) {
  .site-content--ckeditor-4 .product-section--supported .product-section__content p {
    width: 550px; } }

.site-content--ckeditor-4 .product-section--a11y {
  background-size: 100% 97%;
  background-position: 0 0; }
  .site-content--ckeditor-4 .product-section--a11y .product-section__content {
    padding-top: 5rem;
    justify-content: start; }
    @media (min-width: 1280px) {
      .site-content--ckeditor-4 .product-section--a11y .product-section__content h2 {
        width: 540px; } }
    .site-content--ckeditor-4 .product-section--a11y .product-section__content p {
      width: 350px; }
      .site-content--ckeditor-4 .product-section--a11y .product-section__content p:last-of-type {
        margin-bottom: 2.5rem; }
        @media (min-width: 1024px) {
          .site-content--ckeditor-4 .product-section--a11y .product-section__content p:last-of-type {
            margin-bottom: 5rem; } }
  .site-content--ckeditor-4 .product-section--a11y .product-section__graphic figure {
    margin-top: 5rem;
    margin-bottom: 0; }

.site-content--ckeditor-4 .product-section--truly-global {
  margin-top: 7.5rem; }
  @media (min-width: 1024px) {
    .site-content--ckeditor-4 .product-section--truly-global .product-section__content p {
      width: 415px; } }
  @media (min-width: 1280px) {
    .site-content--ckeditor-4 .product-section--truly-global .product-section__content {
      width: 800px;
      padding-left: 200px; } }
  @media (min-width: 768px) {
    .site-content--ckeditor-4 .product-section--truly-global.product-section--graphic-right .product-section__graphic {
      padding-left: 5rem; } }

@media (min-width: 1024px) {
  .site-content--ckeditor-4 .product-section--commits {
    margin-top: 10rem; } }

.site-content--ckeditor-4 .product-section--commits .product-section__graphic {
  /* IE 11 fix. */ }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .site-content--ckeditor-4 .product-section--commits .product-section__graphic {
      min-height: 270px; } }
  .site-content--ckeditor-4 .product-section--commits .product-section__graphic img {
    width: 100%;
    height: auto; }

.site-content--ckeditor-4 .product-section--community {
  margin-top: 0; }
  @media (min-width: 768px) {
    .site-content--ckeditor-4 .product-section--community .panes-grid__introduce {
      padding-top: 1.25rem;
      padding-bottom: 2.5rem; } }
  .site-content--ckeditor-4 .product-section--community .panes-grid__item--stackoverflow {
    background-image: url(../images/icon/blue-head-cog.svg); }
  .site-content--ckeditor-4 .product-section--community .panes-grid__item--plugins {
    background-image: url(../images/icon/blue-head-box.svg); }
  .site-content--ckeditor-4 .product-section--community .panes-grid__item--commits {
    background-image: url(../images/icon/blue-head-book.svg); }
  .site-content--ckeditor-4 .product-section--community .panes-grid__item--downloads {
    background-image: url(../images/icon/blue-head-head.svg); }

.cla {
  width: 900px;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
  text-align: inherit; }
  .cla-actions .btn:first-of-type, .cla-actions #cksource_repo_search_forums_form .form-submit:first-of-type, #cksource_repo_search_forums_form .cla-actions .form-submit:first-of-type, .cla-actions #forum-sort .form-submit:first-of-type, #forum-sort .cla-actions .form-submit:first-of-type, .cla-actions .add-plugin input[type="submit"]:first-of-type, .add-plugin .cla-actions input[type="submit"]:first-of-type, .cla-actions
  .add-plugin select[type="submit"]:first-of-type,
  .add-plugin .cla-actions select[type="submit"]:first-of-type, .cla-actions
  .plugin-edit input[type="submit"]:first-of-type,
  .plugin-edit .cla-actions input[type="submit"]:first-of-type, .cla-actions
  .plugin-edit select[type="submit"]:first-of-type,
  .plugin-edit .cla-actions select[type="submit"]:first-of-type, .cla-actions
  .plugin-release input[type="submit"]:first-of-type,
  .plugin-release .cla-actions input[type="submit"]:first-of-type, .cla-actions
  .plugin-release select[type="submit"]:first-of-type,
  .plugin-release .cla-actions select[type="submit"]:first-of-type, .cla-actions .add-plugin .field-type-image-form input[type="file"]:first-of-type, .add-plugin .field-type-image-form .cla-actions input[type="file"]:first-of-type, .cla-actions
  .plugin-edit .field-type-image-form input[type="file"]:first-of-type,
  .plugin-edit .field-type-image-form .cla-actions input[type="file"]:first-of-type, .cla-actions
  .plugin-release .field-type-image-form input[type="file"]:first-of-type,
  .plugin-release .field-type-image-form .cla-actions input[type="file"]:first-of-type, .cla-actions .add-plugin .field-type-image-form button:first-of-type, .add-plugin .field-type-image-form .cla-actions button:first-of-type, .cla-actions
  .plugin-edit .field-type-image-form button:first-of-type,
  .plugin-edit .field-type-image-form .cla-actions button:first-of-type, .cla-actions
  .plugin-release .field-type-image-form button:first-of-type,
  .plugin-release .field-type-image-form .cla-actions button:first-of-type, .cla-actions .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:first-of-type, .add-plugin .field-type-image-form .cla-actions input[name="field_addon_logo_und_0_remove_button"]:first-of-type, .cla-actions
  .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:first-of-type,
  .plugin-edit .field-type-image-form .cla-actions input[name="field_addon_logo_und_0_remove_button"]:first-of-type, .cla-actions
  .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:first-of-type,
  .plugin-release .field-type-image-form .cla-actions input[name="field_addon_logo_und_0_remove_button"]:first-of-type, .cla-actions #editor-builder #draggable-plugins .all-elements-option a:first-of-type, #editor-builder #draggable-plugins .all-elements-option .cla-actions a:first-of-type, .cla-actions
  #editor-builder .languages .all-elements-option a:first-of-type,
  #editor-builder .languages .all-elements-option .cla-actions a:first-of-type, .cla-actions .login .form-control[type="submit"]:first-of-type, .login .cla-actions .form-control[type="submit"]:first-of-type, .cla-actions
  .register .form-control[type="submit"]:first-of-type,
  .register .cla-actions .form-control[type="submit"]:first-of-type, .cla-actions
  .info-page .form-control[type="submit"]:first-of-type,
  .info-page .cla-actions .form-control[type="submit"]:first-of-type, .cla-actions .most-popular__wrapper .add_to_basket:first-of-type, .most-popular__wrapper .cla-actions .add_to_basket:first-of-type, .cla-actions .add-ons-list .views-row .add_to_basket:first-of-type, .add-ons-list .views-row .cla-actions .add_to_basket:first-of-type, .cla-actions
  .add-ons-list .views-row .check-details:first-of-type,
  .add-ons-list .views-row .cla-actions .check-details:first-of-type, .cla-actions
  .add-ons-list .views-row .remove_from_basket:first-of-type,
  .add-ons-list .views-row .cla-actions .remove_from_basket:first-of-type, .cla-actions
  .add-ons-list .views-row .required:first-of-type,
  .add-ons-list .views-row .cla-actions .required:first-of-type, .cla-actions .user-edit input[type="submit"]:first-of-type, .user-edit .cla-actions input[type="submit"]:first-of-type, .cla-actions
  .user-edit select[type="submit"]:first-of-type,
  .user-edit .cla-actions select[type="submit"]:first-of-type {
    margin-right: 1rem; }
  .cla__list {
    list-style: none;
    padding-left: 0; }
    .cla__list li {
      list-style: none; }
  .cla span.checked:before, .cla span.error:before {
    width: 14px;
    height: 14px; }
  .cla span.checked:before {
    content: "\2713";
    color: #2dbe61; }
  .cla span.error:before {
    content: "\0292b";
    color: #d9534f; }

.site-content--forum .feed-icon {
  display: none; }

.forum-topic-legend.forum-topic-closed .alert {
  padding: 2.5rem; }

.forum-topic-legend.forum-topic-closed p:first-of-type {
  margin-top: 0; }

.forum-topic-legend.forum-topic-closed + section {
  margin-top: 0; }

.forum-table {
  width: 100%;
  margin: 0;
  border: 0;
  font-size: 1.6rem; }
  .forum-table-name {
    float: left; }
    .forum-table-name a {
      font-size: 3rem;
      font-weight: 200; }
  .forum-table-superheader {
    display: inline-block;
    width: 100%;
    margin-top: 5rem; }
    .forum-table-superheader .forum-collapsible {
      float: right;
      display: block;
      width: 24px;
      height: 24px;
      background-image: url(../images/icons/collapsing.svg);
      background-repeat: no-repat;
      background-size: contain;
      cursor: pointer; }
      .forum-table-superheader .forum-collapsible.container-collapsed {
        background-image: url(../images/icons/uncollapsing.svg); }
  .forum-table-wrap:first-of-type .forum-table-superheader {
    margin-top: 2.5rem; }
  .forum-table tbody {
    border: none; }
  .forum-table tr.even, .forum-table tr.odd {
    background-color: white; }
    .forum-table tr.even td,
    .forum-table tr.even td.active, .forum-table tr.odd td,
    .forum-table tr.odd td.active {
      background-color: white; }
  .forum-table th {
    padding: 1.5rem 1rem;
    background-color: rgba(0, 0, 0, 0.03);
    color: rgba(64, 81, 102, 0.96);
    font-size: 1.9rem;
    font-weight: 600;
    text-align: left; }
    .forum-table th.views-field-topic-icon {
      width: 85px; }
    .forum-table th img {
      margin-left: .5rem; }
  .forum-table td {
    padding: 1.5rem 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06); }
    .forum-table td, .forum-table td.active {
      background-color: white; }
    .forum-table td:nth-of-type a {
      color: rgba(64, 81, 102, 0.96);
      text-decoration: underline; }

.forum-name a {
  font-size: 2.1rem;
  font-weight: 500; }

.forum-description {
  margin-top: 1rem; }

.forum-details {
  width: 100%; }

.forum-last-reply {
  min-width: 240px;
  color: rgba(64, 81, 102, 0.75); }
  .forum-last-reply a {
    color: rgba(64, 81, 102, 0.96); }
  .forum-last-reply a.username {
    color: #30a3f1; }

.forum-list-icon-wrapper span {
  display: block;
  width: 24px;
  height: 24px;
  margin-left: 1rem;
  margin-right: 1rem;
  background-image: url(../images/icons/threads.svg);
  background-repeat: no-repat;
  background-size: contain;
  text-indent: -9999px; }

.forum-tools {
  float: right;
  margin-top: 2.5rem; }

#cksource_repo_search_forums_form {
  margin-top: 5rem;
  width: 100%;
  text-align: right; }
  #cksource_repo_search_forums_form input[type="text"] {
    display: inline-block; }
    @media (min-width: 768px) {
      #cksource_repo_search_forums_form input[type="text"] {
        width: 350px; } }
  #cksource_repo_search_forums_form .form-item {
    display: inline-block; }
  #cksource_repo_search_forums_form .form-submit {
    margin-left: 1rem;
    padding: 1.05rem 2rem 1rem;
    vertical-align: top; }

#forum-topic-list {
  margin-top: 2.5rem; }
  #forum-topic-list .forum-topic-title {
    font-size: 1.9rem; }
  #forum-topic-list .topic-icon {
    display: block;
    width: 65px;
    height: 65px;
    background-image: url(../images/icons/ico-chat-round.svg);
    background-repeat: no-repat;
    background-size: contain;
    text-indent: -9999px; }

#forum-sort {
  float: left;
  margin-top: 2.5rem; }
  #forum-sort .form-item {
    display: inline-block;
    margin: 0 1rem 0 0; }
  #forum-sort .form-submit {
    padding: 0.65rem 1.6rem;
    vertical-align: top; }

.forum-post {
  position: relative;
  margin-top: 2.5rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.1); }
  .forum-post-info {
    display: inline-block;
    width: 100%;
    padding: 1rem;
    text-align: right; }
  .forum-post-wrapper {
    display: flex; }
  .forum-post-title {
    font-size: 3rem; }
    .forum-post-title h1 {
      font-size: 3.2rem;
      line-height: 4rem;
      font-weight: 400;
      font-family: "Nunito Sans",sans-serif; }
  .forum-post-panel-sub {
    padding: 2.5rem 2.5rem 5rem;
    font-size: 1.6rem;
    flex: 250px 0 0; }
  .forum-post-panel-main {
    width: calc(100% - 250px); }
    .forum-post-panel-main .author-signature {
      font-size: 1.6rem;
      padding: 1rem;
      background-color: rgba(0, 0, 0, 0.03); }
      .forum-post-panel-main .author-signature p:nth-of-type(2) {
        margin-top: 0; }
      .forum-post-panel-main .author-signature p:last-of-type {
        margin-bottom: 0; }
      .forum-post-panel-main .author-signature .h1 {
        display: none; }
  .forum-post-content {
    padding: 2.5rem;
    padding-left: 0; }
  .forum-post .author-pane-inner {
    color: rgba(64, 81, 102, 0.75);
    font-size: 1.3rem; }
    .forum-post .author-pane-inner a.username {
      font-size: font-scale(medium); }
  .forum-post-footer {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    font-size: 1.6rem; }

#forum-comments .forum-post-title a {
  font-size: 2.4rem; }

.forum-posted-on {
  display: inline;
  margin-right: 2.5rem;
  color: rgba(64, 81, 102, 0.75);
  font-size: 1.6rem; }

.forum-post-number {
  font-size: 1.6rem; }

#cksource_repo_search_forums_form .form-item {
  margin: 0; }

#forum-statistics {
  display: none; }

.page-forum .breadcrumbs, .node-type-forum .breadcrumbs {
  display: none;
  width: 100%;
  color: rgba(64, 81, 102, 0.75);
  font-size: 1.6rem;
  margin-bottom: 15px; }
  @media (min-width: 768px) {
    .page-forum .breadcrumbs, .node-type-forum .breadcrumbs {
      display: inline-block; } }
  .page-forum .breadcrumbs ul, .node-type-forum .breadcrumbs ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  .page-forum .breadcrumbs__item, .node-type-forum .breadcrumbs__item {
    float: left;
    display: block; }
    .page-forum .breadcrumbs__item a, .node-type-forum .breadcrumbs__item a {
      color: rgba(64, 81, 102, 0.96); }
    .page-forum .breadcrumbs__item:not(.breadcrumbs__item--current):after, .node-type-forum .breadcrumbs__item:not(.breadcrumbs__item--current):after {
      content: "/";
      display: inline-block;
      margin-left: 10px;
      margin-right: 10px;
      color: rgba(64, 81, 102, 0.48); }
  .page-forum .breadcrumbs__item--current, .node-type-forum .breadcrumbs__item--current {
    color: rgba(64, 81, 102, 0.75); }

#forum-content .row h1 {
  font-size: 3.2rem;
  line-height: 4rem;
  font-weight: 400;
  font-family: "Nunito Sans",sans-serif; }

#forum-content #forum .forum-tools,
#forum-content #forum .forum-list-icon-legend,
#forum-content #forum .forum-topic-legend {
  display: none; }

.purchase {
  margin-top: 2.5rem;
  padding-bottom: 5rem; }
  .purchase .messages {
    width: 1000px;
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
    border-radius: 4px;
    text-align: inherit;
    font-size: 1.6rem;
    margin-bottom: 5rem;
    padding: 2.5rem 5rem; }
    .purchase .messages.error {
      background-color: rgba(217, 83, 79, 0.08); }
    .purchase .messages ul {
      margin: 0;
      padding-left: 1.5rem; }

.purchase-details {
  position: relative;
  width: 1000px;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
  text-align: inherit;
  border-radius: 4px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.22), 0 6px 6px rgba(0, 0, 0, 0.1); }
  .purchase-details legend {
    display: none; }
  .purchase-details label {
    font-weight: 500; }
  .purchase-details input {
    vertical-align: baseline; }
  .purchase-details__header {
    position: relative;
    padding: 2.5rem 5rem;
    background-color: #f2f9ff;
    border-radius: 4px 4px 0 0; }
    .purchase-details__header h2 {
      margin: 0 0 10px;
      padding-left: 10rem;
      font-size: 2.4rem;
      line-height: 3rem; }
    .purchase-details__header .prices-info {
      padding-left: 10rem;
      font-weight: 400;
      font-size: 2.4rem; }
      .purchase-details__header .prices-info .discount {
        color: rgba(64, 81, 102, 0.75);
        text-decoration: line-through;
        padding: 0 1rem;
        font-weight: 600;
        font-size: 2.1rem; }
    .purchase-details__header .ecosystem-logo {
      position: absolute;
      left: 5rem;
      top: 20%;
      width: 76px;
      height: 76px;
      background-image: url(../images/ecosystem-logo.png);
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 999; }
    .purchase-details__header .col-xs-12 {
      padding: 0; }
  .purchase-details .wrapper {
    padding: 2.5rem 5rem; }
    .purchase-details .wrapper table {
      width: 100%; }
      .purchase-details .wrapper table thead tr,
      .purchase-details .wrapper table .heading {
        font-weight: 600;
        font-size: 1.6rem; }
        .purchase-details .wrapper table thead tr th,
        .purchase-details .wrapper table .heading th {
          border-bottom-width: 2px;
          font-weight: 700;
          padding: 2px 5px; }
          .purchase-details .wrapper table thead tr th.price,
          .purchase-details .wrapper table .heading th.price {
            text-align: right; }
      .purchase-details .wrapper table tr td {
        padding: 2px 5px;
        border-bottom: 1px solid #cecece; }
        .purchase-details .wrapper table tr td.price {
          min-width: 164px;
          text-align: right; }
      .purchase-details .wrapper table tr:last-child td {
        border-bottom: 0; }
    .purchase-details .wrapper .hidden-start {
      display: none; }
      .purchase-details .wrapper .hidden-start.visible {
        display: inline-block; }
    .purchase-details .wrapper .renewal-unchecked {
      text-decoration: line-through; }
  .purchase-details .product-name {
    font-size: 2.1rem;
    font-weight: 400;
    color: #30a3f1; }
  .purchase-details .price--new {
    font-size: 2.1rem;
    font-weight: 400;
    color: #2dbe61; }
  .purchase-details ol {
    padding-left: 20px; }
    .purchase-details ol li {
      font-size: 2rem;
      margin-bottom: 5rem; }
      .purchase-details ol li .connectors-wrapper {
        display: flex;
        justify-content: center; }
        .purchase-details ol li .connectors-wrapper > div {
          text-align: center;
          margin-left: 2.5rem; }
          .purchase-details ol li .connectors-wrapper > div:first-of-type {
            margin: 0; }
          .purchase-details ol li .connectors-wrapper > div label {
            display: block;
            width: 75px;
            height: 50px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: 50% 0;
            cursor: pointer;
            text-indent: -9999px; }
          .purchase-details ol li .connectors-wrapper > div.php label {
            background-image: url(../images/languages/php.svg); }
          .purchase-details ol li .connectors-wrapper > div.java label {
            background-image: url(../images/languages/java.svg); }
          .purchase-details ol li .connectors-wrapper > div.asp label {
            background-image: url(../images/languages/asp.svg); }
          .purchase-details ol li .connectors-wrapper > div.asp-net label {
            background-image: url(../images/languages/asp.net.svg); }
          .purchase-details ol li .connectors-wrapper > div.coldfusion label {
            background-image: url(../images/languages/cf.svg);
            background-size: 50px 60px;
            background-position: 50% -8px; }
      .purchase-details ol li:last-of-type {
        margin-bottom: 0; }
      .purchase-details ol li > * {
        font-size: 1.6rem; }
      .purchase-details ol li p {
        color: rgba(64, 81, 102, 0.75);
        margin-top: 1rem; }
      .purchase-details ol li h4 {
        font-size: inherit; }
        .purchase-details ol li h4 + .form-inline,
        .purchase-details ol li h4 + .form-group,
        .purchase-details ol li h4 + label,
        .purchase-details ol li h4 + div {
          margin-top: 2.5rem; }
  .purchase-details .label {
    color: rgba(64, 81, 102, 0.96);
    font-weight: 500;
    margin-bottom: 0; }
  .purchase-details .form-inline .label {
    display: inline-block; }
  .purchase-details .form-inline label + input,
  .purchase-details .form-inline label + select,
  .purchase-details .form-inline .label + input {
    margin-left: 1.5rem; }
  .purchase-details .form-inline input + label {
    margin-left: .5rem; }
  .purchase-details .form-inline input[type="radio"] + label {
    font-weight: 400; }
  .purchase-details .options-group {
    display: inline-block;
    margin-left: 1.5rem; }
  .purchase-details .form-group {
    display: inline-block;
    width: 100%;
    margin-bottom: 2.5rem; }
    .purchase-details .form-group:last-of-type {
      margin-bottom: 0; }
    .purchase-details .form-group label,
    .purchase-details .form-group .label {
      width: 25%;
      text-align: right; }
    .purchase-details .form-group input + label {
      width: auto; }
    .purchase-details .form-group input[type="text"],
    .purchase-details .form-group .options-group,
    .purchase-details .form-group select {
      width: 65%; }
  .purchase-details .opt {
    display: inline-block;
    width: 100%; }
    .purchase-details .opt p {
      position: absolute;
      bottom: 2.5rem;
      left: 2.5rem;
      margin: 0;
      font-size: 1.6rem; }
    .purchase-details .opt .btn, .purchase-details .opt #cksource_repo_search_forums_form .form-submit, #cksource_repo_search_forums_form .purchase-details .opt .form-submit, .purchase-details .opt #forum-sort .form-submit, #forum-sort .purchase-details .opt .form-submit, .purchase-details .opt .add-plugin input[type="submit"], .add-plugin .purchase-details .opt input[type="submit"], .purchase-details .opt
    .add-plugin select[type="submit"],
    .add-plugin .purchase-details .opt select[type="submit"], .purchase-details .opt
    .plugin-edit input[type="submit"],
    .plugin-edit .purchase-details .opt input[type="submit"], .purchase-details .opt
    .plugin-edit select[type="submit"],
    .plugin-edit .purchase-details .opt select[type="submit"], .purchase-details .opt
    .plugin-release input[type="submit"],
    .plugin-release .purchase-details .opt input[type="submit"], .purchase-details .opt
    .plugin-release select[type="submit"],
    .plugin-release .purchase-details .opt select[type="submit"], .purchase-details .opt .add-plugin .field-type-image-form input[type="file"], .add-plugin .field-type-image-form .purchase-details .opt input[type="file"], .purchase-details .opt
    .plugin-edit .field-type-image-form input[type="file"],
    .plugin-edit .field-type-image-form .purchase-details .opt input[type="file"], .purchase-details .opt
    .plugin-release .field-type-image-form input[type="file"],
    .plugin-release .field-type-image-form .purchase-details .opt input[type="file"], .purchase-details .opt .add-plugin .field-type-image-form button, .add-plugin .field-type-image-form .purchase-details .opt button, .purchase-details .opt
    .plugin-edit .field-type-image-form button,
    .plugin-edit .field-type-image-form .purchase-details .opt button, .purchase-details .opt
    .plugin-release .field-type-image-form button,
    .plugin-release .field-type-image-form .purchase-details .opt button, .purchase-details .opt .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], .add-plugin .field-type-image-form .purchase-details .opt input[name="field_addon_logo_und_0_remove_button"], .purchase-details .opt
    .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
    .plugin-edit .field-type-image-form .purchase-details .opt input[name="field_addon_logo_und_0_remove_button"], .purchase-details .opt
    .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
    .plugin-release .field-type-image-form .purchase-details .opt input[name="field_addon_logo_und_0_remove_button"], .purchase-details .opt #editor-builder #draggable-plugins .all-elements-option a, #editor-builder #draggable-plugins .all-elements-option .purchase-details .opt a, .purchase-details .opt
    #editor-builder .languages .all-elements-option a,
    #editor-builder .languages .all-elements-option .purchase-details .opt a, .purchase-details .opt .login .form-control[type="submit"], .login .purchase-details .opt .form-control[type="submit"], .purchase-details .opt
    .register .form-control[type="submit"],
    .register .purchase-details .opt .form-control[type="submit"], .purchase-details .opt
    .info-page .form-control[type="submit"],
    .info-page .purchase-details .opt .form-control[type="submit"], .purchase-details .opt .most-popular__wrapper .add_to_basket, .most-popular__wrapper .purchase-details .opt .add_to_basket, .purchase-details .opt .add-ons-list .views-row .add_to_basket, .add-ons-list .views-row .purchase-details .opt .add_to_basket, .purchase-details .opt
    .add-ons-list .views-row .check-details,
    .add-ons-list .views-row .purchase-details .opt .check-details, .purchase-details .opt
    .add-ons-list .views-row .remove_from_basket,
    .add-ons-list .views-row .purchase-details .opt .remove_from_basket, .purchase-details .opt
    .add-ons-list .views-row .required,
    .add-ons-list .views-row .purchase-details .opt .required, .purchase-details .opt .user-edit input[type="submit"], .user-edit .purchase-details .opt input[type="submit"], .purchase-details .opt
    .user-edit select[type="submit"],
    .user-edit .purchase-details .opt select[type="submit"] {
      display: block;
      float: right;
      margin-top: 2.5rem; }
  .purchase-details .mandatory {
    color: #d9534f; }
  .purchase-details #individual_corporate_tip,
  .purchase-details #owner-fields {
    display: none; }
  .purchase-details .add-bundle input {
    margin-right: .5rem; }
  .purchase-details .error label {
    color: rgba(64, 81, 102, 0.96); }
  .purchase-details .error .form-control {
    border-color: #d9534f; }
  .purchase-details .error input[type="checkbox"],
  .purchase-details .error input[type="radio"] {
    border-color: #d9534f;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
    .purchase-details .error input[type="checkbox"] + label,
    .purchase-details .error input[type="radio"] + label {
      color: #d9534f; }
  .purchase-details .thanks {
    text-align: center;
    margin: 0; }
    .purchase-details .thanks .purchase-thanks__icon {
      display: block;
      height: 150px;
      width: 200px;
      margin-top: 2.5rem;
      margin-bottom: 5rem;
      margin-left: auto;
      margin-right: auto;
      background-image: url(../images/icons/ckecked.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center; }

#confirmation section {
  margin-top: 0; }
  #confirmation section:last-of-type {
    margin-bottom: 0; }

#confirmation h3 a {
  font-size: .5em;
  font-weight: 600; }

#confirmation button[type="submit"] {
  float: right;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }

#confirmation ul {
  list-style: none;
  margin: 0;
  padding: 0;
  padding: 2.5rem 0; }
  #confirmation ul li {
    display: flex;
    flex-wrap: wrap;
    padding-left: 10rem;
    font-size: 1.6rem;
    margin-bottom: 2.5rem; }
    #confirmation ul li .flex-1-1 {
      width: 100%;
      padding: 0 1rem; }
    #confirmation ul li .flex-1-2 {
      width: 100%;
      padding: 0 1rem; }
      @media (min-width: 768px) {
        #confirmation ul li .flex-1-2 {
          width: 50%; } }
    #confirmation ul li .purchase__field-title {
      margin-top: 0;
      margin-bottom: 1rem;
      font-weight: 600;
      color: rgba(64, 81, 102, 0.75); }
    #confirmation ul li .purchase__field-value {
      margin: 0;
      padding: 1.5rem;
      background-color: #f2f2f2;
      border: 1px dashed #d0d0d0;
      border-radius: 4px; }

#confirmation .payment-options ol {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-left: 10rem; }

#confirmation .payment-options .payment-option {
  position: relative;
  display: flex;
  padding-left: 2.5rem;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  background-color: white;
  font-weight: 400;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: all 200ms ease-in-out; }
  #confirmation .payment-options .payment-option--active {
    background-color: #f2f9ff;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.22), 0 6px 6px rgba(0, 0, 0, 0.1); }
  #confirmation .payment-options .payment-option label {
    flex-grow: 1;
    padding-top: 2.5rem;
    padding-right: 10rem;
    padding-bottom: 2.5rem;
    margin-left: 2.5rem;
    margin-bottom: 0;
    cursor: pointer; }
  #confirmation .payment-options .payment-option input[type="radio"] {
    cursor: pointer; }
  #confirmation .payment-options .payment-option__title {
    display: block;
    margin-bottom: 1rem;
    font-size: 1.9rem;
    font-weight: 600;
    color: rgba(64, 81, 102, 0.75); }
  #confirmation .payment-options .payment-option__description {
    display: block;
    font-size: 1.6rem;
    font-weight: 400; }
  #confirmation .payment-options .payment-option__icon {
    position: absolute;
    right: 2.5rem;
    top: 50%;
    height: 50px;
    width: 50px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transform: translateY(-50%);
    z-index: 99; }
    #confirmation .payment-options .payment-option__icon--pl {
      height: 65px;
      width: 65px;
      background-image: url(../images/icons/paylane.png); }
    #confirmation .payment-options .payment-option__icon--pp {
      height: 75px;
      width: 75px;
      background-image: url(../images/icons/paypal.svg); }
    #confirmation .payment-options .payment-option__icon--bwt {
      background-image: url(../images/icons/bank.svg); }
    #confirmation .payment-options .payment-option__icon--so {
      background-image: url(../images/icons/receipt.svg); }

.sharepoint-activation ol {
  padding: 0 0 0 1rem; }

.sharepoint-activation,
.ckfinder-activation {
  float: left;
  width: 100%; }
  .sharepoint-activation .buyerInformation,
  .ckfinder-activation .buyerInformation {
    border-bottom: 1px solid #e5e5e5; }
  .sharepoint-activation fieldset,
  .ckfinder-activation fieldset {
    padding: 25px 15px 0 0; }
  .sharepoint-activation form,
  .ckfinder-activation form {
    color: #575757; }
    .sharepoint-activation form label,
    .ckfinder-activation form label {
      font-weight: 500;
      padding: 10px 0; }
      .sharepoint-activation form label > small,
      .ckfinder-activation form label > small {
        font-size: 1.4rem; }
    .sharepoint-activation form legend,
    .ckfinder-activation form legend {
      padding-bottom: 10px; }
    .sharepoint-activation form textarea[name*=hwkey],
    .ckfinder-activation form textarea[name*=hwkey] {
      display: block;
      width: 100%; }
  .sharepoint-activation .activation-title .dev_domains .dev_2,
  .ckfinder-activation .activation-title .dev_domains .dev_2 {
    margin-top: 10px; }
  .sharepoint-activation .activation-title .existing,
  .ckfinder-activation .activation-title .existing {
    padding: 10px;
    border-bottom: 1px solid #d4d4d4; }
  .sharepoint-activation .activation-title input[type="submit"],
  .ckfinder-activation .activation-title input[type="submit"] {
    margin: 10px 0; }
  .sharepoint-activation .activation-title + .activated-title,
  .ckfinder-activation .activation-title + .activated-title {
    border-top: 1px solid #d4d4d4; }
  .sharepoint-activation .activated-title,
  .ckfinder-activation .activated-title {
    margin-top: 2.5rem;
    padding-top: 2.5rem; }
    .sharepoint-activation .activated-title div.row:not(:first-child),
    .ckfinder-activation .activated-title div.row:not(:first-child) {
      margin-top: 2.5rem;
      padding-top: 2.5rem; }
    .sharepoint-activation .activated-title div.row h3,
    .ckfinder-activation .activated-title div.row h3 {
      margin: 0 0 15px 5px;
      position: relative;
      padding-bottom: 10px; }
      .sharepoint-activation .activated-title div.row h3:after,
      .ckfinder-activation .activated-title div.row h3:after {
        content: "";
        display: block;
        width: 120px;
        border-bottom: 1px solid #d4d4d4;
        height: 6px;
        position: absolute;
        left: -5px;
        bottom: 0; }
    .sharepoint-activation .activated-title div.row p,
    .ckfinder-activation .activated-title div.row p {
      font-size: 1.6rem; }
      .sharepoint-activation .activated-title div.row p.dev_btn,
      .ckfinder-activation .activated-title div.row p.dev_btn {
        text-align: center; }
      .sharepoint-activation .activated-title div.row p.category,
      .ckfinder-activation .activated-title div.row p.category {
        margin-bottom: 10px;
        font-size: 1.5rem;
        text-align: center; }
      .sharepoint-activation .activated-title div.row p > strong,
      .ckfinder-activation .activated-title div.row p > strong {
        min-width: 100px;
        display: inline-block;
        text-align: right;
        padding-right: 10px; }
        @media (min-width: 1280px) {
          .sharepoint-activation .activated-title div.row p > strong,
          .ckfinder-activation .activated-title div.row p > strong {
            min-width: 120px; } }
    .sharepoint-activation .activated-title h4,
    .ckfinder-activation .activated-title h4 {
      text-align: center; }
  .sharepoint-activation #edit-save + .activated-title,
  .ckfinder-activation #edit-save + .activated-title {
    border-top: 1px solid #d4d4d4; }

#ckfinder3-purchase-notice {
  font-size: 1.4rem;
  color: #856404;
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  border-radius: 4px;
  padding: 1.2em;
  margin-top: 20px;
  margin-bottom: 0;
  display: none; }

.site-content--purchase-legal .content {
  width: 850px;
  max-width: 100%;
  margin: 0 auto; }
  @media (min-width: 768px) {
    .site-content--purchase-legal .content {
      margin-top: 5rem; } }
  .site-content--purchase-legal .content > #annex,
  .site-content--purchase-legal .content > .annex,
  .site-content--purchase-legal .content > ol {
    list-style-type: none;
    counter-reset: listNumbering;
    padding: 0; }
    .site-content--purchase-legal .content > #annex > li:not(.sub),
    .site-content--purchase-legal .content > .annex > li:not(.sub),
    .site-content--purchase-legal .content > ol > li:not(.sub) {
      counter-increment: listNumbering; }
      .site-content--purchase-legal .content > #annex > li:not(.sub):before,
      .site-content--purchase-legal .content > .annex > li:not(.sub):before,
      .site-content--purchase-legal .content > ol > li:not(.sub):before {
        content: counter(listNumbering) ".";
        font-size: 2.2rem;
        font-weight: 600; }
      .site-content--purchase-legal .content > #annex > li:not(.sub) h3,
      .site-content--purchase-legal .content > .annex > li:not(.sub) h3,
      .site-content--purchase-legal .content > ol > li:not(.sub) h3 {
        display: inline; }
      .site-content--purchase-legal .content > #annex > li:not(.sub) ul,
      .site-content--purchase-legal .content > .annex > li:not(.sub) ul,
      .site-content--purchase-legal .content > ol > li:not(.sub) ul {
        list-style: lower-latin;
        margin-bottom: 2.8rem; }
    .site-content--purchase-legal .content > #annex li.sub,
    .site-content--purchase-legal .content > .annex li.sub,
    .site-content--purchase-legal .content > ol li.sub {
      list-style: none;
      position: relative; }
      .site-content--purchase-legal .content > #annex li.sub span.sub,
      .site-content--purchase-legal .content > .annex li.sub span.sub,
      .site-content--purchase-legal .content > ol li.sub span.sub {
        position: absolute;
        left: -20px; }
    .site-content--purchase-legal .content > #annex ul.sub-2,
    .site-content--purchase-legal .content > .annex ul.sub-2,
    .site-content--purchase-legal .content > ol ul.sub-2 {
      list-style: none; }
      .site-content--purchase-legal .content > #annex ul.sub-2 li.sub-2,
      .site-content--purchase-legal .content > .annex ul.sub-2 li.sub-2,
      .site-content--purchase-legal .content > ol ul.sub-2 li.sub-2 {
        position: relative;
        list-style: none; }
  .site-content--purchase-legal .content .support,
  .site-content--purchase-legal .content .support > p:first-child {
    margin-top: 0; }
  .site-content--purchase-legal .content table {
    border: 0; }
    .site-content--purchase-legal .content table th, .site-content--purchase-legal .content table td {
      padding: 0.5rem; }
  .site-content--purchase-legal .content .with-padding {
    padding-left: 2.8rem; }

.nightly {
  text-align: center; }
  .nightly-section {
    margin-bottom: 2.5rem; }
    @media (max-width: 767px) {
      .nightly-section {
        font-size: 1.6rem; } }
    @media (min-width: 768px) {
      .nightly-section {
        margin-bottom: 5rem; } }
  @media (max-width: 767px) {
    .nightly .introduce {
      padding: 0; } }
  @media (max-width: 767px) {
    .nightly h2 {
      font-size: 19px;
      line-height: 21px; } }
  .nightly h3 {
    margin-top: 0;
    margin-bottom: 2.5rem;
    font-size: 1.9rem;
    font-weight: 600; }
    @media (min-width: 768px) {
      .nightly h3 {
        margin-bottom: 5rem;
        font-size: 2.1rem; } }
  .nightly h4 {
    margin-bottom: 1.25rem;
    font-size: 1.9rem; }
    @media (max-width: 767px) {
      .nightly h4 {
        font-size: 1.6rem; } }
  .nightly ul {
    margin: 0;
    padding: 0;
    list-style: none; }
  .nightly__list--separated li {
    display: inline-block;
    margin-right: 1.25rem; }
    .nightly__list--separated li:after {
      content: '|';
      display: inline-block;
      margin-left: 1.25rem;
      color: rgba(64, 81, 102, 0.48); }
    .nightly__list--separated li:last-of-type:after {
      display: none; }
  .nightly__list--collapsed span {
    display: inline-block;
    width: 115px;
    text-align: right;
    margin-right: 3px; }
    @media (min-width: 768px) {
      .nightly__list--collapsed span {
        width: 170px; } }
  .nightly__list--collapsed a:after {
    content: '|';
    display: inline-block;
    margin-left: 0.625rem;
    color: rgba(64, 81, 102, 0.48); }
  .nightly__list--collapsed a:last-of-type:after {
    display: none; }
  @media (min-width: 1280px) {
    .nightly__list--collapsed li {
      display: inline-block;
      margin-right: 1.25rem; }
      .nightly__list--collapsed li:last-of-type {
        margin-right: 0; }
    .nightly__list--collapsed span {
      display: inline;
      width: auto;
      text-align: left; } }

.addon {
  width: 100%;
  margin-left: 0;
  margin-bottom: 35px;
  padding: 10px 20px 30px; }
  @media (min-width: 1280px) {
    .addon {
      width: calc(100% - 260px - 25px);
      margin-left: 25px; } }
  @media (min-width: 1680px) {
    .addon {
      width: calc(100% - 260px - ( 25px * 2 ));
      margin-left: 25px;
      margin-right: 25px; } }
  .addon--add {
    padding: 0; }
    @media (min-width: 1280px) {
      .addon--add {
        width: 100%;
        margin: 0; } }
  .addon--release {
    padding: 0; }
    @media (min-width: 1280px) {
      .addon--release {
        width: 100%;
        margin: 0; } }
    @media (min-width: 1680px) {
      .addon--release {
        width: calc(100% - 50px);
        margin-left: 2.5rem;
        margin-right: 2.5rem;
        background-color: white; } }
  .addon h2 {
    padding-bottom: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center; }
    @media (min-width: 768px) {
      .addon h2 {
        text-align: left; } }
    .addon h2 a {
      color: rgba(64, 81, 102, 0.96); }
  .addon__screenshots {
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15); }
    .addon__screenshots h3 {
      font-size: 1.9rem;
      font-weight: 700;
      margin-top: 5px;
      margin-bottom: 10px; }
    .addon__screenshots .swiper-container {
      padding-bottom: 20px; }
      .addon__screenshots .swiper-container .swiper-pagination-bullets {
        bottom: 0; }
    .addon__screenshots .slick-dots {
      bottom: -25px; }
      .addon__screenshots .slick-dots li button:before {
        color: rgba(64, 81, 102, 0.75); }
      .addon__screenshots .slick-dots li.slick-active button:before {
        color: rgba(64, 81, 102, 0.75); }
    .addon__screenshots .slick-prev,
    .addon__screenshots .slick-next {
      display: none !important;
      z-index: 99; }
      @media (min-width: 1680px) {
        .addon__screenshots .slick-prev,
        .addon__screenshots .slick-next {
          display: inline-block !important; } }
      .addon__screenshots .slick-prev:before,
      .addon__screenshots .slick-next:before {
        color: rgba(64, 81, 102, 0.75); }
    .addon__screenshots .slick-prev {
      left: 0; }
    .addon__screenshots .slick-next {
      right: 0; }
    .addon__screenshots-images {
      width: 100%; }
      .addon__screenshots-images img {
        display: block;
        max-width: 90%;
        margin: 0 auto 10px; }
  .addon__downloads {
    margin-top: 20px;
    margin-bottom: 15px;
    font-size: 2.1rem;
    text-align: center; }
    .addon__downloads a:hover {
      text-decoration: underline; }
  .addon__info-container {
    display: inline-block;
    width: 100%; }
  .addon__thumb {
    float: left;
    margin: 0 15px 15px 0;
    text-align: center;
    width: 160px;
    height: 160px;
    background: no-repeat center;
    position: relative; }
    .addon__thumb > .addon-of-the-month {
      top: -9px;
      left: -9px;
      position: absolute; }
    @media (min-width: 768px) {
      .addon__thumb {
        text-align: left; } }
  .addon__info {
    float: left;
    width: 100%;
    font-size: 1.3rem;
    font-weight: 400;
    text-align: center;
    margin-top: 10px; }
    @media (min-width: 768px) {
      .addon__info {
        text-align: left;
        margin-top: 0;
        margin-left: -175px;
        padding-left: 175px; } }
    .addon__info ul {
      list-style: none;
      margin: 0;
      padding: 0; }
    .addon__info-list li {
      font-size: 1.3rem;
      font-weight: 400; }
    .addon__info time {
      display: inline-block;
      margin: 15px 0; }
  .addon__link-list li {
    display: inline-block;
    margin-right: 10px;
    font-weight: 400; }
  .addon__description {
    font-size: 1.3rem;
    font-weight: 400;
    display: inline-block;
    width: 100%;
    margin-bottom: 2.5rem; }
    @media (min-width: 768px) {
      .addon__description {
        font-size: 1.6rem; } }
    .addon__description p:last-of-type {
      margin: 0; }
  .addon__actions-wrapper {
    display: inline-block;
    width: 100%;
    padding-bottom: 10px; }
    .addon__actions-wrapper .add_to_basket {
      margin: 0; }
  .addon__actions {
    display: inline-block;
    width: 100%;
    text-align: center; }
    .addon__actions .btn, .addon__actions #cksource_repo_search_forums_form .form-submit, #cksource_repo_search_forums_form .addon__actions .form-submit, .addon__actions #forum-sort .form-submit, #forum-sort .addon__actions .form-submit, .addon__actions .add-plugin input[type="submit"], .add-plugin .addon__actions input[type="submit"], .addon__actions
    .add-plugin select[type="submit"],
    .add-plugin .addon__actions select[type="submit"], .addon__actions
    .plugin-edit input[type="submit"],
    .plugin-edit .addon__actions input[type="submit"], .addon__actions
    .plugin-edit select[type="submit"],
    .plugin-edit .addon__actions select[type="submit"], .addon__actions
    .plugin-release input[type="submit"],
    .plugin-release .addon__actions input[type="submit"], .addon__actions
    .plugin-release select[type="submit"],
    .plugin-release .addon__actions select[type="submit"], .addon__actions .add-plugin .field-type-image-form input[type="file"], .add-plugin .field-type-image-form .addon__actions input[type="file"], .addon__actions
    .plugin-edit .field-type-image-form input[type="file"],
    .plugin-edit .field-type-image-form .addon__actions input[type="file"], .addon__actions
    .plugin-release .field-type-image-form input[type="file"],
    .plugin-release .field-type-image-form .addon__actions input[type="file"], .addon__actions .add-plugin .field-type-image-form button, .add-plugin .field-type-image-form .addon__actions button, .addon__actions
    .plugin-edit .field-type-image-form button,
    .plugin-edit .field-type-image-form .addon__actions button, .addon__actions
    .plugin-release .field-type-image-form button,
    .plugin-release .field-type-image-form .addon__actions button, .addon__actions .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], .add-plugin .field-type-image-form .addon__actions input[name="field_addon_logo_und_0_remove_button"], .addon__actions
    .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
    .plugin-edit .field-type-image-form .addon__actions input[name="field_addon_logo_und_0_remove_button"], .addon__actions
    .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
    .plugin-release .field-type-image-form .addon__actions input[name="field_addon_logo_und_0_remove_button"], .addon__actions #editor-builder #draggable-plugins .all-elements-option a, #editor-builder #draggable-plugins .all-elements-option .addon__actions a, .addon__actions
    #editor-builder .languages .all-elements-option a,
    #editor-builder .languages .all-elements-option .addon__actions a, .addon__actions .login .form-control[type="submit"], .login .addon__actions .form-control[type="submit"], .addon__actions
    .register .form-control[type="submit"],
    .register .addon__actions .form-control[type="submit"], .addon__actions
    .info-page .form-control[type="submit"],
    .info-page .addon__actions .form-control[type="submit"], .addon__actions .most-popular__wrapper .add_to_basket, .most-popular__wrapper .addon__actions .add_to_basket, .addon__actions .add-ons-list .views-row .add_to_basket, .add-ons-list .views-row .addon__actions .add_to_basket, .addon__actions
    .add-ons-list .views-row .check-details,
    .add-ons-list .views-row .addon__actions .check-details, .addon__actions
    .add-ons-list .views-row .remove_from_basket,
    .add-ons-list .views-row .addon__actions .remove_from_basket, .addon__actions
    .add-ons-list .views-row .required,
    .add-ons-list .views-row .addon__actions .required, .addon__actions .user-edit input[type="submit"], .user-edit .addon__actions input[type="submit"], .addon__actions
    .user-edit select[type="submit"],
    .user-edit .addon__actions select[type="submit"] {
      margin-right: 2.5rem; }
      .addon__actions .btn:last-of-type, .addon__actions #cksource_repo_search_forums_form .form-submit:last-of-type, #cksource_repo_search_forums_form .addon__actions .form-submit:last-of-type, .addon__actions #forum-sort .form-submit:last-of-type, #forum-sort .addon__actions .form-submit:last-of-type, .addon__actions .add-plugin input[type="submit"]:last-of-type, .add-plugin .addon__actions input[type="submit"]:last-of-type, .addon__actions
      .add-plugin select[type="submit"]:last-of-type,
      .add-plugin .addon__actions select[type="submit"]:last-of-type, .addon__actions
      .plugin-edit input[type="submit"]:last-of-type,
      .plugin-edit .addon__actions input[type="submit"]:last-of-type, .addon__actions
      .plugin-edit select[type="submit"]:last-of-type,
      .plugin-edit .addon__actions select[type="submit"]:last-of-type, .addon__actions
      .plugin-release input[type="submit"]:last-of-type,
      .plugin-release .addon__actions input[type="submit"]:last-of-type, .addon__actions
      .plugin-release select[type="submit"]:last-of-type,
      .plugin-release .addon__actions select[type="submit"]:last-of-type, .addon__actions .add-plugin .field-type-image-form input[type="file"]:last-of-type, .add-plugin .field-type-image-form .addon__actions input[type="file"]:last-of-type, .addon__actions
      .plugin-edit .field-type-image-form input[type="file"]:last-of-type,
      .plugin-edit .field-type-image-form .addon__actions input[type="file"]:last-of-type, .addon__actions
      .plugin-release .field-type-image-form input[type="file"]:last-of-type,
      .plugin-release .field-type-image-form .addon__actions input[type="file"]:last-of-type, .addon__actions .add-plugin .field-type-image-form button:last-of-type, .add-plugin .field-type-image-form .addon__actions button:last-of-type, .addon__actions
      .plugin-edit .field-type-image-form button:last-of-type,
      .plugin-edit .field-type-image-form .addon__actions button:last-of-type, .addon__actions
      .plugin-release .field-type-image-form button:last-of-type,
      .plugin-release .field-type-image-form .addon__actions button:last-of-type, .addon__actions .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:last-of-type, .add-plugin .field-type-image-form .addon__actions input[name="field_addon_logo_und_0_remove_button"]:last-of-type, .addon__actions
      .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:last-of-type,
      .plugin-edit .field-type-image-form .addon__actions input[name="field_addon_logo_und_0_remove_button"]:last-of-type, .addon__actions
      .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:last-of-type,
      .plugin-release .field-type-image-form .addon__actions input[name="field_addon_logo_und_0_remove_button"]:last-of-type, .addon__actions #editor-builder #draggable-plugins .all-elements-option a:last-of-type, #editor-builder #draggable-plugins .all-elements-option .addon__actions a:last-of-type, .addon__actions
      #editor-builder .languages .all-elements-option a:last-of-type,
      #editor-builder .languages .all-elements-option .addon__actions a:last-of-type, .addon__actions .login .form-control[type="submit"]:last-of-type, .login .addon__actions .form-control[type="submit"]:last-of-type, .addon__actions
      .register .form-control[type="submit"]:last-of-type,
      .register .addon__actions .form-control[type="submit"]:last-of-type, .addon__actions
      .info-page .form-control[type="submit"]:last-of-type,
      .info-page .addon__actions .form-control[type="submit"]:last-of-type, .addon__actions .most-popular__wrapper .add_to_basket:last-of-type, .most-popular__wrapper .addon__actions .add_to_basket:last-of-type, .addon__actions .add-ons-list .views-row .add_to_basket:last-of-type, .add-ons-list .views-row .addon__actions .add_to_basket:last-of-type, .addon__actions
      .add-ons-list .views-row .check-details:last-of-type,
      .add-ons-list .views-row .addon__actions .check-details:last-of-type, .addon__actions
      .add-ons-list .views-row .remove_from_basket:last-of-type,
      .add-ons-list .views-row .addon__actions .remove_from_basket:last-of-type, .addon__actions
      .add-ons-list .views-row .required:last-of-type,
      .add-ons-list .views-row .addon__actions .required:last-of-type, .addon__actions .user-edit input[type="submit"]:last-of-type, .user-edit .addon__actions input[type="submit"]:last-of-type, .addon__actions
      .user-edit select[type="submit"]:last-of-type,
      .user-edit .addon__actions select[type="submit"]:last-of-type {
        margin-right: 0; }
    .addon__actions-info {
      display: inline-block;
      width: 100%;
      padding-top: 5px;
      padding-bottom: 15px;
      margin-bottom: 30px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15); }
  .addon__action {
    padding: 0 5px;
    margin-bottom: 10px;
    text-align: center; }
    @media (min-width: 768px) {
      .addon__action {
        margin: 0;
        text-align: left; } }
    .addon__action .btn, .addon__action #cksource_repo_search_forums_form .form-submit, #cksource_repo_search_forums_form .addon__action .form-submit, .addon__action #forum-sort .form-submit, #forum-sort .addon__action .form-submit, .addon__action .add-plugin input[type="submit"], .add-plugin .addon__action input[type="submit"], .addon__action
    .add-plugin select[type="submit"],
    .add-plugin .addon__action select[type="submit"], .addon__action
    .plugin-edit input[type="submit"],
    .plugin-edit .addon__action input[type="submit"], .addon__action
    .plugin-edit select[type="submit"],
    .plugin-edit .addon__action select[type="submit"], .addon__action
    .plugin-release input[type="submit"],
    .plugin-release .addon__action input[type="submit"], .addon__action
    .plugin-release select[type="submit"],
    .plugin-release .addon__action select[type="submit"], .addon__action .add-plugin .field-type-image-form input[type="file"], .add-plugin .field-type-image-form .addon__action input[type="file"], .addon__action
    .plugin-edit .field-type-image-form input[type="file"],
    .plugin-edit .field-type-image-form .addon__action input[type="file"], .addon__action
    .plugin-release .field-type-image-form input[type="file"],
    .plugin-release .field-type-image-form .addon__action input[type="file"], .addon__action .add-plugin .field-type-image-form button, .add-plugin .field-type-image-form .addon__action button, .addon__action
    .plugin-edit .field-type-image-form button,
    .plugin-edit .field-type-image-form .addon__action button, .addon__action
    .plugin-release .field-type-image-form button,
    .plugin-release .field-type-image-form .addon__action button, .addon__action .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], .add-plugin .field-type-image-form .addon__action input[name="field_addon_logo_und_0_remove_button"], .addon__action
    .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
    .plugin-edit .field-type-image-form .addon__action input[name="field_addon_logo_und_0_remove_button"], .addon__action
    .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
    .plugin-release .field-type-image-form .addon__action input[name="field_addon_logo_und_0_remove_button"], .addon__action #editor-builder #draggable-plugins .all-elements-option a, #editor-builder #draggable-plugins .all-elements-option .addon__action a, .addon__action
    #editor-builder .languages .all-elements-option a,
    #editor-builder .languages .all-elements-option .addon__action a, .addon__action .login .form-control[type="submit"], .login .addon__action .form-control[type="submit"], .addon__action
    .register .form-control[type="submit"],
    .register .addon__action .form-control[type="submit"], .addon__action
    .info-page .form-control[type="submit"],
    .info-page .addon__action .form-control[type="submit"], .addon__action .most-popular__wrapper .add_to_basket, .most-popular__wrapper .addon__action .add_to_basket, .addon__action .add-ons-list .views-row .add_to_basket, .add-ons-list .views-row .addon__action .add_to_basket, .addon__action
    .add-ons-list .views-row .check-details,
    .add-ons-list .views-row .addon__action .check-details, .addon__action
    .add-ons-list .views-row .remove_from_basket,
    .add-ons-list .views-row .addon__action .remove_from_basket, .addon__action
    .add-ons-list .views-row .required,
    .add-ons-list .views-row .addon__action .required, .addon__action .user-edit input[type="submit"], .user-edit .addon__action input[type="submit"], .addon__action
    .user-edit select[type="submit"],
    .user-edit .addon__action select[type="submit"] {
      min-width: 17.5rem; }
    @media (min-width: 768px) {
      .addon__action:first-of-type .btn, .addon__action:first-of-type #cksource_repo_search_forums_form .form-submit, #cksource_repo_search_forums_form .addon__action:first-of-type .form-submit, .addon__action:first-of-type #forum-sort .form-submit, #forum-sort .addon__action:first-of-type .form-submit, .addon__action:first-of-type .add-plugin input[type="submit"], .add-plugin .addon__action:first-of-type input[type="submit"], .addon__action:first-of-type
      .add-plugin select[type="submit"],
      .add-plugin .addon__action:first-of-type select[type="submit"], .addon__action:first-of-type
      .plugin-edit input[type="submit"],
      .plugin-edit .addon__action:first-of-type input[type="submit"], .addon__action:first-of-type
      .plugin-edit select[type="submit"],
      .plugin-edit .addon__action:first-of-type select[type="submit"], .addon__action:first-of-type
      .plugin-release input[type="submit"],
      .plugin-release .addon__action:first-of-type input[type="submit"], .addon__action:first-of-type
      .plugin-release select[type="submit"],
      .plugin-release .addon__action:first-of-type select[type="submit"], .addon__action:first-of-type .add-plugin .field-type-image-form input[type="file"], .add-plugin .field-type-image-form .addon__action:first-of-type input[type="file"], .addon__action:first-of-type
      .plugin-edit .field-type-image-form input[type="file"],
      .plugin-edit .field-type-image-form .addon__action:first-of-type input[type="file"], .addon__action:first-of-type
      .plugin-release .field-type-image-form input[type="file"],
      .plugin-release .field-type-image-form .addon__action:first-of-type input[type="file"], .addon__action:first-of-type .add-plugin .field-type-image-form button, .add-plugin .field-type-image-form .addon__action:first-of-type button, .addon__action:first-of-type
      .plugin-edit .field-type-image-form button,
      .plugin-edit .field-type-image-form .addon__action:first-of-type button, .addon__action:first-of-type
      .plugin-release .field-type-image-form button,
      .plugin-release .field-type-image-form .addon__action:first-of-type button, .addon__action:first-of-type .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], .add-plugin .field-type-image-form .addon__action:first-of-type input[name="field_addon_logo_und_0_remove_button"], .addon__action:first-of-type
      .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
      .plugin-edit .field-type-image-form .addon__action:first-of-type input[name="field_addon_logo_und_0_remove_button"], .addon__action:first-of-type
      .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
      .plugin-release .field-type-image-form .addon__action:first-of-type input[name="field_addon_logo_und_0_remove_button"], .addon__action:first-of-type #editor-builder #draggable-plugins .all-elements-option a, #editor-builder #draggable-plugins .all-elements-option .addon__action:first-of-type a, .addon__action:first-of-type
      #editor-builder .languages .all-elements-option a,
      #editor-builder .languages .all-elements-option .addon__action:first-of-type a, .addon__action:first-of-type .login .form-control[type="submit"], .login .addon__action:first-of-type .form-control[type="submit"], .addon__action:first-of-type
      .register .form-control[type="submit"],
      .register .addon__action:first-of-type .form-control[type="submit"], .addon__action:first-of-type
      .info-page .form-control[type="submit"],
      .info-page .addon__action:first-of-type .form-control[type="submit"], .addon__action:first-of-type .most-popular__wrapper .add_to_basket, .most-popular__wrapper .addon__action:first-of-type .add_to_basket, .addon__action:first-of-type .add-ons-list .views-row .add_to_basket, .add-ons-list .views-row .addon__action:first-of-type .add_to_basket, .addon__action:first-of-type
      .add-ons-list .views-row .check-details,
      .add-ons-list .views-row .addon__action:first-of-type .check-details, .addon__action:first-of-type
      .add-ons-list .views-row .remove_from_basket,
      .add-ons-list .views-row .addon__action:first-of-type .remove_from_basket, .addon__action:first-of-type
      .add-ons-list .views-row .required,
      .add-ons-list .views-row .addon__action:first-of-type .required, .addon__action:first-of-type .user-edit input[type="submit"], .user-edit .addon__action:first-of-type input[type="submit"], .addon__action:first-of-type
      .user-edit select[type="submit"],
      .user-edit .addon__action:first-of-type select[type="submit"] {
        float: right;
        margin-right: 1rem; } }
    @media (min-width: 768px) {
      .addon__action:last-of-type .btn, .addon__action:last-of-type #cksource_repo_search_forums_form .form-submit, #cksource_repo_search_forums_form .addon__action:last-of-type .form-submit, .addon__action:last-of-type #forum-sort .form-submit, #forum-sort .addon__action:last-of-type .form-submit, .addon__action:last-of-type .add-plugin input[type="submit"], .add-plugin .addon__action:last-of-type input[type="submit"], .addon__action:last-of-type
      .add-plugin select[type="submit"],
      .add-plugin .addon__action:last-of-type select[type="submit"], .addon__action:last-of-type
      .plugin-edit input[type="submit"],
      .plugin-edit .addon__action:last-of-type input[type="submit"], .addon__action:last-of-type
      .plugin-edit select[type="submit"],
      .plugin-edit .addon__action:last-of-type select[type="submit"], .addon__action:last-of-type
      .plugin-release input[type="submit"],
      .plugin-release .addon__action:last-of-type input[type="submit"], .addon__action:last-of-type
      .plugin-release select[type="submit"],
      .plugin-release .addon__action:last-of-type select[type="submit"], .addon__action:last-of-type .add-plugin .field-type-image-form input[type="file"], .add-plugin .field-type-image-form .addon__action:last-of-type input[type="file"], .addon__action:last-of-type
      .plugin-edit .field-type-image-form input[type="file"],
      .plugin-edit .field-type-image-form .addon__action:last-of-type input[type="file"], .addon__action:last-of-type
      .plugin-release .field-type-image-form input[type="file"],
      .plugin-release .field-type-image-form .addon__action:last-of-type input[type="file"], .addon__action:last-of-type .add-plugin .field-type-image-form button, .add-plugin .field-type-image-form .addon__action:last-of-type button, .addon__action:last-of-type
      .plugin-edit .field-type-image-form button,
      .plugin-edit .field-type-image-form .addon__action:last-of-type button, .addon__action:last-of-type
      .plugin-release .field-type-image-form button,
      .plugin-release .field-type-image-form .addon__action:last-of-type button, .addon__action:last-of-type .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], .add-plugin .field-type-image-form .addon__action:last-of-type input[name="field_addon_logo_und_0_remove_button"], .addon__action:last-of-type
      .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
      .plugin-edit .field-type-image-form .addon__action:last-of-type input[name="field_addon_logo_und_0_remove_button"], .addon__action:last-of-type
      .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
      .plugin-release .field-type-image-form .addon__action:last-of-type input[name="field_addon_logo_und_0_remove_button"], .addon__action:last-of-type #editor-builder #draggable-plugins .all-elements-option a, #editor-builder #draggable-plugins .all-elements-option .addon__action:last-of-type a, .addon__action:last-of-type
      #editor-builder .languages .all-elements-option a,
      #editor-builder .languages .all-elements-option .addon__action:last-of-type a, .addon__action:last-of-type .login .form-control[type="submit"], .login .addon__action:last-of-type .form-control[type="submit"], .addon__action:last-of-type
      .register .form-control[type="submit"],
      .register .addon__action:last-of-type .form-control[type="submit"], .addon__action:last-of-type
      .info-page .form-control[type="submit"],
      .info-page .addon__action:last-of-type .form-control[type="submit"], .addon__action:last-of-type .most-popular__wrapper .add_to_basket, .most-popular__wrapper .addon__action:last-of-type .add_to_basket, .addon__action:last-of-type .add-ons-list .views-row .add_to_basket, .add-ons-list .views-row .addon__action:last-of-type .add_to_basket, .addon__action:last-of-type
      .add-ons-list .views-row .check-details,
      .add-ons-list .views-row .addon__action:last-of-type .check-details, .addon__action:last-of-type
      .add-ons-list .views-row .remove_from_basket,
      .add-ons-list .views-row .addon__action:last-of-type .remove_from_basket, .addon__action:last-of-type
      .add-ons-list .views-row .required,
      .add-ons-list .views-row .addon__action:last-of-type .required, .addon__action:last-of-type .user-edit input[type="submit"], .user-edit .addon__action:last-of-type input[type="submit"], .addon__action:last-of-type
      .user-edit select[type="submit"],
      .user-edit .addon__action:last-of-type select[type="submit"] {
        margin-left: 1rem; } }
  .addon__releases-wrapper {
    display: inline-block;
    width: 100%; }
    .addon__releases-wrapper h3 {
      font-size: 1.9rem;
      font-weight: 400; }
  .addon__releases {
    display: inline-block;
    width: 100%;
    overflow-x: auto;
    font-size: 1.5rem; }
    .addon__releases table {
      width: 100%;
      border-spacing: 0;
      border-collapse: separate;
      font-size: 1.3rem; }
      .addon__releases table thead {
        color: rgba(64, 81, 102, 0.96);
        background-color: white; }
        .addon__releases table thead tr th {
          text-align: center; }
        .addon__releases table thead tr:first-of-type th:first-of-type {
          text-align: left;
          border-bottom: 1px solid rgba(0, 0, 0, 0.15); }
        .addon__releases table thead tr:last-of-type th {
          border-bottom: 1px solid rgba(0, 0, 0, 0.15); }
      .addon__releases table tbody {
        color: rgba(64, 81, 102, 0.75); }
      .addon__releases table tbody tr:nth-child(4n), .addon__releases table tbody tr:nth-child(4n+3) {
        background-color: white; }
      .addon__releases table th {
        font-weight: 400;
        padding: 8px; }
      .addon__releases table .release-note {
        display: none; }
      .addon__releases table tr.release-note .collapse {
        padding: 8px; }
      .addon__releases table tr:not(.release-note) td {
        padding: 8px;
        text-align: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15); }
        .addon__releases table tr:not(.release-note) td p {
          margin: 0; }
          .addon__releases table tr:not(.release-note) td p a {
            display: inline-block; }
            .addon__releases table tr:not(.release-note) td p a:first-of-type {
              margin-right: 5px; }
        .addon__releases table tr:not(.release-note) td .yes,
        .addon__releases table tr:not(.release-note) td .no {
          text-indent: -9999px; }
        .addon__releases table tr:not(.release-note) td:first-of-type {
          white-space: nowrap;
          text-align: left; }
    .addon__releases .yes,
    .addon__releases .no,
    .addon__releases .no-data {
      display: inline-block;
      vertical-align: middle;
      vertical-align: middle; }
  .addon__support-frame {
    padding: 1.25rem;
    margin: 1.25rem 0;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px; }
    .addon__support-frame p {
      font-size: 1.6rem;
      margin: 1.25rem; }

.add-plugin,
.plugin-edit,
.plugin-release {
  display: inline-block;
  width: 100%;
  padding: 2.5rem; }
  .add-plugin__form,
  .plugin-edit__form,
  .plugin-release__form {
    background-color: rgba(0, 0, 0, 0.03); }
  .add-plugin input,
  .add-plugin select,
  .plugin-edit input,
  .plugin-edit select,
  .plugin-release input,
  .plugin-release select {
    float: left;
    width: 100%;
    height: 100%;
    background-color: white;
    font-size: 1.3rem;
    padding: 6px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px; }
    @media (min-width: 768px) {
      .add-plugin input,
      .add-plugin select,
      .plugin-edit input,
      .plugin-edit select,
      .plugin-release input,
      .plugin-release select {
        font-size: 1.6rem;
        padding: 8px; } }
    @media (min-width: 1280px) {
      .add-plugin input,
      .add-plugin select,
      .plugin-edit input,
      .plugin-edit select,
      .plugin-release input,
      .plugin-release select {
        float: left;
        width: 50%;
        margin-bottom: 2.5rem; } }
  .add-plugin #edit-actions,
  .plugin-edit #edit-actions,
  .plugin-release #edit-actions {
    display: inline-block;
    width: 100%;
    padding-top: 2.5rem;
    text-align: center; }
    .add-plugin #edit-actions.left,
    .plugin-edit #edit-actions.left,
    .plugin-release #edit-actions.left {
      text-align: left; }
    .add-plugin #edit-actions.half,
    .plugin-edit #edit-actions.half,
    .plugin-release #edit-actions.half {
      padding-right: 50%; }
    .add-plugin #edit-actions.right,
    .plugin-edit #edit-actions.right,
    .plugin-release #edit-actions.right {
      text-align: right; }
    .add-plugin #edit-actions input[type="submit"],
    .plugin-edit #edit-actions input[type="submit"],
    .plugin-release #edit-actions input[type="submit"] {
      float: none;
      width: 17.5rem; }
    .add-plugin #edit-actions .form-submit + .form-submit,
    .plugin-edit #edit-actions .form-submit + .form-submit,
    .plugin-release #edit-actions .form-submit + .form-submit {
      margin-left: 2.5rem; }
  .add-plugin textarea + .description,
  .plugin-edit textarea + .description,
  .plugin-release textarea + .description {
    padding-left: 0; }
  .add-plugin .description,
  .plugin-edit .description,
  .plugin-release .description {
    float: left;
    width: 100%;
    margin-bottom: 2.5rem;
    font-size: 1.3rem; }
    @media (min-width: 768px) {
      .add-plugin .description,
      .plugin-edit .description,
      .plugin-release .description {
        font-size: 1.6rem; } }
    @media (min-width: 1280px) {
      .add-plugin .description,
      .plugin-edit .description,
      .plugin-release .description {
        width: 50%;
        padding: 0 15px 0 30px; } }
  .add-plugin .field-type-text-with-summary input,
  .add-plugin .field-type-text-with-summary .description,
  .plugin-edit .field-type-text-with-summary input,
  .plugin-edit .field-type-text-with-summary .description,
  .plugin-release .field-type-text-with-summary input,
  .plugin-release .field-type-text-with-summary .description {
    width: 100%; }
  .add-plugin .form-block,
  .plugin-edit .form-block,
  .plugin-release .form-block {
    margin-bottom: 10px; }
    .add-plugin .form-block .big-label,
    .plugin-edit .form-block .big-label,
    .plugin-release .form-block .big-label {
      display: inline-block;
      width: 100%;
      font-size: 2.1rem;
      font-weight: 300; }
    .add-plugin .form-block__hint,
    .plugin-edit .form-block__hint,
    .plugin-release .form-block__hint {
      font-size: 1.6rem;
      margin: 0 15px; }
  .add-plugin .photo-upload__placeholder-wrapper,
  .plugin-edit .photo-upload__placeholder-wrapper,
  .plugin-release .photo-upload__placeholder-wrapper {
    float: left; }
    .add-plugin .photo-upload__placeholder-wrapper .placeholder,
    .plugin-edit .photo-upload__placeholder-wrapper .placeholder,
    .plugin-release .photo-upload__placeholder-wrapper .placeholder {
      display: inline-block;
      width: 150px;
      height: 150px;
      background-image: url(../images/thumbs/ico-plugin.png);
      background-repeat: no-repeat;
      background-size: 100%; }
  .add-plugin .photo-upload__description-wrapper,
  .plugin-edit .photo-upload__description-wrapper,
  .plugin-release .photo-upload__description-wrapper {
    float: left;
    width: calc(100% - 150px); }
  .add-plugin .field-widget-text-textarea-with-summary textarea,
  .plugin-edit .field-widget-text-textarea-with-summary textarea,
  .plugin-release .field-widget-text-textarea-with-summary textarea {
    width: 100%; }
  .add-plugin .field-widget-text-textarea-with-summary .description,
  .plugin-edit .field-widget-text-textarea-with-summary .description,
  .plugin-release .field-widget-text-textarea-with-summary .description {
    width: 100%;
    padding: 0; }
  .add-plugin .form-checkboxes,
  .plugin-edit .form-checkboxes,
  .plugin-release .form-checkboxes {
    float: left;
    width: 100%; }
    @media (min-width: 1280px) {
      .add-plugin .form-checkboxes,
      .plugin-edit .form-checkboxes,
      .plugin-release .form-checkboxes {
        width: 50%; } }
    .add-plugin .form-checkboxes label,
    .add-plugin .form-checkboxes input[type="checkbox"],
    .plugin-edit .form-checkboxes label,
    .plugin-edit .form-checkboxes input[type="checkbox"],
    .plugin-release .form-checkboxes label,
    .plugin-release .form-checkboxes input[type="checkbox"] {
      float: left;
      width: auto; }
    .add-plugin .form-checkboxes label,
    .plugin-edit .form-checkboxes label,
    .plugin-release .form-checkboxes label {
      font-size: 1.6rem;
      font-weight: 400;
      margin-top: 2px;
      margin-bottom: 0; }
    .add-plugin .form-checkboxes input[type="checkbox"],
    .plugin-edit .form-checkboxes input[type="checkbox"],
    .plugin-release .form-checkboxes input[type="checkbox"] {
      vertical-align: middle;
      font-size: 100%;
      line-height: normal;
      height: auto;
      margin-left: 20px;
      margin-top: 5px;
      margin-bottom: 0;
      margin-right: 5px; }
      .add-plugin .form-checkboxes input[type="checkbox"]:first-of-type,
      .plugin-edit .form-checkboxes input[type="checkbox"]:first-of-type,
      .plugin-release .form-checkboxes input[type="checkbox"]:first-of-type {
        margin-left: 0; }
      .add-plugin .form-checkboxes input[type="checkbox"]:last-of-type,
      .plugin-edit .form-checkboxes input[type="checkbox"]:last-of-type,
      .plugin-release .form-checkboxes input[type="checkbox"]:last-of-type {
        margin-left: 0;
        clear: left; }
  .add-plugin .field-type-image-form,
  .plugin-edit .field-type-image-form,
  .plugin-release .field-type-image-form {
    display: inline-block;
    width: 100%;
    font-size: 1.6rem; }
    .add-plugin .field-type-image-form input[type="file"],
    .plugin-edit .field-type-image-form input[type="file"],
    .plugin-release .field-type-image-form input[type="file"] {
      width: auto; }
    .add-plugin .field-type-image-form .fileinputs + input[type="submit"],
    .plugin-edit .field-type-image-form .fileinputs + input[type="submit"],
    .plugin-release .field-type-image-form .fileinputs + input[type="submit"] {
      display: none; }
    .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
    .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
    .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"] {
      margin-left: 2.5rem;
      width: auto;
      float: none; }
    .add-plugin .field-type-image-form .description,
    .plugin-edit .field-type-image-form .description,
    .plugin-release .field-type-image-form .description {
      padding: 0;
      margin-top: 10px; }
  .add-plugin a.tabledrag-handle,
  .plugin-edit a.tabledrag-handle,
  .plugin-release a.tabledrag-handle {
    cursor: move;
    float: left;
    height: 1.7em;
    margin-left: -1em;
    overflow: hidden;
    text-decoration: none; }
    .add-plugin a.tabledrag-handle .handle,
    .plugin-edit a.tabledrag-handle .handle,
    .plugin-release a.tabledrag-handle .handle {
      background: url(https://ckeditor.com/cke4/misc/draggable.png) no-repeat 6px 9px;
      height: 13px;
      margin: -0.4em 0.5em;
      padding: 0.42em 0.5em;
      width: 13px; }

.plugin-release .form-checkboxes input[type="checkbox"]:last-of-type {
  clear: none;
  margin-left: 20px; }

@media (min-width: 1280px) {
  .plugin-release .file-browser-button__wrapper {
    display: inline-block;
    float: left;
    clear: both;
    width: 50%;
    text-align: right; } }

.plugin-release input[type="file"] {
  margin-bottom: 1rem; }

.addon_download {
  display: none; }

.downloads-info {
  display: none; }

.side-banner {
  margin: 0 auto 2.5rem;
  color: white;
  text-align: center; }
  .side-banner:last-of-type {
    margin-bottom: 0; }
  .side-banner h2 {
    line-height: 1em;
    font-size: 3rem; }
  .side-banner p {
    font-size: 1.6rem;
    font-weight: 400;
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .side-banner a {
    display: block;
    width: 80%;
    margin: 0 auto;
    color: white;
    background: transparent;
    border-color: white; }
    .side-banner a:hover {
      background-color: white;
      color: rgba(64, 81, 102, 0.96); }
  .side-banner--finder {
    height: 400px;
    padding-top: 195px;
    background-image: url(../images/backgrounds/banner--finder.png);
    background-size: cover;
    background-repeat: no-repeat; }
    .side-banner--finder h2 {
      padding-top: 40px; }
  .side-banner--ac {
    height: 400px;
    padding-top: 205px;
    background-image: url(../images/backgrounds/banner--ac.png);
    background-size: cover;
    background-repeat: no-repeat; }
  .side-banner--ei {
    height: 400px;
    padding-top: 205px;
    background-image: url(../images/backgrounds/banner--ei.png);
    background-size: cover;
    background-repeat: no-repeat; }
    .side-banner--ei h2 {
      padding-top: 15px; }
    .side-banner--ei p {
      font-size: 1.4rem;
      margin: 1rem 0.7rem; }

@media (min-width: 1280px) {
  .section-container.builder, .builder.site-info__wrapper {
    padding-left: 1px;
    padding-right: 1px; } }

#editor-builder {
  position: relative;
  font-size: 16px;
  font-size: 1.6rem; }
  #editor-builder .btn, #editor-builder #cksource_repo_search_forums_form .form-submit, #cksource_repo_search_forums_form #editor-builder .form-submit, #editor-builder #forum-sort .form-submit, #forum-sort #editor-builder .form-submit, #editor-builder .add-plugin input[type="submit"], .add-plugin #editor-builder input[type="submit"], #editor-builder
  .add-plugin select[type="submit"],
  .add-plugin #editor-builder select[type="submit"], #editor-builder
  .plugin-edit input[type="submit"],
  .plugin-edit #editor-builder input[type="submit"], #editor-builder
  .plugin-edit select[type="submit"],
  .plugin-edit #editor-builder select[type="submit"], #editor-builder
  .plugin-release input[type="submit"],
  .plugin-release #editor-builder input[type="submit"], #editor-builder
  .plugin-release select[type="submit"],
  .plugin-release #editor-builder select[type="submit"], #editor-builder .add-plugin .field-type-image-form input[type="file"], .add-plugin .field-type-image-form #editor-builder input[type="file"], #editor-builder
  .plugin-edit .field-type-image-form input[type="file"],
  .plugin-edit .field-type-image-form #editor-builder input[type="file"], #editor-builder
  .plugin-release .field-type-image-form input[type="file"],
  .plugin-release .field-type-image-form #editor-builder input[type="file"], #editor-builder .add-plugin .field-type-image-form button, .add-plugin .field-type-image-form #editor-builder button, #editor-builder
  .plugin-edit .field-type-image-form button,
  .plugin-edit .field-type-image-form #editor-builder button, #editor-builder
  .plugin-release .field-type-image-form button,
  .plugin-release .field-type-image-form #editor-builder button, #editor-builder .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], .add-plugin .field-type-image-form #editor-builder input[name="field_addon_logo_und_0_remove_button"], #editor-builder
  .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
  .plugin-edit .field-type-image-form #editor-builder input[name="field_addon_logo_und_0_remove_button"], #editor-builder
  .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
  .plugin-release .field-type-image-form #editor-builder input[name="field_addon_logo_und_0_remove_button"], #editor-builder #draggable-plugins .all-elements-option a, #editor-builder .languages .all-elements-option a, #editor-builder .login .form-control[type="submit"], .login #editor-builder .form-control[type="submit"], #editor-builder
  .register .form-control[type="submit"],
  .register #editor-builder .form-control[type="submit"], #editor-builder
  .info-page .form-control[type="submit"],
  .info-page #editor-builder .form-control[type="submit"], #editor-builder .most-popular__wrapper .add_to_basket, .most-popular__wrapper #editor-builder .add_to_basket, #editor-builder .add-ons-list .views-row .add_to_basket, .add-ons-list .views-row #editor-builder .add_to_basket, #editor-builder
  .add-ons-list .views-row .check-details,
  .add-ons-list .views-row #editor-builder .check-details, #editor-builder
  .add-ons-list .views-row .remove_from_basket,
  .add-ons-list .views-row #editor-builder .remove_from_basket, #editor-builder
  .add-ons-list .views-row .required,
  .add-ons-list .views-row #editor-builder .required, #editor-builder .user-edit input[type="submit"], .user-edit #editor-builder input[type="submit"], #editor-builder
  .user-edit select[type="submit"],
  .user-edit #editor-builder select[type="submit"] {
    background-color: white; }
    #editor-builder .btn:active, #editor-builder #cksource_repo_search_forums_form .form-submit:active, #cksource_repo_search_forums_form #editor-builder .form-submit:active, #editor-builder #forum-sort .form-submit:active, #forum-sort #editor-builder .form-submit:active, #editor-builder .add-plugin input[type="submit"]:active, .add-plugin #editor-builder input[type="submit"]:active, #editor-builder
    .add-plugin select[type="submit"]:active,
    .add-plugin #editor-builder select[type="submit"]:active, #editor-builder
    .plugin-edit input[type="submit"]:active,
    .plugin-edit #editor-builder input[type="submit"]:active, #editor-builder
    .plugin-edit select[type="submit"]:active,
    .plugin-edit #editor-builder select[type="submit"]:active, #editor-builder
    .plugin-release input[type="submit"]:active,
    .plugin-release #editor-builder input[type="submit"]:active, #editor-builder
    .plugin-release select[type="submit"]:active,
    .plugin-release #editor-builder select[type="submit"]:active, #editor-builder .add-plugin .field-type-image-form input[type="file"]:active, .add-plugin .field-type-image-form #editor-builder input[type="file"]:active, #editor-builder
    .plugin-edit .field-type-image-form input[type="file"]:active,
    .plugin-edit .field-type-image-form #editor-builder input[type="file"]:active, #editor-builder
    .plugin-release .field-type-image-form input[type="file"]:active,
    .plugin-release .field-type-image-form #editor-builder input[type="file"]:active, #editor-builder .add-plugin .field-type-image-form button:active, .add-plugin .field-type-image-form #editor-builder button:active, #editor-builder
    .plugin-edit .field-type-image-form button:active,
    .plugin-edit .field-type-image-form #editor-builder button:active, #editor-builder
    .plugin-release .field-type-image-form button:active,
    .plugin-release .field-type-image-form #editor-builder button:active, #editor-builder .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:active, .add-plugin .field-type-image-form #editor-builder input[name="field_addon_logo_und_0_remove_button"]:active, #editor-builder
    .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:active,
    .plugin-edit .field-type-image-form #editor-builder input[name="field_addon_logo_und_0_remove_button"]:active, #editor-builder
    .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:active,
    .plugin-release .field-type-image-form #editor-builder input[name="field_addon_logo_und_0_remove_button"]:active, #editor-builder #draggable-plugins .all-elements-option a:active, #editor-builder .languages .all-elements-option a:active, #editor-builder .login .form-control[type="submit"]:active, .login #editor-builder .form-control[type="submit"]:active, #editor-builder
    .register .form-control[type="submit"]:active,
    .register #editor-builder .form-control[type="submit"]:active, #editor-builder
    .info-page .form-control[type="submit"]:active,
    .info-page #editor-builder .form-control[type="submit"]:active, #editor-builder .most-popular__wrapper .add_to_basket:active, .most-popular__wrapper #editor-builder .add_to_basket:active, #editor-builder .add-ons-list .views-row .add_to_basket:active, .add-ons-list .views-row #editor-builder .add_to_basket:active, #editor-builder
    .add-ons-list .views-row .check-details:active,
    .add-ons-list .views-row #editor-builder .check-details:active, #editor-builder
    .add-ons-list .views-row .remove_from_basket:active,
    .add-ons-list .views-row #editor-builder .remove_from_basket:active, #editor-builder
    .add-ons-list .views-row .required:active,
    .add-ons-list .views-row #editor-builder .required:active, #editor-builder .user-edit input[type="submit"]:active, .user-edit #editor-builder input[type="submit"]:active, #editor-builder
    .user-edit select[type="submit"]:active,
    .user-edit #editor-builder select[type="submit"]:active, #editor-builder .btn:hover, #editor-builder #cksource_repo_search_forums_form .form-submit:hover, #cksource_repo_search_forums_form #editor-builder .form-submit:hover, #editor-builder #forum-sort .form-submit:hover, #forum-sort #editor-builder .form-submit:hover, #editor-builder .add-plugin input[type="submit"]:hover, .add-plugin #editor-builder input[type="submit"]:hover, #editor-builder
    .add-plugin select[type="submit"]:hover,
    .add-plugin #editor-builder select[type="submit"]:hover, #editor-builder
    .plugin-edit input[type="submit"]:hover,
    .plugin-edit #editor-builder input[type="submit"]:hover, #editor-builder
    .plugin-edit select[type="submit"]:hover,
    .plugin-edit #editor-builder select[type="submit"]:hover, #editor-builder
    .plugin-release input[type="submit"]:hover,
    .plugin-release #editor-builder input[type="submit"]:hover, #editor-builder
    .plugin-release select[type="submit"]:hover,
    .plugin-release #editor-builder select[type="submit"]:hover, #editor-builder .add-plugin .field-type-image-form input[type="file"]:hover, .add-plugin .field-type-image-form #editor-builder input[type="file"]:hover, #editor-builder
    .plugin-edit .field-type-image-form input[type="file"]:hover,
    .plugin-edit .field-type-image-form #editor-builder input[type="file"]:hover, #editor-builder
    .plugin-release .field-type-image-form input[type="file"]:hover,
    .plugin-release .field-type-image-form #editor-builder input[type="file"]:hover, #editor-builder .add-plugin .field-type-image-form button:hover, .add-plugin .field-type-image-form #editor-builder button:hover, #editor-builder
    .plugin-edit .field-type-image-form button:hover,
    .plugin-edit .field-type-image-form #editor-builder button:hover, #editor-builder
    .plugin-release .field-type-image-form button:hover,
    .plugin-release .field-type-image-form #editor-builder button:hover, #editor-builder .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:hover, .add-plugin .field-type-image-form #editor-builder input[name="field_addon_logo_und_0_remove_button"]:hover, #editor-builder
    .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:hover,
    .plugin-edit .field-type-image-form #editor-builder input[name="field_addon_logo_und_0_remove_button"]:hover, #editor-builder
    .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:hover,
    .plugin-release .field-type-image-form #editor-builder input[name="field_addon_logo_und_0_remove_button"]:hover, #editor-builder #draggable-plugins .all-elements-option a:hover, #editor-builder .languages .all-elements-option a:hover, #editor-builder .login .form-control[type="submit"]:hover, .login #editor-builder .form-control[type="submit"]:hover, #editor-builder
    .register .form-control[type="submit"]:hover,
    .register #editor-builder .form-control[type="submit"]:hover, #editor-builder
    .info-page .form-control[type="submit"]:hover,
    .info-page #editor-builder .form-control[type="submit"]:hover, #editor-builder .most-popular__wrapper .add_to_basket:hover, .most-popular__wrapper #editor-builder .add_to_basket:hover, #editor-builder .add-ons-list .views-row .add_to_basket:hover, .add-ons-list .views-row #editor-builder .add_to_basket:hover, #editor-builder
    .add-ons-list .views-row .check-details:hover,
    .add-ons-list .views-row #editor-builder .check-details:hover, #editor-builder
    .add-ons-list .views-row .remove_from_basket:hover,
    .add-ons-list .views-row #editor-builder .remove_from_basket:hover, #editor-builder
    .add-ons-list .views-row .required:hover,
    .add-ons-list .views-row #editor-builder .required:hover, #editor-builder .user-edit input[type="submit"]:hover, .user-edit #editor-builder input[type="submit"]:hover, #editor-builder
    .user-edit select[type="submit"]:hover,
    .user-edit #editor-builder select[type="submit"]:hover, #editor-builder .btn:focus, #editor-builder #cksource_repo_search_forums_form .form-submit:focus, #cksource_repo_search_forums_form #editor-builder .form-submit:focus, #editor-builder #forum-sort .form-submit:focus, #forum-sort #editor-builder .form-submit:focus, #editor-builder .add-plugin input[type="submit"]:focus, .add-plugin #editor-builder input[type="submit"]:focus, #editor-builder
    .add-plugin select[type="submit"]:focus,
    .add-plugin #editor-builder select[type="submit"]:focus, #editor-builder
    .plugin-edit input[type="submit"]:focus,
    .plugin-edit #editor-builder input[type="submit"]:focus, #editor-builder
    .plugin-edit select[type="submit"]:focus,
    .plugin-edit #editor-builder select[type="submit"]:focus, #editor-builder
    .plugin-release input[type="submit"]:focus,
    .plugin-release #editor-builder input[type="submit"]:focus, #editor-builder
    .plugin-release select[type="submit"]:focus,
    .plugin-release #editor-builder select[type="submit"]:focus, #editor-builder .add-plugin .field-type-image-form input[type="file"]:focus, .add-plugin .field-type-image-form #editor-builder input[type="file"]:focus, #editor-builder
    .plugin-edit .field-type-image-form input[type="file"]:focus,
    .plugin-edit .field-type-image-form #editor-builder input[type="file"]:focus, #editor-builder
    .plugin-release .field-type-image-form input[type="file"]:focus,
    .plugin-release .field-type-image-form #editor-builder input[type="file"]:focus, #editor-builder .add-plugin .field-type-image-form button:focus, .add-plugin .field-type-image-form #editor-builder button:focus, #editor-builder
    .plugin-edit .field-type-image-form button:focus,
    .plugin-edit .field-type-image-form #editor-builder button:focus, #editor-builder
    .plugin-release .field-type-image-form button:focus,
    .plugin-release .field-type-image-form #editor-builder button:focus, #editor-builder .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:focus, .add-plugin .field-type-image-form #editor-builder input[name="field_addon_logo_und_0_remove_button"]:focus, #editor-builder
    .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:focus,
    .plugin-edit .field-type-image-form #editor-builder input[name="field_addon_logo_und_0_remove_button"]:focus, #editor-builder
    .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:focus,
    .plugin-release .field-type-image-form #editor-builder input[name="field_addon_logo_und_0_remove_button"]:focus, #editor-builder #draggable-plugins .all-elements-option a:focus, #editor-builder .languages .all-elements-option a:focus, #editor-builder .login .form-control[type="submit"]:focus, .login #editor-builder .form-control[type="submit"]:focus, #editor-builder
    .register .form-control[type="submit"]:focus,
    .register #editor-builder .form-control[type="submit"]:focus, #editor-builder
    .info-page .form-control[type="submit"]:focus,
    .info-page #editor-builder .form-control[type="submit"]:focus, #editor-builder .most-popular__wrapper .add_to_basket:focus, .most-popular__wrapper #editor-builder .add_to_basket:focus, #editor-builder .add-ons-list .views-row .add_to_basket:focus, .add-ons-list .views-row #editor-builder .add_to_basket:focus, #editor-builder
    .add-ons-list .views-row .check-details:focus,
    .add-ons-list .views-row #editor-builder .check-details:focus, #editor-builder
    .add-ons-list .views-row .remove_from_basket:focus,
    .add-ons-list .views-row #editor-builder .remove_from_basket:focus, #editor-builder
    .add-ons-list .views-row .required:focus,
    .add-ons-list .views-row #editor-builder .required:focus, #editor-builder .user-edit input[type="submit"]:focus, .user-edit #editor-builder input[type="submit"]:focus, #editor-builder
    .user-edit select[type="submit"]:focus,
    .user-edit #editor-builder select[type="submit"]:focus {
      color: white;
      background-color: #30a3f1; }
  #editor-builder input[type="file"] {
    display: none; }
  #editor-builder input[type="radio"] {
    left: 2px; }
  #editor-builder h3 + p {
    margin-bottom: 25px; }
  #editor-builder .all-elements-option {
    margin-left: 0 !important;
    background: #f7f7f7;
    border-top: 1px solid #ebebeb;
    bottom: 0;
    left: 0;
    position: absolute;
    height: 90px;
    display: inline-block;
    width: 100%; }
  #editor-builder .presets {
    margin-bottom: 5rem;
    border: none; }
    #editor-builder .presets #presets-opts {
      list-style: none;
      padding: 0;
      margin: 2.5rem auto 0;
      text-align: center; }
      @media (min-width: 1280px) {
        #editor-builder .presets #presets-opts {
          max-width: 880px; } }
      #editor-builder .presets #presets-opts li {
        display: inline-block;
        width: 230px;
        margin-right: 20px; }
      #editor-builder .presets #presets-opts img {
        margin-bottom: 10px; }
      #editor-builder .presets #presets-opts label {
        font-size: 1.5rem;
        position: relative;
        max-width: 100%;
        margin: 0 auto;
        text-align: center; }
        #editor-builder .presets #presets-opts label .presets-opts__thumb {
          border: 1px solid rgba(0, 0, 0, 0.15);
          margin-bottom: 15px;
          width: 228px;
          height: 109px;
          background-repeat: no-repeat; }
          #editor-builder .presets #presets-opts label .presets-opts__thumb--basic {
            background-image: url(../images/backgrounds/package-basic.png); }
          #editor-builder .presets #presets-opts label .presets-opts__thumb--standard {
            background-image: url(../images/backgrounds/package-standard.png); }
          #editor-builder .presets #presets-opts label .presets-opts__thumb--full {
            background-image: url(../images/backgrounds/package-full.png); }
          #editor-builder .presets #presets-opts label .presets-opts__thumb--selected {
            border: 2px solid #30a3f1; }
    #editor-builder .presets .compare-presets {
      float: right;
      margin-right: 15px; }
    #editor-builder .presets #presets_table {
      display: none; }
  #editor-builder .preset-description {
    display: none;
    margin: 2.5rem auto;
    text-align: center; }
    @media (min-width: 1280px) {
      #editor-builder .preset-description {
        max-width: 880px; } }
    #editor-builder .preset-description--active {
      display: block; }
  #editor-builder #draggable-plugins,
  #editor-builder .languages {
    display: inline-block;
    width: 100%;
    padding: 0;
    overflow: hidden;
    position: relative; }
    @media (min-width: 1280px) {
      #editor-builder #draggable-plugins,
      #editor-builder .languages {
        display: block;
        width: 945px;
        margin: 0 auto; } }
    #editor-builder #draggable-plugins .languages__actions,
    #editor-builder #draggable-plugins .plugins-actions,
    #editor-builder .languages .languages__actions,
    #editor-builder .languages .plugins-actions {
      display: inline-block;
      width: 100%;
      position: relative;
      height: 65px; }
      @media (min-width: 1280px) {
        #editor-builder #draggable-plugins .languages__actions,
        #editor-builder #draggable-plugins .plugins-actions,
        #editor-builder .languages .languages__actions,
        #editor-builder .languages .plugins-actions {
          display: block;
          float: left;
          width: 65px;
          height: 460px; } }
    #editor-builder #draggable-plugins .additional-plugins,
    #editor-builder #draggable-plugins .plugins-presets,
    #editor-builder #draggable-plugins .selection,
    #editor-builder .languages .additional-plugins,
    #editor-builder .languages .plugins-presets,
    #editor-builder .languages .selection {
      display: inline-block;
      width: 100%;
      clear: none;
      border-left: 1px solid rgba(0, 0, 0, 0.15);
      border-top: none;
      border-right: none;
      border-bottom: none;
      padding: 0;
      margin: 0;
      background: transparent;
      height: 475px;
      position: relative; }
      @media (min-width: 1280px) {
        #editor-builder #draggable-plugins .additional-plugins,
        #editor-builder #draggable-plugins .plugins-presets,
        #editor-builder #draggable-plugins .selection,
        #editor-builder .languages .additional-plugins,
        #editor-builder .languages .plugins-presets,
        #editor-builder .languages .selection {
          display: block;
          float: left;
          width: 440px;
          height: 471px; } }
      #editor-builder #draggable-plugins .additional-plugins h4,
      #editor-builder #draggable-plugins .additional-plugins label.big,
      #editor-builder #draggable-plugins .plugins-presets h4,
      #editor-builder #draggable-plugins .plugins-presets label.big,
      #editor-builder #draggable-plugins .selection h4,
      #editor-builder #draggable-plugins .selection label.big,
      #editor-builder .languages .additional-plugins h4,
      #editor-builder .languages .additional-plugins label.big,
      #editor-builder .languages .plugins-presets h4,
      #editor-builder .languages .plugins-presets label.big,
      #editor-builder .languages .selection h4,
      #editor-builder .languages .selection label.big {
        font-size: 1.7rem;
        margin: 0 0 0 -1px;
        width: calc(100% + 2px);
        background: white none;
        padding: 0 0 5px 5px;
        border-bottom: 1px solid #ebebeb;
        color: #4b4b4b;
        font-weight: 400; }
      #editor-builder #draggable-plugins .additional-plugins #languages-to-chose,
      #editor-builder #draggable-plugins .additional-plugins #chosen-languages,
      #editor-builder #draggable-plugins .plugins-presets #languages-to-chose,
      #editor-builder #draggable-plugins .plugins-presets #chosen-languages,
      #editor-builder #draggable-plugins .selection #languages-to-chose,
      #editor-builder #draggable-plugins .selection #chosen-languages,
      #editor-builder .languages .additional-plugins #languages-to-chose,
      #editor-builder .languages .additional-plugins #chosen-languages,
      #editor-builder .languages .plugins-presets #languages-to-chose,
      #editor-builder .languages .plugins-presets #chosen-languages,
      #editor-builder .languages .selection #languages-to-chose,
      #editor-builder .languages .selection #chosen-languages {
        display: inline-block;
        width: 100%;
        border: none;
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        border-right: 1px solid rgba(0, 0, 0, 0.15);
        height: 350px;
        padding: 0; }
        @media (min-width: 1280px) {
          #editor-builder #draggable-plugins .additional-plugins #languages-to-chose,
          #editor-builder #draggable-plugins .additional-plugins #chosen-languages,
          #editor-builder #draggable-plugins .plugins-presets #languages-to-chose,
          #editor-builder #draggable-plugins .plugins-presets #chosen-languages,
          #editor-builder #draggable-plugins .selection #languages-to-chose,
          #editor-builder #draggable-plugins .selection #chosen-languages,
          #editor-builder .languages .additional-plugins #languages-to-chose,
          #editor-builder .languages .additional-plugins #chosen-languages,
          #editor-builder .languages .plugins-presets #languages-to-chose,
          #editor-builder .languages .plugins-presets #chosen-languages,
          #editor-builder .languages .selection #languages-to-chose,
          #editor-builder .languages .selection #chosen-languages {
            height: 350px; } }
      #editor-builder #draggable-plugins .additional-plugins .box,
      #editor-builder #draggable-plugins .plugins-presets .box,
      #editor-builder #draggable-plugins .selection .box,
      #editor-builder .languages .additional-plugins .box,
      #editor-builder .languages .plugins-presets .box,
      #editor-builder .languages .selection .box {
        display: inline-block;
        width: 100%;
        list-style: none;
        overflow: auto;
        border-right: 1px solid rgba(0, 0, 0, 0.15);
        height: 350px;
        padding: 0; }
        @media (min-width: 1280px) {
          #editor-builder #draggable-plugins .additional-plugins .box,
          #editor-builder #draggable-plugins .plugins-presets .box,
          #editor-builder #draggable-plugins .selection .box,
          #editor-builder .languages .additional-plugins .box,
          #editor-builder .languages .plugins-presets .box,
          #editor-builder .languages .selection .box {
            height: 350px; } }
        #editor-builder #draggable-plugins .additional-plugins .box > li,
        #editor-builder #draggable-plugins .plugins-presets .box > li,
        #editor-builder #draggable-plugins .selection .box > li,
        #editor-builder .languages .additional-plugins .box > li,
        #editor-builder .languages .plugins-presets .box > li,
        #editor-builder .languages .selection .box > li {
          border-bottom: 1px solid #eeeeee; }
        #editor-builder #draggable-plugins .additional-plugins .box.active,
        #editor-builder #draggable-plugins .plugins-presets .box.active,
        #editor-builder #draggable-plugins .selection .box.active,
        #editor-builder .languages .additional-plugins .box.active,
        #editor-builder .languages .plugins-presets .box.active,
        #editor-builder .languages .selection .box.active {
          font-weight: inherit; }
      #editor-builder #draggable-plugins .additional-plugins option,
      #editor-builder #draggable-plugins .plugins-presets option,
      #editor-builder #draggable-plugins .selection option,
      #editor-builder .languages .additional-plugins option,
      #editor-builder .languages .plugins-presets option,
      #editor-builder .languages .selection option {
        font-size: 1.5rem;
        color: rgba(64, 81, 102, 0.75);
        line-height: 1.6;
        padding: 3px 10px; }
      #editor-builder #draggable-plugins .additional-plugins input[type="checkbox"],
      #editor-builder #draggable-plugins .plugins-presets input[type="checkbox"],
      #editor-builder #draggable-plugins .selection input[type="checkbox"],
      #editor-builder .languages .additional-plugins input[type="checkbox"],
      #editor-builder .languages .plugins-presets input[type="checkbox"],
      #editor-builder .languages .selection input[type="checkbox"] {
        cursor: pointer; }
      #editor-builder #draggable-plugins .additional-plugins .all-elements-option,
      #editor-builder #draggable-plugins .plugins-presets .all-elements-option,
      #editor-builder #draggable-plugins .selection .all-elements-option,
      #editor-builder .languages .additional-plugins .all-elements-option,
      #editor-builder .languages .plugins-presets .all-elements-option,
      #editor-builder .languages .selection .all-elements-option {
        border-right: 1px solid rgba(0, 0, 0, 0.15); }
      #editor-builder #draggable-plugins .additional-plugins .ui-resizable-s,
      #editor-builder #draggable-plugins .plugins-presets .ui-resizable-s,
      #editor-builder #draggable-plugins .selection .ui-resizable-s,
      #editor-builder .languages .additional-plugins .ui-resizable-s,
      #editor-builder .languages .plugins-presets .ui-resizable-s,
      #editor-builder .languages .selection .ui-resizable-s {
        display: none;
        height: 16px;
        border-radius: 0;
        width: calc(100% + 2px);
        bottom: 0;
        left: 0;
        margin-left: -1px;
        margin-bottom: -1px;
        background-color: rgba(48, 163, 241, 0.8);
        background-image: url(../images/resize-vertical.png);
        background-position: center;
        background-repeat: no-repeat;
        border: 1px solid rgba(0, 0, 0, 0.15);
        z-index: 999 !important; }
        @media (min-width: 1680px) {
          #editor-builder #draggable-plugins .additional-plugins .ui-resizable-s,
          #editor-builder #draggable-plugins .plugins-presets .ui-resizable-s,
          #editor-builder #draggable-plugins .selection .ui-resizable-s,
          #editor-builder .languages .additional-plugins .ui-resizable-s,
          #editor-builder .languages .plugins-presets .ui-resizable-s,
          #editor-builder .languages .selection .ui-resizable-s {
            display: inline-block; } }
      #editor-builder #draggable-plugins .additional-plugins .ui-draggable-dragging,
      #editor-builder #draggable-plugins .plugins-presets .ui-draggable-dragging,
      #editor-builder #draggable-plugins .selection .ui-draggable-dragging,
      #editor-builder .languages .additional-plugins .ui-draggable-dragging,
      #editor-builder .languages .plugins-presets .ui-draggable-dragging,
      #editor-builder .languages .selection .ui-draggable-dragging {
        border: 1px solid #bbb !important;
        z-index: 100;
        padding: 8px 10px 8px 10px;
        width: auto;
        display: inline-block;
        max-width: 500px;
        min-width: 300px;
        background-color: white; }
      #editor-builder #draggable-plugins .additional-plugins .ui-droppable-active,
      #editor-builder #draggable-plugins .plugins-presets .ui-droppable-active,
      #editor-builder #draggable-plugins .selection .ui-droppable-active,
      #editor-builder .languages .additional-plugins .ui-droppable-active,
      #editor-builder .languages .plugins-presets .ui-droppable-active,
      #editor-builder .languages .selection .ui-droppable-active {
        font-weight: inherit; }
    #editor-builder #draggable-plugins .plugins-presets ol,
    #editor-builder #draggable-plugins .additional-plugins ol,
    #editor-builder .languages .plugins-presets ol,
    #editor-builder .languages .additional-plugins ol {
      margin: 0; }
      #editor-builder #draggable-plugins .plugins-presets ol li,
      #editor-builder #draggable-plugins .additional-plugins ol li,
      #editor-builder .languages .plugins-presets ol li,
      #editor-builder .languages .additional-plugins ol li {
        position: relative;
        cursor: pointer;
        line-height: 2em;
        padding-left: 10px;
        padding-top: 2px;
        padding-bottom: 0; }
        #editor-builder #draggable-plugins .plugins-presets ol li .machine_name,
        #editor-builder #draggable-plugins .additional-plugins ol li .machine_name,
        #editor-builder .languages .plugins-presets ol li .machine_name,
        #editor-builder .languages .additional-plugins ol li .machine_name {
          display: none; }
        #editor-builder #draggable-plugins .plugins-presets ol li > strong,
        #editor-builder #draggable-plugins .additional-plugins ol li > strong,
        #editor-builder .languages .plugins-presets ol li > strong,
        #editor-builder .languages .additional-plugins ol li > strong {
          margin-left: 15px;
          font-weight: 400; }
          #editor-builder #draggable-plugins .plugins-presets ol li > strong a,
          #editor-builder #draggable-plugins .additional-plugins ol li > strong a,
          #editor-builder .languages .plugins-presets ol li > strong a,
          #editor-builder .languages .additional-plugins ol li > strong a {
            color: rgba(64, 81, 102, 0.75);
            font-size: 1.4rem;
            line-height: 1.5;
            padding-right: 15px; }
        #editor-builder #draggable-plugins .plugins-presets ol li .plugin-ico,
        #editor-builder #draggable-plugins .additional-plugins ol li .plugin-ico,
        #editor-builder .languages .plugins-presets ol li .plugin-ico,
        #editor-builder .languages .additional-plugins ol li .plugin-ico {
          margin-top: 3px;
          display: inline-block; }
          #editor-builder #draggable-plugins .plugins-presets ol li .plugin-ico--preset:before,
          #editor-builder #draggable-plugins .additional-plugins ol li .plugin-ico--preset:before,
          #editor-builder .languages .plugins-presets ol li .plugin-ico--preset:before,
          #editor-builder .languages .additional-plugins ol li .plugin-ico--preset:before {
            content: '';
            display: inline-block; }
          #editor-builder #draggable-plugins .plugins-presets ol li .plugin-ico--selected:before,
          #editor-builder #draggable-plugins .additional-plugins ol li .plugin-ico--selected:before,
          #editor-builder .languages .plugins-presets ol li .plugin-ico--selected:before,
          #editor-builder .languages .additional-plugins ol li .plugin-ico--selected:before {
            content: '';
            display: inline-block; }
          #editor-builder #draggable-plugins .plugins-presets ol li .plugin-ico--required:before,
          #editor-builder #draggable-plugins .additional-plugins ol li .plugin-ico--required:before,
          #editor-builder .languages .plugins-presets ol li .plugin-ico--required:before,
          #editor-builder .languages .additional-plugins ol li .plugin-ico--required:before {
            content: '';
            display: inline-block; }
          #editor-builder #draggable-plugins .plugins-presets ol li .plugin-ico--optional:before,
          #editor-builder #draggable-plugins .additional-plugins ol li .plugin-ico--optional:before,
          #editor-builder .languages .plugins-presets ol li .plugin-ico--optional:before,
          #editor-builder .languages .additional-plugins ol li .plugin-ico--optional:before {
            content: '';
            display: inline-block; }
        #editor-builder #draggable-plugins .plugins-presets ol li .required-info p,
        #editor-builder #draggable-plugins .plugins-presets ol li .plugin-info p,
        #editor-builder #draggable-plugins .additional-plugins ol li .required-info p,
        #editor-builder #draggable-plugins .additional-plugins ol li .plugin-info p,
        #editor-builder .languages .plugins-presets ol li .required-info p,
        #editor-builder .languages .plugins-presets ol li .plugin-info p,
        #editor-builder .languages .additional-plugins ol li .required-info p,
        #editor-builder .languages .additional-plugins ol li .plugin-info p {
          font-size: 1.4rem;
          line-height: 2.2em;
          margin-top: 0;
          margin-bottom: 10px; }
        #editor-builder #draggable-plugins .plugins-presets ol li .required-info a,
        #editor-builder #draggable-plugins .plugins-presets ol li .plugin-info a,
        #editor-builder #draggable-plugins .additional-plugins ol li .required-info a,
        #editor-builder #draggable-plugins .additional-plugins ol li .plugin-info a,
        #editor-builder .languages .plugins-presets ol li .required-info a,
        #editor-builder .languages .plugins-presets ol li .plugin-info a,
        #editor-builder .languages .additional-plugins ol li .required-info a,
        #editor-builder .languages .additional-plugins ol li .plugin-info a {
          padding-right: 14px; }
          #editor-builder #draggable-plugins .plugins-presets ol li .required-info a:hover,
          #editor-builder #draggable-plugins .plugins-presets ol li .plugin-info a:hover,
          #editor-builder #draggable-plugins .additional-plugins ol li .required-info a:hover,
          #editor-builder #draggable-plugins .additional-plugins ol li .plugin-info a:hover,
          #editor-builder .languages .plugins-presets ol li .required-info a:hover,
          #editor-builder .languages .plugins-presets ol li .plugin-info a:hover,
          #editor-builder .languages .additional-plugins ol li .required-info a:hover,
          #editor-builder .languages .additional-plugins ol li .plugin-info a:hover {
            background: url("https://c.cksource.com/a/5/img/sprites.png") no-repeat 100% -3880px; }
        #editor-builder #draggable-plugins .plugins-presets ol li .required-info .last-updated,
        #editor-builder #draggable-plugins .plugins-presets ol li .plugin-info .last-updated,
        #editor-builder #draggable-plugins .additional-plugins ol li .required-info .last-updated,
        #editor-builder #draggable-plugins .additional-plugins ol li .plugin-info .last-updated,
        #editor-builder .languages .plugins-presets ol li .required-info .last-updated,
        #editor-builder .languages .plugins-presets ol li .plugin-info .last-updated,
        #editor-builder .languages .additional-plugins ol li .required-info .last-updated,
        #editor-builder .languages .additional-plugins ol li .plugin-info .last-updated {
          font-size: 0.85em;
          margin-left: 10px;
          font-style: italic; }
        #editor-builder #draggable-plugins .plugins-presets ol li .required-info .visit,
        #editor-builder #draggable-plugins .plugins-presets ol li .plugin-info .visit,
        #editor-builder #draggable-plugins .additional-plugins ol li .required-info .visit,
        #editor-builder #draggable-plugins .additional-plugins ol li .plugin-info .visit,
        #editor-builder .languages .plugins-presets ol li .required-info .visit,
        #editor-builder .languages .plugins-presets ol li .plugin-info .visit,
        #editor-builder .languages .additional-plugins ol li .required-info .visit,
        #editor-builder .languages .additional-plugins ol li .plugin-info .visit {
          font-size: 0.85em;
          margin-right: 20px;
          float: right; }
        #editor-builder #draggable-plugins .plugins-presets ol li .plugin-info,
        #editor-builder #draggable-plugins .additional-plugins ol li .plugin-info,
        #editor-builder .languages .plugins-presets ol li .plugin-info,
        #editor-builder .languages .additional-plugins ol li .plugin-info {
          display: none;
          margin: calc(2.5em /4);
          margin-bottom: 2px; }
        #editor-builder #draggable-plugins .plugins-presets ol li .required-info,
        #editor-builder #draggable-plugins .additional-plugins ol li .required-info,
        #editor-builder .languages .plugins-presets ol li .required-info,
        #editor-builder .languages .additional-plugins ol li .required-info {
          margin-left: 20px;
          font-size: 85%; }
        #editor-builder #draggable-plugins .plugins-presets ol li.current,
        #editor-builder #draggable-plugins .additional-plugins ol li.current,
        #editor-builder .languages .plugins-presets ol li.current,
        #editor-builder .languages .additional-plugins ol li.current {
          background: #EBEBEB; }
    #editor-builder #draggable-plugins .additional-plugins .all-elements-option,
    #editor-builder .languages .additional-plugins .all-elements-option {
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 5px; }
      #editor-builder #draggable-plugins .additional-plugins .all-elements-option .search,
      #editor-builder .languages .additional-plugins .all-elements-option .search {
        margin-top: 5px; }
        #editor-builder #draggable-plugins .additional-plugins .all-elements-option .search input,
        #editor-builder .languages .additional-plugins .all-elements-option .search input {
          background-color: #eeeeee;
          padding-left: calc(_space() / 2); }
      #editor-builder #draggable-plugins .additional-plugins .all-elements-option #filter-plugins,
      #editor-builder .languages .additional-plugins .all-elements-option #filter-plugins {
        width: 100%;
        margin: 6px auto 0;
        text-align: right; }
        #editor-builder #draggable-plugins .additional-plugins .all-elements-option #filter-plugins label,
        #editor-builder .languages .additional-plugins .all-elements-option #filter-plugins label {
          font-weight: normal;
          font-size: 1.5rem;
          vertical-align: middle; }
          #editor-builder #draggable-plugins .additional-plugins .all-elements-option #filter-plugins label > strong,
          #editor-builder .languages .additional-plugins .all-elements-option #filter-plugins label > strong {
            font-weight: 700;
            margin-left: 0; }
    #editor-builder #draggable-plugins .plugins-presets .all-elements-option,
    #editor-builder .languages .plugins-presets .all-elements-option {
      padding-top: 20px; }
    #editor-builder #draggable-plugins .remove-item,
    #editor-builder #draggable-plugins .add-item,
    #editor-builder .languages .remove-item,
    #editor-builder .languages .add-item {
      width: 56px;
      height: 42px;
      text-align: center;
      border-radius: 4px;
      left: 50%;
      top: 50%;
      margin-top: -21px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      position: absolute;
      background: white; }
      @media (min-width: 1280px) {
        #editor-builder #draggable-plugins .remove-item,
        #editor-builder #draggable-plugins .add-item,
        #editor-builder .languages .remove-item,
        #editor-builder .languages .add-item {
          width: 42px;
          height: 56px; } }
      #editor-builder #draggable-plugins .remove-item span,
      #editor-builder #draggable-plugins .add-item span,
      #editor-builder .languages .remove-item span,
      #editor-builder .languages .add-item span {
        font-size: 0;
        height: 14px;
        margin: auto;
        text-indent: -9999px;
        width: 9px;
        vertical-align: middle; }
        #editor-builder #draggable-plugins .remove-item span.mo-remove-ico, #editor-builder #draggable-plugins .remove-item span.mo-add-ico,
        #editor-builder #draggable-plugins .add-item span.mo-remove-ico,
        #editor-builder #draggable-plugins .add-item span.mo-add-ico,
        #editor-builder .languages .remove-item span.mo-remove-ico,
        #editor-builder .languages .remove-item span.mo-add-ico,
        #editor-builder .languages .add-item span.mo-remove-ico,
        #editor-builder .languages .add-item span.mo-add-ico {
          transform: rotate(90deg); }
        #editor-builder #draggable-plugins .remove-item span.mo-remove-ico, #editor-builder #draggable-plugins .remove-item span.mo-add-ico,
        #editor-builder #draggable-plugins .add-item span.mo-remove-ico,
        #editor-builder #draggable-plugins .add-item span.mo-add-ico,
        #editor-builder .languages .remove-item span.mo-remove-ico,
        #editor-builder .languages .remove-item span.mo-add-ico,
        #editor-builder .languages .add-item span.mo-remove-ico,
        #editor-builder .languages .add-item span.mo-add-ico {
          display: block; }
          @media (min-width: 1280px) {
            #editor-builder #draggable-plugins .remove-item span.mo-remove-ico, #editor-builder #draggable-plugins .remove-item span.mo-add-ico,
            #editor-builder #draggable-plugins .add-item span.mo-remove-ico,
            #editor-builder #draggable-plugins .add-item span.mo-add-ico,
            #editor-builder .languages .remove-item span.mo-remove-ico,
            #editor-builder .languages .remove-item span.mo-add-ico,
            #editor-builder .languages .add-item span.mo-remove-ico,
            #editor-builder .languages .add-item span.mo-add-ico {
              display: none; } }
        #editor-builder #draggable-plugins .remove-item span.add-ico, #editor-builder #draggable-plugins .remove-item span.remove-ico,
        #editor-builder #draggable-plugins .add-item span.add-ico,
        #editor-builder #draggable-plugins .add-item span.remove-ico,
        #editor-builder .languages .remove-item span.add-ico,
        #editor-builder .languages .remove-item span.remove-ico,
        #editor-builder .languages .add-item span.add-ico,
        #editor-builder .languages .add-item span.remove-ico {
          display: none; }
          @media (min-width: 1280px) {
            #editor-builder #draggable-plugins .remove-item span.add-ico, #editor-builder #draggable-plugins .remove-item span.remove-ico,
            #editor-builder #draggable-plugins .add-item span.add-ico,
            #editor-builder #draggable-plugins .add-item span.remove-ico,
            #editor-builder .languages .remove-item span.add-ico,
            #editor-builder .languages .remove-item span.remove-ico,
            #editor-builder .languages .add-item span.add-ico,
            #editor-builder .languages .add-item span.remove-ico {
              display: block; } }
    #editor-builder #draggable-plugins .remove-item,
    #editor-builder .languages .remove-item {
      margin-left: 10px; }
      @media (min-width: 1280px) {
        #editor-builder #draggable-plugins .remove-item,
        #editor-builder .languages .remove-item {
          margin-left: -21px;
          margin-top: 10px; } }
      #editor-builder #draggable-plugins .remove-item span,
      #editor-builder .languages .remove-item span {
        background: url("https://c.cksource.com/a/5/img/sprites.png") no-repeat -180px -1624px; }
    #editor-builder #draggable-plugins .add-item,
    #editor-builder .languages .add-item {
      margin-left: -60px; }
      @media (min-width: 1280px) {
        #editor-builder #draggable-plugins .add-item,
        #editor-builder .languages .add-item {
          margin-left: -21px;
          margin-top: -60px; } }
      #editor-builder #draggable-plugins .add-item span,
      #editor-builder .languages .add-item span {
        background: url("https://c.cksource.com/a/5/img/sprites.png") no-repeat -167px -1624px; }
    #editor-builder #draggable-plugins .all-elements-option,
    #editor-builder .languages .all-elements-option {
      padding: 15px; }
      #editor-builder #draggable-plugins .all-elements-option input[type="checkbox"],
      #editor-builder .languages .all-elements-option input[type="checkbox"] {
        margin-left: 15px;
        opacity: 1; }
      #editor-builder #draggable-plugins .all-elements-option label,
      #editor-builder .languages .all-elements-option label {
        display: inline;
        color: rgba(64, 81, 102, 0.96); }
      #editor-builder #draggable-plugins .all-elements-option .show-plugins-from-preset,
      #editor-builder .languages .all-elements-option .show-plugins-from-preset {
        text-align: right;
        display: inline-block;
        float: right;
        margin-top: 7px; }
        #editor-builder #draggable-plugins .all-elements-option .show-plugins-from-preset label,
        #editor-builder .languages .all-elements-option .show-plugins-from-preset label {
          font-weight: normal;
          font-size: 1.5rem;
          vertical-align: middle; }
  #editor-builder .legend {
    display: inline-block;
    clear: both;
    width: 100%;
    text-align: center;
    margin: 2.5rem 0; }
    #editor-builder .legend h5 {
      font-weight: 300;
      font-size: 1.9rem; }
    #editor-builder .legend__wrapper {
      display: inline-block; }
      @media (min-width: 1280px) {
        #editor-builder .legend__wrapper {
          float: none; } }
    #editor-builder .legend__description {
      display: inline-block;
      margin-top: 13px; }
      @media (min-width: 1280px) {
        #editor-builder .legend__description {
          margin-top: 3px; } }
    #editor-builder .legend__ico {
      display: inline-block;
      clear: left;
      margin-right: 5px;
      margin-top: 10px; }
      #editor-builder .legend__ico:before {
        content: '';
        display: inline-block; }
      @media (min-width: 1280px) {
        #editor-builder .legend__ico {
          clear: none;
          margin-top: 0; }
          #editor-builder .legend__ico--optional, #editor-builder .legend__ico--required {
            margin-left: 2.5rem; } }
  #editor-builder #a11ychecker-gpl {
    display: none;
    padding: 2.5rem; }
    #editor-builder #a11ychecker-gpl p {
      font-weight: normal;
      margin: 0; }
  #editor-builder .download-types {
    border: 1px solid rgba(0, 0, 0, 0.15);
    margin-top: 40px;
    background-color: rgba(0, 0, 0, 0.03);
    padding: 2.5rem;
    text-align: center; }
    #editor-builder .download-types legend {
      display: none; }
    @media (min-width: 1280px) {
      #editor-builder .download-types {
        width: 100%; } }
    #editor-builder .download-types input[type="checkbox"],
    #editor-builder .download-types input[type="radio"] {
      margin-top: 5px; }
    #editor-builder .download-types h3 {
      position: relative;
      text-align: center;
      margin: 0 auto;
      padding-top: 15px;
      display: none; }
      #editor-builder .download-types h3:before {
        content: " ";
        background: url("https://c.cksource.com/a/1/img/ajax-loader.gif") 0 0 no-repeat;
        position: absolute;
        left: -26px;
        top: 26px;
        display: block;
        width: 20px;
        height: 20px; }
    #editor-builder .download-types .terms-of-use {
      display: inline-block;
      width: 100%;
      padding: 5px;
      text-align: left; }
      #editor-builder .download-types .terms-of-use.error {
        background-image: none;
        border: 1px solid #d9534f; }
      #editor-builder .download-types .terms-of-use input[type="checkbox"] {
        float: left;
        margin-right: 10px;
        margin-top: 7px; }
      #editor-builder .download-types .terms-of-use label {
        float: left;
        margin-bottom: 0;
        width: calc(100% - 30px);
        font-weight: 400; }
    #editor-builder .download-types .btn, #editor-builder .download-types #cksource_repo_search_forums_form .form-submit, #cksource_repo_search_forums_form #editor-builder .download-types .form-submit, #editor-builder .download-types #forum-sort .form-submit, #forum-sort #editor-builder .download-types .form-submit, #editor-builder .download-types .add-plugin input[type="submit"], .add-plugin #editor-builder .download-types input[type="submit"], #editor-builder .download-types
    .add-plugin select[type="submit"],
    .add-plugin #editor-builder .download-types select[type="submit"], #editor-builder .download-types
    .plugin-edit input[type="submit"],
    .plugin-edit #editor-builder .download-types input[type="submit"], #editor-builder .download-types
    .plugin-edit select[type="submit"],
    .plugin-edit #editor-builder .download-types select[type="submit"], #editor-builder .download-types
    .plugin-release input[type="submit"],
    .plugin-release #editor-builder .download-types input[type="submit"], #editor-builder .download-types
    .plugin-release select[type="submit"],
    .plugin-release #editor-builder .download-types select[type="submit"], #editor-builder .download-types .add-plugin .field-type-image-form input[type="file"], .add-plugin .field-type-image-form #editor-builder .download-types input[type="file"], #editor-builder .download-types
    .plugin-edit .field-type-image-form input[type="file"],
    .plugin-edit .field-type-image-form #editor-builder .download-types input[type="file"], #editor-builder .download-types
    .plugin-release .field-type-image-form input[type="file"],
    .plugin-release .field-type-image-form #editor-builder .download-types input[type="file"], #editor-builder .download-types .add-plugin .field-type-image-form button, .add-plugin .field-type-image-form #editor-builder .download-types button, #editor-builder .download-types
    .plugin-edit .field-type-image-form button,
    .plugin-edit .field-type-image-form #editor-builder .download-types button, #editor-builder .download-types
    .plugin-release .field-type-image-form button,
    .plugin-release .field-type-image-form #editor-builder .download-types button, #editor-builder .download-types .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], .add-plugin .field-type-image-form #editor-builder .download-types input[name="field_addon_logo_und_0_remove_button"], #editor-builder .download-types
    .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
    .plugin-edit .field-type-image-form #editor-builder .download-types input[name="field_addon_logo_und_0_remove_button"], #editor-builder .download-types
    .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
    .plugin-release .field-type-image-form #editor-builder .download-types input[name="field_addon_logo_und_0_remove_button"], #editor-builder .download-types #draggable-plugins .all-elements-option a, #editor-builder #draggable-plugins .all-elements-option .download-types a, #editor-builder .download-types .languages .all-elements-option a, #editor-builder .languages .all-elements-option .download-types a, #editor-builder .download-types .login .form-control[type="submit"], .login #editor-builder .download-types .form-control[type="submit"], #editor-builder .download-types
    .register .form-control[type="submit"],
    .register #editor-builder .download-types .form-control[type="submit"], #editor-builder .download-types
    .info-page .form-control[type="submit"],
    .info-page #editor-builder .download-types .form-control[type="submit"], #editor-builder .download-types .most-popular__wrapper .add_to_basket, .most-popular__wrapper #editor-builder .download-types .add_to_basket, #editor-builder .download-types .add-ons-list .views-row .add_to_basket, .add-ons-list .views-row #editor-builder .download-types .add_to_basket, #editor-builder .download-types
    .add-ons-list .views-row .check-details,
    .add-ons-list .views-row #editor-builder .download-types .check-details, #editor-builder .download-types
    .add-ons-list .views-row .remove_from_basket,
    .add-ons-list .views-row #editor-builder .download-types .remove_from_basket, #editor-builder .download-types
    .add-ons-list .views-row .required,
    .add-ons-list .views-row #editor-builder .download-types .required, #editor-builder .download-types .user-edit input[type="submit"], .user-edit #editor-builder .download-types input[type="submit"], #editor-builder .download-types
    .user-edit select[type="submit"],
    .user-edit #editor-builder .download-types select[type="submit"] {
      display: block;
      top: 0;
      min-width: 22.5rem;
      margin: 15px auto 0; }
    #editor-builder .download-types .download-options__wrapper {
      display: inline-block;
      width: 100%;
      text-align: center;
      padding: 5px 0 0; }
      #editor-builder .download-types .download-options__wrapper ol {
        display: inline-block;
        position: relative;
        margin: 0;
        padding: 0;
        top: 0;
        list-style: none; }
        #editor-builder .download-types .download-options__wrapper ol li {
          white-space: nowrap;
          float: left;
          margin-right: 2.5rem; }
          #editor-builder .download-types .download-options__wrapper ol li:last-child {
            margin-right: 0; }
          #editor-builder .download-types .download-options__wrapper ol li input[type="radio"] {
            float: left;
            margin-right: 5px; }
          #editor-builder .download-types .download-options__wrapper ol li label {
            float: left;
            font-size: 1.5rem; }
  #editor-builder .skins-wrapper {
    display: inline-block;
    width: 100%; }
    #editor-builder .skins-wrapper .skinsBox {
      display: inline-block;
      width: 100%;
      list-style: none;
      padding: 0 30px; }
      #editor-builder .skins-wrapper .skinsBox li {
        display: inline-block;
        padding: 0; }
        #editor-builder .skins-wrapper .skinsBox li.selected .skin__thumb {
          border: 2px solid #30a3f1; }
        #editor-builder .skins-wrapper .skinsBox li .skin__thumb {
          display: block;
          width: 170px;
          height: 120px;
          margin: 0 auto;
          border: 1px solid rgba(0, 0, 0, 0.15); }
        #editor-builder .skins-wrapper .skinsBox li:focus, #editor-builder .skins-wrapper .skinsBox li:active {
          outline: none; }
        #editor-builder .skins-wrapper .skinsBox li label {
          margin-top: 5px;
          display: inline-block;
          width: 100%;
          font-weight: 400;
          font-size: 1.5rem;
          text-align: center; }
        #editor-builder .skins-wrapper .skinsBox li input[type="radio"] {
          left: auto;
          display: block;
          margin: 0 auto; }
      #editor-builder .skins-wrapper .skinsBox .slick-next,
      #editor-builder .skins-wrapper .skinsBox .slick-prev {
        top: 50px;
        transform: none; }
        #editor-builder .skins-wrapper .skinsBox .slick-next:before,
        #editor-builder .skins-wrapper .skinsBox .slick-prev:before {
          color: #cacaca;
          font-size: 26px; }
      #editor-builder .skins-wrapper .skinsBox .slick-prev {
        left: 15px; }
      #editor-builder .skins-wrapper .skinsBox .slick-next {
        right: 15px; }
  #editor-builder .builder-loader {
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: white --A700;
    z-index: 999; }
    #editor-builder .builder-loader .spinner {
      position: absolute;
      top: 5%;
      left: 50%;
      width: 50px;
      height: 50px;
      margin-left: -25px;
      border: 5px solid rgba(48, 163, 241, 0.3);
      border-radius: 50%;
      border-top-color: #30a3f1;
      transform: none; }
    #editor-builder .builder-loader--loading {
      display: block; }
      #editor-builder .builder-loader--loading .spinner {
        animation: spin 1s ease-in-out infinite; }

@keyframes spin {
  to {
    transform: rotate(360deg); } }

#download_thanks {
  display: none;
  text-align: center; }
  #download_thanks h2 {
    margin-bottom: 2.5rem; }
  #download_thanks label {
    display: none; }
  #download_thanks #mc-embedded-subscribe {
    margin: 0 auto;
    display: block; }

.cookies-box {
  display: none;
  position: relative;
  width: 100%;
  color: white;
  background-color: #030e1b;
  text-align: center;
  z-index: 999; }
  .cookies-box--active {
    display: inline-block;
    max-height: 120px;
    padding: 20px 0;
    opacity: 1;
    transition: all 600ms ease-in-out; }
  .cookies-box--accepted {
    max-height: 0;
    padding: 0;
    opacity: 0;
    overflow: hidden; }
  @media (min-width: 1024px) {
    .cookies-box {
      font-size: 2.1rem; } }
  .cookies-box p {
    margin: 0;
    font-size: 1rem; }
    .cookies-box p a {
      font-weight: 600; }
    @media (min-width: 768px) {
      .cookies-box p {
        font-size: 1.6rem; } }
  .cookies-box .cookies-agree {
    margin-left: 1rem; }
  .cookies-box .btn--xs {
    padding: 3px 13px;
    line-height: 1.5;
    margin-left: .5rem;
    margin-top: .5rem; }
    @media (min-width: 768px) {
      .cookies-box .btn--xs {
        margin-left: 1rem;
        margin-top: 0; } }

#addon_machine_name {
  display: none; }

.hidden, .element-invisible, .add_new_skin_category {
  display: none !important; }

.fileinputs {
  position: relative; }

.fakefile {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  cursor: pointer; }

#add-on-node-form #edit-field-screenshots .tabledrag-toggle-weight-wrapper,
.addon--release input[name="field_release_file_und_0_upload_button"] {
  display: none; }

.add-plugin,
.plugin-edit,
.addon--release,
.plugin-release {
  /**
	* Image upload widget.
	*/ }
  .add-plugin .field-type-image-form.field-name-field-screenshots-form label,
  .plugin-edit .field-type-image-form.field-name-field-screenshots-form label,
  .addon--release .field-type-image-form.field-name-field-screenshots-form label,
  .plugin-release .field-type-image-form.field-name-field-screenshots-form label {
    display: none; }
  .add-plugin div.image-preview,
  .plugin-edit div.image-preview,
  .addon--release div.image-preview,
  .plugin-release div.image-preview {
    float: none;
    padding: 1rem; }
  .add-plugin div.image-widget-data,
  .plugin-edit div.image-widget-data,
  .addon--release div.image-widget-data,
  .plugin-release div.image-widget-data {
    float: none;
    padding: 1rem; }
  @media (min-width: 768px) {
    .add-plugin select.form-control--big,
    .plugin-edit select.form-control--big,
    .addon--release select.form-control--big,
    .plugin-release select.form-control--big {
      height: 43px; } }
  .add-plugin select.form-control--big option,
  .plugin-edit select.form-control--big option,
  .addon--release select.form-control--big option,
  .plugin-release select.form-control--big option {
    padding: 9px 13px; }
  .add-plugin textarea,
  .plugin-edit textarea,
  .addon--release textarea,
  .plugin-release textarea {
    display: inline-block;
    width: 100%;
    height: 100%;
    padding: 8px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px; }
    @media (min-width: 768px) {
      .add-plugin textarea,
      .plugin-edit textarea,
      .addon--release textarea,
      .plugin-release textarea {
        font-size: 1.6rem; } }
  .add-plugin input,
  .plugin-edit input,
  .addon--release input,
  .plugin-release input {
    background-color: white; }
  .add-plugin label,
  .add-plugin .field-type-image-form.field-name-field-screenshots-form legend,
  .plugin-edit label,
  .plugin-edit .field-type-image-form.field-name-field-screenshots-form legend,
  .addon--release label,
  .addon--release .field-type-image-form.field-name-field-screenshots-form legend,
  .plugin-release label,
  .plugin-release .field-type-image-form.field-name-field-screenshots-form legend {
    font-weight: bold;
    display: inline-block;
    width: 100%;
    margin-bottom: 5px;
    font-size: 1.6rem;
    font-weight: 600; }
  .add-plugin .input-wrapper,
  .plugin-edit .input-wrapper,
  .addon--release .input-wrapper,
  .plugin-release .input-wrapper {
    display: inline-block;
    width: 100%;
    margin-bottom: 15px; }
    .add-plugin .input-wrapper:before, .add-plugin .input-wrapper:after,
    .plugin-edit .input-wrapper:before,
    .plugin-edit .input-wrapper:after,
    .addon--release .input-wrapper:before,
    .addon--release .input-wrapper:after,
    .plugin-release .input-wrapper:before,
    .plugin-release .input-wrapper:after {
      content: ' ';
      display: table; }
    .add-plugin .input-wrapper:after,
    .plugin-edit .input-wrapper:after,
    .addon--release .input-wrapper:after,
    .plugin-release .input-wrapper:after {
      clear: both; }
    @media (min-width: 768px) {
      .add-plugin .input-wrapper,
      .plugin-edit .input-wrapper,
      .addon--release .input-wrapper,
      .plugin-release .input-wrapper {
        margin-bottom: 2.5rem; } }
  .add-plugin .middle-submit,
  .plugin-edit .middle-submit,
  .addon--release .middle-submit,
  .plugin-release .middle-submit {
    text-align: center; }
    .add-plugin .middle-submit .btn, .add-plugin .middle-submit #cksource_repo_search_forums_form .form-submit, #cksource_repo_search_forums_form .add-plugin .middle-submit .form-submit, .add-plugin .middle-submit #forum-sort .form-submit, #forum-sort .add-plugin .middle-submit .form-submit, .add-plugin .middle-submit input[type="submit"], .add-plugin .middle-submit select[type="submit"], .add-plugin .middle-submit .field-type-image-form input[type="file"], .add-plugin .field-type-image-form .middle-submit input[type="file"],
    .plugin-edit .field-type-image-form .add-plugin .middle-submit input[type="file"],
    .plugin-release .field-type-image-form .add-plugin .middle-submit input[type="file"], .add-plugin .middle-submit .field-type-image-form button, .add-plugin .field-type-image-form .middle-submit button,
    .plugin-edit .field-type-image-form .add-plugin .middle-submit button,
    .plugin-release .field-type-image-form .add-plugin .middle-submit button, .add-plugin .middle-submit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], .add-plugin .field-type-image-form .middle-submit input[name="field_addon_logo_und_0_remove_button"],
    .plugin-edit .field-type-image-form .add-plugin .middle-submit input[name="field_addon_logo_und_0_remove_button"],
    .plugin-release .field-type-image-form .add-plugin .middle-submit input[name="field_addon_logo_und_0_remove_button"], .add-plugin .middle-submit #editor-builder #draggable-plugins .all-elements-option a, #editor-builder #draggable-plugins .all-elements-option .add-plugin .middle-submit a, .add-plugin .middle-submit
    #editor-builder .languages .all-elements-option a,
    #editor-builder .languages .all-elements-option .add-plugin .middle-submit a, .add-plugin .middle-submit .login .form-control[type="submit"], .login .add-plugin .middle-submit .form-control[type="submit"], .add-plugin .middle-submit
    .register .form-control[type="submit"],
    .register .add-plugin .middle-submit .form-control[type="submit"], .add-plugin .middle-submit
    .info-page .form-control[type="submit"],
    .info-page .add-plugin .middle-submit .form-control[type="submit"], .add-plugin .middle-submit .most-popular__wrapper .add_to_basket, .most-popular__wrapper .add-plugin .middle-submit .add_to_basket, .add-plugin .middle-submit .add-ons-list .views-row .add_to_basket, .add-ons-list .views-row .add-plugin .middle-submit .add_to_basket, .add-plugin .middle-submit
    .add-ons-list .views-row .check-details,
    .add-ons-list .views-row .add-plugin .middle-submit .check-details, .add-plugin .middle-submit
    .add-ons-list .views-row .remove_from_basket,
    .add-ons-list .views-row .add-plugin .middle-submit .remove_from_basket, .add-plugin .middle-submit
    .add-ons-list .views-row .required,
    .add-ons-list .views-row .add-plugin .middle-submit .required,
    .plugin-edit .middle-submit .btn,
    .plugin-edit .middle-submit #cksource_repo_search_forums_form .form-submit, #cksource_repo_search_forums_form
    .plugin-edit .middle-submit .form-submit,
    .plugin-edit .middle-submit #forum-sort .form-submit, #forum-sort
    .plugin-edit .middle-submit .form-submit, .plugin-edit .middle-submit input[type="submit"], .plugin-edit .middle-submit select[type="submit"], .add-plugin .field-type-image-form
    .plugin-edit .middle-submit input[type="file"], .plugin-edit .middle-submit .field-type-image-form input[type="file"], .plugin-edit .field-type-image-form .middle-submit input[type="file"],
    .plugin-release .field-type-image-form
    .plugin-edit .middle-submit input[type="file"], .add-plugin .field-type-image-form
    .plugin-edit .middle-submit button, .plugin-edit .middle-submit .field-type-image-form button, .plugin-edit .field-type-image-form .middle-submit button,
    .plugin-release .field-type-image-form
    .plugin-edit .middle-submit button, .add-plugin .field-type-image-form
    .plugin-edit .middle-submit input[name="field_addon_logo_und_0_remove_button"], .plugin-edit .middle-submit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], .plugin-edit .field-type-image-form .middle-submit input[name="field_addon_logo_und_0_remove_button"],
    .plugin-release .field-type-image-form
    .plugin-edit .middle-submit input[name="field_addon_logo_und_0_remove_button"],
    .plugin-edit .middle-submit #editor-builder #draggable-plugins .all-elements-option a, #editor-builder #draggable-plugins .all-elements-option
    .plugin-edit .middle-submit a,
    .plugin-edit .middle-submit
    #editor-builder .languages .all-elements-option a,
    #editor-builder .languages .all-elements-option
    .plugin-edit .middle-submit a,
    .plugin-edit .middle-submit .login .form-control[type="submit"], .login
    .plugin-edit .middle-submit .form-control[type="submit"],
    .plugin-edit .middle-submit
    .register .form-control[type="submit"],
    .register
    .plugin-edit .middle-submit .form-control[type="submit"],
    .plugin-edit .middle-submit
    .info-page .form-control[type="submit"],
    .info-page
    .plugin-edit .middle-submit .form-control[type="submit"],
    .plugin-edit .middle-submit .most-popular__wrapper .add_to_basket, .most-popular__wrapper
    .plugin-edit .middle-submit .add_to_basket,
    .plugin-edit .middle-submit .add-ons-list .views-row .add_to_basket, .add-ons-list .views-row
    .plugin-edit .middle-submit .add_to_basket,
    .plugin-edit .middle-submit
    .add-ons-list .views-row .check-details,
    .add-ons-list .views-row
    .plugin-edit .middle-submit .check-details,
    .plugin-edit .middle-submit
    .add-ons-list .views-row .remove_from_basket,
    .add-ons-list .views-row
    .plugin-edit .middle-submit .remove_from_basket,
    .plugin-edit .middle-submit
    .add-ons-list .views-row .required,
    .add-ons-list .views-row
    .plugin-edit .middle-submit .required,
    .addon--release .middle-submit .btn,
    .addon--release .middle-submit #cksource_repo_search_forums_form .form-submit, #cksource_repo_search_forums_form
    .addon--release .middle-submit .form-submit,
    .addon--release .middle-submit #forum-sort .form-submit, #forum-sort
    .addon--release .middle-submit .form-submit,
    .addon--release .middle-submit .add-plugin input[type="submit"], .add-plugin
    .addon--release .middle-submit input[type="submit"],
    .addon--release .middle-submit
    .add-plugin select[type="submit"],
    .add-plugin
    .addon--release .middle-submit select[type="submit"],
    .addon--release .middle-submit
    .plugin-edit input[type="submit"],
    .plugin-edit
    .addon--release .middle-submit input[type="submit"],
    .addon--release .middle-submit
    .plugin-edit select[type="submit"],
    .plugin-edit
    .addon--release .middle-submit select[type="submit"],
    .addon--release .middle-submit
    .plugin-release input[type="submit"],
    .plugin-release
    .addon--release .middle-submit input[type="submit"],
    .addon--release .middle-submit
    .plugin-release select[type="submit"],
    .plugin-release
    .addon--release .middle-submit select[type="submit"],
    .addon--release .middle-submit .add-plugin .field-type-image-form input[type="file"], .add-plugin .field-type-image-form
    .addon--release .middle-submit input[type="file"],
    .addon--release .middle-submit
    .plugin-edit .field-type-image-form input[type="file"],
    .plugin-edit .field-type-image-form
    .addon--release .middle-submit input[type="file"],
    .addon--release .middle-submit
    .plugin-release .field-type-image-form input[type="file"],
    .plugin-release .field-type-image-form
    .addon--release .middle-submit input[type="file"],
    .addon--release .middle-submit .add-plugin .field-type-image-form button, .add-plugin .field-type-image-form
    .addon--release .middle-submit button,
    .addon--release .middle-submit
    .plugin-edit .field-type-image-form button,
    .plugin-edit .field-type-image-form
    .addon--release .middle-submit button,
    .addon--release .middle-submit
    .plugin-release .field-type-image-form button,
    .plugin-release .field-type-image-form
    .addon--release .middle-submit button,
    .addon--release .middle-submit .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], .add-plugin .field-type-image-form
    .addon--release .middle-submit input[name="field_addon_logo_und_0_remove_button"],
    .addon--release .middle-submit
    .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
    .plugin-edit .field-type-image-form
    .addon--release .middle-submit input[name="field_addon_logo_und_0_remove_button"],
    .addon--release .middle-submit
    .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
    .plugin-release .field-type-image-form
    .addon--release .middle-submit input[name="field_addon_logo_und_0_remove_button"],
    .addon--release .middle-submit #editor-builder #draggable-plugins .all-elements-option a, #editor-builder #draggable-plugins .all-elements-option
    .addon--release .middle-submit a,
    .addon--release .middle-submit
    #editor-builder .languages .all-elements-option a,
    #editor-builder .languages .all-elements-option
    .addon--release .middle-submit a,
    .addon--release .middle-submit .login .form-control[type="submit"], .login
    .addon--release .middle-submit .form-control[type="submit"],
    .addon--release .middle-submit
    .register .form-control[type="submit"],
    .register
    .addon--release .middle-submit .form-control[type="submit"],
    .addon--release .middle-submit
    .info-page .form-control[type="submit"],
    .info-page
    .addon--release .middle-submit .form-control[type="submit"],
    .addon--release .middle-submit .most-popular__wrapper .add_to_basket, .most-popular__wrapper
    .addon--release .middle-submit .add_to_basket,
    .addon--release .middle-submit .add-ons-list .views-row .add_to_basket, .add-ons-list .views-row
    .addon--release .middle-submit .add_to_basket,
    .addon--release .middle-submit
    .add-ons-list .views-row .check-details,
    .add-ons-list .views-row
    .addon--release .middle-submit .check-details,
    .addon--release .middle-submit
    .add-ons-list .views-row .remove_from_basket,
    .add-ons-list .views-row
    .addon--release .middle-submit .remove_from_basket,
    .addon--release .middle-submit
    .add-ons-list .views-row .required,
    .add-ons-list .views-row
    .addon--release .middle-submit .required,
    .addon--release .middle-submit .user-edit input[type="submit"], .user-edit
    .addon--release .middle-submit input[type="submit"],
    .addon--release .middle-submit
    .user-edit select[type="submit"],
    .user-edit
    .addon--release .middle-submit select[type="submit"],
    .plugin-release .middle-submit .btn,
    .plugin-release .middle-submit #cksource_repo_search_forums_form .form-submit, #cksource_repo_search_forums_form
    .plugin-release .middle-submit .form-submit,
    .plugin-release .middle-submit #forum-sort .form-submit, #forum-sort
    .plugin-release .middle-submit .form-submit, .plugin-release .middle-submit input[type="submit"], .plugin-release .middle-submit select[type="submit"], .add-plugin .field-type-image-form
    .plugin-release .middle-submit input[type="file"],
    .plugin-edit .field-type-image-form
    .plugin-release .middle-submit input[type="file"], .plugin-release .middle-submit .field-type-image-form input[type="file"], .plugin-release .field-type-image-form .middle-submit input[type="file"], .add-plugin .field-type-image-form
    .plugin-release .middle-submit button,
    .plugin-edit .field-type-image-form
    .plugin-release .middle-submit button, .plugin-release .middle-submit .field-type-image-form button, .plugin-release .field-type-image-form .middle-submit button, .add-plugin .field-type-image-form
    .plugin-release .middle-submit input[name="field_addon_logo_und_0_remove_button"],
    .plugin-edit .field-type-image-form
    .plugin-release .middle-submit input[name="field_addon_logo_und_0_remove_button"], .plugin-release .middle-submit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], .plugin-release .field-type-image-form .middle-submit input[name="field_addon_logo_und_0_remove_button"],
    .plugin-release .middle-submit #editor-builder #draggable-plugins .all-elements-option a, #editor-builder #draggable-plugins .all-elements-option
    .plugin-release .middle-submit a,
    .plugin-release .middle-submit
    #editor-builder .languages .all-elements-option a,
    #editor-builder .languages .all-elements-option
    .plugin-release .middle-submit a,
    .plugin-release .middle-submit .login .form-control[type="submit"], .login
    .plugin-release .middle-submit .form-control[type="submit"],
    .plugin-release .middle-submit
    .register .form-control[type="submit"],
    .register
    .plugin-release .middle-submit .form-control[type="submit"],
    .plugin-release .middle-submit
    .info-page .form-control[type="submit"],
    .info-page
    .plugin-release .middle-submit .form-control[type="submit"],
    .plugin-release .middle-submit .most-popular__wrapper .add_to_basket, .most-popular__wrapper
    .plugin-release .middle-submit .add_to_basket,
    .plugin-release .middle-submit .add-ons-list .views-row .add_to_basket, .add-ons-list .views-row
    .plugin-release .middle-submit .add_to_basket,
    .plugin-release .middle-submit
    .add-ons-list .views-row .check-details,
    .add-ons-list .views-row
    .plugin-release .middle-submit .check-details,
    .plugin-release .middle-submit
    .add-ons-list .views-row .remove_from_basket,
    .add-ons-list .views-row
    .plugin-release .middle-submit .remove_from_basket,
    .plugin-release .middle-submit
    .add-ons-list .views-row .required,
    .add-ons-list .views-row
    .plugin-release .middle-submit .required {
      width: 100%; }
      .add-plugin .middle-submit .btn--big, .add-plugin .middle-submit #edit-actions input[type="submit"], .add-plugin #edit-actions .middle-submit input[type="submit"],
      .plugin-edit #edit-actions .add-plugin .middle-submit input[type="submit"],
      .plugin-release #edit-actions .add-plugin .middle-submit input[type="submit"], .user-edit #edit-actions .add-plugin .middle-submit input[type="submit"],
      .plugin-edit .middle-submit .btn--big, .add-plugin #edit-actions
      .plugin-edit .middle-submit input[type="submit"], .plugin-edit .middle-submit #edit-actions input[type="submit"], .plugin-edit #edit-actions .middle-submit input[type="submit"],
      .plugin-release #edit-actions
      .plugin-edit .middle-submit input[type="submit"], .user-edit #edit-actions
      .plugin-edit .middle-submit input[type="submit"],
      .addon--release .middle-submit .btn--big,
      .addon--release .middle-submit .add-plugin #edit-actions input[type="submit"], .add-plugin #edit-actions
      .addon--release .middle-submit input[type="submit"],
      .addon--release .middle-submit
      .plugin-edit #edit-actions input[type="submit"],
      .plugin-edit #edit-actions
      .addon--release .middle-submit input[type="submit"],
      .addon--release .middle-submit
      .plugin-release #edit-actions input[type="submit"],
      .plugin-release #edit-actions
      .addon--release .middle-submit input[type="submit"],
      .addon--release .middle-submit .user-edit #edit-actions input[type="submit"], .user-edit #edit-actions
      .addon--release .middle-submit input[type="submit"],
      .plugin-release .middle-submit .btn--big, .add-plugin #edit-actions
      .plugin-release .middle-submit input[type="submit"],
      .plugin-edit #edit-actions
      .plugin-release .middle-submit input[type="submit"], .plugin-release .middle-submit #edit-actions input[type="submit"], .plugin-release #edit-actions .middle-submit input[type="submit"], .user-edit #edit-actions
      .plugin-release .middle-submit input[type="submit"] {
        padding: 9px 13px; }
      @media (min-width: 1680px) {
        .add-plugin .middle-submit .btn, .add-plugin .middle-submit #cksource_repo_search_forums_form .form-submit, #cksource_repo_search_forums_form .add-plugin .middle-submit .form-submit, .add-plugin .middle-submit #forum-sort .form-submit, #forum-sort .add-plugin .middle-submit .form-submit, .add-plugin .middle-submit input[type="submit"], .add-plugin .middle-submit select[type="submit"], .add-plugin .middle-submit .field-type-image-form input[type="file"], .add-plugin .field-type-image-form .middle-submit input[type="file"],
        .plugin-edit .field-type-image-form .add-plugin .middle-submit input[type="file"],
        .plugin-release .field-type-image-form .add-plugin .middle-submit input[type="file"], .add-plugin .middle-submit .field-type-image-form button, .add-plugin .field-type-image-form .middle-submit button,
        .plugin-edit .field-type-image-form .add-plugin .middle-submit button,
        .plugin-release .field-type-image-form .add-plugin .middle-submit button, .add-plugin .middle-submit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], .add-plugin .field-type-image-form .middle-submit input[name="field_addon_logo_und_0_remove_button"],
        .plugin-edit .field-type-image-form .add-plugin .middle-submit input[name="field_addon_logo_und_0_remove_button"],
        .plugin-release .field-type-image-form .add-plugin .middle-submit input[name="field_addon_logo_und_0_remove_button"], .add-plugin .middle-submit #editor-builder #draggable-plugins .all-elements-option a, #editor-builder #draggable-plugins .all-elements-option .add-plugin .middle-submit a, .add-plugin .middle-submit
        #editor-builder .languages .all-elements-option a,
        #editor-builder .languages .all-elements-option .add-plugin .middle-submit a, .add-plugin .middle-submit .login .form-control[type="submit"], .login .add-plugin .middle-submit .form-control[type="submit"], .add-plugin .middle-submit
        .register .form-control[type="submit"],
        .register .add-plugin .middle-submit .form-control[type="submit"], .add-plugin .middle-submit
        .info-page .form-control[type="submit"],
        .info-page .add-plugin .middle-submit .form-control[type="submit"], .add-plugin .middle-submit .most-popular__wrapper .add_to_basket, .most-popular__wrapper .add-plugin .middle-submit .add_to_basket, .add-plugin .middle-submit .add-ons-list .views-row .add_to_basket, .add-ons-list .views-row .add-plugin .middle-submit .add_to_basket, .add-plugin .middle-submit
        .add-ons-list .views-row .check-details,
        .add-ons-list .views-row .add-plugin .middle-submit .check-details, .add-plugin .middle-submit
        .add-ons-list .views-row .remove_from_basket,
        .add-ons-list .views-row .add-plugin .middle-submit .remove_from_basket, .add-plugin .middle-submit
        .add-ons-list .views-row .required,
        .add-ons-list .views-row .add-plugin .middle-submit .required,
        .plugin-edit .middle-submit .btn,
        .plugin-edit .middle-submit #cksource_repo_search_forums_form .form-submit, #cksource_repo_search_forums_form
        .plugin-edit .middle-submit .form-submit,
        .plugin-edit .middle-submit #forum-sort .form-submit, #forum-sort
        .plugin-edit .middle-submit .form-submit, .plugin-edit .middle-submit input[type="submit"], .plugin-edit .middle-submit select[type="submit"], .add-plugin .field-type-image-form
        .plugin-edit .middle-submit input[type="file"], .plugin-edit .middle-submit .field-type-image-form input[type="file"], .plugin-edit .field-type-image-form .middle-submit input[type="file"],
        .plugin-release .field-type-image-form
        .plugin-edit .middle-submit input[type="file"], .add-plugin .field-type-image-form
        .plugin-edit .middle-submit button, .plugin-edit .middle-submit .field-type-image-form button, .plugin-edit .field-type-image-form .middle-submit button,
        .plugin-release .field-type-image-form
        .plugin-edit .middle-submit button, .add-plugin .field-type-image-form
        .plugin-edit .middle-submit input[name="field_addon_logo_und_0_remove_button"], .plugin-edit .middle-submit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], .plugin-edit .field-type-image-form .middle-submit input[name="field_addon_logo_und_0_remove_button"],
        .plugin-release .field-type-image-form
        .plugin-edit .middle-submit input[name="field_addon_logo_und_0_remove_button"],
        .plugin-edit .middle-submit #editor-builder #draggable-plugins .all-elements-option a, #editor-builder #draggable-plugins .all-elements-option
        .plugin-edit .middle-submit a,
        .plugin-edit .middle-submit
        #editor-builder .languages .all-elements-option a,
        #editor-builder .languages .all-elements-option
        .plugin-edit .middle-submit a,
        .plugin-edit .middle-submit .login .form-control[type="submit"], .login
        .plugin-edit .middle-submit .form-control[type="submit"],
        .plugin-edit .middle-submit
        .register .form-control[type="submit"],
        .register
        .plugin-edit .middle-submit .form-control[type="submit"],
        .plugin-edit .middle-submit
        .info-page .form-control[type="submit"],
        .info-page
        .plugin-edit .middle-submit .form-control[type="submit"],
        .plugin-edit .middle-submit .most-popular__wrapper .add_to_basket, .most-popular__wrapper
        .plugin-edit .middle-submit .add_to_basket,
        .plugin-edit .middle-submit .add-ons-list .views-row .add_to_basket, .add-ons-list .views-row
        .plugin-edit .middle-submit .add_to_basket,
        .plugin-edit .middle-submit
        .add-ons-list .views-row .check-details,
        .add-ons-list .views-row
        .plugin-edit .middle-submit .check-details,
        .plugin-edit .middle-submit
        .add-ons-list .views-row .remove_from_basket,
        .add-ons-list .views-row
        .plugin-edit .middle-submit .remove_from_basket,
        .plugin-edit .middle-submit
        .add-ons-list .views-row .required,
        .add-ons-list .views-row
        .plugin-edit .middle-submit .required,
        .addon--release .middle-submit .btn,
        .addon--release .middle-submit #cksource_repo_search_forums_form .form-submit, #cksource_repo_search_forums_form
        .addon--release .middle-submit .form-submit,
        .addon--release .middle-submit #forum-sort .form-submit, #forum-sort
        .addon--release .middle-submit .form-submit,
        .addon--release .middle-submit .add-plugin input[type="submit"], .add-plugin
        .addon--release .middle-submit input[type="submit"],
        .addon--release .middle-submit
        .add-plugin select[type="submit"],
        .add-plugin
        .addon--release .middle-submit select[type="submit"],
        .addon--release .middle-submit
        .plugin-edit input[type="submit"],
        .plugin-edit
        .addon--release .middle-submit input[type="submit"],
        .addon--release .middle-submit
        .plugin-edit select[type="submit"],
        .plugin-edit
        .addon--release .middle-submit select[type="submit"],
        .addon--release .middle-submit
        .plugin-release input[type="submit"],
        .plugin-release
        .addon--release .middle-submit input[type="submit"],
        .addon--release .middle-submit
        .plugin-release select[type="submit"],
        .plugin-release
        .addon--release .middle-submit select[type="submit"],
        .addon--release .middle-submit .add-plugin .field-type-image-form input[type="file"], .add-plugin .field-type-image-form
        .addon--release .middle-submit input[type="file"],
        .addon--release .middle-submit
        .plugin-edit .field-type-image-form input[type="file"],
        .plugin-edit .field-type-image-form
        .addon--release .middle-submit input[type="file"],
        .addon--release .middle-submit
        .plugin-release .field-type-image-form input[type="file"],
        .plugin-release .field-type-image-form
        .addon--release .middle-submit input[type="file"],
        .addon--release .middle-submit .add-plugin .field-type-image-form button, .add-plugin .field-type-image-form
        .addon--release .middle-submit button,
        .addon--release .middle-submit
        .plugin-edit .field-type-image-form button,
        .plugin-edit .field-type-image-form
        .addon--release .middle-submit button,
        .addon--release .middle-submit
        .plugin-release .field-type-image-form button,
        .plugin-release .field-type-image-form
        .addon--release .middle-submit button,
        .addon--release .middle-submit .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], .add-plugin .field-type-image-form
        .addon--release .middle-submit input[name="field_addon_logo_und_0_remove_button"],
        .addon--release .middle-submit
        .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
        .plugin-edit .field-type-image-form
        .addon--release .middle-submit input[name="field_addon_logo_und_0_remove_button"],
        .addon--release .middle-submit
        .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
        .plugin-release .field-type-image-form
        .addon--release .middle-submit input[name="field_addon_logo_und_0_remove_button"],
        .addon--release .middle-submit #editor-builder #draggable-plugins .all-elements-option a, #editor-builder #draggable-plugins .all-elements-option
        .addon--release .middle-submit a,
        .addon--release .middle-submit
        #editor-builder .languages .all-elements-option a,
        #editor-builder .languages .all-elements-option
        .addon--release .middle-submit a,
        .addon--release .middle-submit .login .form-control[type="submit"], .login
        .addon--release .middle-submit .form-control[type="submit"],
        .addon--release .middle-submit
        .register .form-control[type="submit"],
        .register
        .addon--release .middle-submit .form-control[type="submit"],
        .addon--release .middle-submit
        .info-page .form-control[type="submit"],
        .info-page
        .addon--release .middle-submit .form-control[type="submit"],
        .addon--release .middle-submit .most-popular__wrapper .add_to_basket, .most-popular__wrapper
        .addon--release .middle-submit .add_to_basket,
        .addon--release .middle-submit .add-ons-list .views-row .add_to_basket, .add-ons-list .views-row
        .addon--release .middle-submit .add_to_basket,
        .addon--release .middle-submit
        .add-ons-list .views-row .check-details,
        .add-ons-list .views-row
        .addon--release .middle-submit .check-details,
        .addon--release .middle-submit
        .add-ons-list .views-row .remove_from_basket,
        .add-ons-list .views-row
        .addon--release .middle-submit .remove_from_basket,
        .addon--release .middle-submit
        .add-ons-list .views-row .required,
        .add-ons-list .views-row
        .addon--release .middle-submit .required,
        .addon--release .middle-submit .user-edit input[type="submit"], .user-edit
        .addon--release .middle-submit input[type="submit"],
        .addon--release .middle-submit
        .user-edit select[type="submit"],
        .user-edit
        .addon--release .middle-submit select[type="submit"],
        .plugin-release .middle-submit .btn,
        .plugin-release .middle-submit #cksource_repo_search_forums_form .form-submit, #cksource_repo_search_forums_form
        .plugin-release .middle-submit .form-submit,
        .plugin-release .middle-submit #forum-sort .form-submit, #forum-sort
        .plugin-release .middle-submit .form-submit, .plugin-release .middle-submit input[type="submit"], .plugin-release .middle-submit select[type="submit"], .add-plugin .field-type-image-form
        .plugin-release .middle-submit input[type="file"],
        .plugin-edit .field-type-image-form
        .plugin-release .middle-submit input[type="file"], .plugin-release .middle-submit .field-type-image-form input[type="file"], .plugin-release .field-type-image-form .middle-submit input[type="file"], .add-plugin .field-type-image-form
        .plugin-release .middle-submit button,
        .plugin-edit .field-type-image-form
        .plugin-release .middle-submit button, .plugin-release .middle-submit .field-type-image-form button, .plugin-release .field-type-image-form .middle-submit button, .add-plugin .field-type-image-form
        .plugin-release .middle-submit input[name="field_addon_logo_und_0_remove_button"],
        .plugin-edit .field-type-image-form
        .plugin-release .middle-submit input[name="field_addon_logo_und_0_remove_button"], .plugin-release .middle-submit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], .plugin-release .field-type-image-form .middle-submit input[name="field_addon_logo_und_0_remove_button"],
        .plugin-release .middle-submit #editor-builder #draggable-plugins .all-elements-option a, #editor-builder #draggable-plugins .all-elements-option
        .plugin-release .middle-submit a,
        .plugin-release .middle-submit
        #editor-builder .languages .all-elements-option a,
        #editor-builder .languages .all-elements-option
        .plugin-release .middle-submit a,
        .plugin-release .middle-submit .login .form-control[type="submit"], .login
        .plugin-release .middle-submit .form-control[type="submit"],
        .plugin-release .middle-submit
        .register .form-control[type="submit"],
        .register
        .plugin-release .middle-submit .form-control[type="submit"],
        .plugin-release .middle-submit
        .info-page .form-control[type="submit"],
        .info-page
        .plugin-release .middle-submit .form-control[type="submit"],
        .plugin-release .middle-submit .most-popular__wrapper .add_to_basket, .most-popular__wrapper
        .plugin-release .middle-submit .add_to_basket,
        .plugin-release .middle-submit .add-ons-list .views-row .add_to_basket, .add-ons-list .views-row
        .plugin-release .middle-submit .add_to_basket,
        .plugin-release .middle-submit
        .add-ons-list .views-row .check-details,
        .add-ons-list .views-row
        .plugin-release .middle-submit .check-details,
        .plugin-release .middle-submit
        .add-ons-list .views-row .remove_from_basket,
        .add-ons-list .views-row
        .plugin-release .middle-submit .remove_from_basket,
        .plugin-release .middle-submit
        .add-ons-list .views-row .required,
        .add-ons-list .views-row
        .plugin-release .middle-submit .required {
          min-width: 240px;
          width: auto; } }

.left-sidebar-block {
  float: left;
  width: 100%;
  margin-bottom: 2.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15); }
  .left-sidebar-block h4 {
    margin: 0; }
  @media (min-width: 1280px) {
    .left-sidebar-block {
      width: 260px;
      padding-right: 2.5rem;
      margin-bottom: 0;
      border-bottom: none; } }
  @media (min-width: 1280px) {
    .left-sidebar-block__item, .left-sidebar-block__options {
      margin-bottom: 2.5rem; } }
  .left-sidebar-block__item--centered, .left-sidebar-block__options--centered {
    text-align: center; }
  .left-sidebar-block__options h2 {
    font-size: 2.4rem; }
  .left-sidebar-block__options .input-wrapper,
  .left-sidebar-block__options .select-wrapper {
    margin-bottom: 15px; }
    .left-sidebar-block__options .input-wrapper:last-of-type,
    .left-sidebar-block__options .select-wrapper:last-of-type {
      margin-bottom: 0; }
  .left-sidebar-block__options .addon-of-the-month {
    display: inline-block;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: #f2f9ff;
    list-style: none; }
    .left-sidebar-block__options .addon-of-the-month__item {
      margin: 2em auto;
      width: 128px; }
      .left-sidebar-block__options .addon-of-the-month__item p {
        display: block;
        width: 126px;
        height: 127px;
        position: relative;
        margin: 0;
        border-radius: 15px;
        border: 1px solid #d4d4d4;
        text-align: center; }
        .left-sidebar-block__options .addon-of-the-month__item p img {
          position: absolute;
          top: -10px;
          left: -10px; }
        .left-sidebar-block__options .addon-of-the-month__item p span {
          display: inline-block;
          margin-top: 50px;
          transform: rotate(-45deg);
          font-weight: bold;
          margin-left: 30px;
          line-height: 1.8em;
          font-size: 16px; }
  .left-sidebar-block__submit {
    margin-top: 10px;
    text-align: center; }
    .left-sidebar-block__submit .btn, .left-sidebar-block__submit #cksource_repo_search_forums_form .form-submit, #cksource_repo_search_forums_form .left-sidebar-block__submit .form-submit, .left-sidebar-block__submit #forum-sort .form-submit, #forum-sort .left-sidebar-block__submit .form-submit, .left-sidebar-block__submit .add-plugin input[type="submit"], .add-plugin .left-sidebar-block__submit input[type="submit"], .left-sidebar-block__submit
    .add-plugin select[type="submit"],
    .add-plugin .left-sidebar-block__submit select[type="submit"], .left-sidebar-block__submit
    .plugin-edit input[type="submit"],
    .plugin-edit .left-sidebar-block__submit input[type="submit"], .left-sidebar-block__submit
    .plugin-edit select[type="submit"],
    .plugin-edit .left-sidebar-block__submit select[type="submit"], .left-sidebar-block__submit
    .plugin-release input[type="submit"],
    .plugin-release .left-sidebar-block__submit input[type="submit"], .left-sidebar-block__submit
    .plugin-release select[type="submit"],
    .plugin-release .left-sidebar-block__submit select[type="submit"], .left-sidebar-block__submit .add-plugin .field-type-image-form input[type="file"], .add-plugin .field-type-image-form .left-sidebar-block__submit input[type="file"], .left-sidebar-block__submit
    .plugin-edit .field-type-image-form input[type="file"],
    .plugin-edit .field-type-image-form .left-sidebar-block__submit input[type="file"], .left-sidebar-block__submit
    .plugin-release .field-type-image-form input[type="file"],
    .plugin-release .field-type-image-form .left-sidebar-block__submit input[type="file"], .left-sidebar-block__submit .add-plugin .field-type-image-form button, .add-plugin .field-type-image-form .left-sidebar-block__submit button, .left-sidebar-block__submit
    .plugin-edit .field-type-image-form button,
    .plugin-edit .field-type-image-form .left-sidebar-block__submit button, .left-sidebar-block__submit
    .plugin-release .field-type-image-form button,
    .plugin-release .field-type-image-form .left-sidebar-block__submit button, .left-sidebar-block__submit .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], .add-plugin .field-type-image-form .left-sidebar-block__submit input[name="field_addon_logo_und_0_remove_button"], .left-sidebar-block__submit
    .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
    .plugin-edit .field-type-image-form .left-sidebar-block__submit input[name="field_addon_logo_und_0_remove_button"], .left-sidebar-block__submit
    .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
    .plugin-release .field-type-image-form .left-sidebar-block__submit input[name="field_addon_logo_und_0_remove_button"], .left-sidebar-block__submit #editor-builder #draggable-plugins .all-elements-option a, #editor-builder #draggable-plugins .all-elements-option .left-sidebar-block__submit a, .left-sidebar-block__submit
    #editor-builder .languages .all-elements-option a,
    #editor-builder .languages .all-elements-option .left-sidebar-block__submit a, .left-sidebar-block__submit .login .form-control[type="submit"], .login .left-sidebar-block__submit .form-control[type="submit"], .left-sidebar-block__submit
    .register .form-control[type="submit"],
    .register .left-sidebar-block__submit .form-control[type="submit"], .left-sidebar-block__submit
    .info-page .form-control[type="submit"],
    .info-page .left-sidebar-block__submit .form-control[type="submit"], .left-sidebar-block__submit .most-popular__wrapper .add_to_basket, .most-popular__wrapper .left-sidebar-block__submit .add_to_basket, .left-sidebar-block__submit .add-ons-list .views-row .add_to_basket, .add-ons-list .views-row .left-sidebar-block__submit .add_to_basket, .left-sidebar-block__submit
    .add-ons-list .views-row .check-details,
    .add-ons-list .views-row .left-sidebar-block__submit .check-details, .left-sidebar-block__submit
    .add-ons-list .views-row .remove_from_basket,
    .add-ons-list .views-row .left-sidebar-block__submit .remove_from_basket, .left-sidebar-block__submit
    .add-ons-list .views-row .required,
    .add-ons-list .views-row .left-sidebar-block__submit .required, .left-sidebar-block__submit .user-edit input[type="submit"], .user-edit .left-sidebar-block__submit input[type="submit"], .left-sidebar-block__submit
    .user-edit select[type="submit"],
    .user-edit .left-sidebar-block__submit select[type="submit"] {
      width: auto;
      max-width: 90%; }
      @media (min-width: 1280px) {
        .left-sidebar-block__submit .btn, .left-sidebar-block__submit #cksource_repo_search_forums_form .form-submit, #cksource_repo_search_forums_form .left-sidebar-block__submit .form-submit, .left-sidebar-block__submit #forum-sort .form-submit, #forum-sort .left-sidebar-block__submit .form-submit, .left-sidebar-block__submit .add-plugin input[type="submit"], .add-plugin .left-sidebar-block__submit input[type="submit"], .left-sidebar-block__submit
        .add-plugin select[type="submit"],
        .add-plugin .left-sidebar-block__submit select[type="submit"], .left-sidebar-block__submit
        .plugin-edit input[type="submit"],
        .plugin-edit .left-sidebar-block__submit input[type="submit"], .left-sidebar-block__submit
        .plugin-edit select[type="submit"],
        .plugin-edit .left-sidebar-block__submit select[type="submit"], .left-sidebar-block__submit
        .plugin-release input[type="submit"],
        .plugin-release .left-sidebar-block__submit input[type="submit"], .left-sidebar-block__submit
        .plugin-release select[type="submit"],
        .plugin-release .left-sidebar-block__submit select[type="submit"], .left-sidebar-block__submit .add-plugin .field-type-image-form input[type="file"], .add-plugin .field-type-image-form .left-sidebar-block__submit input[type="file"], .left-sidebar-block__submit
        .plugin-edit .field-type-image-form input[type="file"],
        .plugin-edit .field-type-image-form .left-sidebar-block__submit input[type="file"], .left-sidebar-block__submit
        .plugin-release .field-type-image-form input[type="file"],
        .plugin-release .field-type-image-form .left-sidebar-block__submit input[type="file"], .left-sidebar-block__submit .add-plugin .field-type-image-form button, .add-plugin .field-type-image-form .left-sidebar-block__submit button, .left-sidebar-block__submit
        .plugin-edit .field-type-image-form button,
        .plugin-edit .field-type-image-form .left-sidebar-block__submit button, .left-sidebar-block__submit
        .plugin-release .field-type-image-form button,
        .plugin-release .field-type-image-form .left-sidebar-block__submit button, .left-sidebar-block__submit .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], .add-plugin .field-type-image-form .left-sidebar-block__submit input[name="field_addon_logo_und_0_remove_button"], .left-sidebar-block__submit
        .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
        .plugin-edit .field-type-image-form .left-sidebar-block__submit input[name="field_addon_logo_und_0_remove_button"], .left-sidebar-block__submit
        .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
        .plugin-release .field-type-image-form .left-sidebar-block__submit input[name="field_addon_logo_und_0_remove_button"], .left-sidebar-block__submit #editor-builder #draggable-plugins .all-elements-option a, #editor-builder #draggable-plugins .all-elements-option .left-sidebar-block__submit a, .left-sidebar-block__submit
        #editor-builder .languages .all-elements-option a,
        #editor-builder .languages .all-elements-option .left-sidebar-block__submit a, .left-sidebar-block__submit .login .form-control[type="submit"], .login .left-sidebar-block__submit .form-control[type="submit"], .left-sidebar-block__submit
        .register .form-control[type="submit"],
        .register .left-sidebar-block__submit .form-control[type="submit"], .left-sidebar-block__submit
        .info-page .form-control[type="submit"],
        .info-page .left-sidebar-block__submit .form-control[type="submit"], .left-sidebar-block__submit .most-popular__wrapper .add_to_basket, .most-popular__wrapper .left-sidebar-block__submit .add_to_basket, .left-sidebar-block__submit .add-ons-list .views-row .add_to_basket, .add-ons-list .views-row .left-sidebar-block__submit .add_to_basket, .left-sidebar-block__submit
        .add-ons-list .views-row .check-details,
        .add-ons-list .views-row .left-sidebar-block__submit .check-details, .left-sidebar-block__submit
        .add-ons-list .views-row .remove_from_basket,
        .add-ons-list .views-row .left-sidebar-block__submit .remove_from_basket, .left-sidebar-block__submit
        .add-ons-list .views-row .required,
        .add-ons-list .views-row .left-sidebar-block__submit .required, .left-sidebar-block__submit .user-edit input[type="submit"], .user-edit .left-sidebar-block__submit input[type="submit"], .left-sidebar-block__submit
        .user-edit select[type="submit"],
        .user-edit .left-sidebar-block__submit select[type="submit"] {
          width: 100%;
          max-width: 100%; } }
  .left-sidebar-block .dropdown {
    margin-bottom: 1rem; }
  .left-sidebar-block .search #edit-query {
    height: 35px; }

.category-filter {
  display: inline-block;
  width: 100%;
  font-size: 1.6rem;
  list-style: none;
  margin: 0;
  padding: 0; }
  .category-filter__list-item {
    position: relative;
    padding: 12px;
    padding-left: 0;
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15); }
    .category-filter__list-item a {
      color: rgba(64, 81, 102, 0.96); }
      .category-filter__list-item a:hover {
        color: #30a3f1; }
    .category-filter__list-item--active a {
      color: #30a3f1; }
    .category-filter__list-item:last-of-type {
      border-bottom: none; }
  .category-filter__threads-quantity {
    position: absolute;
    right: 5px;
    top: 50%;
    border-radius: 8px;
    padding: 2px 8px;
    background-color: #d0d0d0;
    transform: translateY(-50%); }

.login,
.register,
.info-page {
  width: 900px;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
  margin-top: 7.5rem;
  margin-bottom: 10rem; }
  .login--small,
  .register--small,
  .info-page--small {
    max-width: 850px; }
  .login form,
  .register form,
  .info-page form {
    max-width: 100%;
    width: 640px;
    margin: 0 auto; }
  .login label,
  .register label,
  .info-page label {
    text-align: left; }
  .login h2 + h3,
  .register h2 + h3,
  .info-page h2 + h3 {
    width: 700px;
    margin: 2.5rem auto 1.5rem; }
  .login .form-control,
  .register .form-control,
  .info-page .form-control {
    margin-bottom: 2.5rem; }
  .login .focus-placeholder,
  .register .focus-placeholder,
  .info-page .focus-placeholder {
    display: none; }
  .login .sign-up__encourage,
  .register .sign-up__encourage,
  .info-page .sign-up__encourage {
    margin-top: 0;
    margin-bottom: 2.5rem;
    font-size: 2.1rem;
    font-weight: 200; }
    .login .sign-up__encourage.spaced,
    .register .sign-up__encourage.spaced,
    .info-page .sign-up__encourage.spaced {
      margin-bottom: 5rem; }
  .login .btn, .login #cksource_repo_search_forums_form .form-submit, #cksource_repo_search_forums_form .login .form-submit, .login #forum-sort .form-submit, #forum-sort .login .form-submit, .login .add-plugin input[type="submit"], .add-plugin .login input[type="submit"], .login
  .add-plugin select[type="submit"],
  .add-plugin .login select[type="submit"], .login
  .plugin-edit input[type="submit"],
  .plugin-edit .login input[type="submit"], .login
  .plugin-edit select[type="submit"],
  .plugin-edit .login select[type="submit"], .login
  .plugin-release input[type="submit"],
  .plugin-release .login input[type="submit"], .login
  .plugin-release select[type="submit"],
  .plugin-release .login select[type="submit"], .login .add-plugin .field-type-image-form input[type="file"], .add-plugin .field-type-image-form .login input[type="file"], .login
  .plugin-edit .field-type-image-form input[type="file"],
  .plugin-edit .field-type-image-form .login input[type="file"], .login
  .plugin-release .field-type-image-form input[type="file"],
  .plugin-release .field-type-image-form .login input[type="file"], .login .add-plugin .field-type-image-form button, .add-plugin .field-type-image-form .login button, .login
  .plugin-edit .field-type-image-form button,
  .plugin-edit .field-type-image-form .login button, .login
  .plugin-release .field-type-image-form button,
  .plugin-release .field-type-image-form .login button, .login .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], .add-plugin .field-type-image-form .login input[name="field_addon_logo_und_0_remove_button"], .login
  .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
  .plugin-edit .field-type-image-form .login input[name="field_addon_logo_und_0_remove_button"], .login
  .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
  .plugin-release .field-type-image-form .login input[name="field_addon_logo_und_0_remove_button"], .login #editor-builder #draggable-plugins .all-elements-option a, #editor-builder #draggable-plugins .all-elements-option .login a, .login
  #editor-builder .languages .all-elements-option a,
  #editor-builder .languages .all-elements-option .login a, .login .form-control[type="submit"], .login .most-popular__wrapper .add_to_basket, .most-popular__wrapper .login .add_to_basket, .login .add-ons-list .views-row .add_to_basket, .add-ons-list .views-row .login .add_to_basket, .login
  .add-ons-list .views-row .check-details,
  .add-ons-list .views-row .login .check-details, .login
  .add-ons-list .views-row .remove_from_basket,
  .add-ons-list .views-row .login .remove_from_basket, .login
  .add-ons-list .views-row .required,
  .add-ons-list .views-row .login .required, .login .user-edit input[type="submit"], .user-edit .login input[type="submit"], .login
  .user-edit select[type="submit"],
  .user-edit .login select[type="submit"],
  .register .btn,
  .register #cksource_repo_search_forums_form .form-submit, #cksource_repo_search_forums_form
  .register .form-submit,
  .register #forum-sort .form-submit, #forum-sort
  .register .form-submit,
  .register .add-plugin input[type="submit"], .add-plugin
  .register input[type="submit"],
  .register
  .add-plugin select[type="submit"],
  .add-plugin
  .register select[type="submit"],
  .register
  .plugin-edit input[type="submit"],
  .plugin-edit
  .register input[type="submit"],
  .register
  .plugin-edit select[type="submit"],
  .plugin-edit
  .register select[type="submit"],
  .register
  .plugin-release input[type="submit"],
  .plugin-release
  .register input[type="submit"],
  .register
  .plugin-release select[type="submit"],
  .plugin-release
  .register select[type="submit"],
  .register .add-plugin .field-type-image-form input[type="file"], .add-plugin .field-type-image-form
  .register input[type="file"],
  .register
  .plugin-edit .field-type-image-form input[type="file"],
  .plugin-edit .field-type-image-form
  .register input[type="file"],
  .register
  .plugin-release .field-type-image-form input[type="file"],
  .plugin-release .field-type-image-form
  .register input[type="file"],
  .register .add-plugin .field-type-image-form button, .add-plugin .field-type-image-form
  .register button,
  .register
  .plugin-edit .field-type-image-form button,
  .plugin-edit .field-type-image-form
  .register button,
  .register
  .plugin-release .field-type-image-form button,
  .plugin-release .field-type-image-form
  .register button,
  .register .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], .add-plugin .field-type-image-form
  .register input[name="field_addon_logo_und_0_remove_button"],
  .register
  .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
  .plugin-edit .field-type-image-form
  .register input[name="field_addon_logo_und_0_remove_button"],
  .register
  .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
  .plugin-release .field-type-image-form
  .register input[name="field_addon_logo_und_0_remove_button"],
  .register #editor-builder #draggable-plugins .all-elements-option a, #editor-builder #draggable-plugins .all-elements-option
  .register a,
  .register
  #editor-builder .languages .all-elements-option a,
  #editor-builder .languages .all-elements-option
  .register a, .register .form-control[type="submit"],
  .register .most-popular__wrapper .add_to_basket, .most-popular__wrapper
  .register .add_to_basket,
  .register .add-ons-list .views-row .add_to_basket, .add-ons-list .views-row
  .register .add_to_basket,
  .register
  .add-ons-list .views-row .check-details,
  .add-ons-list .views-row
  .register .check-details,
  .register
  .add-ons-list .views-row .remove_from_basket,
  .add-ons-list .views-row
  .register .remove_from_basket,
  .register
  .add-ons-list .views-row .required,
  .add-ons-list .views-row
  .register .required,
  .register .user-edit input[type="submit"], .user-edit
  .register input[type="submit"],
  .register
  .user-edit select[type="submit"],
  .user-edit
  .register select[type="submit"],
  .info-page .btn,
  .info-page #cksource_repo_search_forums_form .form-submit, #cksource_repo_search_forums_form
  .info-page .form-submit,
  .info-page #forum-sort .form-submit, #forum-sort
  .info-page .form-submit,
  .info-page .add-plugin input[type="submit"], .add-plugin
  .info-page input[type="submit"],
  .info-page
  .add-plugin select[type="submit"],
  .add-plugin
  .info-page select[type="submit"],
  .info-page
  .plugin-edit input[type="submit"],
  .plugin-edit
  .info-page input[type="submit"],
  .info-page
  .plugin-edit select[type="submit"],
  .plugin-edit
  .info-page select[type="submit"],
  .info-page
  .plugin-release input[type="submit"],
  .plugin-release
  .info-page input[type="submit"],
  .info-page
  .plugin-release select[type="submit"],
  .plugin-release
  .info-page select[type="submit"],
  .info-page .add-plugin .field-type-image-form input[type="file"], .add-plugin .field-type-image-form
  .info-page input[type="file"],
  .info-page
  .plugin-edit .field-type-image-form input[type="file"],
  .plugin-edit .field-type-image-form
  .info-page input[type="file"],
  .info-page
  .plugin-release .field-type-image-form input[type="file"],
  .plugin-release .field-type-image-form
  .info-page input[type="file"],
  .info-page .add-plugin .field-type-image-form button, .add-plugin .field-type-image-form
  .info-page button,
  .info-page
  .plugin-edit .field-type-image-form button,
  .plugin-edit .field-type-image-form
  .info-page button,
  .info-page
  .plugin-release .field-type-image-form button,
  .plugin-release .field-type-image-form
  .info-page button,
  .info-page .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], .add-plugin .field-type-image-form
  .info-page input[name="field_addon_logo_und_0_remove_button"],
  .info-page
  .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
  .plugin-edit .field-type-image-form
  .info-page input[name="field_addon_logo_und_0_remove_button"],
  .info-page
  .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
  .plugin-release .field-type-image-form
  .info-page input[name="field_addon_logo_und_0_remove_button"],
  .info-page #editor-builder #draggable-plugins .all-elements-option a, #editor-builder #draggable-plugins .all-elements-option
  .info-page a,
  .info-page
  #editor-builder .languages .all-elements-option a,
  #editor-builder .languages .all-elements-option
  .info-page a, .info-page .form-control[type="submit"],
  .info-page .most-popular__wrapper .add_to_basket, .most-popular__wrapper
  .info-page .add_to_basket,
  .info-page .add-ons-list .views-row .add_to_basket, .add-ons-list .views-row
  .info-page .add_to_basket,
  .info-page
  .add-ons-list .views-row .check-details,
  .add-ons-list .views-row
  .info-page .check-details,
  .info-page
  .add-ons-list .views-row .remove_from_basket,
  .add-ons-list .views-row
  .info-page .remove_from_basket,
  .info-page
  .add-ons-list .views-row .required,
  .add-ons-list .views-row
  .info-page .required,
  .info-page .user-edit input[type="submit"], .user-edit
  .info-page input[type="submit"],
  .info-page
  .user-edit select[type="submit"],
  .user-edit
  .info-page select[type="submit"] {
    margin-right: 2.5rem; }
    .login .btn:last-of-type, .login #cksource_repo_search_forums_form .form-submit:last-of-type, #cksource_repo_search_forums_form .login .form-submit:last-of-type, .login #forum-sort .form-submit:last-of-type, #forum-sort .login .form-submit:last-of-type, .login .add-plugin input[type="submit"]:last-of-type, .add-plugin .login input[type="submit"]:last-of-type, .login
    .add-plugin select[type="submit"]:last-of-type,
    .add-plugin .login select[type="submit"]:last-of-type, .login
    .plugin-edit input[type="submit"]:last-of-type,
    .plugin-edit .login input[type="submit"]:last-of-type, .login
    .plugin-edit select[type="submit"]:last-of-type,
    .plugin-edit .login select[type="submit"]:last-of-type, .login
    .plugin-release input[type="submit"]:last-of-type,
    .plugin-release .login input[type="submit"]:last-of-type, .login
    .plugin-release select[type="submit"]:last-of-type,
    .plugin-release .login select[type="submit"]:last-of-type, .login .add-plugin .field-type-image-form input[type="file"]:last-of-type, .add-plugin .field-type-image-form .login input[type="file"]:last-of-type, .login
    .plugin-edit .field-type-image-form input[type="file"]:last-of-type,
    .plugin-edit .field-type-image-form .login input[type="file"]:last-of-type, .login
    .plugin-release .field-type-image-form input[type="file"]:last-of-type,
    .plugin-release .field-type-image-form .login input[type="file"]:last-of-type, .login .add-plugin .field-type-image-form button:last-of-type, .add-plugin .field-type-image-form .login button:last-of-type, .login
    .plugin-edit .field-type-image-form button:last-of-type,
    .plugin-edit .field-type-image-form .login button:last-of-type, .login
    .plugin-release .field-type-image-form button:last-of-type,
    .plugin-release .field-type-image-form .login button:last-of-type, .login .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:last-of-type, .add-plugin .field-type-image-form .login input[name="field_addon_logo_und_0_remove_button"]:last-of-type, .login
    .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:last-of-type,
    .plugin-edit .field-type-image-form .login input[name="field_addon_logo_und_0_remove_button"]:last-of-type, .login
    .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:last-of-type,
    .plugin-release .field-type-image-form .login input[name="field_addon_logo_und_0_remove_button"]:last-of-type, .login #editor-builder #draggable-plugins .all-elements-option a:last-of-type, #editor-builder #draggable-plugins .all-elements-option .login a:last-of-type, .login
    #editor-builder .languages .all-elements-option a:last-of-type,
    #editor-builder .languages .all-elements-option .login a:last-of-type, .login .form-control[type="submit"]:last-of-type, .login .most-popular__wrapper .add_to_basket:last-of-type, .most-popular__wrapper .login .add_to_basket:last-of-type, .login .add-ons-list .views-row .add_to_basket:last-of-type, .add-ons-list .views-row .login .add_to_basket:last-of-type, .login
    .add-ons-list .views-row .check-details:last-of-type,
    .add-ons-list .views-row .login .check-details:last-of-type, .login
    .add-ons-list .views-row .remove_from_basket:last-of-type,
    .add-ons-list .views-row .login .remove_from_basket:last-of-type, .login
    .add-ons-list .views-row .required:last-of-type,
    .add-ons-list .views-row .login .required:last-of-type, .login .user-edit input[type="submit"]:last-of-type, .user-edit .login input[type="submit"]:last-of-type, .login
    .user-edit select[type="submit"]:last-of-type,
    .user-edit .login select[type="submit"]:last-of-type,
    .register .btn:last-of-type,
    .register #cksource_repo_search_forums_form .form-submit:last-of-type, #cksource_repo_search_forums_form
    .register .form-submit:last-of-type,
    .register #forum-sort .form-submit:last-of-type, #forum-sort
    .register .form-submit:last-of-type,
    .register .add-plugin input[type="submit"]:last-of-type, .add-plugin
    .register input[type="submit"]:last-of-type,
    .register
    .add-plugin select[type="submit"]:last-of-type,
    .add-plugin
    .register select[type="submit"]:last-of-type,
    .register
    .plugin-edit input[type="submit"]:last-of-type,
    .plugin-edit
    .register input[type="submit"]:last-of-type,
    .register
    .plugin-edit select[type="submit"]:last-of-type,
    .plugin-edit
    .register select[type="submit"]:last-of-type,
    .register
    .plugin-release input[type="submit"]:last-of-type,
    .plugin-release
    .register input[type="submit"]:last-of-type,
    .register
    .plugin-release select[type="submit"]:last-of-type,
    .plugin-release
    .register select[type="submit"]:last-of-type,
    .register .add-plugin .field-type-image-form input[type="file"]:last-of-type, .add-plugin .field-type-image-form
    .register input[type="file"]:last-of-type,
    .register
    .plugin-edit .field-type-image-form input[type="file"]:last-of-type,
    .plugin-edit .field-type-image-form
    .register input[type="file"]:last-of-type,
    .register
    .plugin-release .field-type-image-form input[type="file"]:last-of-type,
    .plugin-release .field-type-image-form
    .register input[type="file"]:last-of-type,
    .register .add-plugin .field-type-image-form button:last-of-type, .add-plugin .field-type-image-form
    .register button:last-of-type,
    .register
    .plugin-edit .field-type-image-form button:last-of-type,
    .plugin-edit .field-type-image-form
    .register button:last-of-type,
    .register
    .plugin-release .field-type-image-form button:last-of-type,
    .plugin-release .field-type-image-form
    .register button:last-of-type,
    .register .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:last-of-type, .add-plugin .field-type-image-form
    .register input[name="field_addon_logo_und_0_remove_button"]:last-of-type,
    .register
    .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:last-of-type,
    .plugin-edit .field-type-image-form
    .register input[name="field_addon_logo_und_0_remove_button"]:last-of-type,
    .register
    .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:last-of-type,
    .plugin-release .field-type-image-form
    .register input[name="field_addon_logo_und_0_remove_button"]:last-of-type,
    .register #editor-builder #draggable-plugins .all-elements-option a:last-of-type, #editor-builder #draggable-plugins .all-elements-option
    .register a:last-of-type,
    .register
    #editor-builder .languages .all-elements-option a:last-of-type,
    #editor-builder .languages .all-elements-option
    .register a:last-of-type, .register .form-control[type="submit"]:last-of-type,
    .register .most-popular__wrapper .add_to_basket:last-of-type, .most-popular__wrapper
    .register .add_to_basket:last-of-type,
    .register .add-ons-list .views-row .add_to_basket:last-of-type, .add-ons-list .views-row
    .register .add_to_basket:last-of-type,
    .register
    .add-ons-list .views-row .check-details:last-of-type,
    .add-ons-list .views-row
    .register .check-details:last-of-type,
    .register
    .add-ons-list .views-row .remove_from_basket:last-of-type,
    .add-ons-list .views-row
    .register .remove_from_basket:last-of-type,
    .register
    .add-ons-list .views-row .required:last-of-type,
    .add-ons-list .views-row
    .register .required:last-of-type,
    .register .user-edit input[type="submit"]:last-of-type, .user-edit
    .register input[type="submit"]:last-of-type,
    .register
    .user-edit select[type="submit"]:last-of-type,
    .user-edit
    .register select[type="submit"]:last-of-type,
    .info-page .btn:last-of-type,
    .info-page #cksource_repo_search_forums_form .form-submit:last-of-type, #cksource_repo_search_forums_form
    .info-page .form-submit:last-of-type,
    .info-page #forum-sort .form-submit:last-of-type, #forum-sort
    .info-page .form-submit:last-of-type,
    .info-page .add-plugin input[type="submit"]:last-of-type, .add-plugin
    .info-page input[type="submit"]:last-of-type,
    .info-page
    .add-plugin select[type="submit"]:last-of-type,
    .add-plugin
    .info-page select[type="submit"]:last-of-type,
    .info-page
    .plugin-edit input[type="submit"]:last-of-type,
    .plugin-edit
    .info-page input[type="submit"]:last-of-type,
    .info-page
    .plugin-edit select[type="submit"]:last-of-type,
    .plugin-edit
    .info-page select[type="submit"]:last-of-type,
    .info-page
    .plugin-release input[type="submit"]:last-of-type,
    .plugin-release
    .info-page input[type="submit"]:last-of-type,
    .info-page
    .plugin-release select[type="submit"]:last-of-type,
    .plugin-release
    .info-page select[type="submit"]:last-of-type,
    .info-page .add-plugin .field-type-image-form input[type="file"]:last-of-type, .add-plugin .field-type-image-form
    .info-page input[type="file"]:last-of-type,
    .info-page
    .plugin-edit .field-type-image-form input[type="file"]:last-of-type,
    .plugin-edit .field-type-image-form
    .info-page input[type="file"]:last-of-type,
    .info-page
    .plugin-release .field-type-image-form input[type="file"]:last-of-type,
    .plugin-release .field-type-image-form
    .info-page input[type="file"]:last-of-type,
    .info-page .add-plugin .field-type-image-form button:last-of-type, .add-plugin .field-type-image-form
    .info-page button:last-of-type,
    .info-page
    .plugin-edit .field-type-image-form button:last-of-type,
    .plugin-edit .field-type-image-form
    .info-page button:last-of-type,
    .info-page
    .plugin-release .field-type-image-form button:last-of-type,
    .plugin-release .field-type-image-form
    .info-page button:last-of-type,
    .info-page .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:last-of-type, .add-plugin .field-type-image-form
    .info-page input[name="field_addon_logo_und_0_remove_button"]:last-of-type,
    .info-page
    .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:last-of-type,
    .plugin-edit .field-type-image-form
    .info-page input[name="field_addon_logo_und_0_remove_button"]:last-of-type,
    .info-page
    .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:last-of-type,
    .plugin-release .field-type-image-form
    .info-page input[name="field_addon_logo_und_0_remove_button"]:last-of-type,
    .info-page #editor-builder #draggable-plugins .all-elements-option a:last-of-type, #editor-builder #draggable-plugins .all-elements-option
    .info-page a:last-of-type,
    .info-page
    #editor-builder .languages .all-elements-option a:last-of-type,
    #editor-builder .languages .all-elements-option
    .info-page a:last-of-type, .info-page .form-control[type="submit"]:last-of-type,
    .info-page .most-popular__wrapper .add_to_basket:last-of-type, .most-popular__wrapper
    .info-page .add_to_basket:last-of-type,
    .info-page .add-ons-list .views-row .add_to_basket:last-of-type, .add-ons-list .views-row
    .info-page .add_to_basket:last-of-type,
    .info-page
    .add-ons-list .views-row .check-details:last-of-type,
    .add-ons-list .views-row
    .info-page .check-details:last-of-type,
    .info-page
    .add-ons-list .views-row .remove_from_basket:last-of-type,
    .add-ons-list .views-row
    .info-page .remove_from_basket:last-of-type,
    .info-page
    .add-ons-list .views-row .required:last-of-type,
    .add-ons-list .views-row
    .info-page .required:last-of-type,
    .info-page .user-edit input[type="submit"]:last-of-type, .user-edit
    .info-page input[type="submit"]:last-of-type,
    .info-page
    .user-edit select[type="submit"]:last-of-type,
    .user-edit
    .info-page select[type="submit"]:last-of-type {
      margin-right: 0; }
    .login .btn--fixed,
    .register .btn--fixed,
    .info-page .btn--fixed {
      margin-top: 2.5rem;
      min-width: 195px; }

#edit-mollom-captcha {
  display: block; }

#main-content {
  outline: none; }

.section.messages-wrapper + #main-content {
  padding-top: 0; }

.page-title {
  max-width: 100%;
  padding: 0;
  overflow: hidden;
  margin: 25px auto 0;
  text-align: left; }
  .page-title h1 {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 4.2rem;
    font-weight: 200;
    line-height: 1.04;
    margin-bottom: 2.5rem;
    position: relative; }
  .page-title__wrapper {
    margin-top: 0; }
    .page-title__wrapper + .section {
      margin-top: 2.5rem; }
  .page-title--middle {
    text-align: center; }

.text--center {
  text-align: center; }

.thumb__documentation {
  width: 150px;
  height: 150px;
  margin: 0 auto 2.5rem;
  background-image: url(../images/backgrounds/documentation.svg);
  background-repeat: no-repeat;
  background-size: cover; }
  @media (min-width: 768px) {
    .thumb__documentation {
      width: 215px;
      height: 215px; } }

#admin-menu.admin-menu-position-fixed {
  z-index: 9999991; }

#admin-menu .dropdown {
  display: block; }

#admin-menu input {
  height: auto; }

#admin-menu .menu {
  top: auto;
  width: auto;
  z-index: auto; }

.pager {
  display: inline-block;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  width: 100%;
  text-align: center; }
  .pager ul {
    list-style: none;
    padding: 0; }
    .pager ul li {
      display: none;
      width: 45%; }
      .pager ul li.pagination__prev, .pager ul li.pagination__next {
        display: inline-block;
        max-width: 17.5rem; }
      .pager ul li.pagination__prev {
        margin-right: 5px; }
      .pager ul li.pagination__next {
        margin-left: 5px; }
      @media (min-width: 1024px) {
        .pager ul li .pagination__next-label {
          display: none; } }
      .pager ul li .pagination__prev-arrow,
      .pager ul li .pagination__next-arrow {
        margin: 0; }
      .pager ul li .pagination__next-arrow {
        display: none; }
        @media (min-width: 1024px) {
          .pager ul li .pagination__next-arrow {
            display: block; } }
      @media (min-width: 1024px) {
        .pager ul li .pagination__prev-label {
          display: none; } }
      .pager ul li .pagination__prev-arrow {
        display: none; }
        @media (min-width: 1024px) {
          .pager ul li .pagination__prev-arrow {
            display: block; } }
      @media (min-width: 1024px) {
        .pager ul li {
          display: inline-block;
          width: auto; } }
  .pager a {
    display: inline-block;
    width: 100%;
    padding: 1rem;
    margin: 0;
    font-size: 1.3rem;
    font-weight: 500;
    text-decoration: none;
    color: rgba(64, 81, 102, 0.75);
    transition: all 200ms ease-in-out;
    border: 1px solid rgba(0, 0, 0, 0.15); }
    @media (min-width: 1024px) {
      .pager a {
        width: auto;
        padding: 0 1rem;
        font-weight: 300; } }
    .pager a span {
      margin-right: 0; }
    .pager a:hover, .pager a.current-page {
      background-color: rgba(0, 0, 0, 0.03);
      color: rgba(64, 81, 102, 0.96); }

.content-block {
  float: left; }
  .content-block--with-left-sidebar {
    width: 100%;
    margin-left: 0; }
    @media (min-width: 1280px) {
      .content-block--with-left-sidebar {
        width: calc(100% - 260px - 25px);
        margin-left: 25px; } }
    @media (min-width: 1280px) {
      .content-block--with-left-sidebar {
        width: calc(100% - 260px - ( 25px * 2 ));
        margin-left: 25px;
        margin-right: 25px; } }

.most-popular {
  margin-top: 5px;
  display: inline-block;
  width: 100%; }
  .most-popular__wrapper {
    position: relative;
    padding-top: 10px;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15); }
    .most-popular__wrapper h3 {
      padding-bottom: 20px;
      margin-top: 10px;
      margin-bottom: 0; }
    .most-popular__wrapper .downloads__counter {
      margin-top: 5px;
      display: inline-block;
      font-weight: 700; }
    .most-popular__wrapper .add_to_basket {
      float: right; }
      @media (min-width: 768px) {
        .most-popular__wrapper .add_to_basket {
          width: 200px; } }
  .most-popular__thumb {
    float: left;
    margin-right: 10px; }
  .most-popular__description {
    float: left; }
    .most-popular__description h4 {
      margin: 0; }
      .most-popular__description h4 a:hover {
        text-decoration: underline; }
    .most-popular__description .author {
      margin: 0;
      color: rgba(64, 81, 102, 0.96);
      font-size: 1.6rem; }

.most-popular p {
  font-weight: 400; }

.add-ons-list {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0; }
  .add-ons-list .views-row {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    padding-bottom: 2.5rem; }
    @media (min-width: 1280px) {
      .add-ons-list .views-row {
        padding-bottom: 7.5rem; } }
    .add-ons-list .views-row:last-of-type {
      border-bottom: none; }
    .add-ons-list .views-row > div {
      font-weight: 400;
      font-size: 1.6rem; }
      .add-ons-list .views-row > div h4 {
        margin-top: 2.5rem;
        margin-bottom: 5px;
        font-size: 1.9rem; }
        @media (min-width: 768px) {
          .add-ons-list .views-row > div h4 {
            font-size: 2.1rem; } }
        .add-ons-list .views-row > div h4 a:hover {
          text-decoration: underline; }
      .add-ons-list .views-row > div p {
        color: rgba(64, 81, 102, 0.75);
        font-size: 1.6rem;
        font-weight: 400;
        margin: 0; }
        .add-ons-list .views-row > div p.presets {
          font-size: 1.3rem; }
        .add-ons-list .views-row > div p:last-of-type {
          margin-bottom: 15px; }
    .add-ons-list .views-row .add_to_basket,
    .add-ons-list .views-row .check-details,
    .add-ons-list .views-row .remove_from_basket,
    .add-ons-list .views-row .required {
      display: none !important;
      float: right;
      margin-top: 20px;
      position: relative; }
      @media (min-width: 1280px) {
        .add-ons-list .views-row .add_to_basket,
        .add-ons-list .views-row .check-details,
        .add-ons-list .views-row .remove_from_basket,
        .add-ons-list .views-row .required {
          display: block !important;
          width: 17.5rem; } }
    .add-ons-list .views-row .add_to_basket {
      display: none !important; }
      @media (min-width: 1280px) {
        .add-ons-list .views-row .add_to_basket {
          display: block !important;
          width: 17.5rem; } }
    .add-ons-list .views-row .remove_from_basket {
      display: none !important; }
      @media (min-width: 1280px) {
        .add-ons-list .views-row .remove_from_basket {
          display: block !important;
          width: 17.5rem; } }
    .add-ons-list .views-row .required {
      display: none !important; }
      @media (min-width: 1280px) {
        .add-ons-list .views-row .required {
          display: block !important;
          width: 17.5rem; } }

.add-on-description .add_to_basket,
.add-on-description .remove_from_basket,
.add-on-description .required,
.add-on-description .btn-cta {
  position: relative; }

@media (min-width: 1280px) {
  .add-on-description .add_to_basket {
    width: 17.5rem; } }

@media (min-width: 1280px) {
  .add-on-description .remove_from_basket {
    width: 17.5rem; } }

@media (min-width: 1280px) {
  .add-on-description .required {
    width: 17.5rem; } }

.ckbuilder__logo {
  margin-bottom: 2.5rem; }

.commercial_ico:before {
  background: url(http://c.cksource.com/a/1/img/dollar.png) no-repeat scroll center center transparent;
  content: ' ';
  height: 17px;
  line-height: 17px;
  margin: 0;
  text-align: center;
  width: 17px;
  position: absolute;
  left: -22px;
  top: 5px; }

#presets_table .comparision__table th,
#presets_table .comparision__table td {
  min-width: 70px;
  padding: 8px 12px; }

.version-comparision {
  margin-top: 10px; }
  @media (min-width: 768px) {
    .version-comparision {
      margin-top: 0; } }
  @media (min-width: 1280px) {
    .version-comparision {
      width: 960px;
      max-width: 100%; } }
  .version-comparision--packages {
    margin: 0 auto; }
    @media (min-width: 768px) {
      .version-comparision--packages {
        margin-bottom: 70px; } }
    @media (min-width: 1280px) {
      .version-comparision--packages {
        margin-bottom: 100px; } }

.version-comparision__table-wrapper {
  padding: 0;
  overflow: auto; }
  .version-comparision__table-wrapper .table-title h2 {
    display: inline-block;
    margin-top: 2.5rem;
    margin-bottom: 0;
    padding-bottom: 20px; }
  .version-comparision__table-wrapper .table-title h3 {
    margin-top: 0;
    margin-bottom: 2.5rem; }

.comparision__table {
  width: 100%;
  margin-top: 0;
  font-size: 1.3rem; }
  @media (min-width: 1280px) {
    .comparision__table {
      margin: 0 auto;
      text-align: inherit; } }
  .comparision__table td {
    width: 138px;
    min-width: 138px;
    padding: 1.5rem;
    text-align: center;
    line-height: 1.4;
    border-right: 1px solid rgba(0, 0, 0, 0.15);
    border-color: rgba(0, 0, 0, 0.03); }
    @media (min-width: 1280px) {
      .comparision__table td {
        width: 125px;
        min-width: 125px; } }
    .comparision__table td p {
      margin: .5rem; }
    .comparision__table td:first-of-type {
      width: 300px;
      text-align: left; }
    .comparision__table td:last-of-type {
      border-right: none; }
    .comparision__table td.checked:after {
      content: '';
      display: block;
      width: 17px;
      height: 12px;
      margin: 0 auto;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url(../images/icons/check.svg); }
    .comparision__table td.optional:after {
      content: '';
      display: block;
      width: 17px;
      height: 12px;
      margin: 0 auto;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url(../images/icons/circle.svg); }
    .comparision__table td .btn, .comparision__table td #cksource_repo_search_forums_form .form-submit, #cksource_repo_search_forums_form .comparision__table td .form-submit, .comparision__table td #forum-sort .form-submit, #forum-sort .comparision__table td .form-submit, .comparision__table td .add-plugin input[type="submit"], .add-plugin .comparision__table td input[type="submit"], .comparision__table td
    .add-plugin select[type="submit"],
    .add-plugin .comparision__table td select[type="submit"], .comparision__table td
    .plugin-edit input[type="submit"],
    .plugin-edit .comparision__table td input[type="submit"], .comparision__table td
    .plugin-edit select[type="submit"],
    .plugin-edit .comparision__table td select[type="submit"], .comparision__table td
    .plugin-release input[type="submit"],
    .plugin-release .comparision__table td input[type="submit"], .comparision__table td
    .plugin-release select[type="submit"],
    .plugin-release .comparision__table td select[type="submit"], .comparision__table td .add-plugin .field-type-image-form input[type="file"], .add-plugin .field-type-image-form .comparision__table td input[type="file"], .comparision__table td
    .plugin-edit .field-type-image-form input[type="file"],
    .plugin-edit .field-type-image-form .comparision__table td input[type="file"], .comparision__table td
    .plugin-release .field-type-image-form input[type="file"],
    .plugin-release .field-type-image-form .comparision__table td input[type="file"], .comparision__table td .add-plugin .field-type-image-form button, .add-plugin .field-type-image-form .comparision__table td button, .comparision__table td
    .plugin-edit .field-type-image-form button,
    .plugin-edit .field-type-image-form .comparision__table td button, .comparision__table td
    .plugin-release .field-type-image-form button,
    .plugin-release .field-type-image-form .comparision__table td button, .comparision__table td .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], .add-plugin .field-type-image-form .comparision__table td input[name="field_addon_logo_und_0_remove_button"], .comparision__table td
    .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
    .plugin-edit .field-type-image-form .comparision__table td input[name="field_addon_logo_und_0_remove_button"], .comparision__table td
    .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
    .plugin-release .field-type-image-form .comparision__table td input[name="field_addon_logo_und_0_remove_button"], .comparision__table td #editor-builder #draggable-plugins .all-elements-option a, #editor-builder #draggable-plugins .all-elements-option .comparision__table td a, .comparision__table td
    #editor-builder .languages .all-elements-option a,
    #editor-builder .languages .all-elements-option .comparision__table td a, .comparision__table td .login .form-control[type="submit"], .login .comparision__table td .form-control[type="submit"], .comparision__table td
    .register .form-control[type="submit"],
    .register .comparision__table td .form-control[type="submit"], .comparision__table td
    .info-page .form-control[type="submit"],
    .info-page .comparision__table td .form-control[type="submit"], .comparision__table td .most-popular__wrapper .add_to_basket, .most-popular__wrapper .comparision__table td .add_to_basket, .comparision__table td .add-ons-list .views-row .add_to_basket, .add-ons-list .views-row .comparision__table td .add_to_basket, .comparision__table td
    .add-ons-list .views-row .check-details,
    .add-ons-list .views-row .comparision__table td .check-details, .comparision__table td
    .add-ons-list .views-row .remove_from_basket,
    .add-ons-list .views-row .comparision__table td .remove_from_basket, .comparision__table td
    .add-ons-list .views-row .required,
    .add-ons-list .views-row .comparision__table td .required, .comparision__table td .user-edit input[type="submit"], .user-edit .comparision__table td input[type="submit"], .comparision__table td
    .user-edit select[type="submit"],
    .user-edit .comparision__table td select[type="submit"] {
      display: block; }
    .comparision__table td .contact-link {
      font-weight: 600; }
    .comparision__table td.to--left {
      text-align: left; }
      .comparision__table td.to--left p {
        margin-left: 0; }
  .comparision__table th {
    font-size: 1.9rem;
    font-weight: 400;
    text-align: center;
    padding: 1.5rem;
    border-right: 1px solid rgba(0, 0, 0, 0.15);
    border-color: rgba(0, 0, 0, 0.03);
    white-space: nowrap; }
    .comparision__table th:first-of-type {
      width: 300px;
      text-align: left; }
    .comparision__table th:last-of-type {
      border-right: none; }
  .comparision__table tr:nth-child(even):not(.pricing-features__row--actions) {
    background-color: rgba(0, 0, 0, 0.03); }
  .comparision__table tr.subheader {
    font-size: 1.9rem;
    font-weight: 600; }

.table-legend {
  width: 100%;
  font-size: 1.6rem; }
  @media (min-width: 1280px) {
    .table-legend {
      margin: 2.5rem auto 0;
      text-align: inherit; } }
  .table-legend h3 {
    font-size: 2.1rem;
    font-weight: 600; }
  .table-legend__list {
    list-style: none;
    margin: 0;
    padding: 0; }
  .table-legend__item {
    padding-left: 10px; }
    .table-legend__item--checked:before {
      content: '';
      display: inline-block;
      width: 17px;
      height: 12px;
      margin-right: 15px;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url(../images/icons/check.svg); }
    .table-legend__item--optional:before {
      content: '';
      display: inline-block;
      width: 17px;
      height: 12px;
      margin-right: 15px;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url(../images/icons/circle.svg); }

.redirect-modal {
  text-align: center; }
  .redirect-modal__info {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 5px; }
  .redirect-modal__link {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 20px; }
  .redirect-modal__counter {
    font-size: 5rem; }
  .redirect-modal .counter-units {
    margin-left: 5px;
    font-size: 3rem;
    font-weight: 500;
    color: rgba(64, 81, 102, 0.75); }

.right-sidebar {
  display: inline-block;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.03);
  padding: 20px;
  margin-bottom: 30px; }
  .right-sidebar h3 {
    margin-top: 0;
    margin-bottom: 10px; }
  .right-sidebar__header {
    display: inline-block;
    width: 100%; }
    .right-sidebar__header--small {
      margin: 0; }
      .right-sidebar__header--small img {
        margin: 0; }
    .right-sidebar__header h2 {
      margin-top: 0;
      margin-bottom: 10px;
      line-height: .8;
      font-size: 2.8rem; }

.right-sidebar__section {
  display: inline-block;
  width: 100%;
  padding-top: 20px; }
  .right-sidebar__section-header {
    display: inline-block;
    width: 100%; }
    .right-sidebar__section-header h3 {
      font-size: 1.6rem;
      font-weight: 700;
      margin: 0; }
    .right-sidebar__section-header .tagline {
      margin: 0;
      font-size: 1.3rem; }
  .right-sidebar__section p {
    margin: 0;
    font-size: 1.6rem; }
  .right-sidebar__section .plugins-list {
    margin: 0;
    padding: 0;
    list-style: none; }
    .right-sidebar__section .plugins-list__item {
      text-decoration: none;
      font-size: 1.6rem; }
      .right-sidebar__section .plugins-list__item:before {
        content: '';
        float: left;
        margin-right: 15px; }
      .right-sidebar__section .plugins-list__item:last-of-type {
        padding: 0; }
  .right-sidebar__section .skin-link {
    font-size: 1.6rem; }
    .right-sidebar__section .skin-link:before {
      content: '';
      float: left;
      margin-right: 15px; }
  .right-sidebar__section:last-of-type {
    border-bottom: none; }

.fixedsticky {
  top: 100px;
  max-width: 291px; }

.search {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-size: 1.6rem;
  background-color: white; }
  .search:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 10px;
    color: rgba(64, 81, 102, 0.75);
    transform: translateY(-50%); }
  .search__input {
    width: 100%;
    border: none;
    padding-right: 35px;
    padding-left: 20px;
    border-radius: 4px; }

#presets_table {
  display: none; }

#presets_table, #ckeditor_versions_compare, .simplemodal-wrap {
  position: relative; }

.compare-presets {
  cursor: pointer; }

/* Overwrite for modal dialog */
#presets_table .comparision__table th,
#presets_table .comparision__table td {
  min-width: 70px; }

#presets_table .comparision__table td, #presets_table .comparision__table th {
  padding: 8px 12px; }

#simplemodal-overlay {
  background-color: #000;
  width: 100%;
  height: 100%; }

#simplemodal-container {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  max-width: 960px;
  background: #fff;
  padding: 30px 30px 40px; }
  @media (min-width: 1280px) {
    #simplemodal-container {
      min-height: 498px; } }
  #simplemodal-container .simplemodal-data {
    font-size: 1.4rem;
    overflow: hidden; }
    #simplemodal-container .simplemodal-data.addon_download h3 {
      margin: 0;
      padding: 10px 0; }
    #simplemodal-container .simplemodal-data.addon_download li {
      line-height: 2em; }
  #simplemodal-container a.modalCloseImg {
    background: url("https://c.cksource.com/a/5/img/sprites.png") no-repeat -142px -1640px;
    width: 13px;
    height: 13px;
    display: inline;
    z-index: 3200;
    position: absolute;
    top: 14px;
    right: 19px;
    cursor: pointer; }

.site-info {
  background-color: rgba(0, 0, 0, 0.03); }
  .site-info__wrapper {
    padding: 20px;
    color: rgba(64, 81, 102, 0.75);
    text-align: center; }
    @media (min-width: 768px) {
      .site-info__wrapper {
        text-align: left; } }
  .site-info__thumb {
    float: right;
    margin-right: 2.5rem; }
  .site-info__links-wrapper {
    font-size: 1.6rem;
    margin-top: 2.5rem; }
    @media (min-width: 768px) {
      .site-info__links-wrapper {
        padding: 15px 0 0 40px;
        margin-top: 0; } }
  .site-info__links {
    margin: 0;
    padding: 0;
    list-style: none; }
    @media (min-width: 768px) {
      .site-info__links {
        margin-bottom: 15px; } }
    .site-info__links-item {
      padding: 1px 0;
      font-weight: 400; }
  .site-info__version {
    margin-bottom: 15px; }
    .site-info__version .label {
      display: inline-block;
      width: 100%; }
    .site-info__version .version-number {
      display: inline-block;
      width: 100%;
      font-weight: bold; }
  .site-info__issue-link {
    font-weight: bold;
    text-decoration: none;
    color: rgba(64, 81, 102, 0.75); }
  @media (min-width: 768px) {
    .site-info__product {
      padding: 2.5rem;
      border-right: 1px solid rgba(0, 0, 0, 0.03); } }
  .site-info__product h2 {
    margin: 0 0 5px; }
  .site-info__product .tagline {
    margin: 0 0 2.5rem; }
  .site-info__action-block .btn, .site-info__action-block #cksource_repo_search_forums_form .form-submit, #cksource_repo_search_forums_form .site-info__action-block .form-submit, .site-info__action-block #forum-sort .form-submit, #forum-sort .site-info__action-block .form-submit, .site-info__action-block .add-plugin input[type="submit"], .add-plugin .site-info__action-block input[type="submit"], .site-info__action-block
  .add-plugin select[type="submit"],
  .add-plugin .site-info__action-block select[type="submit"], .site-info__action-block
  .plugin-edit input[type="submit"],
  .plugin-edit .site-info__action-block input[type="submit"], .site-info__action-block
  .plugin-edit select[type="submit"],
  .plugin-edit .site-info__action-block select[type="submit"], .site-info__action-block
  .plugin-release input[type="submit"],
  .plugin-release .site-info__action-block input[type="submit"], .site-info__action-block
  .plugin-release select[type="submit"],
  .plugin-release .site-info__action-block select[type="submit"], .site-info__action-block .add-plugin .field-type-image-form input[type="file"], .add-plugin .field-type-image-form .site-info__action-block input[type="file"], .site-info__action-block
  .plugin-edit .field-type-image-form input[type="file"],
  .plugin-edit .field-type-image-form .site-info__action-block input[type="file"], .site-info__action-block
  .plugin-release .field-type-image-form input[type="file"],
  .plugin-release .field-type-image-form .site-info__action-block input[type="file"], .site-info__action-block .add-plugin .field-type-image-form button, .add-plugin .field-type-image-form .site-info__action-block button, .site-info__action-block
  .plugin-edit .field-type-image-form button,
  .plugin-edit .field-type-image-form .site-info__action-block button, .site-info__action-block
  .plugin-release .field-type-image-form button,
  .plugin-release .field-type-image-form .site-info__action-block button, .site-info__action-block .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], .add-plugin .field-type-image-form .site-info__action-block input[name="field_addon_logo_und_0_remove_button"], .site-info__action-block
  .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
  .plugin-edit .field-type-image-form .site-info__action-block input[name="field_addon_logo_und_0_remove_button"], .site-info__action-block
  .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
  .plugin-release .field-type-image-form .site-info__action-block input[name="field_addon_logo_und_0_remove_button"], .site-info__action-block #editor-builder #draggable-plugins .all-elements-option a, #editor-builder #draggable-plugins .all-elements-option .site-info__action-block a, .site-info__action-block
  #editor-builder .languages .all-elements-option a,
  #editor-builder .languages .all-elements-option .site-info__action-block a, .site-info__action-block .login .form-control[type="submit"], .login .site-info__action-block .form-control[type="submit"], .site-info__action-block
  .register .form-control[type="submit"],
  .register .site-info__action-block .form-control[type="submit"], .site-info__action-block
  .info-page .form-control[type="submit"],
  .info-page .site-info__action-block .form-control[type="submit"], .site-info__action-block .most-popular__wrapper .add_to_basket, .most-popular__wrapper .site-info__action-block .add_to_basket, .site-info__action-block .add-ons-list .views-row .add_to_basket, .add-ons-list .views-row .site-info__action-block .add_to_basket, .site-info__action-block
  .add-ons-list .views-row .check-details,
  .add-ons-list .views-row .site-info__action-block .check-details, .site-info__action-block
  .add-ons-list .views-row .remove_from_basket,
  .add-ons-list .views-row .site-info__action-block .remove_from_basket, .site-info__action-block
  .add-ons-list .views-row .required,
  .add-ons-list .views-row .site-info__action-block .required, .site-info__action-block .user-edit input[type="submit"], .user-edit .site-info__action-block input[type="submit"], .site-info__action-block
  .user-edit select[type="submit"],
  .user-edit .site-info__action-block select[type="submit"] {
    min-width: 120px;
    margin-left: 5px; }
    .site-info__action-block .btn:first-of-type, .site-info__action-block #cksource_repo_search_forums_form .form-submit:first-of-type, #cksource_repo_search_forums_form .site-info__action-block .form-submit:first-of-type, .site-info__action-block #forum-sort .form-submit:first-of-type, #forum-sort .site-info__action-block .form-submit:first-of-type, .site-info__action-block .add-plugin input[type="submit"]:first-of-type, .add-plugin .site-info__action-block input[type="submit"]:first-of-type, .site-info__action-block
    .add-plugin select[type="submit"]:first-of-type,
    .add-plugin .site-info__action-block select[type="submit"]:first-of-type, .site-info__action-block
    .plugin-edit input[type="submit"]:first-of-type,
    .plugin-edit .site-info__action-block input[type="submit"]:first-of-type, .site-info__action-block
    .plugin-edit select[type="submit"]:first-of-type,
    .plugin-edit .site-info__action-block select[type="submit"]:first-of-type, .site-info__action-block
    .plugin-release input[type="submit"]:first-of-type,
    .plugin-release .site-info__action-block input[type="submit"]:first-of-type, .site-info__action-block
    .plugin-release select[type="submit"]:first-of-type,
    .plugin-release .site-info__action-block select[type="submit"]:first-of-type, .site-info__action-block .add-plugin .field-type-image-form input[type="file"]:first-of-type, .add-plugin .field-type-image-form .site-info__action-block input[type="file"]:first-of-type, .site-info__action-block
    .plugin-edit .field-type-image-form input[type="file"]:first-of-type,
    .plugin-edit .field-type-image-form .site-info__action-block input[type="file"]:first-of-type, .site-info__action-block
    .plugin-release .field-type-image-form input[type="file"]:first-of-type,
    .plugin-release .field-type-image-form .site-info__action-block input[type="file"]:first-of-type, .site-info__action-block .add-plugin .field-type-image-form button:first-of-type, .add-plugin .field-type-image-form .site-info__action-block button:first-of-type, .site-info__action-block
    .plugin-edit .field-type-image-form button:first-of-type,
    .plugin-edit .field-type-image-form .site-info__action-block button:first-of-type, .site-info__action-block
    .plugin-release .field-type-image-form button:first-of-type,
    .plugin-release .field-type-image-form .site-info__action-block button:first-of-type, .site-info__action-block .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:first-of-type, .add-plugin .field-type-image-form .site-info__action-block input[name="field_addon_logo_und_0_remove_button"]:first-of-type, .site-info__action-block
    .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:first-of-type,
    .plugin-edit .field-type-image-form .site-info__action-block input[name="field_addon_logo_und_0_remove_button"]:first-of-type, .site-info__action-block
    .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:first-of-type,
    .plugin-release .field-type-image-form .site-info__action-block input[name="field_addon_logo_und_0_remove_button"]:first-of-type, .site-info__action-block #editor-builder #draggable-plugins .all-elements-option a:first-of-type, #editor-builder #draggable-plugins .all-elements-option .site-info__action-block a:first-of-type, .site-info__action-block
    #editor-builder .languages .all-elements-option a:first-of-type,
    #editor-builder .languages .all-elements-option .site-info__action-block a:first-of-type, .site-info__action-block .login .form-control[type="submit"]:first-of-type, .login .site-info__action-block .form-control[type="submit"]:first-of-type, .site-info__action-block
    .register .form-control[type="submit"]:first-of-type,
    .register .site-info__action-block .form-control[type="submit"]:first-of-type, .site-info__action-block
    .info-page .form-control[type="submit"]:first-of-type,
    .info-page .site-info__action-block .form-control[type="submit"]:first-of-type, .site-info__action-block .most-popular__wrapper .add_to_basket:first-of-type, .most-popular__wrapper .site-info__action-block .add_to_basket:first-of-type, .site-info__action-block .add-ons-list .views-row .add_to_basket:first-of-type, .add-ons-list .views-row .site-info__action-block .add_to_basket:first-of-type, .site-info__action-block
    .add-ons-list .views-row .check-details:first-of-type,
    .add-ons-list .views-row .site-info__action-block .check-details:first-of-type, .site-info__action-block
    .add-ons-list .views-row .remove_from_basket:first-of-type,
    .add-ons-list .views-row .site-info__action-block .remove_from_basket:first-of-type, .site-info__action-block
    .add-ons-list .views-row .required:first-of-type,
    .add-ons-list .views-row .site-info__action-block .required:first-of-type, .site-info__action-block .user-edit input[type="submit"]:first-of-type, .user-edit .site-info__action-block input[type="submit"]:first-of-type, .site-info__action-block
    .user-edit select[type="submit"]:first-of-type,
    .user-edit .site-info__action-block select[type="submit"]:first-of-type {
      margin-right: 5px; }
    @media (min-width: 768px) {
      .site-info__action-block .btn, .site-info__action-block #cksource_repo_search_forums_form .form-submit, #cksource_repo_search_forums_form .site-info__action-block .form-submit, .site-info__action-block #forum-sort .form-submit, #forum-sort .site-info__action-block .form-submit, .site-info__action-block .add-plugin input[type="submit"], .add-plugin .site-info__action-block input[type="submit"], .site-info__action-block
      .add-plugin select[type="submit"],
      .add-plugin .site-info__action-block select[type="submit"], .site-info__action-block
      .plugin-edit input[type="submit"],
      .plugin-edit .site-info__action-block input[type="submit"], .site-info__action-block
      .plugin-edit select[type="submit"],
      .plugin-edit .site-info__action-block select[type="submit"], .site-info__action-block
      .plugin-release input[type="submit"],
      .plugin-release .site-info__action-block input[type="submit"], .site-info__action-block
      .plugin-release select[type="submit"],
      .plugin-release .site-info__action-block select[type="submit"], .site-info__action-block .add-plugin .field-type-image-form input[type="file"], .add-plugin .field-type-image-form .site-info__action-block input[type="file"], .site-info__action-block
      .plugin-edit .field-type-image-form input[type="file"],
      .plugin-edit .field-type-image-form .site-info__action-block input[type="file"], .site-info__action-block
      .plugin-release .field-type-image-form input[type="file"],
      .plugin-release .field-type-image-form .site-info__action-block input[type="file"], .site-info__action-block .add-plugin .field-type-image-form button, .add-plugin .field-type-image-form .site-info__action-block button, .site-info__action-block
      .plugin-edit .field-type-image-form button,
      .plugin-edit .field-type-image-form .site-info__action-block button, .site-info__action-block
      .plugin-release .field-type-image-form button,
      .plugin-release .field-type-image-form .site-info__action-block button, .site-info__action-block .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], .add-plugin .field-type-image-form .site-info__action-block input[name="field_addon_logo_und_0_remove_button"], .site-info__action-block
      .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
      .plugin-edit .field-type-image-form .site-info__action-block input[name="field_addon_logo_und_0_remove_button"], .site-info__action-block
      .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
      .plugin-release .field-type-image-form .site-info__action-block input[name="field_addon_logo_und_0_remove_button"], .site-info__action-block #editor-builder #draggable-plugins .all-elements-option a, #editor-builder #draggable-plugins .all-elements-option .site-info__action-block a, .site-info__action-block
      #editor-builder .languages .all-elements-option a,
      #editor-builder .languages .all-elements-option .site-info__action-block a, .site-info__action-block .login .form-control[type="submit"], .login .site-info__action-block .form-control[type="submit"], .site-info__action-block
      .register .form-control[type="submit"],
      .register .site-info__action-block .form-control[type="submit"], .site-info__action-block
      .info-page .form-control[type="submit"],
      .info-page .site-info__action-block .form-control[type="submit"], .site-info__action-block .most-popular__wrapper .add_to_basket, .most-popular__wrapper .site-info__action-block .add_to_basket, .site-info__action-block .add-ons-list .views-row .add_to_basket, .add-ons-list .views-row .site-info__action-block .add_to_basket, .site-info__action-block
      .add-ons-list .views-row .check-details,
      .add-ons-list .views-row .site-info__action-block .check-details, .site-info__action-block
      .add-ons-list .views-row .remove_from_basket,
      .add-ons-list .views-row .site-info__action-block .remove_from_basket, .site-info__action-block
      .add-ons-list .views-row .required,
      .add-ons-list .views-row .site-info__action-block .required, .site-info__action-block .user-edit input[type="submit"], .user-edit .site-info__action-block input[type="submit"], .site-info__action-block
      .user-edit select[type="submit"],
      .user-edit .site-info__action-block select[type="submit"] {
        margin-left: 15px;
        min-width: 0; }
        .site-info__action-block .btn:first-of-type, .site-info__action-block #cksource_repo_search_forums_form .form-submit:first-of-type, #cksource_repo_search_forums_form .site-info__action-block .form-submit:first-of-type, .site-info__action-block #forum-sort .form-submit:first-of-type, #forum-sort .site-info__action-block .form-submit:first-of-type, .site-info__action-block .add-plugin input[type="submit"]:first-of-type, .add-plugin .site-info__action-block input[type="submit"]:first-of-type, .site-info__action-block
        .add-plugin select[type="submit"]:first-of-type,
        .add-plugin .site-info__action-block select[type="submit"]:first-of-type, .site-info__action-block
        .plugin-edit input[type="submit"]:first-of-type,
        .plugin-edit .site-info__action-block input[type="submit"]:first-of-type, .site-info__action-block
        .plugin-edit select[type="submit"]:first-of-type,
        .plugin-edit .site-info__action-block select[type="submit"]:first-of-type, .site-info__action-block
        .plugin-release input[type="submit"]:first-of-type,
        .plugin-release .site-info__action-block input[type="submit"]:first-of-type, .site-info__action-block
        .plugin-release select[type="submit"]:first-of-type,
        .plugin-release .site-info__action-block select[type="submit"]:first-of-type, .site-info__action-block .add-plugin .field-type-image-form input[type="file"]:first-of-type, .add-plugin .field-type-image-form .site-info__action-block input[type="file"]:first-of-type, .site-info__action-block
        .plugin-edit .field-type-image-form input[type="file"]:first-of-type,
        .plugin-edit .field-type-image-form .site-info__action-block input[type="file"]:first-of-type, .site-info__action-block
        .plugin-release .field-type-image-form input[type="file"]:first-of-type,
        .plugin-release .field-type-image-form .site-info__action-block input[type="file"]:first-of-type, .site-info__action-block .add-plugin .field-type-image-form button:first-of-type, .add-plugin .field-type-image-form .site-info__action-block button:first-of-type, .site-info__action-block
        .plugin-edit .field-type-image-form button:first-of-type,
        .plugin-edit .field-type-image-form .site-info__action-block button:first-of-type, .site-info__action-block
        .plugin-release .field-type-image-form button:first-of-type,
        .plugin-release .field-type-image-form .site-info__action-block button:first-of-type, .site-info__action-block .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:first-of-type, .add-plugin .field-type-image-form .site-info__action-block input[name="field_addon_logo_und_0_remove_button"]:first-of-type, .site-info__action-block
        .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:first-of-type,
        .plugin-edit .field-type-image-form .site-info__action-block input[name="field_addon_logo_und_0_remove_button"]:first-of-type, .site-info__action-block
        .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"]:first-of-type,
        .plugin-release .field-type-image-form .site-info__action-block input[name="field_addon_logo_und_0_remove_button"]:first-of-type, .site-info__action-block #editor-builder #draggable-plugins .all-elements-option a:first-of-type, #editor-builder #draggable-plugins .all-elements-option .site-info__action-block a:first-of-type, .site-info__action-block
        #editor-builder .languages .all-elements-option a:first-of-type,
        #editor-builder .languages .all-elements-option .site-info__action-block a:first-of-type, .site-info__action-block .login .form-control[type="submit"]:first-of-type, .login .site-info__action-block .form-control[type="submit"]:first-of-type, .site-info__action-block
        .register .form-control[type="submit"]:first-of-type,
        .register .site-info__action-block .form-control[type="submit"]:first-of-type, .site-info__action-block
        .info-page .form-control[type="submit"]:first-of-type,
        .info-page .site-info__action-block .form-control[type="submit"]:first-of-type, .site-info__action-block .most-popular__wrapper .add_to_basket:first-of-type, .most-popular__wrapper .site-info__action-block .add_to_basket:first-of-type, .site-info__action-block .add-ons-list .views-row .add_to_basket:first-of-type, .add-ons-list .views-row .site-info__action-block .add_to_basket:first-of-type, .site-info__action-block
        .add-ons-list .views-row .check-details:first-of-type,
        .add-ons-list .views-row .site-info__action-block .check-details:first-of-type, .site-info__action-block
        .add-ons-list .views-row .remove_from_basket:first-of-type,
        .add-ons-list .views-row .site-info__action-block .remove_from_basket:first-of-type, .site-info__action-block
        .add-ons-list .views-row .required:first-of-type,
        .add-ons-list .views-row .site-info__action-block .required:first-of-type, .site-info__action-block .user-edit input[type="submit"]:first-of-type, .user-edit .site-info__action-block input[type="submit"]:first-of-type, .site-info__action-block
        .user-edit select[type="submit"]:first-of-type,
        .user-edit .site-info__action-block select[type="submit"]:first-of-type {
          margin-right: 0; } }
    @media (min-width: 1280px) {
      .site-info__action-block .btn, .site-info__action-block #cksource_repo_search_forums_form .form-submit, #cksource_repo_search_forums_form .site-info__action-block .form-submit, .site-info__action-block #forum-sort .form-submit, #forum-sort .site-info__action-block .form-submit, .site-info__action-block .add-plugin input[type="submit"], .add-plugin .site-info__action-block input[type="submit"], .site-info__action-block
      .add-plugin select[type="submit"],
      .add-plugin .site-info__action-block select[type="submit"], .site-info__action-block
      .plugin-edit input[type="submit"],
      .plugin-edit .site-info__action-block input[type="submit"], .site-info__action-block
      .plugin-edit select[type="submit"],
      .plugin-edit .site-info__action-block select[type="submit"], .site-info__action-block
      .plugin-release input[type="submit"],
      .plugin-release .site-info__action-block input[type="submit"], .site-info__action-block
      .plugin-release select[type="submit"],
      .plugin-release .site-info__action-block select[type="submit"], .site-info__action-block .add-plugin .field-type-image-form input[type="file"], .add-plugin .field-type-image-form .site-info__action-block input[type="file"], .site-info__action-block
      .plugin-edit .field-type-image-form input[type="file"],
      .plugin-edit .field-type-image-form .site-info__action-block input[type="file"], .site-info__action-block
      .plugin-release .field-type-image-form input[type="file"],
      .plugin-release .field-type-image-form .site-info__action-block input[type="file"], .site-info__action-block .add-plugin .field-type-image-form button, .add-plugin .field-type-image-form .site-info__action-block button, .site-info__action-block
      .plugin-edit .field-type-image-form button,
      .plugin-edit .field-type-image-form .site-info__action-block button, .site-info__action-block
      .plugin-release .field-type-image-form button,
      .plugin-release .field-type-image-form .site-info__action-block button, .site-info__action-block .add-plugin .field-type-image-form input[name="field_addon_logo_und_0_remove_button"], .add-plugin .field-type-image-form .site-info__action-block input[name="field_addon_logo_und_0_remove_button"], .site-info__action-block
      .plugin-edit .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
      .plugin-edit .field-type-image-form .site-info__action-block input[name="field_addon_logo_und_0_remove_button"], .site-info__action-block
      .plugin-release .field-type-image-form input[name="field_addon_logo_und_0_remove_button"],
      .plugin-release .field-type-image-form .site-info__action-block input[name="field_addon_logo_und_0_remove_button"], .site-info__action-block #editor-builder #draggable-plugins .all-elements-option a, #editor-builder #draggable-plugins .all-elements-option .site-info__action-block a, .site-info__action-block
      #editor-builder .languages .all-elements-option a,
      #editor-builder .languages .all-elements-option .site-info__action-block a, .site-info__action-block .login .form-control[type="submit"], .login .site-info__action-block .form-control[type="submit"], .site-info__action-block
      .register .form-control[type="submit"],
      .register .site-info__action-block .form-control[type="submit"], .site-info__action-block
      .info-page .form-control[type="submit"],
      .info-page .site-info__action-block .form-control[type="submit"], .site-info__action-block .most-popular__wrapper .add_to_basket, .most-popular__wrapper .site-info__action-block .add_to_basket, .site-info__action-block .add-ons-list .views-row .add_to_basket, .add-ons-list .views-row .site-info__action-block .add_to_basket, .site-info__action-block
      .add-ons-list .views-row .check-details,
      .add-ons-list .views-row .site-info__action-block .check-details, .site-info__action-block
      .add-ons-list .views-row .remove_from_basket,
      .add-ons-list .views-row .site-info__action-block .remove_from_basket, .site-info__action-block
      .add-ons-list .views-row .required,
      .add-ons-list .views-row .site-info__action-block .required, .site-info__action-block .user-edit input[type="submit"], .user-edit .site-info__action-block input[type="submit"], .site-info__action-block
      .user-edit select[type="submit"],
      .user-edit .site-info__action-block select[type="submit"] {
        min-width: 17.5rem; } }

.social {
  margin-top: 10rem;
  margin-bottom: 10rem !important; }
  .social__header {
    text-align: center;
    margin-bottom: 2.5rem; }
  .social__thumbs {
    margin: 0 auto;
    text-align: center; }
    @media (min-width: 768px) {
      .social__thumbs {
        max-width: 550px; } }
    @media (min-width: 1024px) {
      .social__thumbs {
        max-width: 820px; } }
  .social__thumb {
    display: inline-block;
    color: rgba(64, 81, 102, 0.96);
    width: 90%; }
    @media (min-width: 768px) {
      .social__thumb {
        min-width: 120px;
        width: auto; } }
    @media (min-width: 1024px) {
      .social__thumb {
        width: 100%; } }
  .social__counter {
    padding-bottom: 15px;
    text-align: center; }
    @media (min-width: 1024px) {
      .social__counter {
        max-width: 180px;
        padding-bottom: 0;
        margin-right: 15px; } }
  .social__brand-icon-container {
    float: left; }
  .social__brand-icon {
    vertical-align: bottom; }
    .social__brand-icon img {
      width: 45px; }
      @media (min-width: 1024px) {
        .social__brand-icon img {
          width: auto;
          height: auto; } }
    .social__brand-icon .ico {
      display: inline-block;
      vertical-align: middle;
      width: 45px;
      height: 45px;
      background-repeat: no-repeat;
      background-size: cover; }
      .social__brand-icon .ico--github {
        background-image: url(../images/icons/github.png); }
      .social__brand-icon .ico--twitter {
        background-image: url(../images/icons/twitter.png); }
      .social__brand-icon .ico--facebook {
        background-image: url(../images/icons/facebook.png); }
      .social__brand-icon .ico--gplus {
        background-image: url(../images/icons/gplus.png); }
      @media (min-width: 1024px) {
        .social__brand-icon .ico {
          width: 68px;
          height: 68px; } }
  .social__number-container {
    float: left;
    padding-left: 15px;
    height: 45px;
    width: calc(100% - 45px);
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15); }
    @media (min-width: 768px) {
      .social__number-container {
        min-width: calc(100% - 45px); } }
    @media (min-width: 1024px) {
      .social__number-container {
        height: 68px;
        min-width: 0;
        width: calc(100% - 68px);
        text-align: none; } }
  .social__number-wrapper {
    display: inline-block; }
    .social__number-wrapper .social__number {
      float: left;
      font-size: 1.9rem;
      margin-top: 3px; }
    .social__number-wrapper .social__number-type {
      float: left;
      clear: left;
      line-height: .8;
      font-size: 1.3rem;
      font-weight: 200; }
    @media (min-width: 1024px) {
      .social__number-wrapper {
        padding-top: 1rem; }
        .social__number-wrapper .social__number {
          font-size: 2.7rem; }
        .social__number-wrapper .social__number-type {
          font-size: _fon-scale(medium);
          line-height: inherit; } }

.right-sidebar.social {
  background-color: transparent;
  padding-left: 0;
  padding-right: 0; }
  @media (min-width: 1024px) {
    .right-sidebar.social .social__counter {
      max-width: 100%;
      margin: 0; }
    .right-sidebar.social .social__number-container {
      text-align: left; } }

.user {
  margin-top: 50px;
  margin-bottom: 40px; }
  @media (min-width: 1280px) {
    .user {
      margin-top: 120px;
      margin-bottom: 100px; } }
  .user__photo {
    display: inline-block;
    width: 100%;
    text-align: center; }
    .user__photo img {
      border-radius: 50%; }
  .user__name {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 4.2rem;
    font-weight: 200;
    line-height: 1.04;
    margin-top: 2.5rem; }
  .user__name-wrapper, .user__description {
    text-align: center; }
  .user__name-wrapper {
    position: relative;
    margin-bottom: 5rem;
    padding-bottom: 60px; }
  .user__country {
    position: absolute;
    left: 50%;
    bottom: 35px;
    font-size: 1.3rem;
    color: rgba(64, 81, 102, 0.75);
    transform: translateX(-50%); }
  .user__website {
    position: absolute;
    left: 50%;
    bottom: 0;
    font-size: 1.9rem;
    color: rgba(64, 81, 102, 0.75);
    transform: translateX(-50%); }
  .user__description {
    margin-bottom: 30px; }
    @media (min-width: 1280px) {
      .user__description {
        max-width: 900px;
        margin: 0 auto; } }
  .user__addons {
    display: inline-block;
    width: 100%;
    margin-top: 5rem; }
    .user__addons-list {
      list-style: none;
      margin: 0 auto;
      padding: 0; }
      @media (min-width: 1280px) {
        .user__addons-list {
          max-width: 830px; } }
    .user__addons h2 {
      text-align: center; }
  .user__addon {
    display: inline-block;
    width: 100%;
    position: relative;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15); }
    .user__addon-thumb {
      float: left;
      margin-right: 1rem; }
      .user__addon-thumb img {
        max-width: 64px;
        max-height: 64px; }
    .user__addon-info {
      float: left;
      width: calc(100% - (64px + 10px)); }
      .user__addon-info h3 {
        font-size: 1.9rem;
        font-weight: 700;
        margin: 0; }
      .user__addon-info p {
        font-size: 1.6rem; }

.user-edit {
  display: inline-block;
  width: 100%;
  padding: 0 2.5rem;
  margin: 0; }
  @media (min-width: 1280px) {
    .user-edit form {
      max-width: 1000px;
      margin: 0 auto; } }
  .user-edit__form {
    background-color: rgba(0, 0, 0, 0.03); }
  .user-edit .form-item {
    display: block;
    clear: both;
    width: 100%; }
  .user-edit label {
    width: 100%; }
  .user-edit input,
  .user-edit select {
    float: left;
    width: 100%;
    height: 100%;
    background-color: white;
    font-size: 1.6rem;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.15); }
    @media (min-width: 1280px) {
      .user-edit input,
      .user-edit select {
        float: left;
        width: 50%;
        margin-bottom: 2.5rem; } }
    .user-edit input[type="checkbox"],
    .user-edit select[type="checkbox"] {
      height: auto; }
  .user-edit select {
    height: 38px; }
  .user-edit fieldset.form-wrapper {
    width: 100%; }
    .user-edit fieldset.form-wrapper legend {
      display: none; }
  .user-edit #edit-actions {
    display: inline-block;
    width: 100%;
    padding-top: 2.5rem;
    text-align: center; }
    .user-edit #edit-actions input[type="submit"] {
      width: 200px;
      float: none; }
  .user-edit textarea + .description {
    padding-left: 0; }
  .user-edit .description {
    float: left;
    width: 100%;
    margin-bottom: 2.5rem;
    font-size: 1.6rem; }
    @media (min-width: 1280px) {
      .user-edit .description {
        width: 50%;
        padding: 0 15px 0 30px; } }
  .user-edit .form-block {
    margin-bottom: 1rem; }
    .user-edit .form-block .big-label {
      display: inline-block;
      width: 100%;
      font-size: 2.1rem;
      font-weight: 200; }
    .user-edit .form-block__hint {
      font-size: 1.6rem;
      margin: 0 15px; }
  .user-edit .photo-upload__placeholder-wrapper {
    float: left; }
    .user-edit .photo-upload__placeholder-wrapper .placeholder {
      display: inline-block;
      width: 150px;
      height: 150px;
      background-image: url(../images/thumbs/ico-plugin.png);
      background-repeat: no-repeat;
      background-size: 100%; }
  .user-edit .photo-upload__description-wrapper {
    float: left;
    width: calc(100% - 150px); }
  .user-edit .field-widget-options-buttons {
    display: inline-block;
    width: 100%;
    margin-top: 5rem; }
  .user-edit .form-checkboxes {
    float: left;
    width: 100%; }
    @media (min-width: 1280px) {
      .user-edit .form-checkboxes {
        width: 50%; } }
    .user-edit .form-checkboxes label,
    .user-edit .form-checkboxes input[type="checkbox"] {
      float: left;
      width: auto; }
    .user-edit .form-checkboxes label {
      font-size: 1.6rem;
      font-weight: 400; }
    .user-edit .form-checkboxes input[type="checkbox"] {
      vertical-align: middle;
      margin-left: 20px;
      margin-top: 5px;
      margin-bottom: 0;
      margin-right: 5px; }
      .user-edit .form-checkboxes input[type="checkbox"]:first-of-type {
        margin-left: 0; }
      .user-edit .form-checkboxes input[type="checkbox"]:last-of-type {
        margin-left: 0;
        clear: left; }
