.side-banner {
	margin: 0 auto _space();
	color: _text-color( primary, light );
	text-align: center;

	&:last-of-type {
		margin-bottom: 0;
	}

	h2 {
		line-height: 1em;
		font-size: 3rem;
	}

	p {
		font: {
			size: _font-scale( medium );
			weight: 400;
		}
		margin: {
			top: 1rem;
			bottom: 1rem;
		}
	}

	a {
		display: block;
		width: 80%;
		margin: 0 auto;
		color: _text-color( primary, light );
		background: transparent;
		border-color: _color( white );

		&:hover {
			background-color: _color( white );
			color: _text-color();
		}
	}

	&--finder {
		height: 400px;
		padding-top: 195px;
		background: {
			image: _image( 'banner--finder.png', 'backgrounds');
			size: cover;
			repeat: no-repeat;
		}

		h2 {
			padding-top: 40px;
		}
	}

	&--ac {
		height: 400px;
		padding-top: 205px;
		background: {
			image: _image( 'banner--ac.png', 'backgrounds');
			size: cover;
			repeat: no-repeat;
		}
	}

	&--ei {
		height: 400px;
		padding-top: 205px;
		background: {
			image: _image( 'banner--ei.png', 'backgrounds');
			size: cover;
			repeat: no-repeat;
		}

		h2 {
			padding-top: 15px;
		}

		p {
			font-size: 1.4rem;
			margin: 1rem 0.7rem;
		}
	}
}
