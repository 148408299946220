$mq__xs: 359px;
$mq__sm: 768px;
$mq__sm-plus: 1024px;
$mq__md: 1280px;
$mq__md-plus: 1440px;
$mq__lg: 1680px;
$mq__hd: 1920px;
$mq__hd-plus--width: 1500px;
$mq__hd-plus--height: 900px;

/* mobile first breakpoint create helper */
@mixin breakpoint( $mq01: 0, $mq2: false, $maxmin: min-width ) {
	@if ( $mq2 == false ) {
		@media ( $maxmin: $mq01 ) {
			@content;
		}
	}
	@else {
		@media ( min-width: $mq01 ) and ( max-width: $mq2 ) {
			@content;
		}
	}
}

@mixin _breakpoint--xs {
	@media ( max-width: $mq__xs ) {
		@content;
	}
}

@mixin _breakpoint--mo {
	@media ( max-width: $mq__sm - 1 ) {
		@content;
	}
}

/* Sizes are defined in ../settings/variables */
@mixin _breakpoint--sm {
	@include breakpoint( $mq__sm ) {
		@content;
	}
}

@mixin _breakpoint--sm-only {
	@include breakpoint( $mq__sm, $mq__md - 1 ) {
		@content;
	}
}

@mixin _breakpoint--sm-plus {
	@include breakpoint( $mq__sm-plus ) {
		@content;
	}
}

@mixin _breakpoint--sm-plus-only {
	@include breakpoint( $mq__sm-plus, $mq__md - 1 ) {
		@content;
	}
}

@mixin _breakpoint--md {
	@include breakpoint( $mq__md ) {
		@content;
	}
}

@mixin _breakpoint--md-only {
	@include breakpoint( $mq__md, $mq__lg - 1 ) {
		@content;
	}
}

@mixin _breakpoint--md-plus {
	@include breakpoint( $mq__md-plus ) {
		@content;
	}
}

@mixin _breakpoint--lg {
	@include breakpoint( $mq__lg ) {
		@content;
	}
}

@mixin _breakpoint--hd-plus {
	@media ( min-width: $mq__hd-plus--width ) and ( min-height: $mq__hd-plus--height ) {
		@content;
	}
}

@mixin _breakpoint--2k-plus {
	@media ( min-width: $mq__hd ) and ( min-height: $mq__sm-plus ) {
		@content;
	}
}

@mixin _hdpi {
	@media only screen and ( min-device-pixel-ratio: 2 ),
		only screen and ( min-resolution: 192dpi ),
		only screen and ( min-resolution: 2dppx ) {

		@content;
	}
}
