.icon {
	display: inline-block;
	vertical-align: middle;
	width: 4rem;
	height: 2.4rem;

	& > * {
		height: 2.4rem;
		width: 4rem;
	}

	&-set {
		.icon {
			margin-right: 1rem;

			&:last-of-type {
				margin-right: 0;
			}
		}
	}

	&--big {
		height: 3.2rem;
		width: 4.5rem;

		& > * {
			height: 3.2rem;
			width: 4.5rem;
		}
	}

	&--small {
		width: 2.2rem;
		height: 2rem;

		& > * {
			width: 2.2rem;
			height: 2rem;
		}
	}

	svg * {
		fill: _text-color( secondary );
		@include _transition;
	}

	&:hover {
		svg * {
			fill: _text-color( primary );
		}
	}

	&--blue {
		svg * {
			fill: lighten( _color( primary ), 20 );
		}

		&:hover {
			svg	* {
				fill: _color( primary, 10 );
			}
		}
	}
}
