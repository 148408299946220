.site-info {
	background-color: _gray();

	&__wrapper {
		@extend .section-container;
		padding: 20px;
		color: _text-color( secondary );
		text-align: center;

		@include _breakpoint--sm {
			text-align: left;
		}
	}

	&__thumb {
		float: right;
		margin-right: _space();
	}

	&__links-wrapper {
		font-size: _font-scale( medium );
		margin-top: _space();

		@include _breakpoint--sm {
			padding: 15px 0 0 40px;
			margin-top: 0;
		}
	}

	&__links {
		margin: 0;
		padding: 0;
		list-style: none;

		@include _breakpoint--sm {
			margin-bottom: 15px;
		}

		&-item {
			padding: 1px 0;
			font-weight: 400;
		}
	}

	&__version {
		margin-bottom: 15px;

		.label {
			display: inline-block;
			width: 100%;
		}

		.version-number {
			display: inline-block;
			width: 100%;
			font-weight: bold;
		}
	}

	&__issue-link {
		font-weight: bold;
		text-decoration: none;
		color: _text-color( secondary );
	}

	&__product {

		@include _breakpoint--sm {
			padding: _space();
			border-right: 1px solid _gray();
		}

		h2 {
			margin: 0 0 5px;
		}

		.tagline {
			margin: 0 0 _space();
		}
	}

	&__action-block {
		.btn {
			min-width: 120px;
			margin-left: 5px;

			&:first-of-type {
				margin-right: 5px;
			}

			@include _breakpoint--sm {
				margin-left: 15px;
				min-width: 0;

				&:first-of-type {
					margin-right: 0;
				}
			}

			@include _breakpoint--md {
				min-width: map-get( $cta-widths, 'normal' );
			}
		}
	}
}