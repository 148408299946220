$side-menu__width: 280px;
$side-menu__width--mobile: 230px;
$side-menu__trigger-width: 40px;
$z-level: (
	'id': _get-zindex( air ) + 3,
	'panel': _get-zindex( air ) + 2,
	'cover': _get-zindex( air ) + 1
);

.site-nav {
	position: absolute;
	top: 1rem;
	left: 0;
	height: $top-menu__height;
	z-index: map-get( $z-level, 'id' );
	@include _transition( padding );

	&:not( .menu--tiny ) {
		@include _breakpoint--sm {
			height: $top-menu__height;
		}
	}

	@include _breakpoint--mo {
		position: fixed;
		top: 0;

		.site-nav__toggler .bar {
			background-color: _color( black );
		}
	}

	&--fixed {
		position: fixed;
		top: 0;

		.site-nav__toggler {
			@include fadeIn(
				$duration: 300ms,
				$function: ease-in,
				$fill: both
			);

			.bar {
				background-color: _color( black );
			}

			@include _breakpoint--md {
				&:focus,
				&:active {
					.bar {
						background-color: _color( primary );
					}
				}
			}
		}
	}

	&--active {
		position: fixed;
		top: 0;
		@include _transition;
	}

	&--no-hero,
	&--active,
	&--hero-inside.site-nav--fixed,
	&--light.site-nav--fixed {
		.site-nav__toggler {
			.bar {
				background-color: _color( black );
			}

			@include _breakpoint--md {
				&:focus,
				&:active {
					.bar {
						background-color: _color( primary );
					}
				}
			}
		}

		.site-nav__current {
			color: _text-color( primary );
		}
	}

	&__toggler {
		position: absolute;
		top: 50%;
		left: 1.5rem;
		height: 39px;
		width: $side-menu__trigger-width;
		padding: 0;
		transform: translateY( -50% );
		cursor: pointer;
		@include _button-reset;
		@include _transition( transform );

		/* Show button above navigation. */
		z-index: map-get( $z-level, 'id' );

		&--on-move {
			transition: transform 300ms ease-in-out, outline 200ms linear 300ms;
		}

		@include _breakpoint--sm {
			left: _space();
		}

		.bar {
			position: absolute;
			left: 5px;
			height: 3px;
			width: 30px;
			display: block;
			background-color: _color( white );
			border-radius: 10px;
			@include _transition( transform );

			&:nth-of-type(1) {
				top: 10px;
				transition: top 200ms ease-in-out 100ms, transform 200ms ease-in-out 100ms;
				animation: mrotr 2s cubic-bezier(.5, .2, .2, 1.01 );
			}

			&:nth-of-type(2) {
				top: 18px;
				transition: opacity 100ms ease-in-out 100ms;
				animation: fade 2s cubic-bezier(.5, .2, .2, 1.01);
			}

			&:nth-of-type(3) {
				top: 26px;
				transition: top 200ms  ease-in-out 100ms, transform 200ms ease-in-out 100ms;
				animation: mrotl 2s cubic-bezier(.5, .2, .2, 1.01);
			}
		}

		&--active {
			@include _transition( transform );

			&:focus,
			&:active {
				outline: none;
			}

			@media ( pointer: fine ) {
				&:focus,
				&:active {
					.bar {
						background-color: _color( primary );
					}
				}
			}

			@include _breakpoint--sm {
				transform: translate( -1rem, -50% );
			}

			.bar {
				&:nth-of-type(1) {
					top: 18px;
					transform: rotate(35deg) scale(0.7, 1) translate(8px, 5px);
					transition: top 200ms ease-in-out 100ms, transform 200ms ease-in-out 100ms;
				}

				&:nth-of-type(2){
					opacity: 0;
				}

				&:nth-of-type(3) {
					top: 18px;
					transform: rotate(-35deg) scale(0.7, 1) translate(5px, -2px);
					transition: top 200ms ease-in-out 100ms, transform 200ms ease-in-out 100ms;
				}
			}
		}
	}

	&__panel {
		// Do not scroll content on container start/end
		overscroll-behavior: contain;

		position: fixed;
		top: 0;
		bottom: 0;
		left: -$side-menu__width--mobile;
		min-height: 100vh;
		width: $side-menu__width--mobile;
		padding: {
			top: _space() * 3;
			left: _space();
			right: _space();
		}
		background-color: _color( white );
		overflow-y: auto;
		z-index: map-get( $z-level, 'panel' );
		@include _transition( transform, 300ms );

		&--active {
			transform: translateX( $side-menu__width--mobile );
		}

		@include _breakpoint--sm {
			left: -$side-menu__width;
			width: $side-menu__width;

			&--active {
				transform: translateX( $side-menu__width );
			}
		}

		nav {
			height: 100%;
		}

		h2 {
			margin-bottom: _half-space() / 2;
			font: {
				size: _font-scale( medium );
				weight: 800;
			}
			line-height: _space();
			letter-spacing: 0;

			a {
				color: _text-color( secondary );

				&:hover {
					text-decoration: none;
					color: _text-color( primary );
				}
			}
		}
	}

	&__sets {
		&-container {
			display: flex;
			// Minus top padding.
			min-height: calc( 100vh - 12rem );
			flex-direction: column;
		}

		&-inner {
			margin-top: _space();

			@include _breakpoint--mo {
				padding-bottom: _half-space();
			}

			&:last-of-type {
				display: flex;
				flex-grow: 1;
				flex-direction: column;
				padding-top: _space();
			}
		}
	}

	&__menu {
		@include _list-reset;
		margin-top: _space();

		&:first-of-type {
			margin-top: 0;
		}

		&-item {
			margin-bottom: 0;

			a {
				display: block;
				position: relative;
				color: _text-color( secondary );
				margin-bottom: .2rem;
				font: {
					size: _font-scale( medium );
					weight: 600;
				}

				&:hover {
					text-decoration: none;
					color: _text-color( primary );
				}
			}

			&--current {
				a {
					text-decoration: none;
					color: _color( primary );
				}
			}

			&-badge {
				position: absolute;
				right: 0;
				top: 50%;
				padding: .4em .8em 0.3em;
				color: _text-color( primary, light );
				font: {
					size: _font-scale( small );
					weight: 800;
				}
				line-height: 1;
				border-radius: 8px;
				background-color: _color( ecosystem );
				transform: translateY( -50% );
			}
		}

		&-category {
			margin-top: _space();
			margin-bottom: .6rem;
			color: _text-color( hint );
			font: {
				size: _font-scale( medium );
				weight: 600;
			}
		}
	}
}

.site-cover {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0;
	background-color: _color( bg-dark, .7 );
	@include _zindex( under );
	@include _transition( opacity );

	&--active {
		z-index: map-get( $z-level, 'cover' );
		opacity: 1;

	}

	&--hide {
		opacity: 0;
	}
}
