.search {
	position: relative;
	@include _border;
	@include _radius;
	font-size: _font-scale( medium );
	background-color: _color( white );

	&:after {
		content: '';
		position: absolute;
		top: 50%;
		right: 10px;
		color: _text-color( secondary );
		transform: translateY( -50% );
		@extend .svg-ico__search;
	}

	&__input {
		width: 100%;
		border: none;
		padding-right: 35px;
		padding-left: 20px;
		@include _radius;
	}
}