.dropdown {
	display: inline-block;
	position: relative;

	&--block {
		display: block;
		width: 100%;
	}

	ul {
		display: none !important;
		position: absolute;
		top: 100%;
		right: 0;
		min-width: 100%;
		background-color: _color( white );
		z-index: 99;
		@include _shadow;

		li {
			width: 100%;

			a {
				color: _text-color( primary );
				padding: _space() / 2;
				line-height: 1.5;

				&:hover {
					color: _text-color( primary );
					background-color: _color( black, 0.05)
				}
			}
		}
	}

	&__list {
		@include _list-reset;
		margin: 0;
		@include _zindex( air );
		@include _radius( bottom );

		li {
			position: relative;
			a {
				display: block;
			}

			&:first-of-type {
				a {
					padding-top: 1.5rem;
				}
			}

			&:last-of-type {
				@include _radius( bottom );

				a {
					padding-bottom: 1.5rem;
					@include _radius( bottom );
				}
			}
		}
	}

	&--dark {
		.dropdown__list {
			background-color: _color( bg-dark, .9 );

			li a {
				color: _text-color( secondary, light );
			}
		}
	}

	&[ data-open="true" ] {
		ul {
			display: block !important;
			@include fadeInDown(
				$duration: 300ms,
				$count: 1,
				$function: ease-in-out,
				$fill: both
			)
		}
	}

	button {
		position: relative;
		padding-right: 3rem;

		&:after {
			content: '\25BC';
			position: absolute;
			right: _space() / 2;
			top: 55%;
			font-size: 1.1rem;
			transform: translateY( -50% );
		}

		&.btn--select {
			display: block;
			width: 100%;
			background-color: _color( white );
			color: _text-color( secondary );
			text-align: left;
			@include _border;
			@include _radius;

			& + .dropdown__list {
				a {
					font-size: _font-scale( medium );

					&:hover {
						text-decoration: none;
					}
				}
			}
		}
	}

	&-menu {
		&__quantity {
			position: absolute;
			right: 5px;
			top: 50%;
			border-radius: 50%;
			padding: 2px 8px;
			background-color: _color( bg );
			color: _color( primary );
			transform: translateY(-50%);
			font-size: 1.4rem;
		}
	}
}
