@import './colors';

$border-radius: 4px;
$border-color: _gray( dark );

/**
 * Display border with default color.
 *
 * @access public
 * @param {String} $side [ all ] - Side of the border.
 * @param {String} $width [ 1px ] - Width of the border.
 */
@mixin _border( $side: all, $width: 1px ) {
	@if ( $side == all ) {
		border: $width solid $border-color;
	}
	@if ( $side == left ) {
		border-left: $width solid $border-color;
	}
	@if ( $side == right ) {
		border-right: $width solid $border-color;
	}
	@if ( $side == top ) {
		border-top: $width solid $border-color;
	}
	@if ( $side == bottom ) {
		border-bottom: $width solid $border-color;
	}
}

/**
 * Set predefinied border radius.
 *
 * @access public
 * @param {String} $side [ all ] - Side of the border radius.
 */
@mixin _radius( $side: all, $radius: $border-radius ) {
	@if ( $side == all ) {
		border-radius: $radius;
	}
	@if ( $side == left ) {
		border-radius: $radius 0 0 $radius;
	}
	@if ( $side == bottom ) {
		border-radius: 0 0 $radius $radius;
	}
	@if ( $side == right ) {
		border-radius: 0 $radius $radius 0;
	}
	@if ( $side == top ) {
		border-radius: $radius $radius 0 0;
	}
}
