.right-sidebar {
	display: inline-block;
	width: 100%;
	background-color: _gray();
	padding: 20px;
	margin-bottom: 30px;

	h3 {
		margin: {
			top: 0;
			bottom: 10px;
		};
	}

	&__header {
		display: inline-block;
		width: 100%;

		&--small {
			margin: 0;

			img {
				margin: 0;
			}
		}

		h2 {
			margin: {
				top: 0;
				bottom: 10px;
			}
			line-height: .8;
			font-size: 2.8rem;
		}
	}
}

.right-sidebar__section {
	display: inline-block;
	width: 100%;
	padding-top: 20px;

	&-header {
		display: inline-block;
		width: 100%;

		h3 {
			font: {
				size: _font-scale( medium );
				weight: 700;
			}
			margin: 0;
		}

		.tagline {
			margin: 0;
			font-size: _font-scale( small );
		}
	}

	p {
		margin: 0;
		font-size: _font-scale( medium );
	}

	.plugins-list {
		margin: 0;
		padding: 0;
		list-style: none;

		&__item {
			text-decoration: none;
			font-size: _font-scale( medium );

			&:before {
				content: '';
				float: left;
				margin-right: 15px;
			}

			&:last-of-type {
				padding: 0;
			}

			&--preset {
				&:before {
					@extend .svg-ico__addon--preset;
				}
			}

			&--selected {
				&:before {
					@extend .svg-ico__addon--selected;
				}
			}

			&--required {
				&:before {
					@extend .svg-ico__addon--required;
				}
			}
		}
	}

	.skin-link {
		font-size: _font-scale( medium );
		&:before {
			content: '';
			float: left;
			margin-right: 15px;
			@extend .svg-ico__addon;
		}
	}

	&:last-of-type {
		border-bottom: none;
	}
}

.fixedsticky {
	top: 100px;
	max-width: 291px;
}