#mce-responses {
	display: flex;

	.response {
		flex-grow: 1;
		margin-top: _space() / 2;
	}

	#mce-error-response {
		color: #f56764;
	}
	#mce-success-response {
		color: #27d465;
	}
}
