@import './vector';

.ico {
	display: inline-block;
	vertical-align: middle;
}

@mixin _icon() {
	display: inline-block;
	vertical-align: middle;
 }
