.purchase {
	margin-top: _space();
	padding-bottom: _double-space();

	.messages {
		@include _middle-content( wide );
		@include _radius;
		text-align: inherit;
		font-size: _font-scale( medium );
		margin-bottom: _double-space();
		padding: _space() _double-space();

		&.error {
			background-color: _color( danger, .08 );
		}

		ul {
			margin: 0;
			padding-left: 1.5rem;
		}
	}
}

.purchase-details {
	position: relative;
	@include _middle-content( wide );
	text-align: inherit;
	@include _radius;
	@include _shadow;

	legend {
		display: none;
	}

	label {
	  font-weight: 500;
	}

	input {
		vertical-align: baseline;
	}

	&__header {
		position: relative;
		padding: _space() _double-space();
		background-color: _color( bg );
		@include _radius( top );

		h2 {
			margin: 0 0 10px;
			padding-left: _double-space() * 2;
			font-size: 2.4rem;
			line-height: 3rem;

		}
		.prices-info {
			padding-left: _double-space() * 2;
			font-weight: 400;
			font-size: 2.4rem;

			.discount {
				color: _text-color( secondary );
				text-decoration: line-through;
				padding: 0 1rem;
				font-weight: 600;
				font-size: 2.1rem;
			}
		}

		.ecosystem-logo {
			position: absolute;
			left: _double-space();
			top: 20%;
			width: 76px;
			height: 76px;
			background: {
				image: _image( 'ecosystem-logo.png' );
				repeat: no-repeat;
				size: contain;
			}
			@include _zindex( air );
		}

		.col-xs-12 {
			padding: 0;
		}
	}

	.wrapper {
		padding: _space() _double-space();

		table {
			width: 100%;

			thead tr,
			.heading {
				font-weight: 600;
				font-size: _font-scale( medium );

				th {
					border-bottom-width: 2px;
					font-weight: 700;
					padding: 2px 5px;

					&.price {
						text-align: right;
					}
				}
			}

			tr {
				td {
					padding: 2px 5px;
					border-bottom: 1px solid #cecece;

					&.price {
						min-width: 164px;
						text-align: right;
					}
				}

				&:last-child td {
					border-bottom: 0;
				}
			}
		}

		.hidden-start {
			display: none;

			&.visible {
				display: inline-block;
			}
		}

		.renewal-unchecked {
			text-decoration: line-through;
		}
	}

	.product-name {
		font: {
			size: _font-scale( big );
			weight: 400;
		}
		color: _color( primary );
	}

	.price--new {
		font: {
			size: _font-scale( big );
			weight: 400;
		}
		color: _color( success );
	}

	ol {
		padding-left: 20px;

		li {
			font-size: 2rem;
			margin-bottom: _double-space();

			.connectors-wrapper {
				display: flex;
				justify-content: center;

				& > div {
					text-align: center;
					margin-left: _space();

					&:first-of-type {
						margin: 0;
					}

					label {
						display: block;
						width: 75px;
						height: 50px;
						background: {
							repeat: no-repeat;
							size: contain;
							position: 50% 0;
						}
						cursor: pointer;
						text-indent: -9999px;
					}

					&.php {
						label {
							background-image: _image( 'php.svg', 'languages' );
						}
					}

					&.java {
						label {
							background-image: _image( 'java.svg', 'languages' );
						}
					}

					&.asp {
						label {
							background-image: _image( 'asp.svg', 'languages' );
						}
					}

					&.asp-net {
						label {
							background-image: _image( 'asp.net.svg', 'languages' );
						}
					}

					&.coldfusion {
						label {
							background: {
								image: _image( 'cf.svg', 'languages' );
								size: 50px 60px;
								position: 50% -8px;
							}
						}
					}
				}
			}

			&:last-of-type {
				margin-bottom: 0;
			}

			& > * {
				font-size: _font-scale( medium );
			}

			p {
				color: _text-color( secondary );
				margin-top: 1rem;
			}

			h4 {
				font-size: inherit;

				& + .form-inline,
				& + .form-group,
				& + label,
				& + div {
					margin-top: _space();
				}
			}
		}
	}

	.label {
		color: _text-color();
		font-weight: 500;
		margin-bottom: 0;
	}

	.form-inline {
		.label {
			display: inline-block;
		}

		label + input,
		label + select,
		.label + input  {
			margin-left: 1.5rem;
		}

		input + label {
			margin-left: .5rem;
		}

		input[ type="radio" ] + label {
			font-weight: 400;
		}
	}

	.options-group {
		display: inline-block;
		margin-left: 1.5rem;
	}

	.form-group {
		display: inline-block;
		width: 100%;
		margin-bottom: _space();

		&:last-of-type {
			margin-bottom: 0;
		}

		label,
		.label {
			width: 25%;
			text-align: right;
		}

		input + label {
			width: auto;
		}

		input[type="text"],
		.options-group,
		select {
			width: 65%;
		}
	}

	.opt {
		display: inline-block;
		width: 100%;

		p {
			position: absolute;
			bottom: 2.5rem;
			left: 2.5rem;
			margin: 0;
			font-size: _font-scale( medium );
		}

		.btn {
			display: block;
			float: right;
			margin-top: _space();
		}
	}

	.mandatory {
		color: _color( danger );
	}

	#individual_corporate_tip,
	#owner-fields {
		display: none;
	}

	.add-bundle {
		input {
			margin-right: .5rem;
		}
	}

	// Errors visualization.
	.error {
		label {
			color: _text-color( primary );
		}

		.form-control {
			border-color: _color( danger );
		}

		input[ type="checkbox" ],
		input[ type="radio" ] {
			border-color: _color( danger );
			box-shadow: inset 0 1px 1px rgba(0,0,0,.075);

			& + label {
				color: _color( danger );
			}
		}
	}

	.thanks {
		text-align: center;
		margin: 0;

		.purchase-thanks__icon {
			display: block;
			height: 150px;
			width: 200px;
			margin: {
				top: _space();
				bottom: _double-space();
				left: auto;
				right: auto;
			}
			background: {
				image: _image( 'ckecked.svg', 'icons' );
				repeat: no-repeat;
				size: contain;
				position: center;
			}
		}
	}
}

#confirmation {
	section {
		margin-top: 0;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	h3 {
		a {
			font-size: .5em;
			font-weight: 600;
		}
	}

	button[ type="submit" ] {
		float: right;
		margin: {
			top: _space();
			bottom: _space();
		}
	}

	ul {
		@include _list-reset;
		padding: _space() 0;

		li {
			display: flex;
			flex-wrap: wrap;
			padding-left: _double-space() * 2;
			font-size: _font-scale( medium );
			margin-bottom: _space();

			.flex-1-1 {
				width: 100%;
				padding: 0 1rem;
			}

			.flex-1-2 {
				width: 100%;
				padding: 0 1rem;

				@include _breakpoint--sm {
					width: 50%;
				}
			}

			.purchase__field {
				&-title {
					margin: {
						top: 0;
						bottom: 1rem;
					}
					font-weight: 600;
					color: _text-color( secondary );
				}

				&-value {
					margin: 0;
					padding: 1.5rem;
					background-color: _gray-hex( light );
					border: 1px dashed _gray-hex( dark );
					@include _radius;
				}
			}
		}
	}

	.payment-options {
		ol {
			@include _list-reset;
			padding-left: _double-space() * 2;
		}

		.payment-option {
			position: relative;
			display: flex;
			padding: {
				left: _space();
			}
			margin: {
				top: _space();
				bottom: _space();
			}
			background-color: _color( white );
			font-weight: 400;
			align-items: center;
			cursor: pointer;
			@include _shadow( small );
			@include _transition;

			&--active {
				background-color: _color( bg );
				@include _shadow();
			}

			label {
				flex-grow: 1;
				padding: {
					top: _space();
					right: _space() * 4;
					bottom: _space();
				}
				margin: {
					left: _space();
					bottom: 0;
				}
				cursor: pointer;
			}

			input[type="radio"] {
				cursor: pointer;
			}

			&__title {
				display: block;
				margin-bottom: 1rem;
				font: {
					size: _font-scale( normal );
					weight: 600;
				}
				color: _text-color( secondary );
			}

			&__description {
				display: block;
				font: {
					size: _font-scale( medium );
					weight: 400;
				}
			}

			&__icon {
				position: absolute;
				right: _space();
				top: 50%;
				height: 50px;
				width: 50px;
				background: {
					size: contain;
					repeat: no-repeat;
					position: center;
				}
				transform: translateY( -50% );
				@include _zindex( float );

				&--pl {
					height: 65px;
					width: 65px;
					background-image: _image( 'paylane.png', 'icons' );
				}

				&--pp {
					height: 75px;
					width: 75px;
					background-image: _image( 'paypal.svg', 'icons' );
				}

				&--bwt {
					background-image: _image( 'bank.svg', 'icons' );
				}

				&--so {
					background-image: _image( 'receipt.svg', 'icons' );
				}
			}
		}
	}
}

.sharepoint-activation ol {
	padding: 0 0 0 1rem;
}

.sharepoint-activation,
.ckfinder-activation {
	float: left;
	width: 100%;

	.buyerInformation {
		border-bottom: 1px solid #e5e5e5;
	}

	fieldset {
		padding: 25px 15px 0 0;
	}

	form {
		color: #575757;

		label {
			font-weight: 500;
			padding: 10px 0;

			& > small {
				font-size: 1.4rem;
			}
		}

		legend {
			padding-bottom: 10px;
		}

		textarea[name*=hwkey] {
			display: block;
			width: 100%;

		}
	}

	.activation-title {
		.dev_domains {
			.dev_2 {
				margin-top: 10px;
			}
		}

		.existing {
			padding: 10px;
			border-bottom: 1px solid #d4d4d4;
		}

		input[type="submit"] {
			margin: 10px 0;
		}
	}

	.activation-title + .activated-title {
		border-top: 1px solid #d4d4d4;
	}

	.activated-title {
		margin-top: _space();
		padding-top: _space();

		div.row {
			&:not(:first-child) {
				margin-top: _space();
				padding-top: _space();
			}

			h3 {
				margin: 0 0 15px 5px;
				position: relative;
				padding-bottom: 10px;

				&:after {
					content: "";
					display: block;
					width: 120px;
					border-bottom: 1px solid #d4d4d4;
					height: 6px;
					position: absolute;
					left: -5px;
					bottom: 0;
				}
			}

			p {
				font-size: _font-scale( medium );

				&.dev_btn {
					text-align: center;
				}

				&.category {
					margin-bottom: 10px;
					font-size: 1.5rem;
					text-align: center;
				}

				& > strong {
					min-width: 100px;
					display: inline-block;
					text-align: right;
					padding-right: 10px;

					@include _breakpoint--md {
						min-width: 120px;
					}
				}
			}
		}

		h4 {
			text-align: center;
		}
	}

	#edit-save + .activated-title {
		border-top: 1px solid #d4d4d4;
	}
}

#ckfinder3-purchase-notice {
	font-size: 1.4rem;
	color: #856404;
	background-color: #fff3cd;
	border: 1px solid #ffeeba;
	border-radius: 4px;
	padding: 1.2em;
	margin-top: 20px;
	margin-bottom: 0;
	display: none;
}
