$content-max-width: 1200px;
$header__height: 450px;
$header__height-new: 360px;

.header {
	position: relative;
	height: 100vh;
	background: {
		attachment: fixed;
		size: cover;
		color: _color( black );
		repeat: no-repeat;
		position: center top;
	}
	color: _color( white );
	text-align: center;
	overflow: hidden;

	&:not( .header--new ) {
		picture {
			img {
				min-width: 100%;
				height: 100vh;

				@include _breakpoint--sm-plus {
					width: 100%;
				}

				@include _breakpoint--md {
					width: auto;
					height: auto;
				}

				@media( min-width: $mq__hd ) {
					width: 100%;
					height: $header__height;
				}
			}
		}
	}

	@include _breakpoint--md {
		height: $header__height;
		background-size: 100% $header__height;
	}

	&--no-tagline {
		.header-content {
			padding-bottom: _space();
		}
	}

	&-content {
		width: 100%;
		padding: {
			left: _space();
			right: _space();
		}
		@include _center;
		@include _zindex( float );
		@include _transition( transform );

		@include _breakpoint--sm {
			top: auto;
			bottom: 50%;
			transform: translate( -50%, 50% );
		}

		@include _breakpoint--md {
			bottom: 0;
			transform: translateX( -50% );
		}
	}

	&-cover {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: linear-gradient( _color( black, .7 ), _color( black, .1 ) );
		opacity: 0.9;
		@include _zindex( under );
		@include _transition;

		&--active {
			@include _zindex( float );
		}
	}

	&__goto-content {
		display: block;
		position: absolute;
		bottom: _space();
		left: 50%;
		width: 68px;
		height: 33px;
		margin-left: -34px;
		background: {
			repeat: no-repeat;
			size: contain;
			image: _image( 'arrow-down.svg', 'icon' );
		}
		@include _zindex( air );
		animation: {
			name: pulseDown;
			duration: 5000ms;
			iteration-count: infinite;
			timing-function: ease;
		}

		@include _breakpoint--sm {
			bottom: _double-space();
		}

		@include _breakpoint--md {
			display: none;
		}
	}

	.btn.btn-cta--long {
		background-color: _color( white );
		border-color: _color( white );
		color: _text-color();

		&:first-of-type {
			margin-right: _half-space();
		}

		@include _breakpoint--sm {
			min-width: 18.5rem;
		}

		&:hover {
			background-color: _color( white );
			border-color: _color( white );
			color: _color( primary );
		}
	}

	h1,
	h2 {
		@include _middle-content;
	}

	h1 {
		position: relative;
		padding-top: 100px;
		margin-bottom: 1rem;

		@include _breakpoint--sm {
			padding-top: 140px;
		}

		@include _breakpoint--md {
			margin-bottom: 0;
		}

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 50%;
			width: 90px;
			height: 90px;
			// Remove not center alignment impression.
			margin-left: 8px;
			transform: translateX( -50% );
			background: {
				repeat: no-repeat;
				size: contain;
			}

			@include _breakpoint--sm {
				width: 130px;
				height: 130px;
				// Becaouse of circle with number.
				margin-left: 7.5px;
			}
		}
	}

	h2 {
		display: none;
		margin: {
			top: _space();
			bottom: _space();
		}
		color: _text-color( secondary, light );
		@include _text-shadow( big );

		@media ( min-height: 460px ) {
			display: block;
		}

		@include _breakpoint--mo {
			font-size: 2.8rem;
			line-height: 3.5rem;
			margin-top: 1rem;
		}

		@include _breakpoint--sm {
			margin-top: 0;
		}
	}

	&--new {
		height: $header__height-new;
		background-size: 100% $header__height-new;
		text-align: left;

		@include _breakpoint--mo {
			height: auto;
		}

		&.header--ckeditor-5 {
			background-color: _color( 'brand-cke5' );
		}

		&.header--ckeditor-4 {
			background-color: _color( 'brand-cke4' );

			.header-content {
				@include _breakpoint--sm-plus {
					width: 655px;
				}
			}
		}

		&.header--ckfinder {
			background-color: _color( 'brand-ckfinder' );

			// Fix overflowing header content vs icon.
			@include _breakpoint--sm-only {
				.header-content {
					width: 420px;
				}
			}
		}

		&.header--real-time-collaborative-editing,
		&.header--real-time-collaborative-editing-demo,
		&.header--track-changes,
		&.header--track-changes-demo,
		&.header--comments,
		&.header--comments-demo,
		&.header--collaboration,
		&.header--startups {
			background-color: _color( 'brand-cke5' );
		}

		&.header--contact {
			background-color: #404F5F;
		}

		&.header--why-ckeditor,
		&.header--enterprise,
		&.header--pricing {
			background-color: #942cc8;
		}

		.header-content {
			width: 480px;
			max-width: 100%;
			padding: 0 _space();
			margin-top: _double-space();

			@include _breakpoint--mo {
				width: 100%;
				position: relative;
				text-align: center;
				margin-bottom: _space();

				// reset vertical center
				transform: none;
				left: auto;

				margin-top: $top-menu__height * 1.5;
			}

			@include _breakpoint--sm {
				top: 50%;
				bottom: auto;
				left: _space();
				padding: 0;
				margin-top: 0;

				// reset vertical center
				transform: translateY( -50% );

				padding-top: $top-menu__height;
			}

			@include _breakpoint--sm-plus {
				width: 700px;
			}

			@include _breakpoint--md {
				left: 50%;
				margin-left: -200px;
				transform: translate( -50%, -50% );
			}

			@include _breakpoint--hd-plus {
				margin-left: -350px;
			}
		}

		picture {
			@include _breakpoint--mo {
				display: none;
			}

			img {
				position: absolute;
				left: 50%;
				transform: translateX( -50% );

				@include _breakpoint--sm {
					transform: translateX( -46.5% );
				}

				@include _breakpoint--sm-plus {
					transform: translateX( -40% );
				}
			}
		}

		h1 {
			padding: 0;
			width: auto;
			font-size: 3.6rem;
			text-align: inherit;

			@include _breakpoint--sm-plus {
				font-size: 4.4rem;
			}
		}

		p {
			margin-top: 1rem;
			font-size: 1.8rem;

			span {
				display: inline-block;
				clear: both;
			}
		}

		.header__goto-content {
			display: none;
		}
	}

	// Set different background image for every page.
	&--letters {
		h1:before {
			background-image: _image( 'letters.svg', 'product' );
			width: 80px;
			margin-left: 0;

			@include _breakpoint--sm {
				width: 115px;
			}
		}
	}

	&--accessibility-checker,
	&--collaborative-editing,
	&--collaboration-features,
	&--ckfinder,
	&--easy-image {
		h1 {
			padding-top: 0;

			:before {
				display: none;
			}
		}
	}

	&--collaborative-editing {
		@include _breakpoint--md {
			.header-content {
				bottom: 60px;
			}
		}
	}

	&--ckeditor-cloud-services {
		h1:before {
			background-image: _image( 'cloud.svg', 'product' );
			width: 80px;
			margin-left: 0;

			@include _breakpoint--sm {
				width: 115px;
			}
		}
	}

	&--404 {
		background-image: _image( '404.jpg', 'header' );
	}

	&--letters-for-drupal {
		background-image: _image( 'letters.jpg', 'header' );
	}

	&--letters-for-wordpress {
		background-image: _image( 'letters-for-wordpress.jpg', 'header' );
	}

	&--blog {
		height: 40vh;

		h2 {
			margin-top: _space();
		}

		h1,
		h2 {
			max-width: 100%;
		}
	}
}
