.content-block {
	float: left;

	&--with-left-sidebar {
		width: 100%;
		margin-left: 0;

		@include _breakpoint--md {
			width: calc(100% - #{$size__left-sidebar--width} - #{$main-space--px});
			margin-left: $main-space--px;
		}

		@include _breakpoint--md {
			width: calc(100% - #{$size__left-sidebar--width} - ( #{$main-space--px} * 2 ) );
			margin: {
				left: $main-space--px;
				right: $main-space--px;
			}
		}
	}
}

.most-popular {
	margin-top: 5px;
	display: inline-block;
	width: 100%;

	&__wrapper {
		position: relative;
		padding: {
			top: 10px;
			bottom: 30px;
		}
		@include _border( bottom );

		h3 {
			padding-bottom: 20px;
			margin: {
				top: 10px;
				bottom: 0;
			}
		}

		.downloads__counter {
			margin-top: 5px;
			display: inline-block;
			font-weight: 700;
		}

		.add_to_basket {
			@extend .btn;
			@extend .btn--small;
			@extend .btn--success;
			float: right;

			@include _breakpoint--sm {
				width: 200px;
			}
		}
	}

	&__thumb {
		float: left;
		margin-right: 10px;
	}

	&__description {
		float: left;

		h4 {
			margin: 0;

			a:hover {
				text-decoration: underline;
			}
		}

		.author {
			margin: 0;
			color: _text-color();
			font-size: _font-scale( medium );
		}
	}
}

.most-popular {
	p {
		font-weight: 400;
	}
}

.add-ons-list {
	display: inline-block;
	list-style: none;
	margin: 0;
	padding: 0;

	.views-row {
		@include _border( bottom );
		padding-bottom: _space();

		@include _breakpoint--md {
			padding-bottom: _space() * 3;
		}

		&:last-of-type {
			border-bottom: none;
		}

		> div {
			font-weight: 400;
			font-size: _font-scale( medium );

			h4 {
				margin: {
					top: _space();
					bottom: 5px;
				}
				font: {
					size: _font-scale( normal );
				}

				@include _breakpoint--sm {
					font-size: _font-scale( big );
				}

				a:hover {
					text-decoration: underline;
				}
			}

			p {
				color: _text-color( secondary );
				font: {
					size: _font-scale( medium );
					weight: 400;
				}
				margin: 0;

				&.presets {
					font-size: 1.3rem;
				}

				&:last-of-type {
					margin-bottom: 15px;
				}
			}
		}

		.add_to_basket,
		.check-details,
		.remove_from_basket,
		.required {
			@extend .btn;
			@extend .btn--small;
			display: none !important;
			float: right;
			margin-top: 20px;
			position: relative;

			@include _breakpoint--md {
				display: block !important;
				width: map-get( $cta-widths, normal );
			}
		}

		.add_to_basket {
			@extend .btn--success;
			display: none !important;

			@include _breakpoint--md {
				display: block !important;
				width: map-get( $cta-widths, normal );
			}
		}

		.remove_from_basket {
			@extend .btn--danger;
			display: none !important;

			@include _breakpoint--md {
				display: block !important;
				width: map-get( $cta-widths, normal );
			}
		}

		.required {
			@extend .btn--disabled;
			display: none !important;

			@include _breakpoint--md {
				display: block !important;
				width: map-get( $cta-widths, normal );
			}
		}
	}
}
.add-on-description {
	.add_to_basket,
	.remove_from_basket,
	.required,
	.btn-cta {
		position: relative;
	}

	.add_to_basket {
		@extend .btn--success;

		@include _breakpoint--md {
			width: map-get( $cta-widths, normal );
		}
	}

	.remove_from_basket {
		@extend .btn--danger;

		@include _breakpoint--md {
			width: map-get( $cta-widths, normal );
		}
	}

	.required {
		// @extend .btn--disabled;

		@include _breakpoint--md {
			width: map-get( $cta-widths, normal );
		}
	}
}

.ckbuilder__logo {
	margin-bottom: _space();
}

&.commercial_ico:before {
	background: url(http://c.cksource.com/a/1/img/dollar.png) no-repeat scroll center center transparent;
	content: ' ';
	height: 17px;
	line-height: 17px;
	margin: 0;
	text-align: center;
	width: 17px;
	position: absolute;
	left: -22px;
	top: 5px;
}
