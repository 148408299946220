$main-gutter: 20px;
$main-space: 2.5rem;
$main-space--px: 25px;
$middle-content-widths: (
	'standard': 900px,
	'wide': 1000px
);

@function _space() {
	@return $main-space;
}

@function _half-space() {
	@return $main-space / 2;
}

@function _double-space() {
	@return $main-space * 2;
}

@mixin _middle-content( $type: standard ) {
	width: map_get( $middle-content-widths, quote( $type ) );
	max-width: 100%;
	margin: 0 auto;
	text-align: center;
}

@mixin _clearfix {
	&:before,
	&:after {
		content: ' ';
		display: table;
	}
	&:after {
		clear: both;
	}
}

.clearfix {
	@include _clearfix;
}
