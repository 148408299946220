.skip-link {
	a {
		position: fixed;
		left: 0;
		top: 0;
		padding: .5rem 1.5rem;
		background-color: _color( black, .9 );
		font-size: _font-scale( medium );
		color: _text-color( primary, light );
		transform: translateX( -100% );
		@include _zindex( 'on-cover' );

		&:active,
		&:focus {
			text-decoration: none;
			transform: translateX( 0 );
		}
	}

	&__list {
		position: relative;
		@include _list-reset();
	}
}
