.login,
.register,
.info-page {
	@include _middle-content();
	margin: {
		top: _space() * 3;
		bottom: _double-space() * 2;
	}

	&--small {
		max-width: 850px;
	}

	form {
		max-width: 100%;
		width: 640px;
		margin: 0 auto;
	}

	label {
		text-align: left;
	}

	h2 + h3 {
		width: 700px;
		margin: _space() auto 1.5rem;
	}

	.form-control {
		margin-bottom: _space();

		&[type="submit"] {
			@extend .btn;
		}
	}

	.focus-placeholder {
		display: none;
	}

	.sign-up__encourage {
		margin: {
			top: 0;
			bottom: _space();
		}
		font: {
			size: _font-scale( big );
			weight: 200;
		}

		&.spaced {
			margin-bottom: _double-space();
		}
	}

	.btn {
		margin-right: _space();

		&:last-of-type {
			margin-right: 0;
		}

		&--fixed {
			margin-top: _space();
			min-width: 195px;
		}
	}
}

#edit-mollom-captcha {
	display: block;
}