.addon {
	width: 100%;
	margin: {
		left: 0;
		bottom: 35px;
	}
	padding: 10px 20px 30px;

	@include _breakpoint--md {
		width: calc(100% - #{$size__left-sidebar--width} - #{$main-space--px});
		margin-left: $main-space--px;
	}

	@include _breakpoint--lg {
			width: calc(100% - #{$size__left-sidebar--width} - ( #{$main-space--px} * 2 ) );
			margin: {
				left: $main-space--px;
				right: $main-space--px;
			}
		}

	&--add {
		padding: 0;

		@include _breakpoint--md {
			width: 100%;
			margin: 0;
		}
	}

	&--release {
		padding: 0;

		@include _breakpoint--md {
			width: 100%;
			margin: 0;
		}

		@include _breakpoint--lg {
			width: calc(100% - 50px);
			margin: {
				left: _space();
				right: _space();
			}
			background-color: _color( white );
		}
	}

	h2 {
		padding-bottom: 20px;
		margin: {
			top: 10px;
			bottom: 10px;
		}
		text-align: center;

		@include _breakpoint--sm {
			text-align: left;
		}

		a {
			color: _text-color();
		}
	}

	&__screenshots {
		width: 100%;
		padding-bottom: 20px;
		margin-bottom: 20px;
		@include _border( bottom );

		h3 {
			font: {
				size: _font-scale( normal );
				weight: 700;
			}
			margin: {
				top: 5px;
				bottom: 10px;
			}
		}

		.swiper-container {
			padding-bottom: 20px;

			.swiper-pagination-bullets {
				bottom: 0;
			}
		}

		.slick-dots {
			bottom: -25px;

			li button {
				&:before {
					color: _text-color( secondary );
				}
			}

			li.slick-active {
				button:before {
					color: _text-color( secondary );
				}
			}
		}

		.slick-prev,
		.slick-next {
			display: none !important;
			@include _zindex( float );

			@include _breakpoint--lg {
				display: inline-block !important;
			}

			&:before {
				color: _text-color( secondary );
			}
		}

		.slick-prev {
			left: 0;
		}

		.slick-next {
			right: 0;
		}

		&-images {
			width: 100%;

			img {
				display: block;
				max-width: 90%;
				margin: 0 auto 10px;
			}
		}
	}

	&__downloads {
		margin: {
			top: 20px;
			bottom: 15px;
		}
		font-size: _font-scale( big );
		text-align: center;

		a:hover {
			text-decoration: underline;
		}
	}

	&__info-container {
		display: inline-block;
		width: 100%;
	}

	&__thumb {
		float: left;
		margin: 0 15px 15px 0;
		text-align: center;

		width: 160px;
		height: 160px;

		background: no-repeat center;

		position: relative;

		> .addon-of-the-month {
			top: -9px;
			left: -9px;
			position: absolute;
		}

		@include _breakpoint--sm {
			text-align: left;
		}
	}

	&__info {
		float: left;
		width: 100%;
		font: {
			size: _font-scale( small );
			weight: 400;
		}
		text-align: center;
		margin-top: 10px;

		@include _breakpoint--sm {
			text-align: left;
			margin-top: 0;
			margin-left: -175px;
			padding-left: 175px;
		}

		ul {
			list-style: none;
			margin: 0;
			padding: 0;
		}

		&-list {
			li {
				font: {
					size: _font-scale( small );
					weight: 400;
				}
			}
		}
		time {
			display: inline-block;
			margin: 15px 0;
		}
	}

	&__link-list {
		li {
			display: inline-block;
			margin-right: 10px;
			font-weight: 400;
		}
	}

	&__description {
		font: {
			size:_font-scale( small );
			weight: 400;
		}
		display: inline-block;
		width: 100%;
		margin-bottom: _space();

		@include _breakpoint--sm {
			font-size: _font-scale( medium );
		}

		p {
			&:last-of-type {
				margin: 0;
			}
		}
	}

	&__actions-wrapper {
		display: inline-block;
		width: 100%;
		padding-bottom: 10px;

		.add_to_basket {
			margin: 0;
		}
	}

	&__actions {
		display: inline-block;
		width: 100%;
		text-align: center;

		.btn {
			margin-right: _space();

			&:last-of-type {
				margin-right: 0;
			}
		}

		&-info {
			display: inline-block;
			width: 100%;
			padding: {
				top: 5px;
				bottom: 15px;
			}
			margin-bottom: 30px;
			@include _border( bottom );
		}

	}

	&__action {
		padding: 0 5px;
		margin-bottom: 10px;
		text-align: center;

		@include _breakpoint--sm {
			margin: 0;
			text-align: left;
		}

		.btn {
			min-width: map-get( $cta-widths, normal );
		}

		&:first-of-type {
			.btn {
				@include _breakpoint--sm {
					float: right;
					margin-right: 1rem;
				}
			}
		}

		&:last-of-type {
			.btn {
				@include _breakpoint--sm {
					margin-left: 1rem;
				}
			}
		}
	}

	&__releases-wrapper {
		display: inline-block;
		width: 100%;

		h3 {
			font: {
				size: _font-scale( normal );
				weight: 400;
			}
		}
	}

	&__releases {
		display: inline-block;
		width: 100%;
		overflow-x: auto;
		font-size: 1.5rem;

		table {
			width: 100%;
			border-spacing: 0;
			border-collapse: separate;
			font-size: _font-scale( small );

			thead  {
				color: _text-color( primary );
				background-color: _color( white );

				tr {
					th {
						text-align: center;
					}
				}

				tr:first-of-type {
					th:first-of-type {
						text-align: left;
						@include _border( bottom );
					}
				}

				tr:last-of-type {
					th {
						@include _border( bottom );
					}
				}
			}

			tbody {
				color: _text-color( secondary );
			}

			tbody tr:nth-child(4n), tbody tr:nth-child(4n+3) {
				background-color: _color( white );
			}

			th {
				font-weight: 400;
				padding: 8px;
			}

			// TODO: apply some JS fore hiding and showing release notes
			.release-note {
				display: none;
			}

			tr.release-note .collapse {
				padding: 8px;
			}

			tr:not(.release-note) td {
				padding: 8px;
				text-align: center;
				@include _border( bottom );

				p {
					margin: 0;

					a {
						display: inline-block;

						&:first-of-type {
							margin-right: 5px;
						}
					}
				}

				.yes,
				.no {
					text-indent: -9999px;
				}

				&:first-of-type {
					white-space: nowrap;
					text-align: left;
				}
			}
		}

		.yes,
		.no,
		.no-data {
			@include _icon;
			vertical-align: middle;
		}

		// TODO: - migrate icons
		.yes {
			@extend .svg-ico__check-black;
		}

		.no {
			@extend .svg-ico__close--red;
		}

		.no-data {
			@extend .svg-ico__unknown;
		}

	}

	&__support-frame {
		padding: _half-space();
		margin: _half-space() 0;
		@include _border( );
		@include _radius( );

		p {
			font-size: _font-scale( medium );
			margin: _half-space( );
		}
	}
}

.add-plugin,
.plugin-edit,
.plugin-release {
	display: inline-block;
	width: 100%;
	padding: _space();

	&__form {
		background-color: _gray();
	}

	input,
	select {
		float: left;
		width: 100%;
		height: 100%;
		background-color: _color( white );
		font-size: _font-scale( small );
		padding: 6px;
		@include _border;
		@include _radius;

		@include _breakpoint--sm {
			font-size: _font-scale( medium );
			padding: 8px;
		}

		@include _breakpoint--md {
			float: left;
			width: 50%;
			margin-bottom: _space();
		}

		&[type="submit"] {
			@extend .btn;
			@extend .btn--small;
		}
	}

	#edit-actions {
		display: inline-block;
		width: 100%;
		padding-top: _space();
		text-align: center;

		&.left {
			text-align: left;
		}

		&.half {
			padding-right: 50%;
		}

		&.right {
			text-align: right;
		}

		input[type="submit"] {
			@extend .btn--big;
			float: none;
			width: map-get( $cta-widths, normal );
		}

		.form-submit + .form-submit {
			margin-left: 2.5rem;
		}
	}

	textarea + .description {
		padding-left: 0;
	}

	.description {
		float: left;
		width: 100%;
		margin-bottom: _space();
		font-size: _font-scale( small );

		@include _breakpoint--sm {
			font-size: _font-scale( medium );
		}

		@include _breakpoint--md {
			width: 50%;
			padding: 0 15px 0 30px;
		}
	}

	.field-type-text-with-summary {
		input,
		.description {
			width: 100%;
		}
	}

	.form-block {
		margin-bottom: 10px;

		.big-label {
			display: inline-block;
			width: 100%;
			font: {
				size: _font-scale( big );
				weight: 300;
			}
		}

		&__hint {
			font-size: _font-scale( medium );
			margin: 0 15px;
		}
	}

	.photo-upload {
		&__placeholder-wrapper {
			float: left;

			.placeholder {
				display: inline-block;
				width: 150px;
				height: 150px;
				background: {
					image: url(../images/thumbs/ico-plugin.png);
					repeat: no-repeat;
					size: 100%;
				}
			}
		}

		&__description-wrapper {
			float: left;
			width: calc(100% - 150px);
		}
	}

	.field-widget-text-textarea-with-summary {
		textarea {
			width: 100%;
		}

		.description {
			width: 100%;
			padding: 0;
		}
	}

	.form-checkboxes {
		float: left;
		width: 100%;

		@include _breakpoint--md {
			width: 50%;
		}

		label,
		input[type="checkbox"] {
			float: left;
			width: auto;
		}

		label {
			font: {
				size: _font-scale( medium );
				weight: 400;
			}
			margin: {
				top: 2px;
				bottom: 0;
			}
		}

		input[type="checkbox"] {
			vertical-align: middle;
			font-size: 100%;
			line-height: normal;
			height: auto;

			margin: {
				left: 20px;
				top: 5px;
				bottom: 0;
				right: 5px;
			}

			&:first-of-type {
				margin-left: 0;
			}

			&:last-of-type {
				margin-left: 0;
				clear: left;
			}
		}
	}

	.field-type-image-form {
		display: inline-block;
		width: 100%;
		font-size: _font-scale( medium );

		input[type="file"] {
			width: auto;
			@extend .btn;
			@extend .btn--small;
			@extend .btn--frame;
		}

		.fileinputs + input[type="submit"] {
			display: none;
		}

		button {
			@extend .btn;
			@extend .btn--small;
		}

		input[name="field_addon_logo_und_0_remove_button"] {
			margin-left: _space();
			width: auto;
			float: none;
			@extend .btn;
			@extend .btn--small;
		}

		.description {
			padding: 0;
			margin-top: 10px;
		}
	}

	a.tabledrag-handle {
		cursor: move;
		float: left;
		height: 1.7em;
		margin-left: -1em;
		overflow: hidden;
		text-decoration: none;

		.handle {
			background: url(https://ckeditor.com/cke4/misc/draggable.png) no-repeat 6px 9px;
			height: 13px;
			margin: -0.4em 0.5em;
			padding: 0.42em 0.5em;
			width: 13px;
		}
	}
}

.plugin-release {
	.form-checkboxes {
		input[type="checkbox"]:last-of-type {
			clear: none;
			margin-left: 20px;
		}
	}

	.file-browser-button__wrapper {
		@include _breakpoint--md {
			display: inline-block;
			float: left;
			clear: both;
			width: 50%;
			text-align: right;
		}
	}

	input[type="file"] {
		margin-bottom: 1rem;
	}
}

// temporary hide modals
.addon_download {
	display: none;
}

.downloads-info {
	display: none;
}
