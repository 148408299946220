@font-face {
	font-family: 'Nunito Sans';
	font-style: normal;
	font-weight: 200;
	src:
		url( '../fonts/NunitoSans-ExtraLight.woff2' ) format( 'woff2' ),
		url( '../fonts/NunitoSans-ExtraLight.woff' ) format( 'woff' ),
		url( '../fonts/NunitoSans-ExtraLight.ttf' ) format( 'ttf' )
}

@font-face {
	font-family: 'Nunito Sans';
	font-style: normal;
	font-weight: 400;
	src:
		url( '../fonts/NunitoSans-Regular.woff2' ) format( 'woff2' ),
		url( '../fonts/NunitoSans-Regular.woff' ) format( 'woff' ),
		url( '../fonts/NunitoSans-Regular.ttf' ) format( 'ttf' )
}

@font-face {
	font-family: 'Nunito Sans';
	font-style: normal;
	font-weight: 600;
	src:
		url( '../fonts/NunitoSans-SemiBold.woff2' ) format( 'woff2' ),
		url( '../fonts/NunitoSans-SemiBold.woff' ) format( 'woff' ),
		url( '../fonts/NunitoSans-SemiBold.ttf' ) format( 'ttf' )
}

@font-face {
	font-family: 'Nunito Sans';
	font-style: normal;
	font-weight: 700;
	src:
		url( '../fonts/NunitoSans-Bold.woff2' ) format( 'woff2' ),
		url( '../fonts/NunitoSans-Bold.woff' ) format( 'woff' ),
		url( '../fonts/NunitoSans-Bold.ttf' ) format( 'ttf' )
}

@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 500;
	src:
		url( '../fonts/Montserrat-Medium.woff2' ) format( 'woff2' ),
		url( '../fonts/Montserrat-Medium.woff' ) format( 'woff' ),
		url( '../fonts/Montserrat-Medium.ttf' ) format( 'ttf' )
}

@font-face {
	font-family: 'Nunito Sans';
	font-style: normal;
	font-weight: 800;
	src:
		url( '../fonts/NunitoSans-ExtraBold.woff2' ) format( 'woff2' ),
		url( '../fonts/NunitoSans-ExtraBold.woff' ) format( 'woff' ),
		url( '../fonts/NunitoSans-ExtraBold.ttf' ) format( 'ttf' )
}
