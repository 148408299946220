$menu__offset: 1rem;
$menu__right-offset: 90px;
$menu__right-offset--mobile: 70px;

@mixin menu_inverse() {
	.menu__page-title a {
		color: _text-color( );
	}

	.menu-more__list {
		background-color: _color( white, .9 );
	}

	.menu-more__toggler {
		color: _color( primary );

		&:hover,
		&:focus {
			background-color: transparent;
			border: none;
			outline: none;
		}

		@include _breakpoint--md {
			&:hover,
			&:focus {
				color: _color( primary );
			}
		}
	}

	.menu-more__list li a,
	ul li a {
		color: _text-color( secondary );

		&:hover {
			color: _text-color( primary );
			text-decoration: none;
		}

		&.menu-product__current-link {
			color: _text-color( primary );
		}
	}

	.menu-product {
		a.btn-icon {
			& * {
				fill: _color( black, .9 );
			}

			&:hover {
				& * {
					fill: _color( black );
				}
			}
		}
	}
}

@mixin menu-white {
	background-color: _color( white );
	@include _transition( all, 200ms );

	.menu-more__list {
		background-color: _color( white, .9 );
	}

	.menu__page-title a {
		color: _text-color( primary );
	}

	.menu-more__toggler {
		color: _color( primary );

		&:hover,
		&:focus {
			background-color: transparent;
			border: none;
			outline: none;
		}

		@include _breakpoint--md {
			&:hover,
			&:focus {
				color: _color( primary );
			}
		}
	}

	ul li a {
		color: _text-color( secondary );

		&:hover {
			color: _text-color( primary );
			text-decoration: none;
		}

		&.menu-product__current-link {
			color: _text-color( primary );
		}
	}

	a.btn-icon {
		& * {
			fill: _color( black, .9 );
		}

		&:hover {
			& * {
				fill: _color( black );
			}
		}
	}
}

.menu {
	position: absolute;
	top: 1rem;
	width: 100%;
	background-color: transparent;
	height: $top-menu__height;
	@include _zindex( air );

	@include _breakpoint--mo {
		top: 0;
		position: fixed;
		background-color: _color( 'white' );
		@include _border( bottom );

		.menu__page-title a,
		.menu-more__toggler {
			color: _text-color();
		}
	}

	&--no-bg {
		&-md {
			@include _breakpoint--md {
				background: transparent !important;

				&.menu--small {
					border-bottom: 0;
				}
			}
		}
	}

	&__page-title {
		display: inline-block;
		height: 100%;
		width: 270px;
		padding-left: $menu__right-offset--mobile;

		&.with-subtitle {
			a {
				font-weight: 400;

				span {
					margin-left: 1.5rem;
					font-weight: 300;
				}
			}
		}

		@include _breakpoint--xs {
			width: 230px;
		}

		@include _breakpoint--sm {
			padding-left: $menu__right-offset;
			width: auto;
		}

		a {
			font: {
				family: 'Nunito Sans', sans-serif;
				weight: 600;
				size: _font-scale( big );
			}
			color: _color( white );
			line-height: $top-menu__height;
			letter-spacing: inherit;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			text-decoration: none;

			&:hover {
				opacity: .8;
			}
		}
	}

	&--no-links {
		@include _breakpoint--mo {
			.menu__page-title {
				width: 100%;
			}
		}
	}

	&__page-subtitle {
		display: none;
		position: relative;
		float: right;
		height: $top-menu__height;
		padding: {
			right: _space() + $menu__offset;
			left: _space() + $menu__offset;
		}
		background-color: _gray();
		@include _radius( left );
		@include _transition( background-color );

		@include _breakpoint--sm-plus {
			display: block;
		}

		p {
			margin: 0;
			font: {
				size: _font-scale( normal );
				weight: 500;
			}
		}
	}

	&:not( .menu--tiny ) {
		@include _breakpoint--sm {
			height: $top-menu__height;

			&,
			h1,
			h2 {
				line-height: $top-menu__height;
			}
		}
	}

	&--tiny {
		.menu__page-title a {
			font: {
				size: 2.4rem;
				weight: 400;
			}
		}
	}

	.progress-bar {
		display: none;
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 3px;

		@include _breakpoint--sm {
			display: block;
		}

		&__fill {
			display: block;
			width: 100%;
			max-width: 0%;
			height: 100%;
			background-color: _color( success );
			@include _transition( max-width, 200ms, ease );
		}
	}

	&-product,
	&-home {
		float: right;
		height: $top-menu__height;
		line-height: $top-menu__height;
		visibility: hidden;
		opacity: 0;
		@include _transition( padding );

		@include _breakpoint--sm {
			padding-right: _space() + $menu__offset;
		}

		&--ready {
			visibility: visible;
			opacity: 1;
		}

		&--legal {
			@include _breakpoint--md {
				visibility: hidden;
				opacity: 0;
			}
		}

		ul {
			display: inline-block;
			vertical-align: top;
			list-style: none;
			margin: 0;
			padding: 0;
		}

		li {
			display: inline-block;
			vertical-align: top;
			height: 100%;

			&:first-of-type {
				margin-left: 0;
			}

			a {
				display: block;
				font: {
					size: _font-scale( medium );
					weight: 600;
				}
				color: _text-color( secondary );
				height: 100%;
				line-height: $top-menu__height;
				padding: 0 $menu__offset;
				@include _transition( color );

				&:hover {
					color: _text-color( primary );
					text-decoration: none;
				}

				&.menu-product__current-link {
					color: _text-color( primary );
				}

				@include _breakpoint--sm {
					color: _text-color( secondary, light );
					line-height: $top-menu__height;

					&:hover {
						color: _text-color( primary, light );
						text-decoration: none;
					}

					&.menu-product__current-link {
						color: _text-color( primary, light );
					}
				}

				@include _breakpoint--sm-only {
					font-size: _font-scale( medium );
				}
			}
		}
	}

	&:not( .menu--tiny ) {
		.menu-product {
			@include _breakpoint--sm {
				height: $top-menu__height;
			}
		}
	}

	&--no-hero,
	&--home {
		@include menu_inverse;
	}

	.github-btn__wrapper {
		display: none;

		@include _breakpoint--sm {
			display: inline-block;
		}
	}

	a.btn-icon {
		display: inline-block;
		width: 30px;
		border: none;
		padding: 0 1rem;
		margin: 0 1rem;
		background-color: transparent;
		vertical-align: middle;

		svg {
			height: 30px;
			width: 30px;
			margin-right: .5rem;

			& * {
				fill: _color( white, .9 );
				@include _transition;
			}
		}

		&:hover {
			svg * {
				fill: _color( white );
			}
		}
	}

	&--small {
		position: fixed;
		top: 0;
		background-color: _color( white );
		@include _border( bottom );
		@include fadeIn(
			$duration: 300ms,
			$function: ease-out,
			$count: 1,
			$fill: both
		);

		.menu-more__list {
			background-color: _color( white, .9 );
		}

		.menu__page-title a {
			color: _text-color( primary );

			@include _breakpoint--sm {
				font-size: _font-scale( normal );
			}
		}

		.menu-more__toggler {
			color: _color( primary );

			&:hover,
			&:focus {
				background-color: transparent;
				border: none;
				outline: none;
			}

			@include _breakpoint--md {
				&:hover,
				&:focus {
					color: _color( primary );
				}
			}
		}

		ul li a {
			color: _text-color( secondary );

			&:hover {
				color: _text-color( primary );
				text-decoration: none;
			}

			&.menu-product__current-link {
				color: _text-color( primary );
			}
		}

		a.btn-icon {
			svg * {
				fill: _color( black, .9 );
			}

			&:hover {
				svg * {
					fill: _color( black );
				}
			}
		}

		&-alpha {
			background-color: _color( white );
			@include _border( bottom );

			.menu-more__list {
				background-color: _color( white );
			}

			&.menu--tiny {
				background-color: _color( white );
			}
		}

		.menu-product,
		.menu-side {
			padding-top: 0;
		}

		.menu__page-subtitle {
			border-radius: 0;
			background-color: transparent;
		}
	}

	&-more {
		display: none;
		height: $top-menu__height;

		&--active {
			display: inline-block;
		}

		&.dropdown {
			ul {
				max-width: 300px;

				@include _breakpoint--sm {
					max-width: 350px;
				}

				li {
					display: block;

					a {
						padding: ( _space() / 2 ) _space() ( _space() / 2 );
						color: _text-color( primary );
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;

						&.menu-product__current-link {
							color: _color( primary );
						}
					}
				}
			}
		}

		&__toggler[ data-toggle="dropdown" ] {
			height: 100%;
			padding: {
				left: $menu__offset;
			}
			border: none;
			background-color: transparent;
			font: {
				size: _font-scale( medium );
				weight: 600;
			}

			&:after {
				right: 1.75rem;
				font-size: .9rem;
				top: 50%;
			}

			@include _breakpoint--sm {
				padding: {
					left: $menu__offset;
					right: 2rem;
				}
				font-size: _font-scale( normal );

				&:after {
					top: 52%;
					right: 0;
					font-size: 1.1rem;
				}
			}

			&:hover,
			&:focus {
				background-color: transparent;
				border: none;
				outline: none;
			}

			@include _breakpoint--md {
				&:hover,
				&:focus {
					color: _color( primary );
				}
			}
		}
	}

	&--trial {
		@include _breakpoint--md {
			&.menu--small {
				ul li {
					&:last-of-type {
						a {
							display: inline-block;
							height: 34px;
							line-height: 34px;
							padding: 0 15px;
							color: _text-color( primary, light );
							font-weight: 600;
							background-color: _color( primary );
							vertical-align: middle;
							@include _radius;

							&:hover,
							&:focus {
								background-color: darken( _color( primary ), 5 );
							}
						}
					}
				}
			}
		}
	}
}
