.dialog-form {
	width: 450px;
	max-width: 90%;
	margin: 0 auto;
	font-size: _font-scale( medium );

	.btn {
		float: right;
	}

	textarea.form-control {
		height: auto;
		resize: none;
	}

	form {
		opacity: 1;
		max-height: 800px;
		overflow: hidden;
		@include _transition( all, 400ms );

		&.fade-out {
			opacity: 0;
			max-height: 1px;
		}
	}

	.form-control {
		font-size: _font-scale( medium );
	}

	.form-response {
		float: none;
		text-align: center;

		&--error {
			border: 2px solid;
			padding: _space() / 2;
			margin: _space() auto;
		}

		&--success {
			position: relative;
			margin: 0;
			padding-bottom: _space() * 3;

			&:before {
				position: absolute;
				width: 50px;
				height: 50px;
				left: 50%;
				bottom: -10px;
				transform: translateX( -50% );
			}
		}
	}
}
