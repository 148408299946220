$footer__offset: _space();
$footer__menu-width: 210px;
$footer--max-width: 1060px;

.footer {
	padding: {
		top: $footer__offset;
		bottom: $footer__offset;
	}
	color: _text-color( primary, light );
	background-color: _color( panel );

	.section-container {
		width: map_get( $middle-content-widths, wide );
		max-width: 100%;

		@include _breakpoint--lg {
			width: map_get( $middle-content-widths, wide );
		}
	}

	&-site-map {
		@include _breakpoint--sm-plus {
			display: flex;
			justify-content: space-around;
		}
	}

	&-menu {
		list-style: none;
		padding-left: 1px;
		margin: {
			top: .5rem;
			bottom: .5rem;
		}

		@include _breakpoint--sm {
			margin-top: 0;
		}

		li {
			height: $base-line-height - 2px;
			line-height: $base-line-height - 2px;
		}

		a {
			color: _text-color( secondary, light );
			font: {
				size: _font-scale( small );
				weight: 600;
			}
		}

		&__wrapper {
			width: 100%;
			text-align: center;
			margin: {
				top: _space();
				bottom: _space();
			}

			@include _breakpoint--sm {
				width: auto;
				text-align: inherit;
			}

			@include _breakpoint--md {
				margin: 0;
				text-align: inherit;

				&:last-of-type {
					width: auto;
				}
			}

			h2 {
				margin: {
					top: 0;
					bottom: .5rem;
				}
				font: {
					size: _font-scale( normal );
					weight: 600;
				}
				line-height: _space();
			}
		}

		&__category-items {
			width: 100%;
			display: flex;
			justify-content: space-around;
			flex-wrap: wrap;

			@include _breakpoint--md {
				justify-content: space-between;
				flex-wrap: nowrap;
			}
		}
	}

	.module-subscribe {
		&__actions {
			width: 400px;
			margin: {
				top: _space();
				bottom: _space();
			}
			box-shadow: none;

			.btn {
				padding: 9.5px 24px;
			}

			@include _breakpoint--md {
				margin: {
					bottom: 0;
					left: 0;
				}

				.btn {
					padding: 10px 24px;
				}
			}

			.form-group {
				input {
					height: 40px;
					border: 2px solid _color( primary );
					font-size: _font-scale( medium );
				}

				&:last-of-type {
					margin-left: 0;
				}
			}

			& + .mc-response {
				box-shadow: none;

				&--success,
				&--error {
					@include _border;
				}
			}
		}
	}
}

.footer-social {
	margin: _space() auto;

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	li {
		display: inline-block;
		margin: {
			left: _space() / 4;
			right: _space() / 4;
		}

		&:first-of-type {
			margin-left: 0;
		}

		.icon {
			svg * {
				fill: _text-color( secondary, light );
			}

			&:hover {
				svg * {
					fill: _text-color( primary, light );
				}
			}
		}
	}

	@include _breakpoint--mo {
		margin-top: 0;
	}

	@include _breakpoint--sm-plus {
		text-align: inherit;
		margin: {
			top: _space();
			bottom: 0;
		}
	}
}


.footer-policy {
	@include _section;
	font-size: _font-scale( tiny );
	color: _text-color( secondary, light );
	text-align: center;

	@include _breakpoint--sm {
		font-size: _font-scale( small );
	}

	@include _breakpoint--md {
		text-align: left;
	}

	&.section-container {
		@include _breakpoint--md {
			margin-top: _double-space();
		}
	}

	&.footer-simple.section-container {
		@include _breakpoint--md {
			margin-top: _half-space();
		}
	}

	p {
		display: inline-block;
		margin: 0;
	}

	&__menu {
		display: inline-block;
		@include _list-reset;
		margin-left: _space();

		li {
			display: inline-block;
			margin-right: _space() / 2;

			&:last-of-type {
				margin-right: 0;
			}

			a {
				color: _text-color( primary, light );

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	.dashboard-link {
		margin: {
			top: _space();
			bottom: _space() / 2;
		}
		border-bottom: 1px solid lighten( _color( 'panel' ), 10 );

		a {
			color: _text-color( secondary, light );
			font-weight: 800;

			&:hover {
				color: _text-color( primary, light );
			}
		}
	}
}

// Mobile version of footer - less links, with two columns only.
.footer-mobile {
	width: 100%;
	max-width: 100%;
	text-align: center;

	ul {
		@include _list-reset();
	}

	@include _breakpoint--sm {
		width: 460px;
		margin: {
			bottom: _space();
			left: auto;
			right: auto;
		}
		text-align: inherit;
	}

	@include _breakpoint--sm-plus {
		display: none;
	}

	h2 {
		margin: 0;
		font: {
			size: _font-scale( normal );
			weight: 600;
		}
		color: _text-color( secondary, light );
		line-height: _double-space();
	}

	&__column {
		margin-bottom: _space();
		padding: 0;

		@include _breakpoint--sm {
			padding: {
				left: _double-space();
				right: _double-space();
			}
		}
	}

	&__social {
		text-align: center;
	}

	&__menu {
		li {
			height: 3rem;
			line-height: 3rem;

			a {
				display: block;
				color: _text-color( primary, light );
				font: {
					size: _font-scale( small );
					weight: 700;
				}

				@include _breakpoint--sm-plus {
					font: {
						size: _font-scale( medium );
					}
				}

				@include _breakpoint--sm-plus {
					font: {
						size: inherit;
						weight: inherit;
					}
				}
			}
		}
	}
}

.footer-newsletter {
	h2 {
		font-size: _font-scale( normal );
		font-weight: 600;
		text-align: center;
		margin-bottom: 0;

		@include _breakpoint--sm {
			margin-bottom: .5rem;
		}
	}

	.module-subscribe {
		&__actions {
			width: 400px;
			max-width: 80%;
			margin: {
				top: _half-space() / 2;
				bottom: 0;
			}
			box-shadow: none;

			.btn {
				padding: 9.5px 24px;
			}

			@include _breakpoint--sm {
				margin-top: 0;
			}

			@include _breakpoint--md {
				.btn {
					padding: 10px 24px;
				}
			}

			.form-group {
				input {
					height: 40px;
					border: 0;
					font-size: _font-scale( medium );
				}

				&:last-of-type {
					margin-left: 0;

					@include _breakpoint--mo {
						margin-top: _half-space();
					}
				}
			}

			& + .mc-response {
				box-shadow: none;

				&--success,
				&--error {
					color: _text-color( primary, light );
					@include _border;
				}

				&--success {
					background-color: _color( 'success' );
				}

				&--error {
					background-color: _color( 'danger' );
				}

				a {
					font-weight: 600;
					color: _text-color( primary, light );
				}
			}
		}
	}
}
