.cookies-box {
	display: none;
	position: relative;
	width: 100%;
	color: _text-color( primary, light );
	background-color: _color( bg-dark );
	text-align: center;
	@include _zindex( air );

	&--active {
		display: inline-block;
		max-height: 120px;
		padding: 20px 0;
		opacity: 1;
		@include _transition( all, 600ms );
	}

	&--accepted {
		max-height: 0;
		padding: 0;
		opacity: 0;
		overflow: hidden;
	}

	@include _breakpoint--sm-plus {
		font-size: _font-scale( big );
	}

	p {
		margin: 0;
		font-size: 1rem;

		a {
			font-weight: 600;
		}

		@include _breakpoint--sm {
			font-size: _font-scale( medium );
		}
	}

	.cookies-agree {
		margin-left: 1rem;
	}

	.btn--xs {
		padding: 3px 13px;
		line-height: 1.5;
		margin: {
			left: .5rem;
			top: .5rem
		}

		@include _breakpoint--sm {
			margin: {
				left: 1rem;
				top: 0;
			}
		}
	}
}