$section__describe-width: 400px;

.section {
	&-describe {
		text-align: center;

		@include _breakpoint--sm {
			@include _center( v );
			max-width: 100%;
			text-align: inherit;

			&--left {
				right: 0;
				text-align: right;

				@include _breakpoint--sm {
					padding-left: 2rem;
				}

				@include _breakpoint--md {
					padding: 0;
				}

				p,
				.btn {
					float: right;
					clear: both;
				}
			}

			&--right {
				left: 0;

				@include _breakpoint--sm {
					padding-right: 2rem;
				}

				@include _breakpoint--md {
					padding: 0;
				}
			}

			&__wrapper {
				position: relative;

				h2 {
					font-size: map-get( $header2-types, 'module' );
				}

				p {
					width: $section__describe-width;
					max-width: 100%;
				}
			}
		}

		&.hint {
			color: _text-color( secondary );

			p:last-of-type {
				margin-top: 0;
			}
		}
	}

	&-wrapper {
		@include _breakpoint--sm {
			display: flex;
		}

		.thumb-wrapper {
			display: inline-block;
			overflow: hidden;

			&--left {
				@include _breakpoint--sm {
					.composition {
						float: right;
					}
				}
			}

			.composition {
				margin: _space() 0;
			}
		}
	}
}

.site-content {
	background-color: _color( white );

	&--no-hero {
		padding-top: 40px;

		@include _breakpoint--sm {
			padding-top: 90px;
		}
	}

	&--blue-bg {
		background: {
			image: url( ../images/bg-home.png );
			repeat: no-repeat;
			position: 50% 25%;
			size: 100% auto;
		}

		&.site-content--ckeditor-5-download {
			background: none;
		}
	}

	&--home,
	&--legal,
	&--inner-header {
		padding-top: 0;
	}
}

.composition {
	display: inline-block;
	position: relative;
	margin: _space() 0;

	&--hint {
		opacity: .5;
	}

	&__thumb {
		background: {
			repeat: no-repeat;
			size: contain;
		}
	}

	@include _breakpoint--mo {
		width: 100%;
		text-align: center;
		background: {
			size: contain;
		}

		&__image {
			display: none;
		}

		&__thumb {
			position: static;
			width: 100%;
			height: 230px;
			margin: 0 auto _space();
			top: auto;
			left: auto;
			right: auto;
			bottom: auto;
			background-position: center;
		}
	}

	&__mobile {
		width: 320px;
		max-width: 100%;
		margin: _space() auto;

		img {
			width: 340px;
			max-width: 100%;
			height: auto;
		}
	}

	@include _breakpoint--sm {
		width: auto;
		background: transparent;

		&,
		&__thumb {
			max-width: 490px;
		}

		&__mobile {
			display: none;
		}
	}

	@include _breakpoint--md {
		display: block;
		transform: none;

		&,
		&__thumb {
			max-width: none;
		}
	}

	&__image {
		position: absolute;
		background-repeat: no-repeat;
		image-rendering: -webkit-optimize-contrast;
	}
}

.introduce {
	display: inline-block;
	width: 100%;
	max-width: 100%;
	text-align: center;
	padding: _space() 1.5rem 0;
	margin-top: _space();

	@include _breakpoint--sm {
		margin-top: _double-space();
	}

	@include _breakpoint--md {
		margin-top: _double-space() * 2;

		& + section {
			margin-top: _double-space();
		}
	}

	h2,
	h3 {
		max-width: 100%;
		margin: {
			left: auto;
			right: auto;
		}
	}

	h2 {
		width: 800px;

		& + h3 {
			width: 1168px;
			max-width: 100%;
			line-height: 4rem;
		}
	}

	p {
		max-width: 100%;
		width: 770px;
		margin: {
			left: auto;
			right: auto;
		}
	}

	.btn-cta {
		margin: {
			top: _space();
			bottom: _space();
		}
	}

	@include _breakpoint--md {
		&--wide {
			h2 {
				width: 1200px;
			}
		}

		&--narrow {
			h2 {
				width: 1080px;
			}

			h3 {
				width: 840px;
			}
		}

		&--middle {
			h2 {
				width: map-get( $middle-content-widths, 'standard' );
			}
		}
	}

	&--sequence {
		margin-top: 0;

		.btn-cta {
			margin-bottom: 0;
		}
	}

	&--thin {
		padding-top: 0;

		p {
			width: 400px;
		}
	}
}

.hidden {
	display: none !important;
}
