.pagination {
	text-align: center;

	a,
	span {
		margin-right: 1rem;
	}

	&:first-child,
	&:last-child {
		font-size: _font-scale( big );
	}

	&:last-child {
		margin-right: 0;
	}
}