%svg-common {
	background: url("../images/vector.svg") no-repeat;
}

.svg-ico__unknown {
	@extend %svg-common;
	background-position: 99.85875706214689% 36.95652173913044%;
	width: 20px;
	height: 20px;
}

.svg-ico__addon {
	@extend %svg-common;
	background-position: 99.71791255289139% 22.897196261682243%;
	width: 19px;
	height: 22px;
}

.svg-ico__addon--preset {
	@extend %svg-common;
	background-position: 99.71791255289139% 33.177570093457945%;
	width: 19px;
	height: 22px;
}

.svg-ico__addon--required {
	@extend %svg-common;
	background-position: 99.71791255289139% 26.32398753894081%;
	width: 19px;
	height: 22px;
}

.svg-ico__addon--selected {
	@extend %svg-common;
	background-position: 99.71791255289139% 29.75077881619938%;
	width: 19px;
	height: 22px;
}

.svg-ico__check {
	@extend %svg-common;
	background-position: 98.72881355932203% 14.923076923076923%;
	width: 20px;
	height: 14px;
}

.svg-ico__check-black {
	@extend %svg-common;
	background-position: 99.85915492957747% 17.076923076923077%;
	width: 18px;
	height: 14px;
}

.svg-ico__close--red {
	@extend %svg-common;
	background-position: 99.01960784313725% 44.46153846153846%;
	width: 14px;
	height: 14px;
}

.svg-ico__api {
	@extend %svg-common;
	background-position: 0 90.7258064516129%;
	width: 84px;
	height: 84px;
}

.svg-ico__arrow-down {
	@extend %svg-common;
	background-position: 92.51700680272108% 0;
	width: 68px;
	height: 68px;
}

.svg-ico__big-white-arrow-down {
	@extend %svg-common;
	background-position: 64.51612903225806% 26.38623326959847%;
	width: 98px;
	height: 57px;
}

.svg-ico__check {
	@extend %svg-common;
	background-position: 99.35064935064935% 16.117216117216117%;
	width: 40px;
	height: 34px;
}

.svg-ico__close-white {
	@extend %svg-common;
	background-position: 99.02912621359224% 22.509225092250922%;
	width: 38px;
	height: 38px;
}

.svg-ico__examples {
	@extend %svg-common;
	background-position: 80.41958041958041% 17.741935483870968%;
	width: 84px;
	height: 84px;
}

.svg-ico__faq {
	@extend %svg-common;
	background-position: 80.41958041958041% 51.61290322580645%;
	width: 84px;
	height: 84px;
}

.svg-ico__fb {
	@extend %svg-common;
	background-position: 55.008210180623976% 84.11214953271028%;
	width: 47px;
	height: 45px;
}

.svg-ico__fb-black {
	@extend %svg-common;
	background-position: 89.32676518883416% 74.95327102803738%;
	width: 47px;
	height: 45px;
}

.svg-ico__fb-flat {
	@extend %svg-common;
	background-position: 27.512355848434925% 88.23529411764706%;
	width: 49px;
	height: 70px;
}

.svg-ico__fb-flat-blue {
	@extend %svg-common;
	background-position: 35.584843492586494% 88.23529411764706%;
	width: 49px;
	height: 70px;
}

.svg-ico__fb-flat-white {
	@extend %svg-common;
	background-position: 92.51700680272108% 13.28125%;
	width: 68px;
	height: 68px;
}

.svg-ico__fb-outline {
	@extend %svg-common;
	background-position: 88.16855753646678% 35.416666666666664%;
	width: 39px;
	height: 52px;
}

.svg-ico__g-plus {
	@extend %svg-common;
	background-position: 89.62108731466228% 44.095940959409596%;
	width: 49px;
	height: 38px;
}

.svg-ico__g-plus-black {
	@extend %svg-common;
	background-position: 89.62108731466228% 58.11808118081181%;
	width: 49px;
	height: 38px;
}

.svg-ico__g-plus-flat {
	@extend %svg-common;
	background-position: 64.74820143884892% 0;
	width: 100px;
	height: 69px;
}

.svg-ico__g-plus-flat-blue {
	@extend %svg-common;
	background-position: 64.74820143884892% 13.50293542074364%;
	width: 100px;
	height: 69px;
}

.svg-ico__g-plus-flat-white {
	@extend %svg-common;
	background-position: 89.62108731466228% 51.1070110701107%;
	width: 49px;
	height: 38px;
}

.svg-ico__g-plus-outline {
	@extend %svg-common;
	background-position: 45.22184300341297% 85.06616257088847%;
	width: 70px;
	height: 51px;
}

.svg-ico__github {
	@extend %svg-common;
	background-position: 14.685314685314685% 73.46938775510205%;
	width: 84px;
	height: 90px;
}

.svg-ico__gitter {
	@extend %svg-common;
	background-position: 80.41958041958041% 34.67741935483871%;
	width: 84px;
	height: 84px;
}

.svg-ico__guides {
	@extend %svg-common;
	background-position: 58.74125874125874% 73.17073170731707%;
	width: 84px;
	height: 88px;
}

.svg-ico__guildes {
	@extend %svg-common;
	background-position: 80.41958041958041% 0;
	width: 84px;
	height: 88px;
}

.svg-ico__ideas {
	@extend %svg-common;
	background-position: 63.716814159292035% 39.877300613496935%;
	width: 91px;
	height: 91px;
}

.svg-ico__instagram {
	@extend %svg-common;
	background-position: 89.32676518883416% 83.52059925093633%;
	width: 47px;
	height: 46px;
}

.svg-ico__instagram-black {
	@extend %svg-common;
	background-position: 0 100%;
	width: 47px;
	height: 46px;
}

.svg-ico__issue-tracker {
	@extend %svg-common;
	background-position: 44.05594405594405% 73.17073170731707%;
	width: 84px;
	height: 88px;
}

.svg-ico__localization {
	@extend %svg-common;
	background-position: 0 73.46938775510205%;
	width: 84px;
	height: 90px;
}

.svg-ico__medium {
	@extend %svg-common;
	background-position: 100% 8.208955223880597%;
	width: 44px;
	height: 44px;
}

.svg-ico__medium-black {
	@extend %svg-common;
	background-position: 100% 0;
	width: 44px;
	height: 44px;
}

.svg-ico__menu {
	@extend %svg-common;
	background-position: 7.6923076923076925% 98.34254143646409%;
	width: 45px;
	height: 37px;
}

.svg-ico__menu-dark {
	@extend %svg-common;
	background-position: 77.9050736497545% 82.87292817679558%;
	width: 45px;
	height: 37px;
}

.svg-ico__search {
	@extend %svg-common;
	background-position: 99.15848527349229% 40.523882896764256%;
	width: 15px;
	height: 15px;
}

.svg-ico__phone {
	@extend %svg-common;
	background-position: 89.47368421052632% 66.35338345864662%;
	width: 48px;
	height: 48px;
}

.svg-ico__stackoverflow {
	@extend %svg-common;
	background-position: 29.37062937062937% 73.17073170731707%;
	width: 84px;
	height: 88px;
}

.svg-ico__tutorials {
	@extend %svg-common;
	background-position: 80.41958041958041% 68.54838709677419%;
	width: 84px;
	height: 84px;
}

.svg-ico__twitter {
	@extend %svg-common;
	background-position: 62.725779967159276% 83.64312267657992%;
	width: 47px;
	height: 42px;
}

.svg-ico__twitter-black {
	@extend %svg-common;
	background-position: 70.44334975369458% 83.64312267657992%;
	width: 47px;
	height: 42px;
}

.svg-ico__twitter-flat {
	@extend %svg-common;
	background-position: 62.82722513089005% 56.07843137254902%;
	width: 83px;
	height: 70px;
}

.svg-ico__twitter-flat-blue {
	@extend %svg-common;
	background-position: 14.659685863874346% 88.23529411764706%;
	width: 83px;
	height: 70px;
}

.svg-ico__twitter-flat-white {
	@extend %svg-common;
	background-position: 89.32676518883416% 91.44981412639405%;
	width: 47px;
	height: 42px;
}

.svg-ico__twitter-outline {
	@extend %svg-common;
	background-position: 90.81803005008348% 25.708884688090738%;
	width: 57px;
	height: 51px;
}

.svg-logo__adobe {
	@extend %svg-common;
	background-position: 0 0;
	width: 120px;
	height: 120px;
}

.svg-logo__att {
	@extend %svg-common;
	background-position: 44.776119402985074% 52.17391304347826%;
	width: 120px;
	height: 120px;
}

.svg-logo__citi {
	@extend %svg-common;
	background-position: 22.388059701492537% 52.17391304347826%;
	width: 120px;
	height: 120px;
}

.svg-logo__disney {
	@extend %svg-common;
	background-position: 0 52.17391304347826%;
	width: 120px;
	height: 120px;
}

.svg-logo__ibm {
	@extend %svg-common;
	background-position: 44.776119402985074% 26.08695652173913%;
	width: 120px;
	height: 120px;
}

.svg-logo__microsoft {
	@extend %svg-common;
	background-position: 44.776119402985074% 0;
	width: 120px;
	height: 120px;
}

.svg-logo__nbc {
	@extend %svg-common;
	background-position: 22.388059701492537% 26.08695652173913%;
	width: 120px;
	height: 120px;
}

.svg-logo__oracle {
	@extend %svg-common;
	background-position: 0 26.08695652173913%;
	width: 120px;
	height: 120px;
}

.svg-logo__siemens {
	@extend %svg-common;
	background-position: 22.388059701492537% 0;
	width: 120px;
	height: 120px;
}
