#addon_machine_name {
  display: none;
}

.hidden, .element-invisible, .add_new_skin_category {
  display: none !important;
}

.fileinputs {
	position: relative;
}

.fakefile {
	// Hide the element because there is no script to handle it yet.
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	cursor: pointer;
}

#add-on-node-form #edit-field-screenshots .tabledrag-toggle-weight-wrapper,
.addon--release input[name="field_release_file_und_0_upload_button"]
{
  display: none;
}

.add-plugin,
.plugin-edit,
.addon--release,
.plugin-release {
	// .field-type-image-form.field-name-field-screenshots-form table input[type="submit"],
	// input[name="files[field_release_file_und_0]"] {
	// 	display: block;
	// 	width: auto;
	// 	@extend .btn;
	// 	@extend .btn--small;
	// 	@extend .btn--frame
	// }

	.field-type-image-form.field-name-field-screenshots-form label {
		display: none;
	}

	/**
	* Image upload widget.
	*/
	div.image-preview {
		float: none;
		padding: 1rem;
	}
	div.image-widget-data {
		float: none;
		padding: 1rem;
	}

  	select.form-control--big {
		@include _breakpoint--sm {
			height: 43px;
		}

		option {
			padding: 9px 13px;
		}
	}

	textarea {
		display: inline-block;
		width: 100%;
		height: 100%;
		padding: 8px;
		background-color: _color( white );
		@include _border;
		@include _radius;

		@include _breakpoint--sm {
			font-size: _font-scale( medium );
		}
	}

	input {
		background-color: _color( white );
	}

	label,
	.field-type-image-form.field-name-field-screenshots-form legend {
		font-weight: bold;
		display: inline-block;
		width: 100%;
		margin-bottom: 5px;
		font: {
			size: _font-scale( medium );
			weight: 600;
		}
	}

	.input-wrapper {
		display: inline-block;
		width: 100%;
		margin-bottom: 15px;
		@include _clearfix;

		@include _breakpoint--sm {
			margin-bottom: _space();
		}
	}

	.middle-submit {
		text-align: center;

		.btn {
			width: 100%;

			&--big {
				padding: 9px 13px;
			}

			@include _breakpoint--lg {
				min-width: 240px;
				width: auto;
			}
		}
	}
}