$addon-thumb--size: 64px;

.user {
	margin: {
		top: 50px;
		bottom: 40px;
	}

	@include _breakpoint--md {
		margin: {
			top: 120px;
			bottom: 100px;
		}
	}

	&__photo {
		display: inline-block;
		width: 100%;
		text-align: center;

		img {
			border-radius: 50%;
		}
	}

	&__name {
		font: {
			family: 'Nunito Sans', sans-serif;
			size: 4.2rem;
			weight: 200;
		}
		line-height: 1.04;
		margin-top: _space();
	}

	&__name-wrapper,
	&__description {
		text-align: center;
	}

	&__name-wrapper {
		position: relative;
		margin-bottom: _double-space();
		padding-bottom: 60px;
	}

	&__country {
		position: absolute;
		left: 50%;
		bottom: 35px;
		font-size: _font-scale( small );
		color: _text-color( secondary );
		transform: translateX( -50% );
	}

	&__website {
		position: absolute;
		left: 50%;
		bottom: 0;
		font-size: _font-scale( normal );
		color: _text-color( secondary );
		transform: translateX( -50% );
	}

	&__description {
		margin-bottom: 30px;

		@include _breakpoint--md {
			// font-size: 2.7rem;
			max-width: map-get( $middle-content-widths, standard );
			margin: 0 auto;
		}
	}

	&__addons {
		display: inline-block;
		width: 100%;
		margin-top: _double-space();

		&-list {
			list-style: none;
			margin: 0 auto;
			padding: 0;

			@include _breakpoint--md {
				max-width: 830px;
			}
		}

		h2 {
			text-align: center;
		}
	}

	&__addon {
		display: inline-block;
		width: 100%;
		position: relative;
		padding: {
			top: 1rem;
			bottom: 1rem;
		}
		@include _border( bottom );

		&-thumb {
			float: left;
			margin-right: 1rem;

			img {
				max-width: $addon-thumb--size;
				max-height: $addon-thumb--size;
			}
		}

		&-info {
			float: left;
			width: calc(100% - (#{$addon-thumb--size} + 10px));

			h3 {
				font: {
					size: _font-scale( normal );
					weight: 700;
				}
				margin: 0;
			}

			p {
				font-size: _font-scale( medium );
			}
		}
	}
}

.user-edit {
	form {
		@include _breakpoint--md {
			max-width: map-get( $middle-content-widths, 'wide' );
			margin: 0 auto;
		}
	}

	display: inline-block;
	width: 100%;
	padding: 0 _space();
	margin: 0;

	&__form {
		background-color: _gray();
	}

	.form-item {
		display: block;
		clear: both;
		width: 100%;
	}
	label {
		width: 100%;
	}

	input,
	select {
		float: left;
		width: 100%;
		height: 100%;
		background-color: _color( white );
		font-size: _font-scale( medium );
		padding: 8px;
		@include _radius;
		@include _border;

		@include _breakpoint--md {
			float: left;
			width: 50%;
			margin-bottom: _space();
		}

		&[type="submit"] {
			@extend .btn;
		}

		&[type="checkbox"] {
			height: auto;
		}
	}

	select {
		height: 38px;
	}

	fieldset.form-wrapper {
		width: 100%;

		legend {
			display: none;
		}
	}

	#edit-actions {
		display: inline-block;
		width: 100%;
		padding-top: _space();
		text-align: center;

		input[type="submit"] {
			@extend .btn--big;
			width: 200px;
			float: none;
		}
	}

	textarea + .description {
		padding-left: 0;
	}

	.description {
		float: left;
		width: 100%;
		margin-bottom: _space();
		font-size: _font-scale( medium );

		@include _breakpoint--md {
			width: 50%;
			padding: 0 15px 0 30px;
		}
	}

	.form-block {
		margin-bottom: 1rem;

		.big-label {
			display: inline-block;
			width: 100%;
			font: {
				size: _font-scale( big );
				weight: 200;
			}
		}

		&__hint {
			font-size: _font-scale( medium );
			margin: 0 15px;
		}
	}

	.photo-upload {
		&__placeholder-wrapper {
			float: left;

			.placeholder {
				display: inline-block;
				width: 150px;
				height: 150px;
				background: {
					image: url(../images/thumbs/ico-plugin.png);
					repeat: no-repeat;
					size: 100%;
				}
			}
		}

		&__description-wrapper {
			float: left;
			width: calc(100% - 150px);
		}
	}

	.field-widget-options-buttons {
		display: inline-block;
		width: 100%;
		margin-top: _double-space();
	}

	.form-checkboxes {
		float: left;
		width: 100%;

		@include _breakpoint--md {
			width: 50%;
		}

		label,
		input[type="checkbox"] {
			float: left;
			width: auto;
		}

		label {
			font: {
				size: _font-scale( medium );
				weight: 400;
			}
		}

		input[type="checkbox"] {
			vertical-align: middle;
			margin: {
				left: 20px;
				top: 5px;
				bottom: 0;
				right: 5px;
			}

			&:first-of-type {
				margin-left: 0;
			}

			&:last-of-type {
				margin-left: 0;
				clear: left;
			}
		}
	}
}