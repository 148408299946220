$builder-widget--width: 880px;
$plugin-selector--width: 440px;

.section-container.builder {
	@include _breakpoint--md {
		padding: {
			left: 1px;
			right: 1px;
		}
	}
}

#editor-builder {
	position: relative;
	font-size: 16px;
	font-size: 1.6rem;

	.btn {
		background-color: _color( white );

		&:active,
		&:hover,
		&:focus {
			color: _color( white );
			background-color: _color( primary );
		}
	}

	input[type="file"] {
		display: none;
	}
	input[type="radio"] {
		left: 2px;
	}

	h3 + p {
		margin-bottom: 25px;
	}

	.all-elements-option {
		margin-left: 0 !important;
		background: #f7f7f7;
		border-top: 1px solid #ebebeb;
		bottom: 0;
		left: 0;
		position: absolute;
		height: 90px;
		display: inline-block;
		width: 100%;
	}

	.presets {
		margin-bottom: _double-space();
		border: none;

		#presets-opts {
			list-style: none;
			padding: 0;
			margin: _space() auto 0;
			text-align: center;

			@include _breakpoint--md {
				max-width: $builder-widget--width;
			}

			li {
				display: inline-block;
				width: 230px;
				margin-right: 20px;
			}

			img {
				margin-bottom: 10px;
			}

			label {
				font-size: 1.5rem;
				position: relative;
				max-width: 100%;
				margin: 0 auto;
				text-align: center;

				.presets-opts__thumb {
					@include _border;
					margin-bottom: 15px;
					width: 228px;
					height: 109px;
					background: {
						repeat: no-repeat;
					}

					&--basic {
						background-image: _image( 'package-basic.png', 'backgrounds' );
					}
					&--standard {
						background-image: _image( 'package-standard.png', 'backgrounds' );
					}
					&--full {
						background-image: _image( 'package-full.png', 'backgrounds' );
					}

					&--selected {
						border: 2px solid _color( primary );
					}
				}
			}
		}

		.compare-presets {
			float: right;
			margin-right: 15px;
		}

		#presets_table {
			display: none;
		}
	}

	.preset-description {
		display: none;
		margin: _space() auto;
		text-align: center;

		@include _breakpoint--md {
			max-width: $builder-widget--width;
		}

		&--active {
			display: block;
		}
	}

	#draggable-plugins,
	.languages {
		display: inline-block;
		width: 100%;
		padding: 0;
		overflow: hidden;
		position: relative;

		@include _breakpoint--md {
			display: block;
			width: ($plugin-selector--width * 2) + 65px;
			margin: 0 auto;
		}

		.languages__actions,
		.plugins-actions {
			display: inline-block;
			width: 100%;
			position: relative;
			height: 65px;

			@include _breakpoint--md {
				display: block;
				float: left;
				width: 65px;
				height: 460px;
			}
		}

		.additional-plugins,
		.plugins-presets,
		.selection {
			display: inline-block;
			width: 100%;
			clear: none;
			@include _border( left );
			border: {
				top: none;
				right: none;
				bottom: none;
			}
			padding: 0;
			margin: 0;
			background: transparent;
			height: 475px;
			position: relative;

			@include _breakpoint--md {
				display: block;
				float: left;
				width: $plugin-selector--width;
				height: 471px;
			}

			h4,
			label.big {
				font-size: 1.7rem;
				margin: 0 0 0 -1px;
				width: calc(100% + 2px);
				background: _color( white ) none;
				padding: 0 0 5px 5px;
				border-bottom: 1px solid #ebebeb;
				color: #4b4b4b;
				font-weight: 400;
			}

			#languages-to-chose,
			#chosen-languages {
				display: inline-block;
				width: 100%;
				border: none;
				@include _border( top );
				@include _border( right );
				height: 350px;
				padding: 0;

				@include _breakpoint--md {
					height: 350px;
				}
			}

			.box {
				display: inline-block;
				width: 100%;
				list-style: none;
				overflow: auto;
				@include _border( right );
				height: 350px;
				padding: 0;

				@include _breakpoint--md {
					height: 350px;
				}

				> li {
					border-bottom: 1px solid _gray-hex( silver );
				}

				&.active {
					font-weight: inherit;
				}
			}

			option {
				font-size: 1.5rem;
				color: _text-color( secondary );
				line-height: 1.6;
				padding: 3px 10px;
			}

			input[type="checkbox"] {
				cursor: pointer;
			}
			.all-elements-option  {
				@include _border( right );
			}

			.ui-resizable-s {
				display: none;
				height: 16px;
				border-radius: 0;
				width: calc(100% + 2px);
				bottom: 0;
				left: 0;
				margin-left: -1px;
				margin-bottom: -1px;
				background: {
					color: _color( primary, .8 );
					image: url(../images/resize-vertical.png);
					position: center;
					repeat: no-repeat;
				}
				@include _border;
				z-index: 999 !important;

				@include _breakpoint--lg {
					display: inline-block;
				}
			}

			.ui-draggable-dragging {
				border: 1px solid #bbb !important;
				z-index: 100;
				padding: 8px 10px 8px 10px;
				width: auto;
				display: inline-block;
				max-width: 500px;
				min-width: 300px;
				background-color: _color( white );
			}

			.ui-droppable-active {
				font-weight: inherit;
			}
		}

		.plugins-presets ol,
		.additional-plugins ol {
			margin: 0;

			li {
				position: relative;
				cursor: pointer;
				line-height: 2em;
				padding: {
					left: 10px;
					top: 2px;
					bottom: 0;
				}

				.machine_name {
					display: none;
				}

				> strong {
					margin-left: 15px;
					font-weight: 400;

					a {
						color: _text-color( secondary );
						font-size: 1.4rem;
						line-height: 1.5;
						padding-right: 15px;
					}
				}

				.plugin-ico {
					margin-top: 3px;
					display: inline-block;

					&--preset {
						&:before {
							content: '';
							display: inline-block;
							@extend .svg-ico__addon--preset;
						}
					}

					&--selected {
						&:before {
							content: '';
							display: inline-block;
							@extend .svg-ico__addon--selected;
						}
					}

					&--required {
						&:before {
							content: '';
							display: inline-block;
							@extend .svg-ico__addon--required;
						}
					}

					&--optional {
						&:before {
							content: '';
							display: inline-block;
							@extend .svg-ico__addon--selected;
						}
					}
				}

				.required-info,
				.plugin-info {
					p {
						font-size: 1.4rem;
						line-height: 2.2em;
						margin: {
							top: 0;
							bottom: 10px;
						}
					}

					a {
						padding-right: 14px;

						&:hover {
							background: url("https://c.cksource.com/a/5/img/sprites.png") no-repeat 100% -3880px;
						}
					}

					.last-updated {
						font-size: 0.85em;
						margin-left: 10px;
						font-style: italic;
					}
					.visit {
						font-size: 0.85em;
						margin-right: 20px;
						float: right;
					}
				}

				.plugin-info {
					display: none;
					margin: calc(2.5em /4);
					margin-bottom: 2px;
				}
				.required-info {
					margin-left: 20px;
					font-size: 85%;
				}

				&.current {
					background: #EBEBEB;
				}
			}
		}

		.additional-plugins {
			.all-elements-option {
				padding: {
					left: 15px;
					right: 15px;
					top: 5px
				}

				.search {
					margin-top: 5px;

					input {
						background-color: _gray-hex( silver );
						padding-left: calc(_space() / 2);
					}
				}

				#filter-plugins {
					width: 100%;
					margin: 6px auto 0;
					text-align: right;

					label {
						font-weight: normal;
						font-size: 1.5rem;
						vertical-align: middle;

						> strong {
							font-weight: 700;
							margin-left: 0;
						}
					}
				}
			}
		}

		.plugins-presets {
			.all-elements-option {
				padding-top: 20px;
			}
		}

		.remove-item,
		.add-item {
			width: 56px;
			height: 42px;
			text-align: center;
			border-radius: 4px;
			left: 50%;
			top: 50%;
			margin-top: -21px;
			border: 1px solid $border-color;
			position: absolute;
			background: _color( white );

			@include _breakpoint--md {
				width: 42px;
				height: 56px;
			}

			span {
				font-size: 0;
				height: 14px;
				margin: auto;
				text-indent: -9999px;
				width: 9px;
				vertical-align: middle;

				&.mo-remove-ico,
				&.mo-add-ico {
					transform: rotate(90deg);
				}

				&.mo-remove-ico,
				&.mo-add-ico {
					display: block;

					@include _breakpoint--md {
						display: none;
					}
				}

				&.add-ico,
				&.remove-ico {
					display: none;

					@include _breakpoint--md {
						display: block;
					}
				}
			}
		}

		.remove-item {
			margin-left: 10px;

			@include _breakpoint--md {
				margin-left: -21px;
				margin-top: 10px;
			}

			span {
				background: url("https://c.cksource.com/a/5/img/sprites.png") no-repeat -180px -1624px;
			}
		}

		.add-item {
			margin-left: -60px;

			@include _breakpoint--md {
				margin-left: -21px;
				margin-top: -60px;
			}

			span {
				background: url("https://c.cksource.com/a/5/img/sprites.png") no-repeat -167px -1624px;
			}
		}

		.all-elements-option {
			padding: 15px;

			a {
				@extend .btn;
				@extend .btn--small;
				@extend .btn--frame;
			}

			input[type="checkbox"] {
				margin-left: 15px;
				opacity: 1;
			}

			label {
				display: inline;
				color: _text-color( primary );
			}

			.show-plugins-from-preset {
				text-align: right;
				display: inline-block;
				float: right;
				margin-top: 7px;

				label {
					font-weight: normal;
					font-size: 1.5rem;
					vertical-align: middle;
				}
			}
		}
	}

	.legend {
		display: inline-block;
		clear: both;
		width: 100%;
		text-align: center;
		margin: _space() 0;

		h5 {
			font: {
				weight: 300;
				size: 1.9rem;
			}
		}

		&__wrapper {
			display: inline-block;

			@include _breakpoint--md {
				float: none;
			}
		}

		&__description {
			display: inline-block;
			margin-top: 13px;

			@include _breakpoint--md {
				margin-top: 3px
			}
		}

		&__ico {
			display: inline-block;
			clear: left;
			margin: {
				right: 5px;
				top: 10px;
			}

			&:before {
				content: '';
				display: inline-block;
			}

			&--preset {
				&:before {
					@extend .svg-ico__addon--preset;
				}
			}

			&--required {
				&:before {
					@extend .svg-ico__addon--required;
				}
			}

			&--optional {
				&:before {
					@extend .svg-ico__addon--selected;
				}
			}

			@include _breakpoint--md {
				clear: none;
				margin-top: 0;

				&--optional,
				&--required {
					margin-left: _space();
				}
			}
		}
	}

	#a11ychecker-gpl {
		display: none;
		padding: _space();

		p {
			font-weight: normal;
			margin: 0;
		}
	}

	.download-types {
		legend {
			display: none;
		}

		@include _border;
		margin-top: 40px;
		background-color: _gray();
		padding: _space();
		text-align: center;

		@include _breakpoint--md {
			width: 100%
		}

		input[type="checkbox"],
		input[type="radio"] {
			margin-top: 5px;
		}

		h3 {
			position: relative;
			text-align: center;
			margin: 0 auto;
			padding-top: 15px;
			display: none;

			&:before {
				content: " ";
				background: url("https://c.cksource.com/a/1/img/ajax-loader.gif") 0 0 no-repeat;
				position: absolute;
				left: -26px;
				top: 26px;
				display: block;
				width: 20px;
				height: 20px;
			}
		}
		.terms-of-use {
			display: inline-block;
			width: 100%;
			padding: 5px;
			text-align: left;

			&.error {
				background-image: none;
				border: 1px solid _color( danger );
			}
			input[type="checkbox"] {
				float: left;
				margin-right: 10px;
				margin-top: 7px;
			}

			label {
				float: left;
				margin-bottom: 0;
				width: calc(100% - 30px);
				font: {
					weight: 400;
				}
			}
		}

		.btn {
			display: block;
			top: 0;
			min-width: map-get( $cta-widths, 'long' );
			margin: 15px auto 0;
		}

		.download-options__wrapper {
			display: inline-block;
			width: 100%;
			text-align: center;
			padding: 5px 0 0;

			ol {
				display: inline-block;
				position: relative;
				margin: 0;
				padding: 0;
				top: 0;
				list-style: none;

				li {
					white-space: nowrap;
					float: left;
					margin-right: _space();

					&:last-child {
						margin-right: 0;
					}
					input[type="radio"] {
						float: left;
						margin-right: 5px;
					}

					label {
						float: left;
						font-size: 1.5rem;
					}
				}
			}
		}
	}

	.skins-wrapper {
		display: inline-block;
		width: 100%;

		.skinsBox {
			display: inline-block;
			width: 100%;
			list-style: none;
			padding: 0 30px;

			li {
				display: inline-block;
				padding: 0;

				&.selected {
					.skin__thumb {
						border: 2px solid _color( primary );
					}
				}
				.skin__thumb {
					display: block;
					width: 170px;
					height: 120px;
					margin: 0 auto;
					@include _border;
				}

				&:focus,
				&:active {
					outline: none;
				}

				label {
					margin-top: 5px;
					display: inline-block;
					width: 100%;
					font: {
						weight: 400;
						size: 1.5rem;
					}
					text-align: center;
				}

				input[type="radio"] {
					left: auto;
					display: block;
					margin: 0 auto;
				}
			}

			.slick-next,
			.slick-prev {
				top: 50px;
				transform: none;

				&:before {
					color: darken( _gray-hex( shadow ), 15%);
					font-size: 26px;
				}
			}

			.slick-prev {
				left: 15px;
			}

			.slick-next {
				right: 15px;
			}
		}
	}

	.builder-loader {
		position: absolute;
		display: none;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: _color( white )--A700;
		@include _zindex( air );

		.spinner {
			position: absolute;
			top: 5%;
			left: 50%;
			width: 50px;
			height: 50px;
			margin-left: -25px;
			border: 5px solid rgba(_color( primary ), .3);
			border-radius: 50%;
			border-top-color: _color( primary );
			transform: none;
		}

		&--loading {
			display: block;

			.spinner {
				animation: spin 1s ease-in-out infinite;
			}
		}
	}

	@keyframes spin {
		to {
			transform: rotate(360deg);
		}
	}
}
