.stickme {
	background-color: transparent;
	@include _transition( all );

	&--sticked,
	&--hold {
		position: fixed;
		width: 100%;
		top: $top-menu__height;
		background-color: _color( white, .9 );
		opacity: .9;
		transform: translateY( 0 );
		@include _border( bottom );
		@include _zindex( air );

		.pricing-licenses {
			margin-bottom: 0;
		}
	}

	&--hold {
		transform: translateY( -10% );
		opacity: .3;
		@include _zindex( under );
	}
}
