$ck-demo__min-height: 500px;
$tab--height: 50px;

/* Let's define individual tab theme color. */
.site-content--ckeditor-5 .tab.tab--active::before {
	background: lighten( _color( brand-cke5 ), 35% );
}

.site-content--ckeditor-4 .tab.tab--active::before {
	background: lighten( _color( brand-cke4 ), 35% );
}

.site-content--collaborative-editing .tab.tab--active::before {
	background: lighten( _color( purple ), 15% );
}

.tabs {
	&__wrapper {
		display: none;
		@include _middle-content;

		@include _breakpoint--sm {
			display: block;
			text-align: inherit;
		}

		@include _breakpoint--md {
			width: $mq__md - 250px;

			&--wide {
				width: 100%;
			}

			&--wide-mid {
				width: $mq__md - 120px;
			}
		}

		.state-change-target {
			display: none;
		}
	}

	&__header {
		position: relative;
		display: block;
		width: 900px;
		max-width: 100%;
		margin: 0 auto;

		/* Move tabs to overflow header. */
		top: -$tab--height;
	}

	&__list {
		display: flex;
		width: 100%;
		align-items: stretch;
		justify-content: center;
		margin: 0;
		padding: 0;

		.tab {
			display: inline-block;
			position: relative;
			flex: 1;
			background: _color( white );
			height: $tab--height;
			/* We need to reset default button border-style. */
			border: 0;
			border-bottom: 1px solid _gray-hex( dark );
			border-left: 1px solid _gray-hex( dark );
			text-align: center;
			font-size: _font-scale( medium );
			color: _text-color( secondary );
			padding: 12px 0;
			cursor: pointer;
			user-select: none;
			@include _transition( background );

			&:first-of-type {
				border-left: 0;
			}

			&--active {
				border-bottom: 0;
				color: _text-color( primary );

				&::before {
					content: '';
					width: 100%;
					position: absolute;
					height: 3px;
					top: -3px;
					left: 0;
					background: lighten( _color( primary ), 35% );
				}
			}

			&:focus,
			&:active {
				outline: none;
				background: _gray-hex( light );
			}

			&:hover:not(.tab--active) {
				background: _gray-hex( light );
			}
		}
	}

	&__content-section {
		position: relative;
		overflow: hidden;
		min-height: 300px;
	}

	&__content {
		display: none;
		width: 100%;
		margin: 0 auto;
		text-align: center;
		min-height: $ck-demo__min-height;
		padding: 0 15px 0;

		&--demo {
			text-align: left;
		}

		&.tabs__content--active {
			display: block;
		}
	}

	.demo-actions {
		text-align: center;

		.btn {
			display: block;
			margin: {
				left: auto;
				right: auto;
				bottom: _space();
			}
			width: 320px;
			max-width: 90%;

			@include _breakpoint--lg {
				display: inline-block;
				margin-right: 17px;
				width: auto;

				&:last-of-type {
					margin-right: 0;
				}
			}
		}
	}

	&__sdk-link {
		text-align: right;
		font-size: 0.7em;
		margin-top: 0.6em;
		clear: both;
	}
}
