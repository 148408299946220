.social {
	margin: {
		top: _double-space() * 2;
		bottom: _double-space() * 2 !important;
	}

	&__header {
		text-align: center;
		margin-bottom: _space();
	}

	&__thumbs {
		margin: 0 auto;
		text-align: center;

		@include _breakpoint--sm {
			max-width: 550px;
		}

		@include _breakpoint--sm-plus {
			max-width: 820px;
		}
	}

	&__thumb {
		display: inline-block;
		color: _text-color( primary );
		width: 90%;

		@include _breakpoint--sm {
			min-width: 120px;
			width: auto;
		}

		@include _breakpoint--sm-plus {
			width: 100%;
		}
	}

	&__counter {
		padding-bottom: 15px;
		text-align: center;

		@include _breakpoint--sm-plus {
			max-width: 180px;
			padding-bottom: 0;
			margin-right: 15px;
		}

	}

	&__brand-icon-container {
		float: left;
	}

	&__brand-icon {
		vertical-align: bottom;

		img {
			width: 45px;

			@include _breakpoint--sm-plus {
				width: auto;
				height: auto;
			}
		}

		.ico {
			@include _icon;
			width: 45px;
			height: 45px;
			background: {
				repeat: no-repeat;
				size: cover;
			}

			&--github {
				background-image: _image( 'github.png', 'icons' );
			}

			&--twitter {
				background-image: _image( 'twitter.png', 'icons' );
			}

			&--facebook {
				background-image: _image( 'facebook.png', 'icons' );
			}

			&--gplus {
				background-image: _image( 'gplus.png', 'icons' );
			}

			@include _breakpoint--sm-plus {
				width: 68px;
				height: 68px;
			}
		}
	}

	&__number-container {
		float: left;
		padding-left: 15px;
		height: 45px;
		width: calc(100% - 45px);
		text-align: center;
		@include _border( bottom );

		@include _breakpoint--sm {
			min-width: calc(100% - 45px);
		}

		@include _breakpoint--sm-plus {
			height: 68px;
			min-width: 0;
			width: calc(100% - 68px);
			text-align: none;
		}
	}

	&__number-wrapper {
		display: inline-block;

		.social__number {
			float: left;
			font-size: _font-scale( normal );
			margin-top: 3px;
		}

		.social__number-type {
			float: left;
			clear: left;
			line-height: .8;
			font: {
				size: _font-scale( small );
				weight: 200;
			}
		}

		@include _breakpoint--sm-plus {
			padding-top: 1rem;

			.social__number {
				font-size: 2.7rem;
			}

			.social__number-type {
				font-size: _fon-scale( medium );
				line-height: inherit;
			}
		}
	}
}

.right-sidebar.social {
	background-color: transparent;
	padding: {
		left: 0;
		right: 0;
	}
	@include _breakpoint--sm-plus {
		.social__counter {
			max-width: 100%;
			margin: 0;
		}

		.social__number-container {
			text-align: left;
		}
	}
}
