#download_thanks {
	display: none;
	text-align: center;

	h2 {
		margin-bottom: _space();
	}

	label {
		display: none;
	}

	#mc-embedded-subscribe {
		margin: 0 auto;
		display: block;
	}
}
