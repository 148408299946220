.form-response {
	display: none;
    float: left;
	text-align: center;
	opacity: 0;
	@include _radius();
	background-color: _color( white, 0.8 );

	@include _breakpoint--sm {
		text-align: right;
	}

	&:before {
		content: '';
		width: 24px;
		height: 24px;
		display: inline-block;
		margin: {
			right: 1rem;
			bottom: .5rem;
		}
		vertical-align: middle;
		background: {
			size: cover;
			repeat: no-repeat;
		}
	}

	&--error {
		color: _color( danger );

		&:before {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 300 300'%3E%3Cpath fill='%23D9534F' d='M150 0C67.2 0 0 67.2 0 150s67.2 150 150 150 150-67.2 150-150S232.8 0 150 0zm56.6 207.2c-6 6-15.7 6-21.7 0L150.7 173 115 208.7c-6 6-15.6 6-21.6 0-6-6-6-15.7 0-21.7l35.7-35.6L96 118c-6-6-6-15.6 0-21.5 6-6 15.6-6 21.6 0l33.2 33.2L182.4 98c6-6 15.7-6 21.7 0s6 15.7 0 21.7l-31.5 31.7 34 34c6 6 6 15.8 0 21.8z'/%3E%3C/svg%3E%0A");
		}
	}

	&--success {
		text-align: center;
		color: _color( success );

		&:before {
			background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 300 300'%3E%3Cpath fill='%235CB85C' d='M150 0C67.2 0 0 67.2 0 150s67.2 150 150 150 150-67.2 150-150S232.8 0 150 0zm73.4 128.8l-76.7 69.5c-2 1.7-4 2.8-6.3 3.4-2 1-4.2 1.4-6.3 1.4-3.7 0-7.5-1.3-10.5-4l-47.2-45c-6-5.8-6.4-15.5-.5-21.7 5.8-6 15.5-6.4 21.7-.5l37.5 35.6 67.8-61.3c6.3-5.6 16-5 21.7 1.2 5.7 6.2 5.2 16-1 21.6z'/%3E%3C/svg%3E%0A");
		}
	}

	&--idle {
		width: 100%;
		margin-top: _space();
	}

	&--active {
		display: block;
		opacity: 1;
		@include fadeIn(
			$duration: 300ms,
			$function: ease-in-out,
			$fill: both
		);

		&.removing {
			opacity: 0;
			animation: none;
			@include _transition( opacity, 200ms );
		}
	}
}
