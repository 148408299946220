section,
.section {
	@include _section;
	overflow: hidden;
	margin-top: _double-space();

	&:last-of-type {
		margin-bottom: _double-space();
	}

	@include _breakpoint--sm {
		margin-top: _double-space() * 2;

		&:first-of-type {
			margin-top: _double-space();
		}

		&:last-of-type {
			margin-bottom: _double-space();
		}
	}

	@include _breakpoint--md {
		padding: 0;
	}
}

.section-container {
	width: 100%;
	padding: {
		left: _space();
		right: _space();
	}

	@include _breakpoint--sm {
		@include _bs-container--fluid();
	}

	@include _breakpoint--md {
		@include _bs-container();
	}
}

header,
main,
footer {
	display: flex;
	width: 100%;
	flex-direction: column;
}

* {
	box-sizing: border-box;
}

a,
button {
	&:focus,
	&:active {
		outline-width: 2px;
		outline-style: solid;
		outline-color: Highlight;

		@media ( -webkit-min-device-pixel-ratio: 0 ) {
			outline-color: -webkit-focus-ring-color;
			outline-style: auto;
		}

		@media ( -moz-touch-enabled: 1 ), ( pointer: coarse ) {
			outline: none;
		}
	}
}
