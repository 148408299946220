.nightly {
	text-align: center;

	&-section {
		margin-bottom: _space();

		@include _breakpoint--mo {
			font-size: _font-scale( medium );
		}

		@include _breakpoint--sm {
			margin-bottom: _double-space();
		}
	}

	.introduce {
		@include _breakpoint--mo {
			padding: 0;
		}
	}

	h2 {
		@include _breakpoint--mo {
			font-size: 19px;
			line-height: 21px;
		}
	}

	h3 {
		margin: {
			top: 0;
			bottom: _space();
		}
		font: {
			size: _font-scale( normal );
			weight: 600;
		}

		@include _breakpoint--sm {
			margin-bottom: _double-space();
			font-size: _font-scale( big );
		}
	}

	h4 {
		margin-bottom: _space()/2;
		font-size: _font-scale( normal );

		@include _breakpoint--mo {
			font-size: _font-scale( medium );
		}
	}

	ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	&__list {
		&--separated {
			li {
				display: inline-block;
				margin-right: _space() / 2;

				&:after {
					content: '|';
					display: inline-block;
					margin-left: _space() / 2;
					color: _text-color( hint );
				}

				&:last-of-type {
					&:after {
						display: none;
					}
				}
			}
		}

		&--collapsed {
			span {
				display: inline-block;
				width: 115px;
				text-align: right;
				margin-right: 3px;

				@include _breakpoint--sm {
					width: 170px;
				}
			}

			a {
				&:after {
					content: '|';
					display: inline-block;
					margin-left: _space() / 4;
					color: _text-color( hint );
				}

				&:last-of-type {
					&:after {
						display: none;
					}
				}
			}

			@include _breakpoint--md {
				li {
					display: inline-block;
					margin-right: _space()/2;

					&:last-of-type {
						margin-right: 0;
					}
				}

				span {
					display: inline;
					width: auto;
					text-align: left;
				}
			}
		}
	}
}
