#presets_table .comparision__table th,
#presets_table .comparision__table td {
	min-width: 70px;
	padding: 8px 12px;
}

.version-comparision {
	margin-top: 10px;

	@include _breakpoint--sm {
		margin-top: 0;
	}

	@include _breakpoint--md {
		width: 960px;
		max-width: 100%;
	}

	&--packages {
		margin: 0 auto;
		@include _breakpoint--sm {
			margin-bottom: 70px;
		}

		@include _breakpoint--md {
			margin-bottom: 100px;
		}
	}
}

.version-comparision__table-wrapper {
	// @extend .container;
	padding: 0;
	overflow: auto;

	.table-title {
		h2 {
			display: inline-block;
			margin: {
				top: _space();
				bottom: 0;
			}
			padding-bottom: 20px;
		}

		h3 {
			margin: {
				top: 0;
				bottom: _space();
			}
		}
	}
}

.comparision__table {
	width: 100%;
	margin-top: 0;
	font-size: _font-scale( small );

	@include _breakpoint--md {
		margin: 0 auto;
		text-align: inherit;
	}

	td {
		width: 138px;
		min-width: 138px;
		padding: 1.5rem;
		text-align: center;
		line-height: 1.4;
		@include _border( right );
		border-color: _gray();

		@include _breakpoint--md {
			width: 125px;
			min-width: 125px;
		}

		p {
			margin: .5rem;
		}

		&:first-of-type {
			width: 300px;
			text-align: left;
		}

		&:last-of-type {
			border-right: none;
		}

		&.checked {
			&:after {
				content: '';
				display: block;
				width: 17px;
				height: 12px;
				margin: 0 auto;
				background: {
					repeat: no-repeat;
					size: contain;
					image: _image( 'check.svg', 'icons' );
				}
			}
		}

		&.optional {
			&:after {
				content: '';
				display: block;
				width: 17px;
				height: 12px;
				margin: 0 auto;
				background: {
					repeat: no-repeat;
					size: contain;
					image: _image( 'circle.svg', 'icons' );
				}
			}
		}

		.btn {
			display: block;
		}

		.contact-link {
			font-weight: 600;
		}

		&.to--left {
			text-align: left;

			p {
				margin-left: 0;
			}
		}
	}

	th {
		font-size: _font-scale( normal );
		font-weight: 400;
		text-align: center;
		padding: 1.5rem;
		@include _border( right );
		border-color: _gray();
		white-space: nowrap;

		&:first-of-type {
			width: 300px;
			text-align: left;
		}

		&:last-of-type {
			border-right: none;
		}
	}

	tr {
		&:nth-child( even ):not( .pricing-features__row--actions ) {
			background-color: _gray();
		}

		&.subheader {
			font: {
				size: _font-scale( normal );
				weight: 600;
			}
		}
	}
}

.table-legend {
	width: 100%;
	font-size: _font-scale( medium );

	@include _breakpoint--md {
		margin: _space() auto 0;
		text-align: inherit;
	}

	h3 {
		font-size: _font-scale( big );
		font-weight: 600;
	}

	&__list {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	&__item {
		padding-left: 10px;

		&--checked {
			&:before {
				content: '';
				display: inline-block;
				width: 17px;
				height: 12px;
				margin-right: 15px;
				background: {
					repeat: no-repeat;
					size: contain;
					image: _image( 'check.svg', 'icons' );
				}
			}
		}

		&--optional {
			&:before {
				content: '';
				display: inline-block;
				width: 17px;
				height: 12px;
				margin-right: 15px;
				background: {
					repeat: no-repeat;
					size: contain;
					image: _image( 'circle.svg', 'icons' );
				}
			}
		}
	}
}

.redirect-modal {
	text-align: center;

	&__info {
		font: {
			size: 2rem;
			weight: 500;
		}
		margin-bottom: 5px;
	}

	&__link {
		font: {
			size: 2rem;
			weight: 500;
		}
		margin-bottom: 20px;
	}

	&__counter {
		font-size: 5rem;
	}

	.counter-units {
		margin-left: 5px;
		font: {
			size: 3rem;
			weight: 500;
		}
		color: _text-color( secondary );
	}
}

	// .big-header {
	// 	&__item {
	// 		@include _border( top );
	// 		background: none;
	// 		vertical-align: top;
	// 		max-width: 120px;
	// 		min-width: 120px;

	// 		@include _breakpoint--sm {
	// 			max-width: 142px;
	// 			min-width: 142px;
	// 		}

	// 		&:first-of-type {
	// 			border: none;
	// 			max-width: auto;
	// 			width: 100%;
	// 		}

	// 		&--invisible {
	// 			border: none;
	// 		}

	// 		&:not(.big-header__item--invisible):nth-of-type(2) {
	// 			@include _border( left );
	// 		}

	// 		&.big-header__item--invisible:nth-of-type(2) {
	// 			@include _border( right );
	// 		}

	// 		&:not(.big-header__item--invisible):last-of-type {
	// 			@include _border( right );
	// 		}

	// 		&-title,
	// 		&-price,
	// 		&-period {
	// 			float: left;
	// 			width: 100%;
	// 			clear: both;
	// 			margin-bottom: 3px;

	// 			@include _breakpoint--sm {
	// 				margin-bottom: 10px;
	// 			}
	// 		}

	// 		&-title {
	// 			font: {
	// 				size: 1.6rem;
	// 				weight: 500;
	// 			}
	// 		}

	// 		&-price {
	// 			font-size: 2.4rem;
	// 			margin-bottom: 15px;

	// 			&--call {
	// 				margin: {
	// 					top: 5px;
	// 					bottom: 8px;
	// 				}
	// 			}
	// 		}

	// 		&-period {
	// 			font-size: 1.1rem;
	// 			line-height: .6;
	// 			height: 6px;
	// 			margin-bottom: 10px;

	// 			@include _breakpoint--sm {
	// 				margin-bottom: 20px;
	// 			}
	// 		}

	// 		.btn {
	// 			display: block;
	// 			margin: 0 auto;
	// 			clear: both;
	// 		}

	// 		&.bottom {
	// 			vertical-align: bottom;
	// 			padding-bottom: 3px;
	// 		}

	// 		&.center {
	// 			text-align: center;
	// 		}

	// 		.infographic-btn {
	// 			margin-bottom: 8px;
	// 		}
	// 	}
	// }

	// tr {
	// 	&.section-header {
	// 		td {
	// 			background-color: _gray();
	// 			font: {
	// 				size: 1.5rem;
	// 				weight: 400;
	// 			}

	// 			&.invisible {
	// 				background: none;
	// 			}

	// 			&:first-of-type {
	// 				font: {
	// 					size: 1.6rem;
	// 					weight: 500;
	// 				}
	// 				padding: 6px 11px;
	// 			}
	// 		}
	// 	}

	// 	&:last-child,
	// 	&.closed-row {
	// 		td {
	// 			@include _border( bottom );
	// 		}
	// 	}

	// 	&.section-separator {
	// 		font-weight: bold;
	// 	}

	// 	&.bolded {
	// 		font-weight: bold;
	// 	}

	// 	&.section-buy {
	// 		td {
	// 			border: none;
	// 			padding: 5px;
	// 			@include _border( top );

	// 			&.invisible {
	// 				border-top: none;
	// 			}

	// 			@include _breakpoint--md {
	// 				padding: 11px;
	// 			}
	// 		}
	// 	}
	// }
