@keyframes pulseDown {
	0% {
		transform: translate3d( 0, 0, 0 );
	}

	40% {
		transform: translate3d( 0, 0, 0 );
	}

	50% {
		transform: translate3d( 0, 1rem, 0 );
	}

	60% {
		transform: translate3d( 0, 0, 0 );
	}

	100% {
		transform: translate3d( 0, 0, 0 );
	}
}

@keyframes _spinner-rotate {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes _fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes _fade-out {
	0% {
		opacity: 1;
	}
	100% {
		display: none;
		opacity: 0;
	}
}

@keyframes _spinner-dash {
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -35px;
	}
	100% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -124px;
	}
}

@keyframes _spinner-color {
	100%,
	0% {
		stroke: red;
	}
	40% {
		stroke: _color( primary );
	}
	66% {
		stroke: green;
	}
	80%,
	90% {
		stroke: orange;
	}
}

.boost {
	&--transform {
		will-change: transform;
	}

	&--opacity {
		will-change: opacity;
	}

	&--background {
		will-change: background-color;
	}
}

@keyframes slideDown {
	0% {
		opacity: 0;
		transform: translate3d( 0, -100px, 0);
	}
	100% {
		opacity: 1;
		transform: translate3d( 0, 0, 0);
	}
}
