$side-menu__width: 350px;

.side-menu {
	position: fixed;
	display: none;
	top: 64px;
	left: 0;
	height: calc( 100vh - 64px );
	width: $side-menu__width;
	padding: 0 _space();
	// dark version
	// background-color: _color( bg-dark, .9 );
	background-color: _color( white );
	@include _zindex( cover );

	&__navigation {
		@include _list-reset;
		margin-top: _space();

		&-item {
			a {
				color: _text-color( primary );
			}
		}
	}

	&__close {
		position: absolute;
		right: _space();
		top: _space();
		// @extend .svg-ico__close-white;
	}
}
