.site-content--ckeditor-4 {
	.product-section {
		&--full-of-features {
			margin-top: _double-space();

			@include _breakpoint--sm {
				margin-top: _double-space() * 1.5;
			}

			@include _breakpoint--md {
				margin-top: _double-space() * 3;
			}

			.product-section {
				&__graphic {
					@include _breakpoint--sm-only {
						img {
							width: 600px;
						}
					}

					@include _breakpoint--sm {
						padding-left: _double-space();
					}
				}
			}

			.columns {
				&__outer {
					@include _breakpoint--md {
						width: 705px;
					}
				}

				&__actions {
					.btn {
						margin: {
							right: 0;
							bottom: _half-space();
						}
					}

					@include _breakpoint--md {
						.btn {
							margin: {
								right: _space();
								bottom: 0;
							}
						}
					}
				}
			}
		}

		&--more-features {
			@include _breakpoint--sm {
				margin-top: _double-space();
			}

			@include _breakpoint--sm-plus {
				margin-top: _double-space() * 2;
			}

			.columns {
				&__actions {
					.btn {
						margin: {
							right: 0;
							bottom: _half-space();
						}
					}

					@include _breakpoint--md {
						.btn {
							margin: {
								right: _space();
								bottom: 0;
							}
						}
					}
				}
			}
		}

		&--easy-to-use {
			@include _breakpoint--sm {
				.columns__item--learn {
					background-image: _image( 'blue-head-book.svg', 'icon' );
				}

				.columns__item--adjust {
					background-image: _image( 'blue-head-box.svg', 'icon' );
				}
			}

			@include _breakpoint--sm-plus {
				margin-top: _double-space() * 2;

				h2 {
					margin-bottom: _space()
				}
			}

			.columns__actions {
				margin-top: _space() * .5;
			}
		}

		&--customizable {
			@include _breakpoint--sm {
				padding: {
					top: _space();
					bottom: _space();
				}
				background-size: 100% 100%;
				background-position: 0 0;
			}

			@include _breakpoint--sm-plus {
				margin-top: _double-space() * 2;
			}

			@include _breakpoint--md {
				padding: 0;
			}

			.product-section {
				&__content {
					@include _breakpoint--md {
						h2 {
							width: 600px;
						}

						p {
							width: 540px;
						}
					}
				}

				&__graphic {
					@include _breakpoint--md {
						padding-left: _double-space() * 2;
					}

					figure {
						margin-bottom: 0;

						@include _breakpoint--sm {
							margin-top: 75px;
						}
					}
				}
			}
		}

		&--supported {
			.product-section {
				&__content {
					p {
						@include _breakpoint--md {
							width: 550px;
						}
					}
				}
			}
		}

		&--a11y {
			background-size: 100% 97%;
			background-position: 0 0;

			.product-section {
				&__content {
					padding-top: _double-space();
					justify-content: start;

					h2 {
						@include _breakpoint--md {
							width: 540px;
						}
					}

					p {
						width: 350px;

						&:last-of-type {
							margin-bottom: _space();

							@include _breakpoint--sm-plus {
								margin-bottom: _double-space();
							}
						}
					}
				}

				&__graphic {
					figure {
						margin-top: _double-space();
						margin-bottom: 0;
					}
				}
			}
		}

		&--truly-global {
			margin-top: _space() * 3;

			.product-section {
				&__content {
					@include _breakpoint--sm-plus {
						p {
							width: 415px;
						}
					}

					@include _breakpoint--md {
						width: 800px;
						padding-left: 200px;
					}
				}
			}

			&.product-section--graphic-right {
				.product-section__graphic {
					@include _breakpoint--sm {
						padding-left: _double-space();
					}
				}
			}
		}

		&--commits {
			@include _breakpoint--sm-plus {
				margin-top: _double-space() * 2;
			}

			.product-section {
				&__graphic {

					/* IE 11 fix. */
					@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
						min-height: 270px;
					}

					img {
						width: 100%;
						height: auto;
					}
				}
			}
		}

		&--community {
			margin-top: 0;

			.panes-grid {
				&__introduce {
					@include _breakpoint--sm {
						padding-top: _half-space();
						padding-bottom: _space();
					}
				}

				&__item {
					&--stackoverflow {
						background-image: _image( 'blue-head-cog.svg', 'icon' );
					}

					&--plugins {
						background-image: _image( 'blue-head-box.svg', 'icon' );
					}

					&--commits {
						background-image: _image( 'blue-head-book.svg', 'icon' );
					}

					&--downloads {
						background-image: _image( 'blue-head-head.svg', 'icon' );
					}
				}
			}
		}
	}
}
