.tingle-modal {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: _get-zindex( on-cover );
	display: flex;
	visibility: hidden;
	flex-direction: column;
	justify-content: center;
	margin: 0 auto;
	align-items: center;
	overflow: hidden;
	background: rgba(0, 0, 0, .8);
	opacity: 0;
	cursor: pointer;
	@include _transition( all, 300ms );

	* {
		box-sizing: border-box;
	}

	h2 {
		text-align: center;
		margin-bottom: _space();
	}

	&--letters {
		.tingle-modal-box {
			padding: _double-space() _double-space() * 2;
		}
	}

	&__loader {
		position: absolute;
		left: 0;
		top: 0;
		background: _color( white );
		width: 100%;
		height: 100%;
		text-align: center;
		opacity: 0;
		z-index: _get-zindex( under );
		@include _transition( all, 700ms );
		@include _radius;

		&--active {
			z-index: _get-zindex( default );
			opacity: 1;

			.ckloader {
				&__spinner {
					&-text {
						&--error {
							display: none
						}
					}
				}
			}
		}

		&--error {
			.ckloader {
				@include _zindex( air );
				opacity: 1;

				&__spinner {
					display: block;

					.circular {
						animation: none;
					}

					.path {
						animation: none;
						stroke: _color( danger );
						stroke-dasharray: 16px;
						stroke-dashoffset: 0;
					}

					&-text {
						display: none;

						&--error {
							color: _color( danger );
							display: block;
							position: relative;
							width: 500px;
							text-align: center;
						}
					}
				}
			}
		}

		.ckloader {
			@include _radius;

			&__inner {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate( -50%, -50% );
				padding-top: 40px;
			}

			&__spinner {
				position: relative;
				width: 90px;
				top: 50%;
				left: 50%;
				transform: translate( -50%, -50% );
			}
		}
	}

	&__close {
		position: absolute;
		top: 1rem;
		right: 1rem;
		padding: 0;
		width: 30px;
		height: 30px;
		border: none;
		background-color: transparent;
		color: _text-color( hint );
		font-size: 32px;
		line-height: 30px;
		cursor: pointer;
		z-index: 1000;
		@include _transition( color );

		&:hover {
			color: _text-color( secondary );
		}
	}

	&__closeLabel {
		display: none;
	}

	&-box {
		position: absolute;
		top: 50%;
		left: 50%;
		flex-shrink: 0;
		margin-top: auto;
		margin-bottom: auto;
		border-radius: 4px;
		background: #fff;
		opacity: 1;
		cursor: auto;
		transition: transform .3s cubic-bezier(.175, .885, .32, 1.275);
		transform: translate( -50%, -50% );
		padding: 4rem;
		max-width: 1000px;
		@include _radius;

		&__content {
			display: flex;
			flex-direction: column;
			max-width: 1000px;
			margin: 0 auto;

			img {
				width: 100%;
			}

			&--mobile {
				text-align: center;
			}
		}

		&__inputs {
			&-row {
				display: flex;
				flex-direction: column;

				label {
					font-weight: normal;
				}

				input {
					&[type="text"] {
						margin-bottom: 20px;
						padding: 5px;
					}
				}
			}
		}

		&--noOverlayClose {
			cursor: default;
		}

		&__footer {
			text-align: center;

			&--sticky {
				position: fixed;
				bottom: -200px; /* TODO : find a better way */
				z-index: 10001;
				opacity: 1;
				transition: bottom .3s ease-in-out .3s;
			}

			button {
				@include _radius;
			}
		}

		// Newsletter form overrides.
		.module-subscribe__actions {
			box-shadow: none;
			margin-top: 0;

			.form-control {
				border: 1px solid $border-color;
			}
		}

		.mc-response {
			box-shadow: none;
			padding: 0;
			margin-bottom: 0;

			&--success {
				font-size: _font-scale( big );
				color: _color( success );
				text-align: center;
			}
		}
	}

	&__header {
		position: relative;
		padding: _double-space() 0 _space();

		h2 {
			margin-bottom: 0;
			color: _text-color( secondary );
		}
	}

	&--info {
		.tingle-modal-box {
			padding: 0;

			&__content-inner {
				padding: _space();

				@include _breakpoint--md {
					padding: 0 _double-space() _space();
				}
			}

			&__footer {
				font-size: _font-scale( medium );
				color: _text-color( secondary );
			}
		}
	}

	&--with-footer {
		.tingle-modal-box__content-inner {
			padding-bottom: 0;
		}
	}
}

  .tingle-modal--confirm .tingle-modal-box {
		text-align: center;
  }

  .tingle-modal
  .tingle-modal--noClose .tingle-modal__close {
		display: none;
  }


  .tingle-enabled {
	overflow: hidden;
	height: 100%;
  }

  .tingle-modal--visible .tingle-modal-box__footer {
	bottom: 0;
  }

  .tingle-enabled .tingle-content-wrapper {
	filter: blur(15px);
  }

  .tingle-modal--visible {
	visibility: visible;
	opacity: 1;
  }

  .tingle-modal--overflow {
	overflow-y: scroll;
	padding-top: 8vh;
  }

  @media ( max-width: 540px ) {

	.tingle-modal {
		display: block;
		width: 100%;

		h2 {
			font-size: 2.5rem;
			margin-bottom: 0;
		}

		&-box {
			width: 90%;
		}

		&--noClose {
			top: 0;
		}

		&--overflow {
			padding: 0;
		}

		.module-subscribe__actions {
			input {
				border: 1px solid _gray-hex();
			}
		}
	}
}
