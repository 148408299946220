.module-cta {
	text-align: center;

	@include _breakpoint--mo {
		padding: {
			top: _space();
			bottom: _space();
		}
	}

	&__actions {
		margin-top: _space();
	}

	&--blue {
		padding: {
			top: _double-space();
			bottom: _double-space();
		}
		background-color: _color( bg );

		h2 {
			width: 1100px;
			max-width: 100%;
			margin: {
				left: auto;
				right: auto;
			}
		}
	}

	&--gray-narrow,
	&--gray {
		padding: {
			top: _double-space();
			bottom: _double-space();
		}
		background-color: _color( 'bg-cta-gray' );

		&,
		&:last-of-type {
			margin-bottom: 0;
		}

		h2 {
			max-width: 100%;
			margin: {
				left: auto;
				right: auto;
			}

			@include _breakpoint--md {
				font-size: 4.4rem;
			}
		}
	}

	&--gray {
		h2 {
			width: 870px;
		}
	}

	&--gray-narrow {
		h2 {
			width: 660px;
		}
	}

	&--2 {
		.btn {
			margin-right: _space();

			&:last-of-type {
				margin-right: 0;
			}
		}
	}

	p {
		width: 740px;
		max-width: 100%;
		margin: 0 auto;

		&:first-of-type {
			margin-top: _space();
		}

		&:last-of-type {
			margin-bottom: _space();
		}
	}

	&--home {
		margin-top: _space();
		background-color: _color( 'bg-cta' );
		padding: {
			top: _double-space();
			bottom: _double-space();
		}

		@include _breakpoint--md {
			margin-top: _space() * 3;
		}

		h2 {
			font-weight: 800;
		}

		p {
			width: 800px;
			margin-top: 0;
		}

		.btn {
			background-color: _color( 'pretty-link' );
			border-color: _color( 'pretty-link' );

			&:hover {
				border-color: darken( _color( 'pretty-link' ), 5 );
				background-color: darken( _color( 'pretty-link' ), 5 );
			}
		}
	}
}
