$shadow-colors: (
	'shadow': rgba( 0, 0, 0, .22 ),
	'shadow-light': rgba(0, 0, 0, 0.05),
	'shadow-dark': rgba(0, 0, 0, 0.1)
);

/**
 * Default box shadows.
 *
 * @type Map
 * @access public
 */
$shadows: (
	tiny: ( 0 0 2px map-get( $shadow-colors, 'shadow-light' ), 0 0 1px map-get( $shadow-colors, 'shadow-dark' ) ),
	small: ( 0 1px 3px map-get( $shadow-colors, 'shadow-light' ), 0 1px 2px map-get( $shadow-colors, 'shadow-dark' ) ),
	default: ( 0 10px 20px map-get( $shadow-colors, 'shadow' ), 0 6px 6px map-get( $shadow-colors, 'shadow-dark' ) ),
	around: ( 0 0 20px map-get( $shadow-colors, 'shadow' ), 0 6px 6px map-get( $shadow-colors, 'shadow-dark' ) ),
	around-light: ( 0 0 25px map-get( $shadow-colors, 'shadow-light' ), 0 8px 8px map-get( $shadow-colors, 'shadow' ) ),
	raise: ( 0 15px 10px -10px map-get( $shadow-colors, 'shadow-dark' ), 0 1px 4px  map-get( $shadow-colors, 'shadow-light' ) ),
	raise-big: ( 0 25px 15px -10px rgba(0, 0, 0, 0.5), 0 2px 4px rgba(0, 0, 0, 0.3))
);

/**
 * Default box shadows transition.
 *
 * @type variable
 * @access public
 */
$shadow-transition: box-shadow 0.3s cubic-bezier( .25, .8, .25, 1);

/**
 * Implement box shadow
 *
 * @access public
 * @param {String} $type [ default ] - Type of box shadow (small || default).
 * @param {Boolean} $transition [ false ] - Determinated if box shadow should be animated.
 */
@mixin _shadow( $type: default, $transition: false ) {
	box-shadow: map-get( $shadows, $type );

	@if ( $transition == true ) {
		transition: $shadow-transition;
	}
}

/**
 * Implement text shadow
 *
 * @access public
 * @param {String} $type [ default ] - Type of box shadow (big || default).
 */
@mixin _text-shadow( $type: default ) {
	@if ( $type == default ) {
		text-shadow: 0 1px 2px rgba( 0, 0, 0, .22 );
	}
	@else {
		text-shadow: 1px 2px 4px rgba( 0, 0, 0, .5 );
	}
}
