#presets_table {
  display: none;
}
#presets_table, #ckeditor_versions_compare, .simplemodal-wrap {
  position: relative;
}
.compare-presets {
  cursor: pointer;
}

/* Overwrite for modal dialog */
#presets_table .comparision__table th,
#presets_table .comparision__table td {
  min-width: 70px;
}
#presets_table .comparision__table td, #presets_table .comparision__table th {
  padding: 8px 12px;
}

#simplemodal-overlay {
  background-color: #000;
  width: 100%;
  height: 100%;
}

#simplemodal-container {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  max-width: 960px;
  background: #fff;
  padding: 30px 30px 40px;

  @include _breakpoint--md {
    min-height: 498px;
  }

  .simplemodal-data {
    font-size: 1.4rem;
    overflow: hidden;

    &.addon_download {
      h3 {
        margin: 0;
        padding: 10px 0;
      }

      li {
        line-height: 2em;
      }
    }
  }

  a.modalCloseImg {
    background: url("https://c.cksource.com/a/5/img/sprites.png") no-repeat -142px -1640px;
    width: 13px;
    height: 13px;
    display: inline;
    z-index: 3200;
    position: absolute;
    top: 14px;
    right: 19px;
    cursor: pointer;
  }
}
