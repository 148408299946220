.site-content--purchase-legal {
	.content {
		width: 850px;
		max-width: 100%;
		margin: 0 auto;

		@include _breakpoint--sm {
			margin-top: _double-space();
		}

		& > #annex,
		& > .annex,
		& > ol {
			list-style-type: none;
			counter-reset: listNumbering;
			padding: 0;

			> li:not(.sub) {
				counter-increment: listNumbering;

				&:before {
					content: counter(listNumbering)'.';
					font: {
						size: 2.2rem;
						weight: 600;
					}
				}

				h3 {
					display: inline;
				}

				ul {
					list-style: lower-latin;
					margin-bottom: $pretty-line-height--rem;
				}
			}

			li.sub {
				list-style: none;
				position: relative;

				span.sub {
					position: absolute;
					left: -$main-gutter;
				}
			}

			ul.sub-2 {
				list-style: none;

				li.sub-2 {
					position: relative;
					list-style: none;
				}
			}
		}

		.support,
		.support > p:first-child {
			margin-top: 0;
		}

		table {
			border: 0;

			th, td {
				padding: 0.5rem;
			}
		}

		.with-padding {
			padding-left: 2.8rem;
		}
	}
}
